import { Component, Input } from '@angular/core';
import { Student } from '@codecraft-works/data-models';
import { CardComponent } from '../card.component';

@Component({
  selector: 'app-student-card',
  templateUrl: './student-card.component.html',
  styleUrls: ['./student-card.component.css'],
})
export class StudentCardComponent extends CardComponent {
  @Input()
  student: Student;

  constructor() {
    super();
  }
}
