import { Component, Input } from '@angular/core';
import { Educator } from '@codecraft-works/data-models';
import { CardComponent } from '../card.component';

@Component({
  selector: 'app-educator-card',
  templateUrl: './educator-card.component.html',
  styleUrls: ['./educator-card.component.css'],
})
export class EducatorCardComponent extends CardComponent {
  @Input()
  educator: Educator;

  constructor() {
    super();
  }
}
