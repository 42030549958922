import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Membership, User } from '@codecraft-works/data-models';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { MembershipService } from '../membership/membership.service';

@Injectable({
  providedIn: 'root',
})
export class MembershipGuard {
  membership$: Observable<Membership>;
  user$: Observable<User>;
  constructor(
    private router: Router,
    private membershipService: MembershipService,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    this.user$ = this.authService.getUser();
    this.membership$ = this.membershipService.getMembership(
      route.params.membershipId
    );

    const data$ = combineLatest([this.user$, this.membership$]);

    return data$
      .pipe(
        map(([user, membership]) => {
          const fetchedMembership = membership ? true : false;
          if (!fetchedMembership) {
            this.router.navigate(['/404']);
            return false;
          } else {
            // If Admin or editor
            if (user.roles && (user.roles.admin || user.roles.editor)) {
              return true;
            }
            // If user is the owner of the membership (since the rules allow assigned
            // users to read as well, but we don't really want them to see this page )
            if (user.uid === membership.uid) {
              return true;
            }
            this.router.navigate(['/permission-denied']);
            return false;
          }
        })
      )
      .pipe(
        // If firestore rules aren't followed
        catchError(() => {
          this.router.navigate(['/permission-denied']);
          return of(false);
        })
      );
  }
}
