import firebase from 'firebase/compat/app';

export type TechInfo = Pick<
  Technology,
  'id' | 'name' | 'description' | 'pictureUrl'
>;

export class Technology {
  /**
   * Timestamp of initial document creation
   */
  created: firebase.firestore.Timestamp;

  id: string;

  /**
   * Timestamp of the last time the document was updated
   */
  modified: firebase.firestore.Timestamp;

  description?: string;
  name: string;
  pictureUrl?: string;
  public: boolean;
  uid: string;
  coursesUsingTech?: string[];
}
