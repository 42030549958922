import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-user-role-cell',
  templateUrl: './user-role-cell.component.html',
  styleUrls: ['./user-role-cell.component.css']
})
export class UserRoleCellComponent implements AgRendererComponent {
  roles: string[];

  constructor() {}

  refresh(params: ICellRendererParams): boolean {
    this.roles = params.data.roles;
    return true;
  }

  agInit(params: ICellRendererParams): void {
    this.roles = params.data.roles;
  }
}
