import { Component } from '@angular/core';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-program-edit-button-cell',
  templateUrl: './program-edit-button-cell.component.html',
  styleUrls: ['./program-edit-button-cell.component.css'],
})
export class ProgramEditButtonCellComponent implements AgRendererComponent {
  programId: any;

  faEdit = faEdit;
  constructor() {}
  refresh(params: ICellRendererParams): boolean {
    this.programId = params.data.id;
    return true;
  }
  agInit(params: ICellRendererParams): void {
    this.programId = params.data.id;
  }
}
