<p *ngIf="alternateText; else elseBlock">
  <span class="badge badge-pill badge-light ">{{ alternateText }}</span>
</p>
<ng-template #elseBlock>
  <div
    *ngIf="
      progressPercentage >= 0 && completedSteps >= 0 && totalSteps > 0 && title
    "
    class="progress"
  >
    <div
      class="progress-bar progress-bar-success"
      role="progressbar"
      [attr.aria-valuenow]="progressPercentage"
      [attr.aria-label]="title"
      aria-valuemin="0"
      aria-valuemax="100"
      [style.width]="progressPercentage + '%'"
    >
      <span class="progress-value"
        >{{ completedSteps }}/{{ totalSteps }} {{ type }} completed -
        {{ progressPercentage | number: '1.0-0' }}% &nbsp;
        <fa-icon
          *ngIf="completedSteps / totalSteps === 1"
          [icon]="faCheck"
          class="fa-lg"
        ></fa-icon
      ></span>
    </div>
  </div>
</ng-template>
