<ng-container *ngIf="membership$ | async as membership">
  <codecraft-hero-block>
    <h1 class="text-primary">
      <fa-icon id="membership-icon" [icon]="faIdBadge"> </fa-icon
      >&nbsp;Membership for
      {{ membership.programName }}
    </h1>
  </codecraft-hero-block>

  <div class="container-fluid mb-3 bg-white">
    <div class="row no-gutters px-2">
      <div
        *ngIf="membership.membershipType === 'recurring'"
        class="col-12 my-auto"
      >
        <button
          (click)="openModal(cancelMembership)"
          class="btn btn-danger float-right"
        >
          Cancel Membership
        </button>
      </div>
    </div>

    <div class="row align-items-center no-gutters p-2 odd first">
      <div class="col-6">Status</div>
      <div class="col-6">
        {{ membership.stripeSubscriptionStatus || 'Active' | titlecase }}
      </div>
    </div>

    <div class="row align-items-center no-gutters p-2 even">
      <div class="col-6">Assigner/Purchaser</div>
      <div class="col-6">
        <strong>{{ membership.customerUser.displayName }}</strong
        ><br />
        {{ membership.customerUser.email }}
      </div>
    </div>

    <div class="row align-items-center no-gutters p-2 odd">
      <div class="col-6">Assignee/Student</div>
      <div class="col-5">
        <ng-container *ngIf="membership.memberUser; else notAssigned">
          <strong>{{ membership.memberUser.displayName }}</strong
          ><br />
          <span class="text-wrap">{{ membership.memberUser.email }}</span>
        </ng-container>
        <ng-template #notAssigned>
          <button
            [routerLink]="['./']"
            fragment="assign"
            class="btn btn-primary"
          >
            Assign Membership
          </button>
        </ng-template>
      </div>
      <div *ngIf="membership.memberUser" class="col-1">
        <a
          routerLink="./"
          fragment="assign"
          role="button"
          class="btn btn-primary"
        >
          Reassign
        </a>
      </div>
    </div>

    <div class="row align-items-center no-gutters p-2 even">
      <div class="col-6">Payment Type</div>
      <div class="col-6">
        {{
          membership.membershipType === 'one_time'
            ? 'One Time'
            : (membership.membershipType | titlecase)
        }}
      </div>
    </div>

    <ng-container *ngIf="membership.membershipType === 'recurring'">
      <div class="row align-items-center no-gutters p-2 odd">
        <div class="col-6">Recurring Cost</div>
        <div class="col-6">{{ membership.cost / 100 | currency }}</div>
      </div>

      <div class="row align-items-center no-gutters p-2 even">
        <div class="col-6">Next Payment Date</div>
        <div class="col-6">
          {{ membership.currentPeriodEndDate * 1000 | date }}
        </div>
      </div>
    </ng-container>

    <div class="row align-items-center no-gutters p-2 odd">
      <div class="col-6">Membership Code</div>
      <div class="col-6">
        {{ membership.membershipRedemptionCode }}
      </div>
    </div>

    <div class="row no-gutters p-2 even">
      <div class="col-12">
        <a
          class="btn btn-primary text-center"
          role="button"
          [routerLink]="'/user'"
          ><fa-icon id="back-icon" [icon]="faChevronLeft"> </fa-icon> &nbsp;
          Return to Memberships</a
        >
      </div>
    </div>
  </div>
</ng-container>

<ng-template #assign>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Assign Membership</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeAllModals()"
    >
      <span aria-hidden="true" class="text-primary">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Does the user you want to assign this membership to already have an account
    on Codecraft Works?
  </div>
  <div class="modal-footer">
    <button
      data-test="membershipAssignYesButton"
      type="button"
      class="btn btn-primary pull-left"
      (click)="openModal(assignExisting, 'assignExisting')"
    >
      Yes
    </button>
    <button
      type="button"
      class="btn btn-primary pull-right"
      (click)="openModal(newUser, 'newUser')"
    >
      No
    </button>
  </div>
</ng-template>

<ng-template #assignExisting>
  <div data-test="assignExistingModalHeader" class="modal-header">
    <h4 class="modal-title pull-left">Assign to Existing User</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeAllModals()"
    >
      <span aria-hidden="true" class="text-primary">&times;</span>
    </button>
  </div>
  <form
    *ngIf="!loading; else loadingSpinner"
    [formGroup]="membershipAssignmentForm"
    (ngSubmit)="assignExistingStudent(); loading = true"
  >
    <div class="modal-body">
      <ng-container *ngIf="membership$ | async as membership">
        <app-membership-assignment-form
          formControlName="membershipAssignmentFormGroup"
          *ngIf="user$ | async as user"
          [user]="user"
          [membership]="membership"
        >
        </app-membership-assignment-form>
      </ng-container>
      <alert *ngIf="assignFail" type="danger" class="m-0">
        Something went wrong assigning your membership</alert
      >
    </div>
    <div class="modal-footer">
      <button
        type="submit"
        [disabled]="
          !membershipAssignmentForm.get('membershipAssignmentFormGroup')
            .value ||
          !membershipAssignmentForm.get('membershipAssignmentFormGroup').value
            .selectedMembers.uid
        "
        class="btn btn-primary"
      >
        Assign this user
      </button>
      <button type="button" class="btn btn-primary" (click)="closeAllModals()">
        Close
      </button>
    </div>
  </form>
</ng-template>

<ng-template #newUser>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Create Student Account</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeAllModals()"
    >
      <span aria-hidden="true" class="text-primary">&times;</span>
    </button>
  </div>
  <form
    *ngIf="!loading; else loadingSpinner"
    [formGroup]="addStudentForm"
    (ngSubmit)="addNewStudent(); loading = true"
  >
    <div class="modal-body">
      <app-membership-add-student-form
        *ngIf="user$ | async as user"
        formControlName="addStudentFormGroup"
        [user]="user"
        [isMultiple]="false"
        [isModal]="true"
      ></app-membership-add-student-form>
      <alert
        *ngIf="newStudentSuccess"
        type="info"
        class="m-0"
        id="alert-new-student"
        data-test="add-student-form-alert-success"
      >
        {{ newStudentReturnMessage }}
      </alert>

      <alert
        *ngIf="newStudentSuccess === false"
        type="danger"
        class="m-0"
        id="alert-new-student"
        data-test="add-student-form-alert-error"
      >
        {{ newStudentReturnMessage }}
      </alert>
    </div>
    <div class="modal-footer">
      <button
        data-test="add-student-form-submit-button"
        type="submit"
        [disabled]="addStudentForm.invalid"
        class="btn btn-primary"
      >
        Create Student Account
      </button>
    </div>
  </form>
</ng-template>

<ng-template #membershipAssigned>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Membership Assigned</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeAllModals()"
    >
      <span aria-hidden="true" class="text-primary">&times;</span>
    </button>
  </div>
  <div class="modal-body mx-auto">
    <fa-icon [icon]="faCheckCircle" class="fa-10x"></fa-icon>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" (click)="closeAllModals()">
      Close
    </button>
  </div>
</ng-template>

<ng-template #cancelMembership>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Cancel Membership</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true" class="text-primary">&times;</span>
    </button>
  </div>
  <div class="modal-body mx-auto">
    In order to cancel your membership, please contact
    <a href="mailto:support@codecraftworks.com"> support@codecraftworks.com </a>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" (click)="closeModal()">
      Close
    </button>
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <codecraft-loading-spinner></codecraft-loading-spinner>
</ng-template>
