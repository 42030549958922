import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { MembershipCodeLog } from '@codecraft-works/data-models';
import firebase from 'firebase/compat/app';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MembershipCodeService {
  constructor(private firebaseDatabase: AngularFirestore) {}

  /* Not implemented yet */
  /* eslint-disable no-unused-vars,@typescript-eslint/no-unused-vars */
  private getLogs(): Observable<MembershipCodeLog[]> {
    const snapshot: AngularFirestoreCollection<MembershipCodeLog> =
      this.firebaseDatabase.collection<MembershipCodeLog>(
        'membership-code-redemption',
        (ref) => ref.orderBy('timestamp')
      );

    return snapshot.snapshotChanges().pipe(
      map((item) => {
        const membershipCodeLogs: MembershipCodeLog[] = [];
        item.map((a) => {
          const data = a.payload.doc.data() as MembershipCodeLog;
          membershipCodeLogs.push(data as MembershipCodeLog);
        });
        return membershipCodeLogs;
      })
    );
  }

  /**
   * Returns observable of log via document id
   * @param id
   */
  /* Not implemented yet */
  /* eslint-disable no-unused-vars,@typescript-eslint/no-unused-vars */
  private getLog(id: string): Observable<MembershipCodeLog> {
    return this.firebaseDatabase
      .collection('membership-code-redemption')
      .doc<MembershipCodeLog>(id)
      .valueChanges();
  }

  /**
   *
   * @param ipAddress
   */
  /* Not implemented yet */
  /* eslint-disable no-unused-vars,@typescript-eslint/no-unused-vars */
  private getLogByIp(ipAddress: string) {
    return this.firebaseDatabase
      .collection('membership-code-redemption', (ref) =>
        ref.where('ipAddress', '==', ipAddress)
      )
      .valueChanges();
  }

  /**
   *
   * @param customerId
   */
  /* Not implemented yet */
  /* eslint-disable no-unused-vars,@typescript-eslint/no-unused-vars */
  private getLogByCustomerId(customerId: string) {
    return this.firebaseDatabase
      .collection('membership-code-redemption', (ref) =>
        ref.where('customerId', '==', customerId)
      )
      .valueChanges();
  }

  /**
   *
   * @param memberUserId
   */
  /* Not implemented yet */
  /* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
  private getLogsByUserId(memberUserId: string) {
    return this.firebaseDatabase
      .collection('membership-code-redemption', (ref) =>
        ref.where('memberUserId', '==', memberUserId)
      )
      .valueChanges();
  }

  /**
   *
   * @param membership
   * @param redemptionCode
   * @param memberUserId
   * @param customerId
   * @param ipAddress
   */
  public createLog(
    membership: string,
    redemptionCode: string,
    memberUserId: string,
    customerId: string,
    ipAddress: string
  ) {
    const id = this.firebaseDatabase.createId();
    this.firebaseDatabase.collection('membership-code-redemption').add({
      id,
      membership,
      redemptionCode,
      memberUserId,
      customerId,
      ipAddress,
      timestamp: firebase.firestore.Timestamp.now(),
    });
  }

  /**
   *
   * @param id
   */
  private deleteLog(id: string) {
    return from(
      this.firebaseDatabase
        .collection('membership-code-redemption')
        .doc(id)
        .delete()
    );
  }
}
