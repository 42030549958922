<card
  [class]="'program-level-' + program.level"
  *ngIf="program || (program$ | async) as program"
  [title]="program.name"
  [showTitle]="false"
  [detailUrl]="['/programs/' + program.id]"
  [editUrl]="['/admin/program/' + program.id]"
  [user]="user"
  [isGrid]="isGrid"
  [showDetailButton]="false"
  [imageUrl]="program.pictureUrl"
  [noPadding]="true"
  [noHoverEffect]="true"
>
  <div class="live" *ngIf="program.live.onAir">
    <span class="badge badge-info badge-pill">
      <fa-icon [icon]="faBroadcastTower"></fa-icon>
      Now Live!
    </span>
  </div>

  <div header>
    <a [routerLink]="['/programs/' + program.id]"
      ><img
        *ngIf="program && program.level"
        class="level-img-overlay"
        [src]="'/assets/img/CC_L' + program.level + 'Gear.svg'"
        [alt]="'Level ' + program.level"
        data-test="program-card-level-img"
      />
      <img
        *ngIf="
          program &&
            program.seats - program.seatMemberships <= 3 &&
            program.seats - program.seatMemberships > 0;
          else soldOut
        "
        class="sold-out-img-overlay"
        src="/assets/img/almost_gone_banner.png"
        alt="Almost gone"
        data-test="program-card-almost-gone-img"
      />
      <ng-template #soldOut>
        <img
          *ngIf="program && program.seats - program.seatMemberships <= 0"
          class="sold-out-img-overlay"
          src="/assets/img/sold_out_banner.png"
          alt="Sold out"
          data-test="program-card-sold-out-img"
        />
      </ng-template>
    </a>
  </div>

  <div footer class="card-text" *ngIf="program">
    <p class="h6">
      <a
        [routerLink]="['/programs/' + program.id]"
        [class]="'program-level-' + program.level"
        >{{ program.name }}</a
      >
    </p>
    <p class="mb-1">
      <span *ngIf="program.level">Level {{ program.level }}</span>
      <span *ngIf="program.level && program.ageGroup">, </span>
      <span *ngIf="program.ageGroup">Ages {{ program.ageGroup }}</span>
      <span
        *ngIf="
          (program.ageGroup || program.level) &&
          program.timeAndDate &&
          program.timeAndDate.startDateTime
        "
        >,
      </span>
      <span *ngIf="program.timeAndDate && program.timeAndDate.startDateTime"
        >Start Date
        {{
          program.timeAndDate.startDateTime.toDate() | date: 'shortDate'
        }}</span
      >
    </p>
    <app-program-add-cart-button
      *ngIf="!hideAddToCart"
      [id]="'addCart-' + program.id"
      [user]="user"
      [program]="program"
    ></app-program-add-cart-button>
  </div>

  <div
    *ngIf="user && (user.roles.admin || user.roles.editor)"
    button-group
    class="btn-group"
  >
    <app-program-copy-button
      class="btn btn-secondary"
      [user]="user"
      [program]="program"
    ></app-program-copy-button>
  </div>
</card>
