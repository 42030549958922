import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ShoppingCartService } from '@app/core/shopping-cart/shopping-cart.service';
import { Program, User } from '@codecraft-works/data-models';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-program-add-cart-button',
  templateUrl: './program-add-cart-button.component.html',
  styleUrls: ['./program-add-cart-button.component.css'],
})
export class ProgramAddCartButtonComponent implements OnInit, OnDestroy {
  @Input() public program: Program;
  @Input() user: User;
  @Input() mobile = false;
  @Input() minimalView = false;
  programQuantity: number;
  maxSeats: number;

  modalRef: BsModalRef;

  @ViewChild('errorModal', { static: true }) errorModal;
  @ViewChild('successModal', { static: true }) successModal;

  handleCartItem$: Observable<boolean>;
  modalSubscription: Subscription;

  constructor(
    private shoppingCartService: ShoppingCartService,
    private modalService: BsModalService
  ) {}

  openModal(template: TemplateRef<any>, program?) {
    if (program) {
      this.modalSubscription = this.modalService.onHidden.subscribe(() => {
        document.getElementById(`add-to-cart-${program.id}`).focus();
      });
    }
    this.modalRef = this.modalService.show(template, {
      class: 'modal-md modal-dialog modal-dialog-centered',
      animated: true,
    });
  }

  closeModalAndReturnFocus() {
    this.modalRef.hide();
    document.getElementById(`add-to-cart-${this.program.id}`).focus();
  }

  ngOnInit() {
    this.maxSeats = this.program.seats;
    this.programQuantity = 1;
  }

  addProgramToCart(quantity) {
    quantity = parseInt(quantity, 10); // Grabbing the value directly from the form is passing a string instead os a number
    this.handleCartItem$ = this.shoppingCartService
      .getShoppingCartItems(this.user.uid)
      .pipe(
        switchMap((cart) => {
          return this.shoppingCartService.handleCartItem(
            this.user,
            this.program,
            quantity,
            cart
          );
        })
      );

    this.handleCartItem$.pipe(take(1)).subscribe((handleCartItemResult) => {
      if (handleCartItemResult) {
        this.openModal(this.successModal, this.program);
      } else {
        this.openModal(this.errorModal, this.program);
      }
    });
  }

  switchToClose() {
    document.getElementById('closeAddedToCartModalButton').focus();
  }

  switchToGoToCart() {
    document.getElementById('goToCartButton').focus();
  }

  ngOnDestroy() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }
}
