import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { UserService } from '@app/core/user/user.service';
import { Membership, User } from '@codecraft-works/data-models';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { ClipboardService } from 'ngx-clipboard';
import { combineLatest, Observable } from 'rxjs';
import { delay, filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-membership-assignment-form',
  templateUrl: './membership-assignment-form.component.html',
  styleUrls: ['./membership-assignment-form.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MembershipAssignmentFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MembershipAssignmentFormComponent),
      multi: true,
    },
  ],
})
export class MembershipAssignmentFormComponent
  implements OnInit, ControlValueAccessor, Validator
{
  @Input()
  user: User;

  @Input()
  membership: Membership;

  public membershipAssignmentFormGroup: FormGroup;
  public selectedMembers: FormControl = new FormControl(null);

  loadingComplete: boolean;
  users$: Observable<User[]>;
  dataSource$: Observable<User[]>;
  noResult: boolean;
  userSelected: any;
  selectionMade: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private clipboardService: ClipboardService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.users$ = this.userService.getUsers();

    this.dataSource$ = combineLatest([
      this.selectedMembers.valueChanges.pipe(
        filter((value) => {
          return typeof value === 'string';
        })
      ),
      this.users$,
    ]).pipe(
      map(([token, users]) => {
        const query = new RegExp(token, 'i');
        return users.filter((user: User) => {
          return query.test(user.displayName + user.userName + user.email);
        });
      })
    );

    this.membershipAssignmentFormGroup = this.formBuilder.group({
      selectedMembers: this.selectedMembers,
    });

    this.loadingComplete = true;
  }

  copy(text: string) {
    this.clipboardService.copyFromContent(text);
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val) {
      this.membershipAssignmentFormGroup.setValue(val, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.membershipAssignmentFormGroup.valueChanges
      .pipe(delay(0))
      .subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled
      ? this.membershipAssignmentFormGroup.disable()
      : this.membershipAssignmentFormGroup.enable();
  }

  validate(): ValidationErrors | null {
    return this.userSelected
      ? null
      : {
          invalidForm: {
            valid: false,
            message: 'Membership assignment form invalid',
          },
        };
  }

  /* Used in template */
  /* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
  onUserSelect(event: TypeaheadMatch): void {
    this.userSelected = event.item as User;
    this.selectedMembers.setValue(this.userSelected);
    this.selectionMade = true;
  }

  chooseAnother(): void {
    this.selectionMade = false;
    this.selectedMembers.setValue('');
  }
}
