import firebase from 'firebase/compat/app';
import { UserInfo } from '../user/user.model';

type StudentUserInfo = Omit<UserInfo, 'email'>;

export class K12Student {
  id: string;
  created: firebase.firestore.Timestamp;
  modified: firebase.firestore.Timestamp;
  uid: string;
  userName: string;
  password: string;
  entityType: 'program' | 'classroom';
  entityId: string;
  user?: StudentUserInfo;
  gradeLevel:
    | 'K'
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10'
    | '11'
    | '12';
  enrolledPrograms?: string[];
}
