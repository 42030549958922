import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { User } from '@codecraft-works/data-models';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css'],
})
export class CardComponent {
  /**
   * Title of the card
   */
  @Input() title: string;

  @Input() icon: FaIconComponent;

  /**
   * Sub title of the card
   */
  @Input() subTitle: string;

  /**
   * Show title of the card
   */
  @Input() showTitle = true;

  @Input() noHoverEffect: boolean;

  /**
   * Card image URL
   */
  @Input() imageUrl: string;

  /**
   * Card image alt text
   */
  @Input() imageAlt: string;

  /**
   * Card detail URL
   */
  @Input() detailUrl: string | any[];

  /**
   * Card detail button text
   */
  @Input() detailButtonText: string;

  /**
   * Card detail button boolean
   */
  @Input() showDetailButton = true;

  /**
   * Current user
   */
  @Input() user: User;

  /**
   * Card edit url
   */
  @Input() editUrl: string | any[];

  /**
   * Additional class(es) to add to footer
   */
  @Input() headerClass: string;

  /**
   * Additional class(es) to add to footer
   */
  @Input() footerClass: string;

  /**
   * Remove padding from the card
   */
  @Input() noPadding: boolean;

  /**
   * Remove rounded and shadow from the card
   */
  @Input() noGutters: boolean;

  @ViewChild('header', { static: true }) headerElement: ElementRef;

  @ViewChild('footer', { static: true }) footerElement: ElementRef;

  @Input()
  isGrid: boolean;

  hasContent(element: ElementRef) {
    const detailContentProjection: NodeList = (
      element.nativeElement as HTMLElement
    ).childNodes;
    for (let x = 0; x < detailContentProjection.length; x++) {
      if (detailContentProjection.item(x).nodeType !== 8) {
        return true; // nodeType == 8 means is a comment
      }
    }
  }
}
