import { Component, OnInit } from '@angular/core';
import { getRedirectResult } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/auth/auth.service';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

@Component({
  selector: 'app-login-with-email',
  templateUrl: './login-with-email.component.html',
  styleUrls: ['./login-with-email.component.css'],
})
export class LoginWithEmailComponent implements OnInit {
  email: string;
  emailSent = false;
  error: any;
  errorMessage: string;
  /**
   * Font awesome sign in icon
   */
  faSignInAlt = faSignInAlt;
  faEnvelope = faEnvelope;
  loading = false;

  constructor(
    private userService: AuthService,
    public fireAuthService: AngularFireAuth,
    private router: Router
  ) {}

  ngOnInit() {
    window.sessionStorage.setItem('loading', '1');
    const url = this.router.url;

    this.confirmSignIn(url);
  }

  async confirmSignIn(url) {
    const auth = getAuth();
    try {
      if (firebase.auth().isSignInWithEmailLink(url)) {
        const email = window.localStorage.getItem('emailForSignIn');

        // If missing email, redirect to login
        if (!email) {
          throw { code: 'emailNotSaved', message: 'No Email Found' };
        }
        if (window.sessionStorage.getItem('loading')) {
          this.showSpinner();
          await getRedirectResult(auth).then((userCredential) => {
            if (userCredential && userCredential.user) {
              this.router.navigate(['/home']);
            }
          });
        }

        // Signin user and remove the email localStorage
        await this.userService
          .loginEmailLinkService(email, url)
          .then((userCredential) => {
            if (userCredential && userCredential.user) {
              window.localStorage.removeItem('emailForSignIn');
              this.router.navigate(['/home']);
            }
          });
      }
    } catch (err) {
      this.errorHandler(err);
      return null;
    }
  }

  async sendEmailLink() {
    try {
      await firebase
        .auth()
        .sendSignInLinkToEmail(this.email, {
          url: window.location.href,
          handleCodeInApp: true,
        });
      window.localStorage.setItem('emailForSignIn', this.email);
      this.errorMessage = '';
      this.emailSent = true;
    } catch (err) {
      this.errorHandler(err);
    }
  }

  errorHandler(error) {
    this.hideSpinner();

    this.error = error;

    switch (error.code) {
      case 'auth/invalid-action-code':
        this.errorMessage =
          'The sign in link you used has expired. Please request a new sign in link by entering your email address above.';
        break;
      case 'emailNotSaved':
        this.errorMessage =
          'We did not find an email saved from earlier. Are you using an old sign in link or did you use a different computer to try and sign in? Please request a new sign in link by entering your email address above.';
        break;
      default:
        error.message
          ? (this.errorMessage = error.message)
          : (this.errorMessage = 'An error occured');
    }
  }

  showSpinner() {
    this.loading = true;
  }

  hideSpinner() {
    window.sessionStorage.removeItem('loading');
    this.loading = false;
  }
}
