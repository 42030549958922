import { Inject, Injectable } from '@angular/core';
import { StorageService } from './storage.service';

/**
 * Session storage service
 * used for persist application data in observable key value pair
 */
@Injectable()
export class SessionStorageService extends StorageService {
  /**
   * Constructor with service injection
   * @param window
   */
  constructor(@Inject('windowObject') window: Window) {
    super(window.sessionStorage);
  }
}
