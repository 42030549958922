import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { Slide } from '@codecraft-works/data-models';
import firebase from 'firebase/compat/app';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SlidesService {
  constructor(private fireDatabase: AngularFirestore) {}
  public getSlides(
    slideIDs?: string[],
    publicOnly?: boolean
  ): Observable<Slide[]> {
    if (slideIDs) {
      const slides: Slide[] = [];
      slideIDs.forEach((slideID) =>
        this.getSlide(slideID).subscribe((slide) => {
          if (publicOnly && slide.public) {
            slides.push(slide);
          } else if (!publicOnly) {
            slides.push(slide);
          }
        })
      );
      return of(slides);
    } else {
      let snapshot: AngularFirestoreCollection<Slide>;
      if (publicOnly) {
        snapshot = this.fireDatabase.collection<Slide>('slides', (ref) =>
          ref.where('public', '==', true)
        );
      } else {
        snapshot = this.fireDatabase.collection<Slide>('slides');
      }

      return snapshot.snapshotChanges().pipe(
        map((item) => {
          const slides: Slide[] = [];
          item.map((a) => {
            const data = a.payload.doc.data() as Slide;
            slides.push(data as Slide);
          });
          return slides;
        })
      );
    }
  }

  public getSlide(slideId: string): Observable<Slide> {
    const slide = this.fireDatabase
      .collection('slides')
      .doc<Slide>(slideId)
      .valueChanges();
    return slide;
  }

  private getAllSlidesCollection() {
    return this.fireDatabase.collection<Slide>('slides');
  }

  private getSlideData(slide: Partial<Slide>) {
    return this.getAllSlidesCollection().doc<Slide>(slide.id);
  }

  public async insertSlide(slide: Partial<Slide>) {
    const newSlide: Slide = {
      id: this.fireDatabase.createId(),
      uid: slide.uid,
      url: slide.url,
      name: slide.name,
      public: slide.public,
      description: slide.description || '',
      created: firebase.firestore.Timestamp.now(),
      modified: firebase.firestore.Timestamp.now(),
    };

    await this.getAllSlidesCollection().doc(newSlide.id).set(newSlide);

    return newSlide.id;
  }

  public updateSlide(slide: Partial<Slide>): Observable<void> {
    return from(
      this.fireDatabase
        .collection<Slide>('slides')
        .doc<Slide>(slide.id)
        .update({
          url: slide.url,
          name: slide.name,
          description: slide.description || '',
          public: slide.public,
          modified: firebase.firestore.Timestamp.now(),
        })
    );
  }
  /**
   * Delete the Slide by slideId from the Firestore
   * @param slideId
   */
  public deleteSlidebyId(slideId: string) {
    if (slideId) {
      return from(this.fireDatabase.collection('slides').doc(slideId).delete());
    } else {
      throw new Error("Can't delete without a key.");
    }
  }
  public deleteSlide(slide: Slide): Observable<void> {
    if (slide) {
      return from(this.getSlideData(slide).delete());
    } else {
      throw new Error("Can't delete without a key.");
    }
  }
}
