import { Roles } from '../role/role.model';
export class User {
  uid: string;
  email: string;
  displayName: string;
  roles: Roles;
  photoURL?: string;
  userName?: string;
  stripeCustomerId?: string;
  associatedMembers?: UserInfo[];
  associatedCustomers?: UserInfo[];
}

export type UserInfo = Pick<User, 'uid' | 'displayName' | 'email' | 'photoURL' | 'userName'>;
