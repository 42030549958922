import firebase from 'firebase/compat/app';
import { GameProject } from '../game-project/game-project.model';
import { WebProject } from '../web-project/web-project.model';
import { CcpcPerson } from './ccpcPerson.model';

export type CcpcWebSubmission = {
  projectType: CcpcSubmissionType.WEB;
  projectSubmission: WebProject;
};

export type CcpcScratchSubmission = {
  projectType: CcpcSubmissionType.SCRATCH;
  scratchUrl: string;
};

export type CcpcGameSubmission = {
  projectType:
    | CcpcSubmissionType.BATTLESHIP_JAVA
    | CcpcSubmissionType.TICTACTOE_JAVA
    | CcpcSubmissionType.TICTACTOE_PYTHON
    | CcpcSubmissionType.TICTACTOE_BLOCKLY;
  projectSubmission: GameProject;
};

export type CcpcSubmission = {
  id: string;
  uid: string;
  tournamentId: string;
  competitionId: string;
  studentName: string;
  submitted: firebase.firestore.Timestamp;
  coach: CcpcPerson;
  teammateName?: string;
} & (CcpcWebSubmission | CcpcScratchSubmission | CcpcGameSubmission);

export enum CcpcSubmissionType {
  WEB = 'web',
  SCRATCH = 'scratch',
  TICTACTOE_JAVA = 'tic-tac-toe java game',
  TICTACTOE_PYTHON = 'tic-tac-toe python game',
  TICTACTOE_BLOCKLY = 'tic-tac-toe blockly game',
  BATTLESHIP_JAVA = 'battleship java game',
}
