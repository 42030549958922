import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.css'],
})
export class AudioPlayerComponent {
  @Input()
  audioUrl: string;

  @Input()
  audioTitle: string;

  constructor() {}
}
