import { AfterViewInit, Component, Input, TemplateRef } from '@angular/core';
import {
  CcpcGameResults,
  CcpcSubmissionType,
} from '@codecraft-works/data-models';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-ccpc-tournament-match',
  templateUrl: './ccpc-tournament-match.component.html',
  styleUrls: ['./ccpc-tournament-match.component.css'],
})
export class CcpcTournamentMatchComponent implements AfterViewInit {
  @Input()
  match: CcpcGameResults;

  @Input()
  type: CcpcSubmissionType;

  language: string;

  gameType: string;

  modalRef: any;

  @Input()
  showWinner = true;

  constructor(private modalService: BsModalService) {}

  ngAfterViewInit(): void {
    switch (this.type) {
      case CcpcSubmissionType.TICTACTOE_JAVA:
        this.language = 'java';
        this.gameType = 'tictactoe';
        break;
      case CcpcSubmissionType.TICTACTOE_BLOCKLY:
      case CcpcSubmissionType.TICTACTOE_PYTHON:
        this.language = 'python';
        this.gameType = 'tictactoe';
        break;
      case CcpcSubmissionType.BATTLESHIP_JAVA:
        this.language = 'java';
        this.gameType = 'battleship';
        break;
      default:
        break;
    }
  }

  openModal(modalName: TemplateRef<any>, modalClass = 'modal-lg') {
    this.modalRef = this.modalService.show(modalName, {
      class: `${modalClass} modal-dialog modal-dialog-centered`,
      animated: true,
    });
  }

  emoji(playerName: string) {
    if (this.match.winner === playerName && this.showWinner) {
      return '⭐ Winner!';
    } else {
      return '';
    }
  }
}
