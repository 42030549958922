import { AbstractControl } from '@angular/forms';
import { User } from '@codecraft-works/data-models';
import { of } from 'rxjs';

export class ValidateDisplayNameNotEmail {
  static createValidator(user: User) {
    return (control: AbstractControl) => {
      return of(
        control.value.trim().toLowerCase() === user.email?.toLowerCase()
          ? { displayNameEmail: true }
          : null
      );
    };
  }
}
