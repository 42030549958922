<ng-container *ngIf="attendanceItem">
  <div
    *ngIf="first"
    id="table-header"
    class="row align-items-center no-gutters p-1 font-weight-bold d-none d-md-flex"
  >
    <div class="col-12 col-sm-4">
      Timestamp
    </div>

    <div class="col-12 col-sm-6">
      User
    </div>

    <div class="col-12 col-sm-2">
      Event
    </div>
  </div>

  <div
    class="row align-items-center no-gutters p-1"
    [ngClass]="{ first: first, last: last, odd: odd, even: even }"
  >
    <div class="col-12 col-sm-4 my-0">
      <label
        *ngIf="attendanceItem.timestamp"
        class="d-sm-block d-md-none badge badge-pill badge-primary"
        >Location
      </label>
      <p *ngIf="attendanceItem.timestamp" class="mb-0">
        {{ attendanceItem.timestamp.toDate() | date: 'long' }}
      </p>
    </div>

    <div class="col-12 col-sm-6 my-0">
      <label
        *ngIf="attendanceItem.userInfo"
        class="d-sm-block d-md-none badge badge-pill badge-primary"
        >Location
      </label>
      <p *ngIf="attendanceItem.userInfo" class="mb-0">
        {{ attendanceItem.userInfo.displayName }}<br /><strong>Username:</strong
        >{{ attendanceItem.userInfo.userName || 'N/A' }}
        <br />
        {{ attendanceItem.userInfo.email }}
      </p>
    </div>

    <div class="col-12 col-sm-2 my-0">
      <label
        *ngIf="attendanceItem.event"
        class="d-sm-block d-md-none badge badge-pill badge-primary"
        >Location
      </label>
      <p *ngIf="attendanceItem.event" class="mb-0">
        {{ attendanceItem.event | titlecase }}
      </p>
    </div>
  </div>
</ng-container>
