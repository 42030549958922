import firebase from 'firebase/compat/app';

// To hold partial data about location
export type LocationInfo = Pick<
  Location,
  'id' | 'name' | 'description' | 'city' | 'state' | 'pictureUrl'
>;

export class Location {
  public id: string;
  public name: string;
  public created: firebase.firestore.Timestamp;
  public modified: firebase.firestore.Timestamp;
  public description: string;
  public address: string;
  public city: string;
  public state: string;
  public zip: number;
  public county: string;
  public country: string;
  public pictureUrl: string;
  public public: boolean;
  public slug: string;
  public uid: string;
}
