import { KeyValuePipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  CodecraftAnswer,
  CodecraftQuestion,
} from '@codecraft-works/data-models';
import { ShufflePipe } from '../util/shuffle.pipe';

@Component({
  standalone: true,
  selector: 'codecraft-quiz-multiple-answer-question',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <fieldset
      *ngIf="questionForm && question"
      [formGroup]="questionForm"
      class="form-group"
    >
      <p>
        <ng-container
          *ngIf="correctQuestionData && correctQuestionData.isCorrect"
          >🎉&nbsp;</ng-container
        >
        <ng-container
          *ngIf="correctQuestionData && !correctQuestionData.isCorrect"
          >⛔&nbsp;</ng-container
        >
        {{ question.prompt }}
      </p>
      <div *ngFor="let choice of question.choices | keyvalue | shuffle">
        <label>
          <input
            [id]="'question_' + choice.key"
            [formControlName]="choice.key"
            type="checkbox"
            [name]="'question_' + choice.key"
            [value]="choice.value"
          />
          {{ choice.value }}
          <ng-container *ngIf="correctQuestionData">
            <ng-container
              *ngIf="correctQuestionData.correctAnswer[choice.key] === true"
              >✅
            </ng-container>
            <ng-container
              *ngIf="
                !correctQuestionData.isCorrect &&
                correctQuestionData.correctAnswer[choice.key] === false &&
                questionForm.controls[choice.key].value === true
              "
              >❌
            </ng-container>
          </ng-container>
        </label>
      </div>
    </fieldset>
  `,
  styles: [],
  imports: [NgIf, NgFor, ReactiveFormsModule, KeyValuePipe, ShufflePipe],
})
export class CodecraftQuizMultipleAnswerQuestionComponent {
  @Input() question: CodecraftQuestion | undefined;
  @Input() questionForm: FormGroup | undefined;
  @Input() correctQuestionData:
    | {
        isCorrect: boolean;
        correctAnswer: CodecraftAnswer;
      }
    | undefined;
}
