import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ListResult } from '@angular/fire/compat/storage/interfaces';
import { FileService } from '@app/core/file-service/file.service';
import { User, WebProject } from '@codecraft-works/data-models';
import firebase from 'firebase/compat/app';
import { combineLatest, from, Observable, take } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class WebProjectService {
  /**
   * Location of project bucket
   */
  private bucketName = 'gs://' + environment.projectBucket + '/';

  /**
   * Initializes required services and database references
   * @param mediaService
   * @param fireDatabase
   * @param fileService
   * @param firepadDatabase
   */
  constructor(
    private fireDatabase: AngularFirestore,
    private fileService: FileService,
    private firepadDatabase: AngularFireDatabase,
    private fireStorage: AngularFireStorage
  ) {}

  /**
   * Return Web Projects as Observable with optional userID
   * @param userID
   */
  public getWebProjects(
    userID?: string,
    rootWebProjectID?: string,
    limit = 25
  ): Observable<WebProject[]> {
    let webCollectionRef: AngularFirestoreCollection<WebProject>;
    if (userID && rootWebProjectID) {
      // If given userID and rootWebProjectID, return web projects that contain a matching `uid` and `root` web project id
      webCollectionRef = this.fireDatabase.collection<WebProject>(
        'web-projects',
        (ref) =>
          ref
            .where('uid', '==', userID)
            .where('root', '==', rootWebProjectID)
            .orderBy('modified', 'desc')
            .limit(limit)
      );
    } else if (userID) {
      // If given userID, return web projects that contain a matching `uid`
      webCollectionRef = this.fireDatabase.collection<WebProject>(
        'web-projects',
        (ref) =>
          ref
            .where('uid', '==', userID)
            .orderBy('modified', 'desc')
            .limit(limit)
      );
    } else {
      // Else return all public web projects
      webCollectionRef = this.fireDatabase.collection<WebProject>(
        'web-projects',
        (ref) =>
          ref
            .where('public', '==', true)
            .orderBy('modified', 'desc')
            .limit(limit)
      );
    }

    return webCollectionRef.valueChanges();
  }

  /**
   * Return entire Web Project's collection
   */
  private getData() {
    return this.fireDatabase.collection<WebProject>('web-projects');
  }

  /**
   * Return Web Project's data via id
   * @param webProject
   */
  private getWebProjectData(webProject: WebProject) {
    return this.fireDatabase.doc<WebProject>(webProject.id);
  }

  /**
   * Returns and Observable of the Web Project via id
   * @param id
   */
  public getWebProject(id: string): Observable<WebProject> {
    const webProject = this.fireDatabase
      .collection('web-projects')
      .doc<WebProject>(id)
      .valueChanges();

    return webProject;
  }

  /**
   * Creates web project with required contents and then inserted into db with corresponding userId
   * @param user
   * @param authUser
   */
  async insertWebProject(user: User) {
    const header =
      '<!DOCTYPE html>\n<html class="no-js" lang="">\n\t' +
      '<head>\n\t\t' +
      '<meta charset="utf-8" />\n\t\t' +
      '<meta http-equiv="x-ua-compatible" content="ie=edge" />\n\t\t' +
      '<title>{{title}}</title>\n\t\t' +
      '<meta name="description" content="" />\n\t\t' +
      '<meta name="viewport" content="width=device-width, initial-scale=1" />\n\t\t' +
      '{{css}}\n\t' +
      '</head>\n\t' +
      '<body>\n';

    const footer = '\n\t\t{{js}}\n\t</body>\n</html>';

    const htmlMap = {
      header: header,
      footer: footer,
      body: '<h1>Welcome to your web project canvas!</h1>',
    };

    const newWebProject = {
      id: this.fireDatabase.createId(),
      created: firebase.firestore.Timestamp.now(),
      modified: firebase.firestore.Timestamp.now(),
      name: 'New Web Project',
      public: false,
      uid: user.uid,
      authid: user.uid,
      user: {
        uid: user.uid,
        displayName: user.displayName,
        photoURL: user.photoURL,
        userName: user.userName || null,
      },
      html: htmlMap,
      css: 'h1 {color:blue;}',
      js: '//Nothing here...yet',
      completedLoad: false,
      lock: false,
      evalJS: true,
      currentActiveUsers: [],
      collaborators: [],
      readme: '',
      featured: false,
      collabOn: false,
    };
    await this.getData().doc(newWebProject.id).set(newWebProject);

    return newWebProject.id;
  }

  /**
   * Copy existing Web Project to new Web Project
   * @param oldWebProject
   * @param forUser
   * @param authUser
   */
  public async forkWebProject(
    oldWebProject: WebProject,
    forUser: User,
    authUser: User = forUser
  ): Promise<string> {
    const newWebProject = {
      id: this.fireDatabase.createId(),
      created: firebase.firestore.Timestamp.now(),
      modified: firebase.firestore.Timestamp.now(),
      name: 'Cloned:' + oldWebProject.name,
      public: oldWebProject.public,
      root: oldWebProject.id,
      uid: forUser.uid,
      authid: authUser.uid,
      user: {
        uid: forUser.uid,
        displayName: forUser.displayName,
        photoURL: forUser.photoURL,
        userName: forUser.userName || null,
      },
      html: oldWebProject.html,
      css: oldWebProject.css,
      js: oldWebProject.js,
      completedLoad: false,
      lock: false,
      evalJS: true,
      currentActiveUsers: [],
      collaborators: [],
      readme: oldWebProject.readme || '',
      featured: false,
      collabOn: false,
    };
    await this.getData().doc(newWebProject.id).set(newWebProject);
    return newWebProject.id;
  }

  /**
   * Call to update web project settings from web project subscription
   * @param webProject
   */
  /* Not implemented */
  /* eslint-disable no-unused-vars,@typescript-eslint/no-unused-vars */
  private updateWebProject(webProject: WebProject): Observable<void> {
    return from(
      this.getWebProjectData(webProject).update({
        modified: firebase.firestore.Timestamp.now(),
        name: webProject.name,
        public: webProject.public,
        root: webProject.root,
        slug: webProject.slug,
        uid: webProject.uid,
        lock: webProject.lock,
        evalJS: webProject.evalJS,
        currentActiveUsers: webProject.currentActiveUsers,
        collaborators: webProject.collaborators,
        readme: webProject.readme,
        featured: webProject.featured,
        collabOn: webProject.collabOn,
      })
    );
  }

  /**
   *  Deletes web project based on subscription
   * @param webProject
   */
  /* Not implemented */
  /* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
  private deleteWebProject(webProject: WebProject): Observable<void> {
    if (webProject) {
      return from(this.getWebProjectData(webProject).delete());
    } else {
      throw new Error("Can't delete without a key.");
    }
  }

  /**
   * Subscribe and return files from Web Project
   * @param id
   * @returns any
   */
  public getWebProjectMediaFiles(
    bucketName: string,
    projectId: string
  ): Promise<void | ListResult> {
    // Since you mentioned your images are in a folder,
    // we'll create a Reference to that folder:
    const allAssets$ = this.fireStorage
      .refFromURL(bucketName)
      .child(`/web/${projectId}/assets/`)
      .listAll();

    return new Promise<ListResult>((resolve) => {
      allAssets$.pipe(take(1)).subscribe((allAssets) => resolve(allAssets));
    });
  }

  /**
   * Subscribe and return files from Web Project
   * @param id
   * @param fileType
   */
  public getWebProjectFiles(id: string, fileType: string): Observable<string> {
    return new Observable((observer) => {
      this.fileService
        .getFile(this.bucketName, 'web/' + id + '/index.' + fileType, 'text')
        .subscribe(
          (response) => {
            observer.next(response);
            observer.complete();
          },
          (error) => {
            console.error(error);
            observer.error(error);
          }
        );
    });
  }

  /**
   * Upload Project files to project db by id
   * @param id
   * @param fileType
   * @param data
   * @param fileContentType
   */
  public uploadWebProjectFiles(
    id: string,
    fileType: string,
    data: string,
    fileContentType: string
  ) {
    this.fileService.uploadFile(
      this.bucketName,
      'web/' + id + '/index.' + fileType,
      data,
      fileContentType
    );
  }

  /**
   * Delete the Web Project from the Firestore
   * @param id
   */
  public deleteDatabaseProject(id: string) {
    if (id) {
      return from(
        this.fireDatabase.collection('web-projects').doc(id).delete()
      );
    } else {
      throw new Error("Can't delete without a key.");
    }
  }

  /**
   * Return Firepad via Web Project's id
   * @param webProjectID
   */
  public getFirepadRef(webProjectID: string) {
    return this.firepadDatabase.database.ref(
      `web-project-firepad/${webProjectID}`
    );
  }

  /**
   * Return Firepad via Web Project's id
   * @param webProjectID
   */
  public getFirepadRefChanges(webProjectId: string): Observable<any> {
    const html$: Observable<any> = this.firepadDatabase
      .list(`web-project-firepad/${`${webProjectId}/html`}`)
      .valueChanges();

    const css$: Observable<any> = this.firepadDatabase
      .list(`web-project-firepad/${`${webProjectId}/css`}`)
      .valueChanges();

    const js$: Observable<any> = this.firepadDatabase
      .list(`web-project-firepad/${`${webProjectId}/js`}`)
      .valueChanges();

    const fireData$ = combineLatest([html$, css$, js$]);
    return fireData$;
  }

  /**
   * Update the Web Project in the Firestore
   * @param key
   * @param editorHtml
   * @param editorCss
   * @param editorJs
   */
  public saveEditorText(
    key: string,
    editorHtml: string,
    editorCss: string,
    editorJs: string
  ) {
    const webProjectRef = this.fireDatabase.collection('web-projects').doc(key);
    webProjectRef.update({
      'html.body': editorHtml,
      css: editorCss,
      js: editorJs,
      modified: firebase.firestore.Timestamp.now(),
    });
  }

  /**
   * Update the evalJS in the web project
   * @param flag
   */
  public updateEvalJS(key: string, flag: boolean) {
    const webProjectRef = this.fireDatabase.collection('web-projects').doc(key);
    webProjectRef.update({
      evalJS: flag,
      modified: firebase.firestore.Timestamp.now(),
    });
  }

  public setCollab(id: string, collabFlag: boolean) {
    this.fireDatabase.collection('web-projects').doc(id).update({
      collabOn: collabFlag,
      modified: firebase.firestore.Timestamp.now(),
    });
  }

  public setCloudVariable(
    webProjectId: string,
    jsonString: string,
    uid: string,
    user: User
  ) {
    // To get size remove spaces from string and get text encoding length
    const jsonSize = new TextEncoder().encode(
      jsonString.replace(/\s/g, '')
    ).length;

    const isAdmin = user.roles.admin;
    const isEditor = user.roles.editor;
    const noop = () => {};

    if (isAdmin || isEditor) {
      noop();
    } else {
      if (jsonSize / 1024 > 1) {
        // eslint-disable-next-line no-throw-literal
        throw 'sizeExceeded';
      }
    }

    const jsonObj = JSON.parse(jsonString);

    return this.firepadDatabase
      .object('cloud-variable/' + webProjectId)
      .set({ uid: uid, value: jsonObj });
  }

  public getCloudVariable(webProjectId: string) {
    return this.firepadDatabase
      .object('cloud-variable/' + webProjectId + '/' + 'value')
      .valueChanges();
  }
}
