<form
  id="quizForm"
  [formGroup]="quizForm"
  (ngSubmit)="updateQuiz(loadingQuiz, confirmQuiz, errorQuiz)"
>
  <!-- Quiz name input -->
  <div class="form-group row">
    <label for="quizName" class="col-sm-4 col-form-label">Quiz Name</label>
    <div class="col-sm-8">
      <input
        type="text"
        class="form-control"
        id="quizName"
        formControlName="quizName"
        aria-describedby="quizNameHelp"
        required
        [ngClass]="{
          'is-invalid': quizForm.get('quizName').invalid,
          'is-valid':
            quizForm.get('quizName').valid && quizForm.get('quizName').dirty
        }"
      />
      <small id="quizNameHelp" class="form-text text-muted"> Quiz Name. </small>
      <div class="invalid-feedback">Please provide a name for this quiz</div>
    </div>
  </div>

  <!-- max retakes input -->
  <div class="form-group row">
    <label for="maxAttempts" class="col-sm-4 col-form-label"
      >Max Attempts</label
    >
    <div class="col-sm-8">
      <input
        type="number"
        class="form-control"
        id="maxAttempts"
        formControlName="maxAttempts"
        aria-describedby="maxAttemptsHelp"
        required
        [ngClass]="{
          'is-invalid': quizForm.get('maxAttempts').invalid,
          'is-valid':
            quizForm.get('maxAttempts').valid &&
            quizForm.get('maxAttempts').dirty
        }"
      />
      <small id="maxAttemptsHelp" class="form-text text-muted">
        Max Attempts. (-1 for unlimited)
      </small>
      <div class="invalid-feedback">
        Please provide a number for the max attempts
      </div>
    </div>
  </div>
  <!-- Unlimited retakes checkbox -->
  <div class="form-group row">
    <label for="unlimitedAttempts" class="col-sm-4 col-form-label"
      >Unlimited Attempts</label
    >
    <div class="col-sm-8">
      <input
        type="checkbox"
        id="unlimitedAttempts"
        formControlName="unlimitedAttempts"
        aria-describedby="unlimitedAttemptsHelp"
        (change)="unlimitedCheckboxChange($event)"
      />
      <small id="unlimitedAttemptsHelp" class="form-text text-muted">
        Unlimited Attempts.
      </small>
    </div>
  </div>

  <!-- min passing score input -->
  <div class="form-group row">
    <label for="passingScore" class="col-sm-4 col-form-label"
      >Minimum Passing Score</label
    >
    <div class="col-sm-8">
      <input
        type="number"
        class="form-control"
        id="passingScore"
        formControlName="passingScore"
        aria-describedby="passingScoreHelp"
        required
        [ngClass]="{
          'is-invalid': quizForm.get('passingScore').invalid,
          'is-valid':
            quizForm.get('passingScore').valid &&
            quizForm.get('passingScore').dirty
        }"
      />
      <small id="passingScoreHelp" class="form-text text-muted">
        Minimum Passing Score.
      </small>
      <div class="invalid-feedback">
        Please provide a number for the minimum passing score
      </div>
    </div>
  </div>

  <!-- quiz questions csv input -->
  <div class="form-group row">
    <label for="quizCsvForm" class="col-sm-4 col-form-label">Quiz CSV</label>
    <div class="col-sm-8">
      <input
        id="quizCsvForm"
        type="file"
        accept=".csv, text/csv"
        (change)="detectMediaFiles($event)"
        class="form-control"
        formControlName="quizCsvForm"
        aria-describedby="quizCsvFormHelp"
        [ngClass]="{
          'is-invalid': quizForm.get('quizCsvForm').invalid,
          'is-valid':
            quizForm.get('quizCsvForm').valid &&
            quizForm.get('quizCsvForm').dirty
        }"
      />
      <small id="quizCsvFormHelp" class="form-text text-muted">
        Quiz CSV.
      </small>
    </div>
  </div>

  <!-- quiz creations csv link -->
  <div class="form-group row">
    <label for="csvCreationLink" class="col-sm-4 col-form-label"
      >Quiz Creation Spreadsheet</label
    >
    <div class="col-sm-8">
      <a
        href="https://docs.google.com/spreadsheets/d/1hQrxDAyfgYck0GuNaTrPTcb82BYfeWXT8mxHdEqR5Oo/edit#gid=1480408895"
        >https://docs.google.com/spreadsheets/d/1hQrxDAyfgYck0GuNaTrPTcb82BYfeWXT8mxHdEqR5Oo/edit#gid=1480408895</a
      >
      <small id="csvCreationLinkHelp" class="form-text text-muted">
        Quiz Creation Link.
      </small>
    </div>
  </div>

  <!-- quiz html copy tag -->
  <ng-container *ngIf="quiz$ | async as quiz">
    <div class="input-group input-group-md mb-2 quizHtmlTagContainer">
      <div class="input-group-prepend">
        <label class="input-group-text" for="quizHtmlTag">
          Quiz HTML Tag:</label
        >
      </div>
      <input
        #inputTarget
        class="quizHtmlTag"
        id="quizHtmlTag form-control"
        type="text"
        value="<codecraft-quiz quizid='{{ quiz.id }}'></codecraft-quiz>"
        [readonly]="true"
      />
      <div class="input-group-append">
        <button
          type="button"
          class="btn btn-primary"
          [ngxClipboard]="inputTarget"
        >
          Copy to Clipboard
        </button>
      </div>
    </div>
  </ng-container>

  <!-- table of all quiz questions -->
  <div>
    <h3>Quiz Questions</h3>
    <ng-container *ngIf="quizQuestions$ | async as quizQuestions; else loading">
      <table [border]="1" width="100%" class="table table-striped">
        <thead>
          <tr>
            <th style="width: 50%" scope="col">Prompt</th>
            <th style="width: 10%" scope="col">Type</th>
            <th style="width: 30%" scope="col">Tags</th>
            <th style="width: 10%" scope="col">Actions</th>
          </tr>
        </thead>
        <ng-container *ngIf="loadingQuestions$ | async as loadingQuestions">
          <ng-container *ngIf="quizQuestions.size === 0 && loadingQuestions">
            <codecraft-loading-spinner></codecraft-loading-spinner>
          </ng-container>
        </ng-container>
        <tbody>
          <tr *ngFor="let question of quizQuestions | keyvalue">
            <td>{{ question.value.prompt }}</td>
            <td>{{ question.value.type }}</td>
            <td>{{ question.value.tags }}</td>
            <td>
              <div
                class="btn-group"
                role="group"
                aria-label="Question Actions Button Group"
              >
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="
                    openQuestionModal({
                      modal: viewQuestion,
                      questionId: question.value.id
                    })
                  "
                >
                  Details
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  (click)="
                    openQuestionModal({
                      modal: modifyQuestion,
                      questionId: question.value.id
                    })
                  "
                >
                  Modify
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div>

  <!-- action buttons flexbox -->
  <div class="buttonGroupsContainer">
    <!-- quiz save/delete actions buttons -->
    <div
      class="btn-group"
      role="group"
      aria-label="Quiz Save/Delete Button Group"
    >
      <span
        class="tooltip-wrapper"
        tabindex="0"
        data-toggle="tooltip"
        title="Save the changes made to this quiz."
      >
        <button
          type="submit"
          class="btn btn-success"
          [disabled]="!quizForm.valid"
        >
          Save Changes
        </button>
      </span>
      <ng-container *ngIf="{ value: hasQuizInstance$ | async } as context">
        <span
          class="tooltip-wrapper"
          tabindex="0"
          data-toggle="tooltip"
          title="Delete this quiz and associated questions. You cannot delete a quiz that has a quiz instance created."
        >
          <button
            type="button"
            class="btn btn-danger"
            [disabled]="context.value === null || context.value === true"
            (click)="openModal(deleteQuiz)"
          >
            Delete Quiz
          </button>
        </span>
      </ng-container>
    </div>

    <!-- bootstrap collapse with three buttons -->
    <div>
      <button
        type="button"
        class="btn btn-primary float-right"
        (click)="addQuestionsCollapsed = !addQuestionsCollapsed"
        [attr.aria-expanded]="!addQuestionsCollapsed"
        aria-controls="addQuestionTypesButtons"
      >
        <fa-icon [icon]="faPlusCircle" class="fa-lg"></fa-icon> Add Question
      </button>
      <br />
      <div
        id="addQuestionTypesButtons"
        class="float-right"
        [collapse]="addQuestionsCollapsed"
        [isAnimated]="true"
      >
        <div
          class="btn-group-vertical"
          role="group"
          aria-label="Add New Question Types Button Group"
        >
          <button
            type="button"
            class="btn btn-dark"
            (click)="
              openQuestionModal({ modal: addQuestion, questionType: 'single' })
            "
          >
            Add Single Select Question
          </button>
          <button
            type="button"
            class="btn btn-dark"
            (click)="
              openQuestionModal({
                modal: addQuestion,
                questionType: 'multiple'
              })
            "
          >
            Add Multiple Select Question
          </button>
          <button
            type="button"
            class="btn btn-dark"
            (click)="
              openQuestionModal({ modal: addQuestion, questionType: 'boolean' })
            "
          >
            Add Boolean Question
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

<!-- Modals -->

<!--Loading Modal-->
<ng-template #loadingQuiz>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Loading... ⏳⌛</h4>
  </div>
  <div class="modal-body">The Quiz is uploading...</div>
</ng-template>
<!--Confiramtion Modal-->
<ng-template #confirmQuiz>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Quiz Information Saved 👩🏼‍💻👨🏼‍💻</h4>
  </div>
  <div class="modal-body">The Quiz Information is safe 🙂</div>

  <button type="button" class="btn btn-success" (click)="modalRef.hide()">
    Got it!
  </button>
</ng-template>
<!--Error Modal-->
<ng-template #errorQuiz>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Quiz Update Error ❌</h4>
  </div>
  <div class="modal-body">
    There was an error updating the quiz: {{ quizUpdateError }}
  </div>

  <button type="button" class="btn btn-success" (click)="modalRef.hide()">
    Got it!
  </button>
</ng-template>
<!--Delete Modal-->
<ng-template #deleteQuiz>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Delete Quiz</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">Are you sure you'd like to delete this quiz?</div>

  <button
    type="button"
    class="btn btn-danger"
    (click)="deleteQuizDocument(); modalRef.hide()"
  >
    Yes, delete F O R E V E R
  </button>
</ng-template>

<!-- Modify Question Modal-->
<ng-template #modifyQuestion>
  <app-quiz-edit-question-modal
    [questionData]="questionModalData"
    [isModify]="true"
    [modalRef]="modalRef"
  >
  </app-quiz-edit-question-modal>
</ng-template>

<!-- View Question Modal-->
<ng-template #viewQuestion>
  <app-quiz-edit-question-modal
    [questionData]="questionModalData"
    [isModify]="false"
    [modalRef]="modalRef"
  >
  </app-quiz-edit-question-modal>
</ng-template>

<!-- Add Question Modal -->
<ng-template #addQuestion>
  <app-quiz-edit-question-modal
    [questionData]="questionModalData"
    [isModify]="true"
    [isNew]="true"
    [modalRef]="modalRef"
    [quizId]="quiz$.value.id"
  >
  </app-quiz-edit-question-modal>
</ng-template>

<!-- Loading template -->
<ng-template #loading> <codecraft-loading-spinner></codecraft-loading-spinner></ng-template>
