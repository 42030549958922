import firebase from 'firebase/compat/app';
import { CcpcPerson } from './ccpcPerson.model';
import { CcpcTournament } from './ccpcTournament.model';

export class CcpcCompetition {
  /**
   * Unique ID string for this document generated by Firebase
   */
  id: string;

  /**
   * Timestamp of initial document creation
   */
  created: firebase.firestore.Timestamp;

  /**
   * Timestamp of the last time the document was updated
   */
  modified: firebase.firestore.Timestamp;

  /**
   * Signifies whether or not document is available for others to see
   */
  public: boolean;

  /**
   * User ID string that identifies the owner of the document
   */
  uid: string;

  /**
   * Name of the competition
   */
  name: string;

  /**
   * Collection of tournaments in competition
   */
  tournaments?: CcpcTournament[];

  /*
   * List of organizations/sponsors for the competition
   */
  sponsors?: CcpcPerson[];

  /**
   * Timestamp of the competition date
   */
  competitionDate: firebase.firestore.Timestamp;
}
