import { pythonGenerator } from 'blockly/python';
import { Blockly, CustomBlock } from 'ngx-blockly-new';
export class OnMyTurnBlock extends CustomBlock {
  constructor() {
    super('tictactoe_onmyturn');
    this.class = OnMyTurnBlock;
  }

  public defineBlock() {
    this.block.appendDummyInput().appendField('On my turn');
    this.block.appendStatementInput('calc_move');
    this.block.setColour(0);
    this.block.setTooltip("Everything inside will run on the player's turn");
    this.block.setHelpUrl('https://app.codecraftworks.com/courses/33629');
  }

  public toPythonCode(block: Blockly.Block): string | any[] {
    pythonGenerator.provideFunction_('import_player', [
      'from tic_tac_toe.player import Player',
    ]);

    // eslint-ignore: Read Only
    pythonGenerator.INDENT = '    ';

    const generatedCode = pythonGenerator.prefixLines(
      pythonGenerator.statementToCode(block, 'calc_move'),
      pythonGenerator.INDENT
    );

    return (
      'class CodecraftPlayer(Player):\n\n' +
      pythonGenerator.INDENT +
      'def calc_move(self):\n' +
      generatedCode
    );
  }
}
