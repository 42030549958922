<card
  [title]="location.name"
  [showTitle]="true"
  [imageUrl]="location.pictureUrl"
  [imageAlt]="location.name"
  [detailUrl]="['/locations/' + location.id]"
  [user]="user"
  [isGrid]="isGrid"
>
  <p *ngIf="location.city && location.state" class="card-text">
    {{ location.city }}, {{ location.state }}
  </p>
</card>
