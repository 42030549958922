import { TitleCasePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  User as AngularFireUser,
  UserCredential,
  browserSessionPersistence,
  getAuth,
  getRedirectResult,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailLink,
} from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { User } from '@codecraft-works/data-models';
import firebase from 'firebase/compat/app';
import {
  Observable,
  ReplaySubject,
  from,
  lastValueFrom,
  of as observableOf,
} from 'rxjs';
import { map, mergeMap, switchMap, take } from 'rxjs/operators';
import displayNames from '../../../assets/js/displayNames.json';
import { SessionStorageService } from './session-storage.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  user$: Observable<User>;
  returnUrl: string;
  authState: Observable<User>;
  private loggedInSubject: ReplaySubject<AngularFireUser> = new ReplaySubject(
    1
  );

  constructor(
    private fireAuthService: AngularFireAuth,
    private db: AngularFirestore,
    private router: Router,
    private titleCase: TitleCasePipe,
    sessionStorage: SessionStorageService,
    private httpClient: HttpClient
  ) {
    firebase.auth().onIdTokenChanged(
      (user) => {
        this.loggedInSubject.next(user);
      },
      (err) => {
        console.error('onIdTokenChanged err:', err);
      }
    );

    this.user$ = this.fireAuthService.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.db.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return observableOf(null);
        }
      })
    );
    const auth = getAuth();
    getRedirectResult(auth).then(
      (result) => {
        if (sessionStorage.get('providers')) {
          sessionStorage.remove('providers');
        }
        if (result && result.user) {
          this.updateUser(result.user);
        }
      },
      (error) => {
        // In case of auth/account-exists-with-different-credential error,
        // you can fetch the providers using this:
        if (error.code === 'auth/account-exists-with-different-credential') {
          firebase
            .auth()
            .fetchSignInMethodsForEmail(error.email)
            .then((providers) => {
              sessionStorage.set('providers', JSON.stringify(providers));
              // The returned 'providers' is a list of the available providers
              // linked to the email address. Please refer to the guide for a more
              // complete explanation on how to recover from this error.
            });
        }
      }
    );

    onAuthStateChanged(auth, (user) => {
      if (user && this.router.url === '/login') {
        this.router.navigate(['/home']);
      }
    });
  }

  isLoggedIn() {
    return new Promise<boolean>((resolve) => {
      this.fireAuthService.authState.pipe(take(1)).subscribe((user) => {
        resolve(user !== null);
      });
    });
  }

  isAdmin() {
    let isAdmin: boolean;
    this.user$.subscribe((user) => (isAdmin = user.roles.admin));
    return isAdmin;
  }

  // Google Service Sign In
  public loginGoogleUserService() {
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    googleProvider.addScope('profile');
    googleProvider.addScope('email');
    firebase.auth().signInWithRedirect(googleProvider);
  }

  // Google Service Sign In
  public loginMSFTUserService() {
    const msftProvider = new firebase.auth.OAuthProvider('microsoft.com');
    msftProvider.addScope('openid');
    msftProvider.addScope('profile');
    msftProvider.addScope('email');
    msftProvider.setCustomParameters({
      tenant: 'common',
    });
    firebase.auth().signInWithRedirect(msftProvider);
  }

  // Email Link Service Sign In
  public loginEmailLinkService(
    email: string,
    url: any
  ): Promise<void | UserCredential> {
    const auth = getAuth();

    setPersistence(auth, browserSessionPersistence);
    return signInWithEmailLink(auth, email, url)
      .then((credential) => {
        this.updateUser(credential.user);
        return credential;
      })
      .catch((error) => {
        console.error('loginEmailLinkService err:', error);
      });
  }

  async loginK12Student(opts: {
    userName: string;
    password: string;
    entityId: string;
    entityType: 'program' | 'classroom';
  }): Promise<string | firebase.auth.UserCredential> {
    const auth = getAuth();

    setPersistence(auth, browserSessionPersistence);

    return await lastValueFrom(
      this.httpClient
        .post('/api/students/loginK12Student', opts, {
          responseType: 'json',
        })
        .pipe(
          map(async (response) => {
            if (response['token']) {
              return await firebase
                .auth()
                .signInWithCustomToken(response['token'])
                .then((credential) => {
                  this.updateUser(credential.user);
                  return credential;
                })
                .catch((error) => {
                  console.error('Error logging in K12 Student', error);
                  return 'Error logging in student';
                });
            }
          })
        )
    ).then((result) => {
      return result;
    });
  }

  public insertUser(authData: firebase.User) {
    // No user information in the database,
    // Create a new user model based off known data from Firebase auth.
    const newUser = {
      uid: authData.uid,
      email: authData.email,
      displayName: authData.displayName || this.getDisplayName(),
      roles: {
        user: true,
      },
      photoURL: authData.photoURL || '/assets/img/80s-codey.jpg',
      created: firebase.firestore.Timestamp.now(),
      modified: firebase.firestore.Timestamp.now(),
    };

    this.db
      .collection<User>('users')
      .doc<User>(newUser.uid)
      .set(newUser, { merge: true });
  }

  // Update user data in Firestore
  public updateUser(authData) {
    const ref = this.db.doc('users/' + authData.uid);
    ref
      .valueChanges()
      .pipe(take(1))
      .subscribe((user) => {
        if (!user) {
          this.insertUser(authData);
        }
      });
  }

  // Certificate update
  public updateUserCertificate(uid: string, user: Partial<User>) {
    const firebaseUser = this.db.doc(`users/${uid}`);
    return from(firebaseUser.update(user));
  }

  // Google Service Sign Out
  public logoutUserService() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        window.sessionStorage.removeItem('loading');
        window.localStorage.removeItem('emailForSignIn');
        this.router.navigate(['/login']);
      });
  }

  public getOtherUser(uid: string): Observable<User> {
    return this.db
      .doc(`users/${uid}`)
      .valueChanges()
      .pipe(
        map((user: User) => {
          return user;
        })
      );
  }

  /**
   * Gets user information from the database and Firebase Auth to create a domain User.
   * @param authUser The Firebase Auth user
   */
  private authUserToUserModel(authUser: AngularFireUser): Observable<User> {
    if (!authUser) {
      return observableOf(null);
    }

    const databaseUserRef = this.db.doc(`users/${authUser.uid}`);
    return databaseUserRef.valueChanges().pipe(
      map((databaseUser: User) => {
        if (databaseUser) {
          return databaseUser as User;
        } else {
          return null;
        }
      })
    );
  }

  public getUser(): Observable<User> {
    return this.loggedInSubject.pipe(
      mergeMap((authUser) => this.authUserToUserModel(authUser))
    );
  }

  get currentUserObservable(): any {
    return this.fireAuthService.authState;
  }

  public getDisplayName(): string {
    // Get the nouns from the JSON
    const nouns = displayNames['nouns'];
    const noun = this.titleCase.transform(
      nouns[Math.floor(Math.random() * nouns.length)]
    );

    // Get the adjectives from the JSON
    const adjectives = displayNames['adjectives'];
    const adjective = this.titleCase.transform(
      adjectives[Math.floor(Math.random() * adjectives.length)]
    );

    return `${adjective} ${noun}`;
  }
}
