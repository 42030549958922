import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { WebProjectService } from '@app/shared/web-project/web-project.service';
import { User } from '@codecraft-works/data-models';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { NgxHotjarService } from 'ngx-hotjar';

/**
 *  Create new web project button
 */
@Component({
  selector: 'app-web-project-new-project-button',
  templateUrl: './web-project-new-project-button.component.html',
  styleUrls: ['./web-project-new-project-button.component.css'],
})
export class WebProjectNewProjectButtonComponent {
  /**
   * Input for user
   */
  @Input() user: User;

  /**
   * Font Awesome icon
   */
  faPlusCircle = faPlusCircle;

  /**
   * Initialize web project service and angular router
   * @param webProjectService
   * @param router
   */
  constructor(
    private webProjectService: WebProjectService,
    private router: Router,
    private hotjarService: NgxHotjarService
  ) {}
  /**
   * Creates a new web project from template to current user
   */
  async newWebProject() {
    const newWebProjectId = await this.webProjectService.insertWebProject(
      this.user
    );
    this.router.navigate([`/web/${newWebProjectId}/`]);
    this.hotjarService.trigger('first_new_or_cloned_web_project');
  }
}
