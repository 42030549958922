import firebase from 'firebase/compat/app';
/**
 * Importing the `User` model to be partially used in this class
 */
import { UserInfo } from '../user/user.model';

// To hold partial data about instructors

export interface InstructorInfo {
  uid: string;
  displayName: string;
  email: string;
  photoURL: string;
}

export class Instructor {
  programsInstructing?: string[];
  location?: string;
  bio?: string;
  teachingRadiusMiles?: number;
  teachesVirtually?: boolean;
  public: boolean;
  slug: string;
  uid: string;
  created: firebase.firestore.Timestamp;
  modified: firebase.firestore.Timestamp;
  /**
   * Partial [user](/classes/User.html) class properties for use in in web project
   *
   * It is an optional property
   *
   * Copy of owner's display name from [user](/classes/User.html#displayName) class
   * `displayName?: string;`
   *
   * Copy of owner's display name from [email](/classes/User.html#email) class
   * `email?: string;`
   *
   * URL of owner's photo from [user](/classes/User.html#photoURL) class
   * `photoURL?: string;`
   *
   */
  user?: UserInfo;
}
