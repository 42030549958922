import { pythonGenerator } from 'blockly/python';
import { Blockly, CustomBlock } from 'ngx-blockly-new';

export class SubmitMoveBlock extends CustomBlock {
  constructor() {
    super('tictactoe_submitmove');
    this.class = SubmitMoveBlock;
  }

  public defineBlock() {
    this.block.appendDummyInput().appendField('Submit Move');
    this.block
      .appendValueInput('ROW')
      .appendField('Row')
      .setAlign(Blockly.ALIGN_RIGHT)
      .setCheck('Number');
    this.block
      .appendValueInput('COL')
      .appendField('Column')
      .setAlign(Blockly.ALIGN_RIGHT)
      .setCheck('Number');
    this.block.setPreviousStatement(true);
    this.block.setNextStatement(true);
    this.block.setColour(120);
    this.block.setTooltip('Submits a move to the game');
    this.block.setHelpUrl('https://app.codecraftworks.com/courses/33629');
  }

  public toPythonCode(): string | any[] {
    return `self.submit_move(${pythonGenerator.valueToCode(
      this.block,
      'ROW',
      0
    )}, ${pythonGenerator.valueToCode(this.block, 'COL', 0)})\n`;
  }
}
