<div class="container">
  <div class="row">
    <div
      class="col-12 col-md-8 offset-md-2"
      id="login"
    >
      <div class="mx-auto text-center p-3">
        <h1>Login to Codecraft Works</h1>
        <div>
          <codecraft-loading-spinner *ngIf="loading$ | async as loading; else login"></codecraft-loading-spinner>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #login>
  <p class="mt-5 d-flex flex-column flex-md-row justify-content-around">
    <a
      *ngIf="!providers || providers.includes('google.com')"
      class="align-middle"
      (click)="logInViaGoogle()"
    >
      <img
        src="/assets/img/google-login.svg"
        width="215px"
        height="41px"
        alt="Sign in with Google"
      />
    </a>
    <a
      *ngIf="!providers || providers.includes('microsoft.com')"
      class="align-middle"
      (click)="logInViaMSFT()"
    >
      <img
        src="/assets/img/msft-login.svg"
        width="215px"
        height="41px"
        alt="Sign in with Microsoft"
      />
    </a>
  </p>
  <ng-container *ngIf="!providers || providers.includes('emailLink')">
    <p
      class="small"
      *ngIf="!providers || providers.length > 1"
    >OR</p>
    <app-login-with-email></app-login-with-email>
    <p class="small mt-2">OR</p>
    <form>
      <p class="text-left">If you are a student and have a 4-digit short code, enter it below and we will send you to
        the right login page
      </p>
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <fa-icon
              [icon]="faHashtag"
              class="fa-lg"
            ></fa-icon>
          </div>
        </div>
        <input
          type="text"
          class="form-control"
          placeholder="Enter your 4-digit code"
          [(ngModel)]="shortCode"
          name="shortCode"
          maxlength="4"
          pattern="[A-Za-z0-9]{4,4}"
          size="4"
          required
        />
        <div class="input-group-append">
          <button
            class="btn btn-primary"
            type="submit"
            [disabled]="!isShortCodeValid()"
            (click)="logInViaShortCode()"
          >Go to Program</button>
        </div>
      </div>
    </form>
  </ng-container>
  <div
    class="alert alert-danger"
    *ngIf="providers"
  >
    You previously signed in with a different account provider. Please sign in
    using that account provider from the available options above.
  </div>

  <p class="text-monospace text-left mt-2">
    It's easiest to use your Google or Microsoft account to sign in or to sign
    up! Do you prefer to login with an email address? No problem! We will reply
    to you with a magic password link.
  </p>
  <p class="text-monospace text-left mt-2">
    Often schools and workplace emails are provided by Microsoft or Google
    services and if yours is, it can be used here too. Of course, personal
    Google or Microsoft accounts will work too.
  </p>
</ng-template>
