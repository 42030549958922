import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { StripeService } from '@app/core/stripe/stripe.service';
import { ShoppingCartItem, User } from '@codecraft-works/data-models';
import { environment } from '@environments/environment';
import { loadStripe } from '@stripe/stripe-js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/remote-config';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ShoppingCartService } from './shopping-cart.service';

declare const ga: any;

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css'],
})
export class ShoppingCartComponent implements OnInit, OnDestroy {
  @Input() user: User;
  stripe;
  cart$: Observable<ShoppingCartItem[]>;

  allSubs = new Subscription();

  cart: ShoppingCartItem[];
  error: string;
  isCheckingOut = false;

  recurringDailyCartTotal: number;
  recurringWeeklyCartTotal: number;
  recurringMonthlyCartTotal: number;
  recurringYearlyCartTotal: number;
  oneTimeCartTotal: number;
  totalCostNow: number;

  sessionId: string;

  @ViewChild('loadingModal')
  loadingModal: TemplateRef<any>;
  loadingModalRef: BsModalRef;

  @ViewChild('invalidCouponCodeModal')
  invalidCouponCodeModal: TemplateRef<any>;
  invalidCouponCodeModalRef: BsModalRef;

  remoteConfig = firebase.remoteConfig();

  invalidCouponCodeModalMessage = this.remoteConfig
    .getValue('company_coupon_code_invalid')
    .asString();

  constructor(
    private shoppingCartService: ShoppingCartService,
    private stripeService: StripeService,
    private modalService: BsModalService,
    private analyticsService: AnalyticsService
  ) {}

  async ngOnInit() {
    this.stripe = await loadStripe(`${environment.stripePublishableKey}`);

    this.cart$ = this.shoppingCartService.getShoppingCartItems(this.user.uid);

    this.allSubs.add(
      this.cart$.subscribe((shoppingCartItems) => {
        this.cart = shoppingCartItems;
        this.oneTimeCartTotal = 0;
        this.recurringDailyCartTotal = 0;
        this.recurringMonthlyCartTotal = 0;
        this.recurringYearlyCartTotal = 0;
        this.recurringWeeklyCartTotal = 0;
        this.totalCostNow = 0;

        shoppingCartItems.forEach((shoppingCartItem) => {
          switch (shoppingCartItem.program.stripe.frequency) {
            case 'daily': {
              const recurringDailyCartTotal =
                shoppingCartItem.program.stripe.price *
                shoppingCartItem.quantity;
              this.recurringDailyCartTotal += recurringDailyCartTotal;
              this.totalCostNow += recurringDailyCartTotal;
              break;
            }
            case 'weekly': {
              const recurringWeeklyCartTotal =
                shoppingCartItem.program.stripe.price *
                shoppingCartItem.quantity;
              this.recurringWeeklyCartTotal += recurringWeeklyCartTotal;
              this.totalCostNow += recurringWeeklyCartTotal;
              break;
            }
            case 'monthly': {
              const recurringMonthlyCartTotal =
                shoppingCartItem.program.stripe.price *
                shoppingCartItem.quantity;
              this.recurringMonthlyCartTotal += recurringMonthlyCartTotal;
              this.totalCostNow += recurringMonthlyCartTotal;
              break;
            }
            case 'yearly': {
              const recurringYearlyCartTotal =
                shoppingCartItem.program.stripe.price *
                shoppingCartItem.quantity;
              this.recurringYearlyCartTotal += recurringYearlyCartTotal;
              this.totalCostNow += recurringYearlyCartTotal;
              break;
            }
            case 'once': {
              const oneTimeCartTotal =
                shoppingCartItem.program.stripe.price *
                shoppingCartItem.quantity;
              this.oneTimeCartTotal += oneTimeCartTotal;
              this.totalCostNow += oneTimeCartTotal;
              break;
            }
            default:
              break;
          }
        });
      })
    );
  }

  checkout() {
    this.openLoadingModal(this.loadingModal);
    this.isCheckingOut = true;
    // No Stripe customer id, check to see if they are already in Stripe
    if (!this.user.stripeCustomerId) {
      this.allSubs.add(
        this.stripeService
          .getStripeCustomerByEmail(this.user)
          .pipe(
            map((stripeCustomer) => {
              return stripeCustomer;
            }),
            catchError(() => {
              return this.stripeService.createStripeCustomer(this.user);
            })
          )
          .subscribe((stripeCustomer) => {
            this.redirectToCheckout(this.user, stripeCustomer);
          })
      );
    } else {
      this.redirectToCheckout(this.user);
    }
  }

  async redirectToCheckout(user: User, stripeCustomer?) {
    // Get default tracker and retrieve the client id

    const analyticsClientId: string =
      typeof ga.getAll === 'function'
        ? await ga.getAll()[0].get('clientId')
        : null;

    this.allSubs.add(
      // Create stripe session
      this.stripeService
        .createStripeSession(
          (stripeCustomer && stripeCustomer.id) ||
            (user && user.stripeCustomerId),
          this.cart,
          user.uid,
          analyticsClientId,
          user.email
        )
        // Send to analytics tracker
        .pipe(
          tap((response) => {
            this.analyticsService.beginCheckoutEvent(
              this.cart,
              response?.body?.id
            );
          })
        )
        // Send to Stripe checkout page
        .pipe(
          map((response) => {
            if (response.status === 200) {
              this.redirectToSession(response.body.id);
            } else if (response.status === 202) {
              this.closeLoadingModal();
              this.error = response.body.error.raw.message;
              this.sessionId = response.body.session.id;
              this.openInvalidCouponCodeModal(this.invalidCouponCodeModal);
            }
          })
        )
        .subscribe(
          () => {},
          (error) => {
            this.closeLoadingModal();
            this.error = error.error.raw.message;
          }
        )
    );
  }

  openLoadingModal(template: TemplateRef<any>) {
    this.loadingModalRef = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
    });
  }

  closeLoadingModal() {
    this.loadingModalRef.hide();
  }

  openInvalidCouponCodeModal(template: TemplateRef<any>) {
    this.invalidCouponCodeModalRef = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
    });
  }

  closeInvalidCouponCodeModal() {
    this.invalidCouponCodeModalRef.hide();
  }

  redirectToSession(sessionId) {
    this.stripe
      .redirectToCheckout({
        sessionId: sessionId,
      })
      .then(function (result) {
        this.closeLoadingModal();

        this.error = result.error.message;
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      })
      .catch((error) => {
        this.closeLoadingModal();
        this.error = error;
      });
  }

  ngOnDestroy() {
    if (this.allSubs) {
      this.allSubs.unsubscribe();
    }
  }
}
