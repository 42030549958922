import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { User } from '@codecraft-works/data-models';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { PaginationDirective } from '../pagination.directive';
import { PaginationService } from '../pagination.service';
@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css'],
  providers: [PaginationService],
})
export class GridComponent
  extends PaginationDirective
  implements OnInit, AfterViewInit, OnDestroy
{
  selected: string;
  @Input()
  entityName: string;

  @Input() user: User;

  @Input() hideAddToCart = false;

  @Input() filteredData: any[];
  asyncSelected: string;
  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;
  source: any;
  obsArray: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  dataSource: Observable<any>;
  searchArray: any[] = [];
  searchObservable: Observable<any>;
  populator: Observable<any>;
  chosenData: any;
  loaded: boolean;
  loadingSub: Subscription;
  paginationSub: Subscription;

  constructor(public data: PaginationService) {
    super(data);
  }

  ngOnInit() {
    super.init();
    this.populator = this.page.data;

    this.loadingSub = this.page.loading.subscribe((loading) => {
      if (!this.loaded && !loading) {
        this.loaded = true;
      }
    });
  }

  ngAfterViewInit() {
    this.paginationSub = this.page.data.subscribe((data) => {
      data.forEach((element) => {
        this.searchArray.push(element);
      });
    });
    this.searchObservable = of(this.searchArray);
  }

  swapObservables() {
    this.populator = this.page.data;
    this.searchArray = [];
  }

  updateList() {
    this.dataSource = new Observable((observer: any) => {
      // Runs on every search
      observer.next(this.asyncSelected);
    }).pipe(mergeMap((token: string) => this.getProjectsAsObservable(token)));

    this.dataSource.subscribe((el) => {
      this.searchObservable = of(el);
    });
    this.populator = this.searchObservable;
  }

  getProjectsAsObservable(token: string): Observable<any> {
    const query = new RegExp(token, 'i');

    return of(
      this.searchArray.filter((element: any) => {
        return query.test(element.name);
      })
    );
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    e;
  }

  ngOnDestroy() {
    if (this.loadingSub) {
      this.loadingSub.unsubscribe();
    }

    if (this.paginationSub) {
      this.paginationSub.unsubscribe();
    }
  }
}
