export class CourseProgress {
  public completed: boolean;
  public id: string;
  public parent?: string;
  public type: 'topic' | 'lessons';
}

export class LearningProgress {
  public courseId: string;
  public uid: string;
  public courseProgress: CourseProgress[];
}
