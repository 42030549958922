<ngx-extended-pdf-viewer
  [src]="src"
  [enablePrint]="false"
  [showSidebarButton]="userCanAccessControls()"
  [ignoreKeyboard]="!userCanAccessControls()"
  [contextMenuAllowed]="false"
  [showBorders]="false"
  [showBookModeButton]="false"
  [showDownloadButton]="false"
  [showFindButton]="false"
  [showOpenFileButton]="false"
  [showPagingButtons]="userCanAccessControls()"
  [showPresentationModeButton]="true"
  [showPrintButton]="false"
  [showPropertiesButton]="false"
  [showRotateButton]="false"
  [showScrollingButton]="true"
  [showSecondaryToolbarButton]="false"
  [zoom]="'page-fit'"
  showSelectToolButton="false"
  (pageChange)="onPageChange($event)"
  [page]="currentPage || 0"
  [handTool]="false"
  useBrowserLocale="true"
  height="100vh"
></ngx-extended-pdf-viewer>
