import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ShoppingCartItem, User } from '@codecraft-works/data-models';
import {
  faBars,
  faChalkboardTeacher,
  faChevronLeft,
  faChevronRight,
  faFileCode,
  faPuzzlePiece,
  faQuestionCircle,
  faShoppingCart,
  faSignOutAlt,
  faTachometerAlt,
  faTrophy,
  faUserCog,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import { Observable, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthService } from '../../core/auth/auth.service';
import { ShoppingCartService } from '../../core/shopping-cart/shopping-cart.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnDestroy {
  user$: Observable<User>;
  route: string;

  /**
   * Font awesome table icon
   */
  faUserCog = faUserCog;
  faShoppingCart = faShoppingCart;
  faFileCode = faFileCode;
  faTachometerAlt = faTachometerAlt;
  faChalkboardTeacher = faChalkboardTeacher;
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;
  faQuestionCircle = faQuestionCircle;
  faSignOutAlt = faSignOutAlt;
  faSignInAlt = faSignInAlt;
  faBars = faBars;
  faUserShield = faUserShield;
  faTrophy = faTrophy;
  faPuzzlePiece = faPuzzlePiece;

  userSub: Subscription;
  user: User;
  cart$: Observable<ShoppingCartItem[]>;
  modalSubscription: Subscription;
  showLabels = true;

  constructor(
    public router: Router,
    private userService: AuthService,
    private shoppingCartService: ShoppingCartService
  ) {
    this.route = this.router.url;

    this.user$ = this.userService.getUser();

    this.cart$ = this.user$.pipe(
      mergeMap((user) => {
        return this.shoppingCartService.getShoppingCartItems(user.uid);
      })
    );
  }

  logOut() {
    window.sessionStorage.removeItem('loading');
    this.userService.logoutUserService();
    this.router.navigate(['/login']);
  }

  ngOnDestroy() {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }
}
