<div class="container-fluid">
  <ng-container *ngIf="loaded; else loading">
    <ng-container *ngIf="chunkedData[currentChunk]?.length > 0; else noData">
      <div
        class="row"
        [ngClass]="{
          'no-gutters rounded dark-shadow mb-3': noGutters
        }"
      >
        <ng-container [ngSwitch]="path">
          <ng-container *ngSwitchCase="'programs'">
            <app-program-card
              *ngFor="let program of chunkedData[currentChunk]"
              [program]="program"
              class="col-12 col-md-6 col-lg-4 col-xl-3"
              [noGutters]="noGutters"
              [isGrid]="isGrid"
              [hideAddToCart]="hideAddToCart"
              [ngClass]="{
                'mb-3': !noGutters
              }"
            ></app-program-card>
          </ng-container>

          <ng-container *ngSwitchCase="'web-projects'">
            <app-web-project-card
              [featuredCard]="featuredCard"
              *ngFor="let webProject of chunkedData[currentChunk]"
              [webProject]="webProject"
              class="col-12 col-md-6 col-lg-4 col-xl-3"
              [noGutters]="noGutters"
              [isGrid]="isGrid"
              [ngClass]="{
                'mb-3': !noGutters
              }"
            ></app-web-project-card>
          </ng-container>

          <ng-container *ngSwitchCase="'certificate'">
            <app-certificate-card
              *ngFor="let certificate of chunkedData[currentChunk]"
              [certificate]="certificate"
              class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3"
              [noGutters]="noGutters"
              [isGrid]="isGrid"
              [ngClass]="{
                'mb-3': !noGutters
              }"
            ></app-certificate-card>
          </ng-container>

          <ng-container *ngSwitchCase="'educator'">
            <app-educator-card
              *ngFor="let educator of chunkedData[currentChunk]"
              [educator]="educator"
              class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3"
              [noGutters]="noGutters"
              [isGrid]="isGrid"
              [ngClass]="{
                'mb-3': !noGutters
              }"
            ></app-educator-card>
          </ng-container>

          <ng-container *ngSwitchCase="'instructor'">
            <app-instructor-card
              *ngFor="let instructor of chunkedData[currentChunk]"
              [instructor]="instructor"
              class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3"
              [noGutters]="noGutters"
              [isGrid]="isGrid"
              [ngClass]="{
                'mb-3': !noGutters
              }"
            ></app-instructor-card>
          </ng-container>

          <ng-container *ngSwitchCase="'location'">
            <app-location-card
              *ngFor="let location of chunkedData[currentChunk]"
              [location]="location"
              class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3"
              [noGutters]="noGutters"
              [isGrid]="isGrid"
              [ngClass]="{
                'mb-3': !noGutters
              }"
            ></app-location-card>
          </ng-container>

          <ng-container *ngSwitchCase="'student'">
            <app-student-card
              *ngFor="let student of chunkedData[currentChunk]"
              [student]="student"
              class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3"
              [noGutters]="noGutters"
              [isGrid]="isGrid"
              [ngClass]="{
                'mb-3': !noGutters
              }"
            ></app-student-card>
          </ng-container>
        </ng-container>
      </div>
      <!--row-->
      <div class="row">
        <div
          class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 mb-3 d-flex"
        >
          <button
            type="button"
            class="btn btn-dark mr-auto"
            [disabled]="currentChunk === 0"
            [ngClass]="{
              disabled: currentChunk === 0
            }"
            (click)="this.prev()"
          >
            <fa-icon [icon]="faChevronLeft" class="fa-lg"></fa-icon> Prev
          </button>
          <button
            type="button"
            class="btn btn-dark ml-auto"
            [disabled]="currentChunk === chunkedData.length - 1"
            [ngClass]="{
              disabled: currentChunk === chunkedData.length - 1
            }"
            (click)="this.next()"
          >
            Next <fa-icon [icon]="faChevronRight" class="fa-lg"></fa-icon>
          </button>
        </div>
      </div>
      <!--row-->
    </ng-container>
  </ng-container>

  <ng-template #noData>
    <div class="row">
      <card class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
        <h6 header class="display-5">No {{ entityName }}s Found</h6>
        <p class="lead display-6">Let's find one!</p>
      </card>
    </div>
    <!--row-->
  </ng-template>

  <ng-template #loading>
    <div class="row">
      <card class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
        <h6 header class="display-5">Loading {{ entityName }}s ...</h6>
        <codecraft-loading-spinner></codecraft-loading-spinner>
      </card>
    </div>
    <!--row-->
  </ng-template>
</div>
<!--container-->
