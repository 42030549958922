import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  CodecraftQuestion,
  CodecraftQuizInstance,
  Queue,
} from '@codecraft-works/data-models';
import { QuizService } from '../util/quiz.service';

@Component({
  standalone: true,
  selector: 'codecraft-quiz-detail-page',
  template: `
    <ng-container *ngIf="quizInstance">
      <p class="h1">{{ quizInstance.name }}</p>
      <hr />
      <p>Number of Questions remaining: {{ questionCount }}</p>
      <p>Minimum Passing Score: {{ quizInstance.passingScore }}</p>
      <p>
        Maximum Attempts:
        {{
          quizInstance.maxAttempts < 0 ? 'Unlimited' : quizInstance.maxAttempts
        }}
      </p>
      <p>Current attempt: {{ quizInstance.attemptCount }}</p>
      <button
        *ngIf="quizInstance.queue"
        class="btn btn-primary"
        (click)="startQuiz()"
        [disabled]="!firstQuestion || !hasAttemptsRemaining"
      >
        {{ isResuming ? 'Resume' : 'Start' }} Quiz
      </button>
    </ng-container>
  `,
  styles: [],
  imports: [NgIf],
})
export class CodecraftQuizDetailPageComponent implements OnInit {
  quizInstance!: CodecraftQuizInstance | undefined;
  firstQuestion!: CodecraftQuestion | undefined;
  isResuming = false;
  questionCount = 0;
  hasAttemptsRemaining = false;

  constructor(private quizService: QuizService) {}

  async ngOnInit(): Promise<void> {
    try {
      this.isResuming = this.quizService.getQuizState().component == 'resuming';
      this.quizInstance = await this.quizService.getQuizInstanceValue();
      if (!this.quizInstance) {
        this.quizService.setQuizState({
          component: 'error',
          error: 'Invalid quiz state',
        });
        return;
      }
      if (
        this.quizInstance?.queue &&
        this.quizInstance.queue instanceof Queue
      ) {
        const queue = this.quizInstance.queue as Queue<CodecraftQuestion>;
        this.firstQuestion = queue.peek();
        if (!this.firstQuestion || !this.firstQuestion.id) {
          this.quizService.setQuizState({
            component: 'error',
            error: 'No questions in queue',
          });
          return;
        }
        this.questionCount = this.quizInstance.queue.size();
      } else {
        this.quizService.setQuizState({
          component: 'error',
          error: 'No questions in queue',
        });
      }
      this.hasAttemptsRemaining =
        this.quizInstance.attemptCount <= this.quizInstance.maxAttempts ||
        this.quizInstance.maxAttempts < 0;
    } catch (error) {
      console.error(error);
      this.quizService.setQuizState({
        component: 'error',
        error: 'Quiz instance fetch error: ' + error,
      });
    }
  }

  startQuiz() {
    this.quizService.setQuizState({
      component: 'in-progress',
      quizId: this.quizInstance?.quizId,
      questionId: this.firstQuestion?.id,
    });
  }
}
