<card
  [title]="learndashTopic.post_title || ''"
  [showTitle]="[learndashTopic.post_title]"
  [isGrid]="true"
  [showDetailButton]="true"
  [detailUrl]="
    '/courses/' +
    [courseId] +
    '/lesson/' +
    [lessonId] +
    '/topic/' +
    [learndashTopic.ID]
  "
  [detailButtonText]="'View Topic'"
>
  <div
    *ngIf="topicStatus"
    style="font-size: 45px; text-align: center; color: green; position: absolute; bottom:0; right:30px;"
  >
    <fa-icon [icon]="faCheck" class="fa-lg"></fa-icon>
  </div>
</card>
