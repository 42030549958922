import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { Location } from '@codecraft-works/data-models';
import firebase from 'firebase/compat/app';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LocationService {
  constructor(private firebaseDatabase: AngularFirestore) {}

  public getLocations(userID?: string): Observable<Location[]> {
    let snapshot: AngularFirestoreCollection<Location>;
    if (userID) {
      snapshot = this.firebaseDatabase.collection<Location>(
        'locations',
        (ref) => ref.where('uid', '==', userID)
      );
    } else {
      snapshot = this.firebaseDatabase.collection<Location>(
        'locations',
        (ref) => ref.where('public', '==', true)
      );
    }
    return snapshot.snapshotChanges().pipe(
      map((item) => {
        const locations: Location[] = [];
        item.map((a) => {
          const data = a.payload.doc.data() as Location;
          locations.push(data as Location);
        });

        return locations;
      })
    );
  }

  public getAllLocations(): Observable<Location[]> {
    const snapshot: AngularFirestoreCollection<Location> =
      this.getAllLocationsCollection();
    return snapshot.snapshotChanges().pipe(
      map((item) => {
        const locations: Location[] = [];
        item.map((a) => {
          const data = a.payload.doc.data() as Location;
          locations.push(data as Location);
        });

        return locations;
      })
    );
  }

  private getAllLocationsCollection() {
    return this.firebaseDatabase.collection<Location>('locations');
  }

  private getLocationData(location: Location) {
    return this.firebaseDatabase.doc<Location>(location.id);
  }

  public getLocation(key: string): Observable<Location> {
    const location = this.firebaseDatabase
      .collection('locations')
      .doc<Location>(key)
      .valueChanges();
    return location;
  }

  public insertLocation(location: Location) {
    this.getAllLocationsCollection().add({
      id: this.firebaseDatabase.createId(),
      name: location.name,
      created: firebase.firestore.Timestamp.now(),
      modified: firebase.firestore.Timestamp.now(),
      description: location.description,
      address: location.address,
      city: location.city,
      state: location.state,
      zip: location.zip,
      county: location.county,
      country: location.country,
      pictureUrl: location.pictureUrl,
      public: location.public,
      slug: location.slug,
      uid: location.uid,
    });
  }

  public updateLocation(location: Location) {
    return from(
      this.getLocationData(location).update({
        name: location.name,
        modified: firebase.firestore.Timestamp.now(),
        description: location.description,
        address: location.address,
        city: location.city,
        state: location.state,
        zip: location.zip,
        county: location.county,
        country: location.country,
        public: location.public,
        slug: location.slug,
        uid: location.uid,
      })
    );
  }

  public deleteLocation(location: Location): Observable<void> {
    if (location) {
      return from(this.getLocationData(location).delete());
    } else {
      throw new Error("Can't delete without a key.");
    }
  }
}
