import { Observable } from 'rxjs';

export class Media {
  $key: string;
  file: File;
  name: string;
  url: Observable<string>;
  progress: Observable<number>;
  createdAt: Date = new Date();
  constructor(file: File) {
    this.file = file;
  }
}
