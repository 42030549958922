import { Component, OnInit } from '@angular/core';
import * as JitsiMeet from 'jitsi-meet-wrapper';

@Component({
  selector: 'app-jitsi-meet',
  templateUrl: './jitsi-meet.component.html',
  styleUrls: ['./jitsi-meet.component.css']
})
export class JitsiMeetComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    const meet = new JitsiMeet('https://meet.jit.si');
    meet.on('ready', () => {
      const conference = meet.join('CodecraftUCF', '#meet');
      conference.on('joined', () => {});
    });
  }
}
