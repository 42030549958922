import {
  AfterViewInit,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { AuthService } from '@app/core/auth/auth.service';
import { UserService } from '@app/core/user/user.service';
import { User } from '@codecraft-works/data-models';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { combineLatest, Observable, of } from 'rxjs';
import { delay, filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-user-type-ahead-form',
  templateUrl: './user-type-ahead-form.component.html',
  styleUrls: ['./user-type-ahead-form.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserTypeaheadFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UserTypeaheadFormComponent),
      multi: true,
    },
  ],
})
export class UserTypeaheadFormComponent
  implements OnInit, AfterViewInit, ControlValueAccessor, Validator
{
  user$: Observable<User>;

  userTypeaheadFormGroup: FormGroup;

  selectUser: FormControl = new FormControl();

  loadingComplete: boolean;
  noResult: boolean;
  userSelected: User;
  selectionMade: boolean;

  @Input()
  users: User[];

  users$: Observable<User[]>;
  dataSource$: Observable<User[]>;

  onTouched: () => void = () => {};

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.user$ = this.authService.getUser();
    this.users$ = this.userService.getUsers();

    this.userTypeaheadFormGroup = this.formBuilder.group({
      selectUser: this.selectUser,
    });
  }

  ngAfterViewInit(): void {
    this.dataSource$ = combineLatest([
      this.selectUser.valueChanges.pipe(
        filter((value) => {
          return typeof value === 'string';
        })
      ),
      this.users ? of(this.users) : this.users$,
    ]).pipe(
      map(([token, users]) => {
        const query = new RegExp(token, 'i');
        return users.filter((user: User) => {
          return query.test(user.displayName + user.userName + user.email);
        });
      })
    );

    this.loadingComplete = true;
  }

  writeValue(val: any): void {
    if (val && this.selectionMade) {
      this.userTypeaheadFormGroup.setValue(
        { selectUser: val },
        { emitEvent: false }
      );
    } else {
      this.userTypeaheadFormGroup.setValue(
        { selectUser: null },
        { emitEvent: false }
      );
    }
  }

  registerOnChange(fn: any): void {
    this.userTypeaheadFormGroup.valueChanges.pipe(delay(0)).subscribe(fn);
    this.onTouched();
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled
      ? this.userTypeaheadFormGroup.disable()
      : this.userTypeaheadFormGroup.enable();
  }

  validate(): ValidationErrors | null {
    return this.selectionMade
      ? null
      : {
          invalidForm: {
            valid: false,
            message: 'User selection form invalid',
          },
        };
  }

  /* Used in template */
  /* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
  onUserSelect(event: TypeaheadMatch): void {
    this.userSelected = event.item as User;
    this.selectUser.setValue(event.item as User);
    this.selectionMade = true;
    this.userTypeaheadFormGroup.markAsDirty();
  }

  chooseAnotherUser(): void {
    this.selectionMade = false;
    this.selectUser.setValue(null);
    this.userTypeaheadFormGroup.markAsPristine();
  }
}
