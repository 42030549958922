import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AuthService } from '@app/core/auth/auth.service';
import { User } from '@codecraft-works/data-models';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { ShoppingCartService } from '../shopping-cart.service';

@Component({
  selector: 'app-shopping-cart-page',
  templateUrl: './shopping-cart-page.component.html',
  styleUrls: ['./shopping-cart-page.component.css']
})
export class ShoppingCartPageComponent implements OnInit, AfterViewInit {
  user$: Observable<User>;
  fragment: string;
  modalRef: BsModalRef;
  @ViewChild('canceled') canceledModal;

  constructor(
    private userService: AuthService,
    private route: ActivatedRoute,
    private titleService: Title,
    private modalService: BsModalService,
    private shoppingCartService: ShoppingCartService,
    private analyticsService: AnalyticsService
  ) {
    this.fragment = this.route.snapshot.fragment;
    this.titleService.setTitle('Codecraft Works - Shopping Cart');
  }

  ngOnInit() {
    this.user$ = this.userService.getUser();
  }

  ngAfterViewInit() {
    switch (this.fragment) {
      case 'canceled':
        this.user$
          .pipe(
            take(1),
            mergeMap(user => {
              return this.shoppingCartService
                .getShoppingCartItems(user.uid)
                .pipe(take(1));
            })
          )
          .subscribe(cart => {
            this.analyticsService.checkoutCanceledEvent(cart);
          });

        this.openModal(this.canceledModal);
        break;

      default:
        break;
    }
  }

  /**
   * Handles opening any modal
   * @param modalName
   */
  openModal(modalName: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modalName, {
      class: 'modal-md modal-dialog modal-dialog-centered'
    });
  }
}
