import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HeroBlockComponent } from './hero-block/hero-block.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { CodecraftQuizComponent } from './quiz/quiz.component';
import { TicTacToeComponent } from './tic-tac-toe/tic-tac-toe.component';

@NgModule({
  imports: [CommonModule, CodecraftQuizComponent, LoadingSpinnerComponent],
  declarations: [HeroBlockComponent, TicTacToeComponent],
  exports: [
    HeroBlockComponent,
    TicTacToeComponent,
    CodecraftQuizComponent,
    LoadingSpinnerComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedComponentsModule {}
