import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/auth/auth.service';
import { MembershipService } from '@app/core/membership/membership.service';
import { LearndashService } from '@app/shared/learndash/learndash.service';
import { Membership, Student, User } from '@codecraft-works/data-models';
import {
  faCheckCircle,
  faChevronLeft,
  faIdBadge,
} from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-membership-details',
  templateUrl: './membership-details.component.html',
  styleUrls: ['./membership-details.component.css'],
})
export class MembershipDetailsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  user$: Observable<User>;
  membership$: Observable<Membership>;
  routeKey: string;
  fragment: string;
  modalRef: BsModalRef;
  @ViewChild('assign') assign;
  @ViewChild('assignExisting') assignExisting;
  @ViewChild('newUser') newUser;
  @ViewChild('membershipAssigned') membershipAssigned;
  @ViewChild('cancelMembership') cancelMembership;
  routeFragmentSub: Subscription;
  faCheckCircle = faCheckCircle;
  faIdBadge = faIdBadge;
  faChevronLeft = faChevronLeft;
  newStudents: any[];
  newStudentReturnMessage: string;
  newStudentSuccess: boolean;
  newStudentSub: Subscription;
  public addStudentForm: FormGroup = new FormGroup({
    addStudentFormGroup: new FormControl(''),
  });
  membershipAssignmentFormGroup = new FormControl('');
  public membershipAssignmentForm: FormGroup = new FormGroup({
    membershipAssignmentFormGroup: this.membershipAssignmentFormGroup,
  });

  membership: Membership;
  loading = false;
  assignFail = false;
  combinedSubscription: Subscription;

  constructor(
    private userService: AuthService,
    private membershipService: MembershipService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private learndashService: LearndashService
  ) {
    this.routeKey = this.route.snapshot.params.membershipId;
  }

  ngOnInit() {
    // User Observable
    this.user$ = this.userService.getUser();
    this.membership$ = this.membershipService.getMembership(this.routeKey);
  }

  /**
   * Handles opening any modal
   * @param modalName
   */
  openModal(modalName: TemplateRef<any>, modalFragment?: string) {
    // Check for fragment change, change route and then have checkFragment() open the modal through the
    if (modalFragment && modalFragment !== this.fragment) {
      this.router.navigate(['./'], {
        fragment: modalFragment,
        relativeTo: this.route,
      });
    } else {
      if (this.modalRef) {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(modalName, {
        class: 'modal-lg modal-dialog modal-dialog-centered',
        animated: true,
      });
    }
  }

  closeAllModals() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
    this.router.navigate([]);
  }

  addNewStudent() {
    this.newStudents = this.addStudentForm.get(
      'addStudentFormGroup'
    ).value.newStudents;
    this.newStudents.map((newStudentData) => {
      const newStudent: Partial<Student> = {
        age: newStudentData.age,
        user: {
          displayName: newStudentData.name,
          email: newStudentData.email,
          uid: null,
        },
      };
      this.newStudentSub = this.user$
        .pipe(
          mergeMap((user) =>
            this.membershipService.createStudentForCustomer(newStudent, user)
          )
        )
        .subscribe(
          (newStudentUser) => {
            this.newStudentReturnMessage = `Your student, ${newStudentUser.user.displayName},  has been invited!`;
            this.newStudentSuccess = true;
            this.loading = false;
            this.openModal(this.assignExisting, 'assignExisting');
          },
          (error) => {
            this.newStudentReturnMessage =
              error.error.error || 'Something went wrong';
            this.newStudentSuccess = false;
            this.loading = false;
          }
        );
    });
  }

  async assignExistingStudent() {
    const selectedUser = this.membershipAssignmentForm.get(
      'membershipAssignmentFormGroup'
    ).value.selectedMembers;

    let unassignedUserId;

    // unassign current student if present
    if (this.membership.memberUser) {
      unassignedUserId = this.membership.memberUser.uid;
      await this.membershipService
        .unassignMembership(this.membership.id)
        .toPromise();
    }

    this.membershipService
      .redeemMembershipByCode(
        this.membership.membershipRedemptionCode,
        selectedUser.uid || selectedUser
      )
      .subscribe(
        async () => {
          await this.learndashService
            .updateCourseAccess(this.membership.programId, {
              usersAdded: [selectedUser.uid],
              usersRemoved: unassignedUserId ? [unassignedUserId] : null,
            })
            .toPromise();
          this.loading = false;
          this.membershipAssignmentForm
            .get('membershipAssignmentFormGroup')
            .setValue({ selectedMembers: '' });
          this.closeAllModals();
          this.checkFragment('membershipAssigned');
        },
        () => {
          this.loading = false;
          this.assignFail = true;
        }
      );
  }

  checkFragment(fragment: string) {
    this.fragment = fragment;
    switch (fragment) {
      case 'assign':
        this.openModal(this.assign);
        break;
      case 'assignExisting':
        this.openModal(this.assignExisting);
        break;
      case 'newUser':
        this.openModal(this.newUser);
        break;
      case 'membershipAssigned':
        this.openModal(this.membershipAssigned);
        break;
    }
  }

  closeModal() {
    this.modalRef.hide();
    this.loading = false;
  }

  ngAfterViewInit() {
    this.combinedSubscription = combineLatest([
      this.membership$,
      this.route.fragment,
    ])
      .pipe(
        map(([membership, fragment]) => ({
          membership,
          fragment,
        }))
      )
      .subscribe((combinedObject) => {
        this.membership = combinedObject.membership;
        if (this.fragment !== combinedObject.fragment) {
          this.router.navigate([]);
          this.checkFragment(combinedObject.fragment);
        }
      });
  }

  ngOnDestroy() {
    this.closeAllModals();
    if (this.combinedSubscription) {
      this.combinedSubscription.unsubscribe();
    }

    if (this.newStudentSub) {
      this.newStudentSub.unsubscribe();
    }
  }
}
