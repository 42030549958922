import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ProgramService } from '@app/shared/program/program.service';
import { StudentService } from '@app/shared/student/student.service';
import { Student, User } from '@codecraft-works/data-models';
import { environment } from '@environments/environment';
import { FirebaseOptions } from 'firebase/app';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { LearndashService } from '../learndash/learndash.service';

@Component({
  selector: 'app-signup-code',
  templateUrl: './signup-code.component.html',
  styleUrls: ['./signup-code.component.css'],
})
export class SignupCodeComponent implements OnInit {
  firebaseConfig: FirebaseOptions = environment.firebaseConfig;
  @Input()
  user: User;

  programSignUpCode: FormControl;

  programForm: FormGroup;

  isInvalid: boolean;

  returnMessage: string;

  constructor(
    private formBuilder: FormBuilder,
    private programService: ProgramService,
    private studentService: StudentService,
    private router: Router,
    private db: AngularFirestore,
    private learndashService: LearndashService
  ) {}

  ngOnInit() {
    this.programSignUpCode = new FormControl('');

    this.programForm = this.formBuilder.group({
      programSignUpCode: this.programSignUpCode,
    });
  }

  signUp() {
    this.returnMessage = '';
    this.isInvalid = false;
    const programSignUpCode: string =
      this.programSignUpCode.value.toLowerCase();
    const programRef = this.db.firestore
      .collection('sign-up-codes')
      .doc(programSignUpCode);

    programRef.get().then((docSnapshot) => {
      if (!docSnapshot.exists) {
        this.returnMessage = 'Invalid program code';
        this.isInvalid = true;
      } else {
        const student$ = this.studentService.getStudent(this.user.uid);
        const program$ = this.programService.getProgram(
          docSnapshot.data().programId
        );

        const studentAndProgram$ = combineLatest([student$, program$]);
        studentAndProgram$
          .pipe(take(1))
          .subscribe(async ([student, program]) => {
            switch (true) {
              case !student: {
                const newStudent: Partial<Student> = {
                  enrolledPrograms: [program.id],
                  uid: this.user.uid,
                  id: this.user.uid,
                };
                const newStudentUser = await this.studentService.insertStudent(
                  this.user,
                  newStudent
                );
                this.programService
                  .updateProgramUsers(newStudentUser, program)
                  .subscribe(async (response) => {
                    await this.learndashService
                      .updateCourseAccess(program.id, {
                        usersAdded: [newStudentUser.uid],
                      })
                      .toPromise();
                    this.router.navigate(['/programs', response.programId]);
                  });
                break;
              }
              case (student &&
                student.enrolledPrograms &&
                !student.enrolledPrograms.includes(program.id)) ||
                (student && !student.enrolledPrograms): {
                const updatedStudent = student;
                if (!updatedStudent.enrolledPrograms) {
                  updatedStudent.enrolledPrograms = [];
                }
                updatedStudent.enrolledPrograms.push(program.id);
                this.studentService.updateStudent(updatedStudent, this.user);
                this.programService
                  .updateProgramUsers(student, program)
                  .subscribe(async (response) => {
                    await this.learndashService
                      .updateCourseAccess(program.id, {
                        usersAdded: [updatedStudent.uid],
                      })
                      .toPromise();
                    this.router.navigate(['/programs', response.programId]);
                  });
                break;
              }
              case student.enrolledPrograms &&
                student.enrolledPrograms.includes(program.id): {
                this.returnMessage = `We are sorry, you are already enrolled in the program, ${program.name}.`;
                this.isInvalid = true;
                break;
              }
              default:
                this.returnMessage = 'We are sorry, something went wrong.';
                this.isInvalid = true;
                break;
            }
          });
      }
    });
  }

  alertOnClosed() {
    this.returnMessage = '';
    this.isInvalid = false;
  }
}
