import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoadingSpinnerComponent } from '@codecraft-works/shared-components';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AgGridModule } from 'ag-grid-angular';
import 'blockly/blocks';
import { NgxBlocklyNewModule as NgxBlocklyModule } from 'ngx-blockly-new';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { UserService } from '../core/user/user.service';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { CardComponent } from './card/card.component';
import { CertificateCardComponent } from './card/certificate-card/certificate-card.component';
import { EducatorCardComponent } from './card/educator-card/educator-card.component';
import { InstructorCardComponent } from './card/instructor-card/instructor-card.component';
import { InstructorCertificateComponent } from './card/instructor-certificate/instructor-certificate.component';
import { LearndashCourseCardComponent } from './card/learndash-course-card/learndash-course-card.component';
import { LearndashLessonCardComponent } from './card/learndash-lesson-card/learndash-lesson-card.component';
import { LearndashTopicCardComponent } from './card/learndash-topic-card/learndash-topic-card.component';
import { LocationCardComponent } from './card/location-card/location-card.component';
import { ProgramAddCartButtonComponent } from './card/program-card/program-add-cart-button/program-add-cart-button.component';
import { ProgramAddWishlistButtonComponent } from './card/program-card/program-add-wishlist-button/program-add-wishlist-button.component';
import { ProgramCardComponent } from './card/program-card/program-card.component';
import { ProgramCopyButtonComponent } from './card/program-card/program-copy-button/program-copy-button.component';
import { QuizCardComponent } from './card/quiz-card/quiz-card.component';
import { StudentCardComponent } from './card/student-card/student-card.component';
import { WebProjectCardComponent } from './card/web-project-card/web-project-card.component';
import { CcpcSubmissionFormComponent } from './ccpc/ccpc-submission-form/ccpc-submission-form.component';
import { CcpcSubmissionFormModalComponent } from './ccpc/ccpc-submission-form-modal/ccpc-submission-form-modal.component';
import { CcpcTournamentBracketComponent } from './ccpc/ccpc-tournament-bracket/ccpc-tournament-bracket.component';
import { CcpcTournamentMatchComponent } from './ccpc/ccpc-tournament-match/ccpc-tournament-match.component';
import { CcpcService } from './ccpc/ccpc.service';
import { CertificateService } from './certificate/certificate.service';
import { ConsoleComponent } from './console/console.component';
import { CustomMaxDirective } from './directives/custom-max-validator.directive';
import { CustomMinDirective } from './directives/custom-min-validator.directive';
import { ExternalLinkDirective } from './directives/external-link.directive';
import { EditorComponent } from './editor/editor.component';
import { EducatorService } from './educator/educator.service';
import { GameDisplayComponent } from './game/game-display/game-display.component';
import { GameDisplayGridComponent } from './game/game-display-grid/game-display-grid.component';
import { GameNewProjectButtonComponent } from './game-project/game-new-project-button/game-new-project-button.component';
import { GameProjectService } from './game-project/game-project.service';
import { GoogleClassroomButtonComponent } from './google-classroom-button/google-classroom-button.component';
import { InstructorService } from './instructor/instructor.service';
import { JitsiMeetComponent } from './jitsi-meet/jitsi-meet.component';
import { LearndashProgressBarComponent } from './learndash/learndash-progress-bar/learndash-progress-bar.component';
import { LearndashService } from './learndash/learndash.service';
import { LocationService } from './location/location.service';
import { MarkdownEditorDirective } from './markdown/markdown-editor/markdown-editor.directive';
import { MarkdownPreviewComponent } from './markdown/markdown-preview/markdown-preview.component';
import { NavbarItemComponent } from './navbar/navbar-item/navbar-item.component';
import { NavbarComponent } from './navbar/navbar.component';
import { GridComponent } from './pagination/grid/grid.component';
import { PaginationService } from './pagination/pagination.service';
import { SliderComponent } from './pagination/slider/slider.component';
import { AttendanceRowComponent } from './pagination/table/attendance-row/attendance-row.component';
import { MembershipRowComponent } from './pagination/table/membership-row/membership-row.component';
import { ProgramRowComponent } from './pagination/table/program-row/program-row.component';
import { TableComponent } from './pagination/table/table.component';
import { CartButtonCellComponent } from './pagination/table-view/cart-button-cell/cart-button-cell.component';
import { CoachEditButtonCellComponent } from './pagination/table-view/coach-edit-button-cell/coach-edit-button-cell.component';
import { CompetitionEditButtonCellComponent } from './pagination/table-view/competition-edit-button-cell/competition-edit-button-cell.component';
import { CompetitionTitleLinkCellComponent } from './pagination/table-view/competition-title-link-cell/competition-title-link-cell.component';
import { EmailLinkCellComponent } from './pagination/table-view/email-link-cell/email-link-cell.component';
import { GameProjectTitleLinkCellComponent } from './pagination/table-view/game-project-title-link-cell/game-project-title-link-cell.component';
import { ProgramCopyButtonCellComponent } from './pagination/table-view/program-copy-button-cell/program-copy-button-cell.component';
import { ProgramEditButtonCellComponent } from './pagination/table-view/program-edit-button-cell/program-edit-button-cell.component';
import { ProgramTitleLinkCellComponent } from './pagination/table-view/program-title-link-cell/program-title-link-cell.component';
import { SeatCountCellComponent } from './pagination/table-view/seat-count-cell/seat-count-cell.component';
import { SponsorEditButtonCellComponent } from './pagination/table-view/sponsor-edit-button-cell/sponsor-edit-button-cell.component';
import { SubmissionProjectCellComponent } from './pagination/table-view/submission-project-cell/submission-project-cell.component';
import { TableViewComponent } from './pagination/table-view/table-view.component';
import { TournamentEditButtonCellComponent } from './pagination/table-view/tournament-edit-button-cell/tournament-edit-button-cell.component';
import { TournamentTitleLinkCellComponent } from './pagination/table-view/tournament-title-link-cell/tournament-title-link-cell.component';
import { UserEditButtonCellComponent } from './pagination/table-view/user-edit-button-cell/user-edit-button-cell.component';
import { UserRoleCellComponent } from './pagination/table-view/user-role-cell/user-role-cell.component';
import { UsernameLinkCellComponent } from './pagination/table-view/username-link-cell/username-link-cell.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { ExternalLinkPipe } from './pipes/external-link.pipe';
import { InfinteLoopPreventionPipe } from './pipes/infinte-loop-prevention.pipe';
import { InstructorNotesPipe } from './pipes/instructor-notes.pipe';
import { KebabCasePipe } from './pipes/kebab-case.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeURLPipe } from './pipes/safe-url.pipe';
import { SyntaxHighlightPipe } from './pipes/syntax-highlight.pipe';
import { ProgramService } from './program/program.service';
import { QuizEditFormComponent } from './quiz/quiz-edit-form/quiz-edit-form.component';
import { QuizEditQuestionModalComponent } from './quiz/quiz-edit-question-modal/quiz-edit-question-modal.component';
import { QuizService } from './quiz/quiz.service';
import { ReadmeComponent } from './readme/readme.component';
import { SignupCodeComponent } from './signup-code/signup-code.component';
import { SlidesService } from './slides/slides.service';
import { StripeElementsFormComponent } from './stripe-elements-form/stripe-elements-form.component';
import { StudentService } from './student/student.service';
import { TechnologyService } from './technology/technology.service';
import { UserTypeaheadFormComponent } from './user/type-ahead-form/user-type-ahead-form.component';
import { WebProjectNewProjectButtonComponent } from './web-project/web-project-new-project-button/web-project-new-project-button.component';
import { WebProjectService } from './web-project/web-project.service';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    HttpClientModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    AlertModule.forRoot(),
    TypeaheadModule.forRoot(),
    CollapseModule.forRoot(),
    ClipboardModule,
    NgxExtendedPdfViewerModule,
    AgGridModule,
    NgxBlocklyModule,
    LoadingSpinnerComponent,
  ],
  declarations: [
    JitsiMeetComponent,
    CardComponent,
    ProgramCardComponent,
    InstructorCardComponent,
    InstructorCertificateComponent,
    StudentCardComponent,
    QuizCardComponent,
    QuizEditFormComponent,
    QuizEditQuestionModalComponent,
    WebProjectCardComponent,
    CertificateCardComponent,
    EducatorCardComponent,
    LocationCardComponent,
    ProgramCopyButtonComponent,
    StripeElementsFormComponent,
    WebProjectNewProjectButtonComponent,
    GridComponent,
    SliderComponent,
    SignupCodeComponent,
    SafeURLPipe,
    MarkdownPreviewComponent,
    MarkdownEditorDirective,
    LearndashCourseCardComponent,
    LearndashLessonCardComponent,
    LearndashTopicCardComponent,
    SafeHtmlPipe,
    LearndashProgressBarComponent,
    ProgramAddCartButtonComponent,
    CustomMaxDirective,
    CustomMinDirective,
    KebabCasePipe,
    InstructorNotesPipe,
    PdfViewerComponent,
    TableComponent,
    ProgramRowComponent,
    InfinteLoopPreventionPipe,
    MembershipRowComponent,
    AttendanceRowComponent,
    EditorComponent,
    NavbarComponent,
    NavbarItemComponent,
    GameNewProjectButtonComponent,
    ReadmeComponent,
    CartButtonCellComponent,
    TableViewComponent,
    UserTypeaheadFormComponent,
    CartButtonCellComponent,
    ProgramEditButtonCellComponent,
    SeatCountCellComponent,
    AudioPlayerComponent,
    ProgramAddWishlistButtonComponent,
    CcpcSubmissionFormComponent,
    CcpcSubmissionFormModalComponent,
    ConsoleComponent,
    CompetitionTitleLinkCellComponent,
    ProgramTitleLinkCellComponent,
    GameProjectTitleLinkCellComponent,
    GameDisplayComponent,
    GameDisplayGridComponent,
    ExternalLinkDirective,
    CompetitionEditButtonCellComponent,
    TournamentTitleLinkCellComponent,
    TournamentEditButtonCellComponent,
    SubmissionProjectCellComponent,
    EmailLinkCellComponent,
    GoogleClassroomButtonComponent,
    ProgramCopyButtonCellComponent,
    UsernameLinkCellComponent,
    UserRoleCellComponent,
    UserEditButtonCellComponent,
    SyntaxHighlightPipe,
    CoachEditButtonCellComponent,
    SponsorEditButtonCellComponent,
    ExternalLinkPipe,
    CcpcTournamentBracketComponent,
    CcpcTournamentMatchComponent,
  ],
  providers: [
    UserService,
    StudentService,
    ProgramService,
    InstructorService,
    EducatorService,
    CertificateService,
    LocationService,
    WebProjectService,
    AngularFireDatabase,
    WebProjectService,
    TechnologyService,
    SlidesService,
    PaginationService,
    LearndashService,
    KebabCasePipe,
    InstructorNotesPipe,
    InfinteLoopPreventionPipe,
    GameProjectService,
    CcpcService,
    QuizService,
  ],
  exports: [
    FontAwesomeModule,
    JitsiMeetComponent,
    CardComponent,
    ProgramCardComponent,
    InstructorCardComponent,
    InstructorCertificateComponent,
    StudentCardComponent,
    QuizCardComponent,
    QuizEditFormComponent,
    QuizEditQuestionModalComponent,
    WebProjectCardComponent,
    CertificateCardComponent,
    EducatorCardComponent,
    LocationCardComponent,
    ProgramCopyButtonComponent,
    StripeElementsFormComponent,
    WebProjectNewProjectButtonComponent,
    GridComponent,
    SliderComponent,
    SignupCodeComponent,
    SafeURLPipe,
    SafeHtmlPipe,
    MarkdownPreviewComponent,
    MarkdownEditorDirective,
    LearndashCourseCardComponent,
    LearndashLessonCardComponent,
    LearndashTopicCardComponent,
    LearndashProgressBarComponent,
    ProgramAddCartButtonComponent,
    CustomMaxDirective,
    CustomMinDirective,
    InstructorNotesPipe,
    PdfViewerComponent,
    TableComponent,
    TableViewComponent,
    ProgramRowComponent,
    MembershipRowComponent,
    EditorComponent,
    NavbarComponent,
    NavbarItemComponent,
    GameNewProjectButtonComponent,
    ReadmeComponent,
    AudioPlayerComponent,
    ProgramAddWishlistButtonComponent,
    CcpcSubmissionFormComponent,
    CcpcSubmissionFormModalComponent,
    UserTypeaheadFormComponent,
    UserTypeaheadFormComponent,
    ConsoleComponent,
    CompetitionTitleLinkCellComponent,
    ProgramTitleLinkCellComponent,
    GameProjectTitleLinkCellComponent,
    GameDisplayComponent,
    GameDisplayGridComponent,
    ExternalLinkDirective,
    CompetitionEditButtonCellComponent,
    TournamentTitleLinkCellComponent,
    TournamentEditButtonCellComponent,
    EmailLinkCellComponent,
    GoogleClassroomButtonComponent,
    ProgramCopyButtonCellComponent,
    UsernameLinkCellComponent,
    UserRoleCellComponent,
    UserEditButtonCellComponent,
    SyntaxHighlightPipe,
    CoachEditButtonCellComponent,
    SponsorEditButtonCellComponent,
    ClipboardModule,
    TypeaheadModule,
    ExternalLinkPipe,
    CcpcTournamentBracketComponent,
    CcpcTournamentMatchComponent,
  ],
})
export class SharedModule {}
