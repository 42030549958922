<!-- <ngtt-single-elimination-tree
  [tournament]="formattedTournament"
  [matchTemplate]="myMatchTemplate"
>
</ngtt-single-elimination-tree> -->
<ng-template #myMatchTemplate let-match>
  <app-ccpc-tournament-match
    *ngIf="match"
    [match]="match"
    [type]="tournament.type"
    [showWinner]="showWinner"
  ></app-ccpc-tournament-match>
</ng-template>
