<div
  *ngIf="program && program.stripe && program.stripe.price"
  class="pt-1 row pr-2 pl-3"
>
  <div class="pt-1 pr-1">
    {{ program.stripe.price / 100 | currency }}

    <ng-container *ngIf="program.stripe.type === 'plan'">
      / {{ program.stripe.frequency }}
    </ng-container>
  </div>

  <ng-container *ngIf="program.stripe">
    <form class="float-right" *ngIf="user; else noUser">
      <div *ngIf="program.seats - program.seatMemberships > 0; else waitList">
        <div class="input-group">
          <div class="input-group-prepend " *ngIf="!minimalView">
            <span
              [id]="
                mobile
                  ? 'add-to-cart-quantity-' + program.id + '-mobile'
                  : 'add-to-cart-quantity-' + program.id
              "
              class="small input-group-text pill-button-left"
              >Quantity
            </span>
          </div>
          <input
            #quantity
            [attr.aria-labelledby]="'add-to-cart-quantity-' + program.id"
            type="number"
            min="1"
            [max]="maxSeats"
            class="form-control col-4"
            [value]="programQuantity"
          />
          <div class="input-group-append">
            <button
              [attr.aria-label]="'Add to cart -' + program.name"
              [id]="
                mobile
                  ? 'add-to-cart-' + program.id + '-mobile'
                  : 'add-to-cart-' + program.id
              "
              type="button"
              class="btn btn-primary pill-button-right"
              (click)="addProgramToCart(quantity.value)"
            >
              Add to cart
            </button>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</div>
<ng-template #errorModal>
  <div class="modal-header">
    <h4 class="modal-title">Not Enough Seats</h4>
  </div>
  <div class="modal-body">
    There are fewer seats available than what you attempted to add to your
    shopping cart.
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary btn-sm pull-right"
      aria-label="Close"
      (click)="closeModalAndReturnFocus()"
    >
      Close
    </button>
  </div>
</ng-template>

<ng-template #successModal>
  <div class="modal-body">
    <p>{{ program.name }} added to cart</p>
    <a
      role="button"
      [id]="mobile ? 'goToCartButton' + '-mobile' : 'goToCartButton'"
      class="btn btn-primary btn-sm"
      aria-label="Go To Cart"
      routerLink="/cart"
      (click)="modalRef.hide()"
      (keydown.shift.tab)="switchToClose($event)"
    >
      Go To Cart
    </a>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      role="button"
      [id]="
        mobile
          ? 'closeAddedToCartMobileButton' + '-mobile'
          : 'closeAddedToCartMobileButton'
      "
      class="btn btn-primary   btn-sm pull-right"
      aria-label="Close"
      (click)="closeModalAndReturnFocus()"
      (keydown.tab)="switchToGoToCart($event)"
    >
      Close
    </button>
  </div>
</ng-template>

<ng-template #noUser>
  <div *ngIf="program.id" class="float-right">
    <a
      class="btn btn-primary pill-button text-white"
      [routerLink]="['/login']"
      [queryParams]="{ redirect: '/programs/' + program.id }"
    >
      Login or Sign Up to Enroll</a
    >
  </div>
</ng-template>

<ng-template #waitList>
  <app-program-add-wishlist-button [user]="user" [program]="program">
  </app-program-add-wishlist-button>
</ng-template>
