import { Component, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/auth/auth.service';
import { User } from '@codecraft-works/data-models';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[appLoginButton]',
  templateUrl: './login-button.component.html',
  styleUrls: ['./login-button.component.css'],
  providers: [],
})
export class LoginButtonComponent {
  /**
   * Input for user
   */
  @Input()
  user: User;

  /**
   * Font awesome sign in icon
   */
  faSignInAlt = faSignInAlt;

  constructor(private userService: AuthService, private router: Router) {}

  @HostListener('click')
  logOut() {
    window.sessionStorage.removeItem('loading');
    this.userService.logoutUserService();
    this.router.navigate(['/login']);
  }
}
