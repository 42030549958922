<a
  [href]="'https://classroom.google.com/share?url=' + this.url"
  class="btn btn-primary btn-block text-decoration-none"
  rel="noopener noreferrer nofollow"
  target="_blank"
>
  Share on Google Classroom
  <img
    src="/assets/img/google-classroom-share-button.svg"
    alt="Google Classroom Icon"
    width="24px"
    height="24px"
  />
</a>
