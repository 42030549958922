import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { GameProject } from '@codecraft-works/data-models';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { JSHINT as JSHINTTest } from 'jshint';
import {
  CustomBlock,
  NgxBlocklyComponent,
  NgxBlocklyConfig,
  NgxBlocklyGenerator,
} from 'ngx-blockly-new';
import { CheckBoardBlock } from '../blocks/tic-tac-toe/checkboard.block';
import { EmptyBlock } from '../blocks/tic-tac-toe/empty.block';
import { GameStateBlock } from '../blocks/tic-tac-toe/gamestate.block';
import { MyTeamBlock } from '../blocks/tic-tac-toe/myteam.block';
import { OnMyTurnBlock } from '../blocks/tic-tac-toe/onmyturn.block';
import { OpposingTeamBlock } from '../blocks/tic-tac-toe/opposingteam.block';
import { SubmitMoveBlock } from '../blocks/tic-tac-toe/submitmove.block';
import { GameProjectService } from '../game-project/game-project.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let CodeMirror: any;

(<any>window).JSHINT = JSHINTTest; // require('jshint').JSHINT;

// eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class EditorComponent implements OnChanges, DoCheck, AfterViewInit {
  @Input()
  projectContent: string;

  @Input()
  language: string;

  editorDirty: boolean;

  @Input()
  icon: FaIconComponent;

  @Input()
  title: string;

  @Input()
  project: GameProject;

  /**
   * Determines if the user is an owner of this project
   */
  @Input() isOwner: boolean;

  /**
   * Determines if the user is an admin
   */
  @Input() isAdmin: boolean;

  /**
   * Determines if the user is an instructor
   */
  @Input() isInstructor: boolean;

  /**
   * Determines if the user is a collaborator of this project
   */
  @Input() isCollaborator: boolean;

  @ViewChild('editorContainer', { static: true })
  editorContainer: ElementRef;

  @ViewChild(NgxBlocklyComponent)
  workspace;

  @Output()
  isDirty = new EventEmitter();

  @Output()
  textModified = new EventEmitter<string>();

  lastEditorWidth: number;

  /**
   * Track if editor is loading text for the first time
   */
  firstTimeLoading = true;

  codeMirror;

  compiledCode: string;

  public customBlocks: CustomBlock[] = [
    new OnMyTurnBlock(),
    new MyTeamBlock(),
    new OpposingTeamBlock(),
    new EmptyBlock(),
    new SubmitMoveBlock(),
    new GameStateBlock(),
    new CheckBoardBlock(),
  ];

  public config: NgxBlocklyConfig = {
    toolbox: `<xml xmlns="https://developers.google.com/blockly/xml" id="toolbox" style="display: none">
    <category name="Logic" colour="#5b80a5">
      <block type="controls_if"></block>
      <block type="logic_compare">
        <field name="OP">EQ</field>
      </block>
      <block type="logic_operation">
        <field name="OP">AND</field>
      </block>
      <block type="logic_negate"></block>
      <block type="logic_boolean">
        <field name="BOOL">TRUE</field>
      </block>
      <block type="logic_null"></block>
      <block type="logic_ternary"></block>
    </category>
    <category name="Loops" colour="#5ba55b">
      <block type="controls_repeat_ext">
        <value name="TIMES">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
      <block type="controls_whileUntil">
        <field name="MODE">WHILE</field>
      </block>
      <block type="controls_for">
        <field name="VAR" id="n;v:jg6UjZ5RBBJw(dby">i</field>
        <value name="FROM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="TO">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
        <value name="BY">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="controls_forEach">
        <field name="VAR" id="n|)6y]LM[LxqFv$;)u@s">j</field>
      </block>
      <block type="controls_flow_statements">
        <field name="FLOW">BREAK</field>
      </block>
    </category>
    <category name="Math" colour="#5b67a5">
      <block type="math_number">
        <field name="NUM">0</field>
      </block>
      <block type="math_arithmetic">
        <field name="OP">ADD</field>
        <value name="A">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="math_single">
        <field name="OP">ROOT</field>
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">9</field>
          </shadow>
        </value>
      </block>
      <block type="math_trig">
        <field name="OP">SIN</field>
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">45</field>
          </shadow>
        </value>
      </block>
      <block type="math_constant">
        <field name="CONSTANT">PI</field>
      </block>
      <block type="math_number_property">
        <mutation divisor_input="false"></mutation>
        <field name="PROPERTY">EVEN</field>
        <value name="NUMBER_TO_CHECK">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
      </block>
      <block type="math_round">
        <field name="OP">ROUND</field>
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">3.1</field>
          </shadow>
        </value>
      </block>
      <block type="math_on_list">
        <mutation op="SUM"></mutation>
        <field name="OP">SUM</field>
      </block>
      <block type="math_modulo">
        <value name="DIVIDEND">
          <shadow type="math_number">
            <field name="NUM">64</field>
          </shadow>
        </value>
        <value name="DIVISOR">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
      <block type="math_constrain">
        <value name="VALUE">
          <shadow type="math_number">
            <field name="NUM">50</field>
          </shadow>
        </value>
        <value name="LOW">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="HIGH">
          <shadow type="math_number">
            <field name="NUM">100</field>
          </shadow>
        </value>
      </block>
      <block type="math_random_int">
        <value name="FROM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="TO">
          <shadow type="math_number">
            <field name="NUM">100</field>
          </shadow>
        </value>
      </block>
      <block type="math_random_float"></block>
    </category>
    <category name="Text" colour="#5ba58c">
      <block type="text">
        <field name="TEXT"></field>
      </block>
      <block type="text_join">
        <mutation items="2"></mutation>
      </block>
      <block type="text_append">
        <field name="VAR" id="IH)IWgL{voLf++*Txc$N">item</field>
        <value name="TEXT">
          <shadow type="text">
            <field name="TEXT"></field>
          </shadow>
        </value>
      </block>
      <block type="text_length">
        <value name="VALUE">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="text_isEmpty">
        <value name="VALUE">
          <shadow type="text">
            <field name="TEXT"></field>
          </shadow>
        </value>
      </block>
      <block type="text_indexOf">
        <field name="END">FIRST</field>
        <value name="VALUE">
          <block type="variables_get">
            <field name="VAR" id="nZ4Lu+$EFOf+?.CWaVI*">text</field>
          </block>
        </value>
        <value name="FIND">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="text_charAt">
        <mutation at="true"></mutation>
        <field name="WHERE">FROM_START</field>
        <value name="VALUE">
          <block type="variables_get">
            <field name="VAR" id="nZ4Lu+$EFOf+?.CWaVI*">text</field>
          </block>
        </value>
      </block>
      <block type="text_getSubstring">
        <mutation at1="true" at2="true"></mutation>
        <field name="WHERE1">FROM_START</field>
        <field name="WHERE2">FROM_START</field>
        <value name="STRING">
          <block type="variables_get">
            <field name="VAR" id="nZ4Lu+$EFOf+?.CWaVI*">text</field>
          </block>
        </value>
      </block>
      <block type="text_changeCase">
        <field name="CASE">UPPERCASE</field>
        <value name="TEXT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="text_trim">
        <field name="MODE">BOTH</field>
        <value name="TEXT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="text_print">
        <value name="TEXT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="text_prompt_ext">
        <mutation type="TEXT"></mutation>
        <field name="TYPE">TEXT</field>
        <value name="TEXT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
    </category>
    <category name="Lists" colour="#745ba5">
      <block type="lists_create_with">
        <mutation items="0"></mutation>
      </block>
      <block type="lists_create_with">
        <mutation items="3"></mutation>
      </block>
      <block type="lists_repeat">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">5</field>
          </shadow>
        </value>
      </block>
      <block type="lists_length"></block>
      <block type="lists_isEmpty"></block>
      <block type="lists_indexOf">
        <field name="END">FIRST</field>
        <value name="VALUE">
          <block type="variables_get">
            <field name="VAR" id="5A6Y|9y*zN?Y1a[x2s$(">list</field>
          </block>
        </value>
      </block>
      <block type="lists_getIndex">
        <mutation statement="false" at="true"></mutation>
        <field name="MODE">GET</field>
        <field name="WHERE">FROM_START</field>
        <value name="VALUE">
          <block type="variables_get">
            <field name="VAR" id="5A6Y|9y*zN?Y1a[x2s$(">list</field>
          </block>
        </value>
      </block>
      <block type="lists_setIndex">
        <mutation at="true"></mutation>
        <field name="MODE">SET</field>
        <field name="WHERE">FROM_START</field>
        <value name="LIST">
          <block type="variables_get">
            <field name="VAR" id="5A6Y|9y*zN?Y1a[x2s$(">list</field>
          </block>
        </value>
      </block>
      <block type="lists_getSublist">
        <mutation at1="true" at2="true"></mutation>
        <field name="WHERE1">FROM_START</field>
        <field name="WHERE2">FROM_START</field>
        <value name="LIST">
          <block type="variables_get">
            <field name="VAR" id="5A6Y|9y*zN?Y1a[x2s$(">list</field>
          </block>
        </value>
      </block>
      <block type="lists_split">
        <mutation mode="SPLIT"></mutation>
        <field name="MODE">SPLIT</field>
        <value name="DELIM">
          <shadow type="text">
            <field name="TEXT">,</field>
          </shadow>
        </value>
      </block>
      <block type="lists_sort">
        <field name="TYPE">NUMERIC</field>
        <field name="DIRECTION">1</field>
      </block>
    </category>
    <category name="Colour" colour="#a5745b">
      <block type="colour_picker">
        <field name="COLOUR">#ff0000</field>
      </block>
      <block type="colour_random"></block>
      <block type="colour_rgb">
        <value name="RED">
          <shadow type="math_number">
            <field name="NUM">100</field>
          </shadow>
        </value>
        <value name="GREEN">
          <shadow type="math_number">
            <field name="NUM">50</field>
          </shadow>
        </value>
        <value name="BLUE">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
      </block>
      <block type="colour_blend">
        <value name="COLOUR1">
          <shadow type="colour_picker">
            <field name="COLOUR">#ff0000</field>
          </shadow>
        </value>
        <value name="COLOUR2">
          <shadow type="colour_picker">
            <field name="COLOUR">#3333ff</field>
          </shadow>
        </value>
        <value name="RATIO">
          <shadow type="math_number">
            <field name="NUM">0.5</field>
          </shadow>
        </value>
      </block>
    </category>
    <sep></sep>
    <category name="Variables" colour="#a55b80" custom="VARIABLE"></category>
    <category name="Functions" colour="#995ba5" custom="PROCEDURE"></category>
    <category name="Tic-Tac-Toe" colour="#745ba5">
      <block type="tictactoe_onmyturn"></block>
      <block type="tictactoe_myteam"></block>
      <block type="tictactoe_opposingteam"></block>
      <block type="tictactoe_empty"></block>
      <block type="tictactoe_submitmove">
        <value name="ROW">
          <block type="math_number">
            <field name="NUM">0</field>
          </block>
        </value>
        <value name="COL">
          <block type="math_number">
            <field name="NUM">0</field>
          </block>
        </value>
      </block>
      <block type="tictactoe_gamestate"></block>
      <block type="tictactoe_checkboard">
        <value name="ROW">
          <block type="math_number">
            <field name="NUM">0</field>
          </block>
        </value>
        <value name="COL">
          <block type="math_number">
            <field name="NUM">0</field>
          </block>
        </value>
      </block>
    </category>
  </xml>`,
    defaultBlocks: true,
    trashcan: true,
    generators: [NgxBlocklyGenerator.PYTHON],
  };

  constructor(private gameProjectService: GameProjectService) {}

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      if (
        propName === 'projectContent' &&
        this.firstTimeLoading &&
        this.language !== 'blockly' &&
        this.editorContainer
      ) {
        this.setEditorText(this.editorContainer);
        this.firstTimeLoading = false;
        this.editorDirty = false;
        this.isDirty.emit(this.editorDirty);
      }
    }
  }

  ngDoCheck() {
    /** This check allows the cursor position to work properly if the editor divs are resized.
     * Normally, code mirror only auto-refreshes the editors when the whole window is resized.
     * This check ensures that they are refreshed when editor divs are resized as well */
    // If the width of any of the code editors change
    if (
      this.lastEditorWidth !== this.editorContainer.nativeElement.offsetWidth &&
      this.codeMirror
    ) {
      // Refresh the code mirror editors and set the new width to check
      this.lastEditorWidth = this.editorContainer.nativeElement.offsetWidth;
      this.codeMirror.refresh();
    } else if (
      this.lastEditorWidth !== this.editorContainer.nativeElement.offsetWidth &&
      this.workspace
    ) {
      // Refresh the code mirror editors and set the new width to check
      this.lastEditorWidth = this.editorContainer.nativeElement.offsetWidth;
      this.workspace.resize();
    }
  }

  ngAfterViewInit() {
    if (
      this.workspace &&
      this.firstTimeLoading &&
      this.language === 'blockly' &&
      this.projectContent
    ) {
      this.setEditorText(this.editorContainer);
      this.firstTimeLoading = false;
      this.editorDirty = false;
      this.isDirty.emit(this.editorDirty);
      this.workspace.workspaceToCode(this.workspace.workspace.id);
    }
  }

  private async setEditorText(container: ElementRef) {
    if (this.language === 'blockly' && this.workspace) {
      this.workspace.fromXml(this.projectContent);
      container.nativeElement.setAttribute(
        'aria-label',
        this.language + ' Editor'
      );
    } else {
      // Config
      const codeMirror = CodeMirror(container.nativeElement, {
        lineWrapping: true,
        matchBrackets: true,
        lineNumbers: true,
        theme: 'material-ocean',
        mode: this.language === 'java' ? 'text/x-java' : this.language,
        smartIndent: true,
        foldGutter: true,
        gutters: [
          'CodeMirror-lint-markers',
          'CodeMirror-foldgutter',
          'CodeMirror-linenumbers',
        ],
        lint: true,
        indentWithTabs: false,
        tabSize: this.language === 'python' ? 4 : 2,
        indentUnit: this.language === 'python' ? 4 : 2,
        autoCloseBrackets: true,
        autoCloseTags: true,
        matchTags: {
          bothTags: true,
        },
        autoRefresh: true,
        hint: true,
        extraKeys: {
          'Ctrl-Space': 'autocomplete',
        },
        viewportMargin: 50,
      });

      codeMirror.setOption('extraKeys', {
        Tab: function (cm) {
          const spaces = Array(cm.getOption('indentUnit') + 1).join(' ');
          cm.replaceSelection(spaces);
        },
      });

      this.codeMirror = codeMirror;

      codeMirror.setValue(this.projectContent);

      container.nativeElement.setAttribute(
        'aria-label',
        this.language + ' Editor'
      );

      codeMirror.on('change', () => {
        if (codeMirror.getValue() !== this.projectContent) {
          this.editorDirty = true;
          this.modifyText(codeMirror.getValue());
          this.isDirty.emit(this.editorDirty);
        } else {
          this.editorDirty = false;
          this.isDirty.emit(this.editorDirty);
        }
      });
    }
  }

  modifyText(value: string) {
    this.textModified.emit(value);
  }

  async onSubmit(): Promise<void> {
    if (
      (this.project && (this.isOwner || this.isAdmin || this.isInstructor)) ||
      (this.project.collabOn && this.isCollaborator)
    ) {
      if (this.language === 'blockly') {
        this.workspace.workspaceToCode(this.workspace.workspace.id);
      }
      await this.gameProjectService.saveEditorText(
        {
        key: this.project.id,
        editorCode: this.language === 'blockly'
          ? this.workspace.toXml()
          : this.codeMirror.getValue(),
        compiledCode: this.language === 'blockly' ? this.compiledCode : null,
        language: this.language
        }
      );
    }
  }

  compilePythonCode(code: string): void {
    this.compiledCode = code;

    if (this.compiledCode !== this.project.compiledCode) {
      this.editorDirty = true;
      this.modifyText(this.compiledCode);
      this.isDirty.emit(this.editorDirty);
    } else {
      this.editorDirty = false;
      this.modifyText(this.compiledCode);
      this.isDirty.emit(this.editorDirty);
    }
  }
}
