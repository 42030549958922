<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  version="1.1"
  width="600px"
  height="600px"
  viewBox="0 0 600 600"
>
  <defs />
  <g id="grid">
    <line
      x1="0"
      x2="600"
      y1="200"
      y2="200"
      stroke="rgb(0, 0, 0)"
      stroke-width="10"
      stroke-miterlimit="10"
    />
    <line
      x1="0"
      x2="600"
      y1="400"
      y2="400"
      stroke="rgb(0, 0, 0)"
      stroke-width="10"
      stroke-miterlimit="10"
    />
    <line
      x1="200"
      x2="200"
      y1="0"
      y2="600"
      stroke="rgb(0, 0, 0)"
      stroke-width="10"
      stroke-miterlimit="10"
    />
    <line
      x1="400"
      x2="400"
      y1="0"
      y2="600"
      stroke="rgb(0, 0, 0)"
      stroke-width="10"
      stroke-miterlimit="10"
    />
  </g>
  <circle
    id="o0"
    [style.display]="this.board.row1[0] === 'O' ? 'inline' : 'none'"
    cx="100"
    cy="100"
    r="50"
    [attr.stroke]="this.board.ocolor ? this.board.ocolor : 'rgb(0, 0, 0)'"
    stroke-width="10"
    stroke-miterlimit="10"
    fill="none"
  />
  <circle
    id="o1"
    [style.display]="this.board.row1[1] === 'O' ? 'inline' : 'none'"
    cx="300"
    cy="100"
    r="50"
    [attr.stroke]="this.board.ocolor ? this.board.ocolor : 'rgb(0, 0, 0)'"
    stroke-width="10"
    stroke-miterlimit="10"
    fill="none"
  />
  <circle
    id="o2"
    [style.display]="this.board.row1[2] === 'O' ? 'inline' : 'none'"
    cx="500"
    cy="100"
    r="50"
    [attr.stroke]="this.board.ocolor ? this.board.ocolor : 'rgb(0, 0, 0)'"
    stroke-width="10"
    stroke-miterlimit="10"
    fill="none"
  />
  <circle
    id="o3"
    [style.display]="this.board.row2[0] === 'O' ? 'inline' : 'none'"
    cx="100"
    cy="300"
    r="50"
    [attr.stroke]="this.board.ocolor ? this.board.ocolor : 'rgb(0, 0, 0)'"
    stroke-width="10"
    stroke-miterlimit="10"
    fill="none"
  />
  <circle
    id="o4"
    [style.display]="this.board.row2[1] === 'O' ? 'inline' : 'none'"
    cx="300"
    cy="300"
    r="50"
    [attr.stroke]="this.board.ocolor ? this.board.ocolor : 'rgb(0, 0, 0)'"
    stroke-width="10"
    stroke-miterlimit="10"
    fill="none"
  />
  <circle
    id="o5"
    [style.display]="this.board.row2[2] === 'O' ? 'inline' : 'none'"
    cx="500"
    cy="300"
    r="50"
    [attr.stroke]="this.board.ocolor ? this.board.ocolor : 'rgb(0, 0, 0)'"
    stroke-width="10"
    stroke-miterlimit="10"
    fill="none"
  />
  <circle
    id="o6"
    [style.display]="this.board.row3[0] === 'O' ? 'inline' : 'none'"
    cx="100"
    cy="500"
    r="50"
    [attr.stroke]="this.board.ocolor ? this.board.ocolor : 'rgb(0, 0, 0)'"
    stroke-width="10"
    stroke-miterlimit="10"
    fill="none"
  />
  <circle
    id="o7"
    [style.display]="this.board.row3[1] === 'O' ? 'inline' : 'none'"
    cx="300"
    cy="500"
    r="50"
    [attr.stroke]="this.board.ocolor ? this.board.ocolor : 'rgb(0, 0, 0)'"
    stroke-width="10"
    stroke-miterlimit="10"
    fill="none"
  />
  <circle
    id="o8"
    [style.display]="this.board.row3[2] === 'O' ? 'inline' : 'none'"
    cx="500"
    cy="500"
    r="50"
    [attr.stroke]="this.board.ocolor ? this.board.ocolor : 'rgb(0, 0, 0)'"
    stroke-width="10"
    stroke-miterlimit="10"
    fill="none"
  />
  <g
    id="x0"
    [style.display]="this.board.row1[0] === 'X' ? 'inline' : 'none'"
    [attr.stroke]="this.board.xcolor ? this.board.xcolor : 'rgb(0, 0, 0)'"
  >
    <line
      x1="50"
      x2="150"
      y1="50"
      y2="150"
      stroke-width="10"
      stroke-miterlimit="10"
      fill="none"
    />
    <line
      x1="150"
      x2="50"
      y1="50"
      y2="150"
      stroke-width="10"
      stroke-miterlimit="10"
      fill="none"
    />
  </g>
  <g
    id="x1"
    [style.display]="this.board.row1[1] === 'X' ? 'inline' : 'none'"
    [attr.stroke]="this.board.xcolor ? this.board.xcolor : 'rgb(0, 0, 0)'"
  >
    <line
      x1="250"
      x2="350"
      y1="50"
      y2="150"
      stroke-width="10"
      stroke-miterlimit="10"
      fill="none"
    />
    <line
      x1="350"
      x2="250"
      y1="50"
      y2="150"
      stroke-width="10"
      stroke-miterlimit="10"
      fill="none"
    />
  </g>
  <g
    id="x2"
    [style.display]="this.board.row1[2] === 'X' ? 'inline' : 'none'"
    [attr.stroke]="this.board.xcolor ? this.board.xcolor : 'rgb(0, 0, 0)'"
  >
    <line
      x1="450"
      x2="550"
      y1="50"
      y2="150"
      stroke-width="10"
      stroke-miterlimit="10"
      fill="none"
    />
    <line
      x1="550"
      x2="450"
      y1="50"
      y2="150"
      stroke-width="10"
      stroke-miterlimit="10"
      fill="none"
    />
  </g>
  <g
    id="x3"
    [style.display]="this.board.row2[0] === 'X' ? 'inline' : 'none'"
    [attr.stroke]="this.board.xcolor ? this.board.xcolor : 'rgb(0, 0, 0)'"
  >
    <line
      x1="50"
      x2="150"
      y1="250"
      y2="350"
      stroke-width="10"
      stroke-miterlimit="10"
      fill="none"
    />
    <line
      x1="150"
      x2="50"
      y1="250"
      y2="350"
      stroke-width="10"
      stroke-miterlimit="10"
      fill="none"
    />
  </g>
  <g
    id="x4"
    [style.display]="this.board.row2[1] === 'X' ? 'inline' : 'none'"
    [attr.stroke]="this.board.xcolor ? this.board.xcolor : 'rgb(0, 0, 0)'"
  >
    <line
      x1="250"
      x2="350"
      y1="250"
      y2="350"
      stroke-width="10"
      stroke-miterlimit="10"
      fill="none"
    />
    <line
      x1="350"
      x2="250"
      y1="250"
      y2="350"
      stroke-width="10"
      stroke-miterlimit="10"
      fill="none"
    />
  </g>
  <g
    id="x5"
    [style.display]="this.board.row2[2] === 'X' ? 'inline' : 'none'"
    [attr.stroke]="this.board.xcolor ? this.board.xcolor : 'rgb(0, 0, 0)'"
  >
    <line
      x1="450"
      x2="550"
      y1="250"
      y2="350"
      stroke-width="10"
      stroke-miterlimit="10"
      fill="none"
    />
    <line
      x1="550"
      x2="450"
      y1="250"
      y2="350"
      stroke-width="10"
      stroke-miterlimit="10"
      fill="none"
    />
  </g>
  <g
    id="x6"
    [style.display]="this.board.row3[0] === 'X' ? 'inline' : 'none'"
    [attr.stroke]="this.board.xcolor ? this.board.xcolor : 'rgb(0, 0, 0)'"
  >
    <line
      x1="50"
      x2="150"
      y1="450"
      y2="550"
      stroke-width="10"
      stroke-miterlimit="10"
      fill="none"
    />
    <line
      x1="150"
      x2="50"
      y1="450"
      y2="550"
      stroke-width="10"
      stroke-miterlimit="10"
      fill="none"
    />
  </g>
  <g
    id="x7"
    [style.display]="this.board.row3[1] === 'X' ? 'inline' : 'none'"
    [attr.stroke]="this.board.xcolor ? this.board.xcolor : 'rgb(0, 0, 0)'"
  >
    <line
      x1="250"
      x2="350"
      y1="450"
      y2="550"
      stroke-width="10"
      stroke-miterlimit="10"
      fill="none"
    />
    <line
      x1="350"
      x2="250"
      y1="450"
      y2="550"
      stroke-width="10"
      stroke-miterlimit="10"
      fill="none"
    />
  </g>
  <g
    id="x8"
    [style.display]="this.board.row3[2] === 'X' ? 'inline' : 'none'"
    [attr.stroke]="this.board.xcolor ? this.board.xcolor : 'rgb(0, 0, 0)'"
  >
    <line
      x1="450"
      x2="550"
      y1="450"
      y2="550"
      stroke-width="10"
      stroke-miterlimit="10"
      fill="none"
    />
    <line
      x1="550"
      x2="450"
      y1="450"
      y2="550"
      stroke-width="10"
      stroke-miterlimit="10"
      fill="none"
    />
  </g>
  <g
    id="row1-strike"
    [style.display]="this.board.row1Strike === 'true' ? 'inline' : 'none'"
    [attr.stroke]="
      this.board.strikecolor ? this.board.strikecolor : 'rgba(255, 0, 0, 0.5)'
    "
  >
    <line
      x1="0"
      x2="600"
      y1="100"
      y2="100"
      stroke-width="10"
      stroke-miterlimit="10"
    />
  </g>
  <g
    id="row2-strike"
    [style.display]="this.board.row2Strike === 'true' ? 'inline' : 'none'"
    [attr.stroke]="
      this.board.strikecolor ? this.board.strikecolor : 'rgba(255, 0, 0, 0.5)'
    "
  >
    <line
      x1="0"
      x2="600"
      y1="300"
      y2="300"
      stroke-width="10"
      stroke-miterlimit="10"
    />
  </g>
  <g
    id="row3-strike"
    [style.display]="this.board.row3Strike === 'true' ? 'inline' : 'none'"
    [attr.stroke]="
      this.board.strikecolor ? this.board.strikecolor : 'rgba(255, 0, 0, 0.5)'
    "
  >
    <line
      x1="0"
      x2="600"
      y1="500"
      y2="500"
      stroke-width="10"
      stroke-miterlimit="10"
    />
  </g>
  <g
    id="col1-strike"
    [style.display]="this.board.col1Strike === 'true' ? 'inline' : 'none'"
    [attr.stroke]="
      this.board.strikecolor ? this.board.strikecolor : 'rgba(255, 0, 0, 0.5)'
    "
  >
    <line
      x1="100"
      x2="100"
      y1="0"
      y2="600"
      stroke-width="10"
      stroke-miterlimit="10"
    />
  </g>
  <g
    id="col2-strike"
    [style.display]="this.board.col2Strike === 'true' ? 'inline' : 'none'"
    [attr.stroke]="
      this.board.strikecolor ? this.board.strikecolor : 'rgba(255, 0, 0, 0.5)'
    "
  >
    <line
      x1="300"
      x2="300"
      y1="0"
      y2="600"
      stroke-width="10"
      stroke-miterlimit="10"
    />
  </g>
  <g
    id="col3-strike"
    [style.display]="this.board.col3Strike === 'true' ? 'inline' : 'none'"
    [attr.stroke]="
      this.board.strikecolor ? this.board.strikecolor : 'rgba(255, 0, 0, 0.5)'
    "
  >
    <line
      x1="500"
      x2="500"
      y1="0"
      y2="600"
      stroke-width="10"
      stroke-miterlimit="10"
    />
  </g>
  <g
    id="diag1-strike"
    [style.display]="this.board.diag1Strike === 'true' ? 'inline' : 'none'"
    [attr.stroke]="
      this.board.strikecolor ? this.board.strikecolor : 'rgba(255, 0, 0, 0.5)'
    "
  >
    <line
      x1="10"
      x2="590"
      y1="20"
      y2="580"
      stroke-width="10"
      stroke-miterlimit="10"
    />
  </g>
  <g
    id="diag2-strike"
    [style.display]="this.board.diag2Strike === 'true' ? 'inline' : 'none'"
    [attr.stroke]="
      this.board.strikecolor ? this.board.strikecolor : 'rgba(255, 0, 0, 0.5)'
    "
  >
    <line
      x1="10"
      x2="590"
      y1="580"
      y2="20"
      stroke-width="10"
      stroke-miterlimit="10"
    />
  </g>
</svg>
<slot></slot>
