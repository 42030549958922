import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@codecraft-works/data-models';

@Pipe({
  name: 'instructorNotes'
})
export class InstructorNotesPipe implements PipeTransform {
  transform(value: string, user: User): any {
    // If the user is an instructor or admin, go ahead and let them see everything
    if (user && user.roles && (user.roles.instructor || user.roles.admin)) {
      return value;
    } else {
      return value.replace(
        /<!-- wp:codecraft-learndash-firebase-plugin\/instructor-notes -->[\s\S]*?<!-- \/wp:codecraft-learndash-firebase-plugin\/instructor-notes -->/gm,
        ''
      );
    }
  }
}
