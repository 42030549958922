import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { QuizService } from '@app/shared/quiz/quiz.service';
import {
  CodecraftQuestion,
  CodecraftQuestionChoice,
} from '@codecraft-works/data-models';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-quiz-edit-question-modal',
  templateUrl: './quiz-edit-question-modal.component.html',
  styleUrls: ['./quiz-edit-question-modal.component.css'],
})
export class QuizEditQuestionModalComponent implements OnInit {
  @Input() isModify: boolean;
  @Input() questionData: CodecraftQuestion;
  @Input() modalRef: BsModalRef;
  @Input() isNew: boolean;
  @Input() quizId?: string;

  confirmModal: BsModalRef;

  questionForm: FormGroup;
  submissionError: string;

  loadingSubmission: boolean;

  constructor(
    private quizService: QuizService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    if (this.isNew && !this.quizId) {
      throw new Error('quizId is required when creating a new question');
    }
    this.questionForm = this.createQuestionForm(this.questionData);
    this.loadingSubmission = false;
  }

  async onSubmit() {
    this.loadingSubmission = true;
    const prompt = this.questionForm.get('questionPrompt').value;

    let choices: CodecraftQuestionChoice = {};
    if (this.questionData.type !== 'boolean') {
      const choicesMap = new Map<string, string>();
      Object.entries(
        this.questionData.choices instanceof Map
          ? Object.fromEntries(this.questionData.choices)
          : this.questionData.choices
      ).forEach(([letter]) => {
        const choice = this.questionForm.get('choice_' + letter).value;
        choicesMap.set(letter, choice);
      });
      choices = choicesMap;
    }

    const tags: string[] = [];
    const tagsForm = this.questionForm.get('tags') as FormArray;
    for (let i = 0; i < tagsForm.length; i++) {
      tags.push(tagsForm.at(i).get('tag').value);
    }

    const updatedQuestion: CodecraftQuestion = {
      ...this.questionData,
      prompt,
      choices,
      tags,
    };

    if (
      this.questionData.type === 'boolean' ||
      this.questionData.type === 'single'
    ) {
      const answer: string | boolean = this.questionForm.get('answer').value;
      updatedQuestion.answer = answer;
    } else if (this.questionData.type === 'multiple') {
      const answer = new Map<string, boolean>();
      Object.entries(
        this.questionData.answer instanceof Map
          ? Object.fromEntries(this.questionData.answer)
          : this.questionData.answer
      ).forEach(([letter]) => {
        const isCorrect = (
          this.questionForm.get('checkboxGroup') as FormGroup
        ).get('answer_' + letter).value;
        answer.set(letter, isCorrect);
      });
      updatedQuestion.answer = answer;
    }

    let result;
    if (this.isNew) {
      result = await this.quizService.createQuestion(
        this.quizId,
        updatedQuestion
      );
    } else {
      result = await this.quizService.updateQuestion(updatedQuestion);
    }
    if (result.success) {
      this.modalRef.hide();
    } else {
      console.error('Submission error:', result.error);
      this.submissionError = result.error;
    }
    this.loadingSubmission = false;
  }

  async deleteQuestion(questionId: string) {
    this.loadingSubmission = true;
    const result = await this.quizService.deleteQuestion(questionId);
    if (result.success) {
      this.modalRef.hide();
    } else {
      console.error('Submission error:', result.error);
      this.submissionError = result.error;
    }
    this.loadingSubmission = false;
  }
  createQuestionForm(question: CodecraftQuestion): FormGroup {
    const questionForm: FormGroup = this.formBuilder.group({
      questionPrompt: new FormControl(
        { value: question.prompt, disabled: !this.isModify },
        [Validators.required]
      ),
      tags: new FormArray([]),
    });

    // add tags
    if (!question.tags) {
      question.tags = [];
    }
    question.tags.forEach((tag) => {
      const tags = questionForm.get('tags') as FormArray;
      const tagGroup = new FormGroup({
        tag: new FormControl({ value: tag, disabled: !this.isModify }, [
          Validators.required,
        ]),
      });
      tags.push(tagGroup);
    });

    if (question.type === 'boolean' || question.type === 'single') {
      questionForm.addControl(
        'answer',
        new FormControl({ value: question.answer, disabled: !this.isModify }, [
          Validators.required,
        ])
      );
    }
    if (question.type === 'single' || question.type === 'multiple') {
      Object.entries(
        question.choices instanceof Map
          ? Object.fromEntries(question.choices)
          : question.choices
      ).forEach(([key, value]) => {
        questionForm.addControl(
          'choice_' + key,
          new FormControl({ value: value, disabled: !this.isModify }, [
            Validators.required,
          ])
        );
      });
    }
    if (question.type === 'multiple' && question.answer instanceof Map) {
      const group = {};
      question.answer.forEach((isCorrect, key) => {
        group['answer_' + key] = new FormControl(
          { value: isCorrect, disabled: !this.isModify },
          []
        );
      });
      questionForm.addControl(
        'checkboxGroup',
        new FormGroup(group, {
          validators: requireCheckboxesToBeCheckedValidator(1),
        })
      );
    }
    return questionForm;
  }
  addTag() {
    const tags = this.questionForm.get('tags') as FormArray;
    const tagGroup = new FormGroup({
      tag: new FormControl('', [Validators.required]),
    });
    tags.push(tagGroup);
  }
  removeTag(index: number) {
    const tags = this.questionForm.get('tags') as FormArray;
    tags.removeAt(index);
  }

  /**
   * Handles open modal
   * @param modalTemplate
   */
  openModal(modalTemplate: TemplateRef<unknown>) {
    this.confirmModal = this.modalService.show(modalTemplate, {
      class: 'modal-dialog modal-dialog-centered',
    });
  }
}

function requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
  return function validate(formGroup: FormGroup) {
    let checked = 0;

    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.controls[key];

      if (control.value === true) {
        checked++;
      }
    });

    if (checked < minRequired) {
      return {
        requireCheckboxesToBeChecked: true,
      };
    }

    return null;
  };
}
