import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-email-link-cell',
  templateUrl: './email-link-cell.component.html',
  styleUrls: ['./email-link-cell.component.css']
})
export class EmailLinkCellComponent implements AgRendererComponent {
  email: string;

  constructor() {}

  refresh(params: ICellRendererParams): boolean {
    this.email = params.data.email;
    return true;
  }

  agInit(params: ICellRendererParams): void {
    this.email = params.data.email;
  }
}
