import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  CodecraftAnswer,
  CodecraftQuestion,
} from '@codecraft-works/data-models';

@Component({
  standalone: true,
  selector: 'codecraft-quiz-boolean-answer-question',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <section *ngIf="questionForm && question" [formGroup]="questionForm">
      <p>
        <ng-container
          *ngIf="correctQuestionData && correctQuestionData.isCorrect"
          >🎉&nbsp;</ng-container
        >
        <ng-container
          *ngIf="correctQuestionData && !correctQuestionData.isCorrect"
          >⛔&nbsp;</ng-container
        >
        {{ question.prompt }}
      </p>
      <div>
        <label>
          <input
            [id]="'question_true'"
            formControlName="question"
            type="radio"
            value="true"
          />
          True
          <ng-container *ngIf="correctQuestionData">
            <ng-container *ngIf="correctQuestionData.correctAnswer === true"
              >✅
            </ng-container>
            <ng-container
              *ngIf="
                !correctQuestionData.isCorrect &&
                questionForm.controls.question.value === 'true'
              "
              >❌
            </ng-container>
          </ng-container>
        </label>
      </div>
      <div>
        <label>
          <input
            [id]="'question_false'"
            formControlName="question"
            type="radio"
            value="false"
          />
          False
          <ng-container *ngIf="correctQuestionData">
            <ng-container *ngIf="correctQuestionData.correctAnswer === false"
              >✅
            </ng-container>
            <ng-container
              *ngIf="
                !correctQuestionData.isCorrect &&
                questionForm.controls.question.value === 'false'
              "
              >❌
            </ng-container>
          </ng-container>
        </label>
      </div>
    </section>
  `,
  styles: [],
  imports: [NgIf, ReactiveFormsModule],
})
export class CodecraftQuizBooleanAnswerQuestionComponent {
  @Input() question: CodecraftQuestion | undefined;
  @Input() questionForm: FormGroup | undefined;
  @Input() correctQuestionData:
    | {
        isCorrect: boolean;
        correctAnswer: CodecraftAnswer;
      }
    | undefined;
}
