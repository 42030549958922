<button
  type="button"
  class="btn btn-primary d-block"
  (click)="this.openModal(editSponsorModal)"
>
  <fa-icon [icon]="faEdit" title="Edit"></fa-icon>
</button>

<!--Edit Sponsor Modal-->
<ng-template #editSponsorModal>
  <form id="sponsorForm" [formGroup]="sponsorForm">
    <div class="modal-header bg-primary text-light">
      <h4 class="modal-title pull-left">Edit Sponsor</h4>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="this.modalRef.hide()"
      >
        <span aria-hidden="true" class="text-light">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label for="sponsorName">Name</label>
        <input
          type="text"
          class="form-control"
          id="sponsorName"
          name="sponsorName"
          formControlName="name"
          required
          [ngClass]="{
            'is-invalid': sponsorName.invalid,
            'is-valid': sponsorName.valid && sponsorName.dirty
          }"
        />
      </div>

      <div class="form-group">
        <label for="sponsorOrganization">Organization</label>
        <input
          type="text"
          class="form-control"
          id="sponsorOrganization"
          name="sponsorOrganization"
          formControlName="organization"
        />
      </div>

      <div class="form-group">
        <label for="sponsorEmail">Email</label>
        <input
          type="email"
          class="form-control"
          id="sponsorEmail"
          name="sponsorEmail"
          formControlName="email"
          required
          [ngClass]="{
            'is-invalid': sponsorEmail.invalid,
            'is-valid': sponsorEmail.valid && sponsorEmail.dirty
          }"
        />
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        [disabled]="sponsorForm.invalid"
        class="btn btn-success float-right pull-right"
        (click)="editSponsor(); modalRef.hide()"
      >
        Save Sponsor
      </button>
    </div>
  </form>
</ng-template>
