import { Project } from '../project/project.model';
import { Tag } from '../tag/tag.model';
import { User } from '../user/user.model';
import { WebProjectHTML } from './web-project-html.model';

/**
 * @classdesc Class representing a web project
 */

export class WebProject extends Project {
  /**
   * Map field which has header, footer and body as string, used to generate index.html
   */
  html: WebProjectHTML;

  /**
   * Used to generate index.css in web project
   */
  css: string;

  /**
   * Used to generate index.js in web project
   */
  js: string;

  /**
   * When an infinite loop or long running JS code is detected this flag will be tripped to turn of JS evaluation.
   */
  evalJS: boolean;
}
