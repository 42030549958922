import firebase from 'firebase/compat/app';

export class StripeInvoice {
  id: string;
  created: firebase.firestore.Timestamp;
  amountDue: number;
  amountPaid: number;
  amountRemaining: number;
  periodStart: firebase.firestore.Timestamp;
  periodEnd: firebase.firestore.Timestamp;
  status: string;
  subscriptionId: string;
}
