import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  standalone: true,
  name: 'shuffle',
})
export class ShufflePipe implements PipeTransform {
  transform(value: any): any {
    if (value instanceof Array) {
      const arrayValue = value as any[];
      arrayValue.sort((a, b) => 0.5 - Math.random());
      return arrayValue;
    } else {
      return value;
    }
  }
}
