import { Component, Input } from '@angular/core';
import { Program } from '@codecraft-works/data-models';

@Component({
  selector: 'app-program-row',
  templateUrl: './program-row.component.html',
  styleUrls: ['./program-row.component.css'],
})
export class ProgramRowComponent {
  @Input() program: Program;
  @Input() first: boolean;
  @Input() last: boolean;
  @Input() even: boolean;
  @Input() odd: boolean;

  constructor() {}
}
