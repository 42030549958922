<form
  id="programForm"
  data-test="programCodeForm"
  [formGroup]="programForm"
  class="form-inline"
>
  <div class="form-group">
    <label class="sr-only" for="programSignUpCode">Program Code</label>
    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text">Program Code</div>
      </div>
      <input
        type="text"
        class="form-control"
        id="programSignUpCode"
        data-test="programSignUpCode"
        formControlName="programSignUpCode"
        aria-describedby="programSignUpCodeHelp"
      />
    </div>
  </div>
  <button
    type="button"
    data-test="programSignUpCodeButton"
    class="btn btn-dark ml-2"
    (click)="signUp()"
  >
    Redeem
  </button>
</form>
<small id="programSignUpCodeHelp" class="form-text text-muted">
  Put in the program code that you received to activate your program.
</small>
<alert
  data-test="signupError"
  *ngIf="isInvalid"
  type="danger"
  class="m-0"
  (onClosed)="alertOnClosed()"
>
  {{ returnMessage }}
</alert>
