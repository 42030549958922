import { Component, Input, OnInit } from '@angular/core';
import { CcpcTournament } from '@codecraft-works/data-models';

// TODO: fix removed Ng-tournament-tree functionality + remove these temporary types
type NgttTournament = any;
type NgttRound = any;

@Component({
  selector: 'app-ccpc-tournament-bracket',
  templateUrl: './ccpc-tournament-bracket.component.html',
  styleUrls: ['./ccpc-tournament-bracket.component.css'],
})
export class CcpcTournamentBracketComponent implements OnInit {
  formattedTournament: NgttTournament;
  @Input()
  tournament: CcpcTournament;
  @Input()
  showWinner = true;
  constructor() {}
  ngOnInit(): void {
    this.formattedTournament = {
      rounds: this.tournament.tournamentResults.rounds.map((round) => {
        let type = 'Winnerbracket';
        if (round.gameResults.length === 1 && !round.oddPlayer) {
          type = 'Final';
        }
        if (round.oddPlayer) {
          round.gameResults.push({
            players: [
              round.oddPlayer.teammateName
                ? round.oddPlayer.studentName +
                  ' & ' +
                  round.oddPlayer.teammateName
                : round.oddPlayer.studentName,
              'Bye Round',
            ],
            exitCode: 0,
            winner: round.oddPlayer.teammateName
              ? round.oddPlayer.studentName +
                ' & ' +
                round.oddPlayer.teammateName
              : round.oddPlayer.studentName,
          });
        }
        return {
          type: type,
          matches: round.gameResults,
        } as NgttRound;
      }),
    };
  }
}
