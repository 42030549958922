import { Pipe, PipeTransform } from '@angular/core';
import 'clipboard';
import 'prismjs';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-sass';
import 'prismjs/components/prism-scss';
import 'prismjs/components/prism-typescript';
import 'prismjs/plugins/toolbar/prism-toolbar';
// eslint-disable-next-line import/order
import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let Prism: any;

@Pipe({
  name: 'syntaxHighlight',
  pure: false,
})
export class SyntaxHighlightPipe implements PipeTransform {
  transform(value: string): string {
    Prism.highlightAll();
    return value;
  }
}
