import { Component, Input } from '@angular/core';
import { Location } from '@codecraft-works/data-models';
import { CardComponent } from '../card.component';

@Component({
  selector: 'app-location-card',
  templateUrl: './location-card.component.html',
  styleUrls: ['./location-card.component.css'],
})
export class LocationCardComponent extends CardComponent {
  @Input()
  public location: Location;

  constructor() {
    super();
  }
}
