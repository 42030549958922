import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { User } from '@codecraft-works/data-models';
import firebase from 'firebase/compat/app';
import { from, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class UserService {
  environment = environment;
  constructor(private db: AngularFirestore, private httpClient: HttpClient) {}

  getUsers(): Observable<User[]> {
    return this.getAllUsersCollection().valueChanges();
  }

  getAllUsersCollection() {
    return this.db.collection<User>('users');
  }

  updateUserRole(
    userId: string,
    role: string,
    roleValue: boolean
  ): Promise<void> {
    const userRoleUpdate = {};
    userRoleUpdate[`roles.${role}`] = roleValue;

    return this.getAllUsersCollection().doc(userId).update(userRoleUpdate);
  }

  checkUserNameNotTaken(userName: string): Observable<boolean> {
    const userNameRef = this.db.firestore
      .collection('user-names')
      .doc(userName);
    return from(
      userNameRef.get().then((docSnapshot) => {
        return !docSnapshot.exists;
      })
    );
  }

  uploadPicture(user: User, file: File): Promise<string> {
    const path = '/user/' + user.uid + '/img/' + file.name;
    const userPictureRef = firebase
      .app()
      .storage(this.environment.assetBucket)
      .ref(path);
    return userPictureRef.put(file).then(() => {
      return userPictureRef
        .getDownloadURL()
        .then(async (url: string) => {
          return url;
        })
        .catch((error) => {
          return error;
        });
    });
  }

  getKlaviyoProfile(user: User): Observable<Object> {
    return this.httpClient.post(
      'api/users/getKlaviyoProfile',
      {
        userEmail: user.email,
      },
      { responseType: 'json' }
    );
  }
}
