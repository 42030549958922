import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  Program,
  ShoppingCartItem,
  StripeCustomer,
  User,
} from '@codecraft-works/data-models';
import { FirebaseOptions } from 'firebase/app';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  firebaseConfig: FirebaseOptions = environment.firebaseConfig;
  environment = environment;

  constructor(
    private firebaseDatabase: AngularFirestore,
    private httpClient: HttpClient
  ) {}

  getCustomerByUserId(userId: string): Observable<StripeCustomer> {
    const stripeCustomer = this.firebaseDatabase
      .collection('stripe-customers')
      .doc<StripeCustomer>(userId)
      .valueChanges();

    return stripeCustomer;
  }

  getStripeCustomer(stripeCustomerId: string): Observable<any> {
    return this.httpClient.get(
      `/api/stripe/customers/getById?stripeCustomerId=${stripeCustomerId}`,
      { responseType: 'json' }
    );
  }

  getStripeCustomerByEmail(user: User): Observable<any> {
    return this.httpClient.get(
      `/api/stripe/customers/getByEmail?email=${user.email}`,
      { responseType: 'json' }
    );
  }

  createStripeCustomer(user: User): Observable<any> {
    return this.httpClient.post(
      '/api/stripe/customers/create',
      {
        email: user.email,
        uid: user.uid,
        site: this.firebaseConfig.projectId,
      },
      { responseType: 'json' }
    );
  }

  createStripeSubscriptionSession(
    stripeCustomerId: string,
    items: any[]
  ): Observable<any> {
    const serializedItems = encodeURIComponent(JSON.stringify(items));
    return this.httpClient.get(
      `/api/stripe/sessions/createSubscription?stripeCustomerId=${stripeCustomerId}&serializedItems=${serializedItems}`,
      { responseType: 'json' }
    );
  }

  createStripeSubscription(
    stripeCustomerId,
    stripePlan,
    quantity,
    anchorDate = null,
    proRate = false,
    couponCode = null
  ): Observable<any> {
    return this.httpClient.get(
      `/api/stripe/subscriptions/create?stripeCustomerId=${stripeCustomerId}&stripePlan=${stripePlan}&anchorDate=${anchorDate}&proRate=${proRate}&quantity=${quantity}&couponCode=${couponCode}`,
      { responseType: 'json' }
    );
  }

  createStripeSession(
    stripeCustomerId: string,
    items: ShoppingCartItem[],
    uid: string,
    clientId: string,
    email: string
  ): Observable<HttpResponse<any>> {
    return this.httpClient.post(
      '/api/stripe/sessions/create',
      {
        stripeCustomerId,
        items,
        uid,
        clientId,
        email,
      },
      { observe: 'response' }
    );
  }

  cancelStripeSubscription(
    stripeSubscriptionId,
    stripeSubscriptionItemId,
    membershipId
  ): Observable<any> {
    return this.httpClient.get(
      `/api/stripe/subscriptions/cancel?stripeSubscriptionId=${stripeSubscriptionId}&stripeSubscriptionItemId=${stripeSubscriptionItemId}&membershipId=${membershipId}`,
      { responseType: 'json' }
    );
  }

  updateStripeDefaultSource(
    stripeCustomerId: string,
    stripeSourceId: string
  ): Observable<any> {
    return this.httpClient.get(
      `/api/stripe/customers/sources/updateDefault?stripeCustomerId=${stripeCustomerId}&stripeSourceId=${stripeSourceId}`,
      { responseType: 'json' }
    );
  }

  createStripeSource(
    stripeCustomerId: string,
    stripeSourceId: string
  ): Observable<any> {
    return this.httpClient.get(
      `/api/stripe/customers/sources/create?stripeCustomerId=${stripeCustomerId}&stripeSourceId=${stripeSourceId}`,
      { responseType: 'json' }
    );
  }

  getStripePlan(stripePlanId: string): Observable<any> {
    return this.httpClient.get(
      `/api/stripe/plans/getById?stripePlanId=${stripePlanId}`,
      { responseType: 'json' }
    );
  }

  getStripeProduct(stripeProductId: string): Observable<any> {
    return this.httpClient.get(
      `/api/stripe/products/getById?stripeProductId=${stripeProductId}`,
      { responseType: 'json' }
    );
  }

  getStripeCoupon(couponCode: string): Observable<any> {
    return this.httpClient.get(
      `/api/stripe/coupons/getById?couponCode=${couponCode}`,
      { responseType: 'json' }
    );
  }

  async insertCustomer(user: User, customer) {
    await this.firebaseDatabase.collection('users').doc(user.uid).update({
      stripeCustomerId: customer.id,
    });

    await this.firebaseDatabase
      .collection('stripe-customers')
      .doc(user.uid)
      .set({
        id: user.uid,
        stripeCustomerId: customer.id,
        created: firebase.firestore.Timestamp.now(),
        modified: firebase.firestore.Timestamp.now(),
      });

    customer.subscriptions.data.forEach(async (subscription) => {
      await this.firebaseDatabase
        .collection('stripe-customers')
        .doc(user.uid)
        .collection('subscriptions')
        .doc(subscription.id)
        .set(subscription);
    });

    return user.uid;
  }

  async insertCustomerSubscription(user: User, subscription) {
    await this.firebaseDatabase
      .collection('stripe-customers')
      .doc(user.uid)
      .collection('subscriptions')
      .doc(subscription.id)
      .set(subscription);
  }

  getUserCards(stripeCustomerId: string): Observable<any> {
    return this.httpClient.get(
      `/api/stripe/customers/sources/getAll?stripeCustomerId=${stripeCustomerId}`
    );
  }

  deleteStripeCard(
    stripeCustomerId: string,
    stripeCardId: string
  ): Observable<any> {
    return this.httpClient.post(
      '/api/stripe/customers/sources/delete',
      {
        stripeCustomerId,
        stripeCardId,
      },
      { responseType: 'json' }
    );
  }

  handleStripeProductFromProgram(opts: {
    program: Partial<Program>;
    price?: number;
    frequency?: string;
  }): Observable<any> {
    const { program, price, frequency } = opts;
    return this.httpClient.post(
      '/api/stripe/products/createUpdateFromProgram',
      {
        program,
        price,
        frequency,
      },
      { responseType: 'json' }
    );
  }
}
