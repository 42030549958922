import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  DoCheck,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { User } from '@codecraft-works/data-models';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgxHotjarService } from 'ngx-hotjar';
import { Observable, Subscription } from 'rxjs';
import { buildInfo } from '../environments/build-info';
import { environment } from '../environments/environment';
import { AuthService } from './core/auth/auth.service';

declare let gtag: Function;
declare const klaviyoIdentify: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('openCloseNav', [
      state('open', style({ opacity: 1, transform: 'none', 'margin-left': 0 })),
      state(
        'closed',
        style({
          opacity: 0,
          transform: 'translateX(-{{navWidth}})',
          'margin-left': '-{{navWidth}}',
        }),
        { params: { navWidth: '160px' } }
      ),
      transition('open => closed', [
        animate('750ms cubic-bezier(0.35, 0, 0.25, 1)'),
      ]),
      transition('closed => open', [
        animate('750ms cubic-bezier(0.35, 0, 0.25, 1)'),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy, DoCheck {
  buildInfo = buildInfo;
  allSubs: Subscription = new Subscription();
  user$: Observable<User>;
  faBars = faBars;
  faTimes = faTimes;

  screenWidth: number;
  navWidth = '160px';
  isCollapsed = false;

  @ViewChild('nav', { static: false })
  nav;

  constructor(
    public router: Router,
    private titleService: Title,
    private authService: AuthService,
    private hjService: NgxHotjarService
  ) {
    if (window.innerWidth < 768) {
      // Window initializes in mobile view
      this.isCollapsed = true;
    }
  }

  ngOnInit() {
    this.user$ = this.authService.getUser();

    this.allSubs.add(
      this.user$.subscribe((user) => {
        if (user && user.email && user.uid) {
          klaviyoIdentify({ $email: user.email, $id: user.uid });
          this.hjService.hj('identify', user.uid, { email: user.email });
        }
      })
    );

    this.allSubs.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          gtag('config', environment.googleAnalyticsTrackingId, {
            page_path: event.urlAfterRedirects,
            page_title: this.titleService.getTitle(),
          });
          if (window.innerWidth < 768) {
            this.isCollapsed = true;
          }
        }
      })
    );

    this.screenWidth = window.innerWidth;
  }

  toggleMenu() {
    this.isCollapsed = !this.isCollapsed;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    // Window transitions between desktop/larger screen view into mobile view
    if (window.innerWidth < 768 && this.screenWidth >= 768) {
      // Window transitions between mobile view into desktop/larger screen view
      this.isCollapsed = true;
    } else if (this.screenWidth < 768 && window.innerWidth >= 768) {
      this.isCollapsed = false;
      this.navWidth = `${this.nav.nativeElement.offsetWidth}px`;
    }
    this.screenWidth = window.innerWidth;
  }

  ngDoCheck() {
    if (
      this.nav &&
      this.nav.nativeElement &&
      `${this.nav.nativeElement.offsetWidth}px` !== this.navWidth
    ) {
      this.navWidth = `${this.nav.nativeElement.offsetWidth}px`;
    }
  }

  ngOnDestroy() {
    if (this.allSubs) {
      this.allSubs.unsubscribe();
    }
  }
}
