import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-google-classroom-button',
  templateUrl: './google-classroom-button.component.html',
  styleUrls: ['./google-classroom-button.component.css'],
})
export class GoogleClassroomButtonComponent {
  @Input()
  url: string;

  constructor() {}
}
