import { KeyValuePipe, NgFor, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  CodecraftAnswer,
  CodecraftQuestion,
} from '@codecraft-works/data-models';
import { ShufflePipe } from '../util/shuffle.pipe';

@Component({
  standalone: true,
  selector: 'codecraft-quiz-single-answer-question',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <section *ngIf="question && questionForm" [formGroup]="questionForm">
      <p>
        <ng-container
          *ngIf="correctQuestionData && correctQuestionData.isCorrect"
          >🎉&nbsp;</ng-container
        >
        <ng-container
          *ngIf="correctQuestionData && !correctQuestionData.isCorrect"
          >⛔&nbsp;</ng-container
        >
        {{ question.prompt }}
      </p>
      <div *ngFor="let choice of question.choices | keyvalue | shuffle">
        <label>
          <input
            [id]="question.id + '_' + choice.key"
            name="question"
            formControlName="question"
            type="radio"
            [value]="choice.key"
          />
          {{ choice.value }}
          <ng-container *ngIf="correctQuestionData">
            <ng-container
              *ngIf="choice.key === correctQuestionData.correctAnswer"
              >✅
            </ng-container>
            <ng-container
              *ngIf="
                !correctQuestionData.isCorrect &&
                choice.key === questionForm.controls.question.value
              "
              >❌
            </ng-container>
          </ng-container>
        </label>
      </div>
    </section>
  `,
  styles: [],
  imports: [NgIf, NgFor, ReactiveFormsModule, KeyValuePipe, ShufflePipe],
})
export class CodecraftQuizSingleAnswerQuestionComponent
  implements AfterViewInit
{
  @Input() question: CodecraftQuestion | undefined;
  @Input() questionForm: FormGroup | undefined;
  @Input() correctQuestionData:
    | {
        isCorrect: boolean;
        correctAnswer: CodecraftAnswer;
      }
    | undefined;

  ngAfterViewInit(): void {
    if (this.question.choices instanceof Map) {
      this.question.choices.forEach((_value, key) => {
        const input = document.getElementById(`${this.question.id}_${key}`);
        input?.setAttribute('value', key);
      });
    }
  }
}
