import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CertificateService } from '@app/shared/certificate/certificate.service';
import { Certificate, Instructor } from '@codecraft-works/data-models';
import { Subscription } from 'rxjs';
import { CardComponent } from '../card.component';

@Component({
  selector: 'app-instructor-card',
  templateUrl: './instructor-card.component.html',
  styleUrls: ['./instructor-card.component.css']
})
export class InstructorCardComponent extends CardComponent
  implements OnInit, OnDestroy {
  @Input()
  instructor: Instructor;

  certificates: Certificate[];
  certificateSub: Subscription;

  constructor(private certificateService: CertificateService) {
    super();
  }

  ngOnInit() {
    this.certificateSub = this.certificateService
      .getCertificates(this.instructor.uid, true)
      .subscribe(certificates => (this.certificates = certificates));
  }

  ngOnDestroy() {
    if (this.certificateSub) {
      this.certificateSub.unsubscribe();
    }
  }
}
