import firebase from 'firebase/compat/app';
/**
 * Importing the `User` model to be partially used in this class
 */
import { UserInfo } from '../user/user.model';
import { MembershipLevel } from './membership-level.model';

export class Membership {
  id: string;
  uid: string;
  created: firebase.firestore.Timestamp;
  modified: firebase.firestore.Timestamp;
  stripeParentObjectId: string;
  stripeChildObjectId: string;
  customerUser?: UserInfo;
  memberUser?: UserInfo;
  membershipRedemptionCode?: string;
  programId: string;
  programName: string;
  cost: number;
  paymentMethod: string;
  membershipType: string; // subscription or product
  startDate?: string;
  startTime?: string;
}

export class SubscriptionMembership extends Membership {
  membershipLevel?: MembershipLevel;
  stripeSubscriptionStatus: string;
  cancelAtPeriodEnd: boolean;
  currentPeriodEndDate: firebase.firestore.Timestamp;
  cancelAt: firebase.firestore.Timestamp;
  subscriptionId: string;
  subscriptionItemId: string;
}
