import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { Certificate } from '@codecraft-works/data-models';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CertificateService {
  constructor(private firebaseDatabase: AngularFirestore) {}

  private getAllCertitficatesCollection() {
    return this.firebaseDatabase.collection<Certificate>('certificates');
  }

  private getCertificateData(certificate: Certificate) {
    return this.firebaseDatabase.doc<Certificate>(certificate.id);
  }

  public getCertificates(
    userID?: string,
    publicOnly?: boolean
  ): Observable<Certificate[]> {
    let snapshot: AngularFirestoreCollection<Certificate>;
    if (userID && !publicOnly) {
      snapshot = this.firebaseDatabase.collection<Certificate>(
        'certificates',
        (ref) => ref.where('certifiedUsers', 'array-contains', userID)
      );
    } else if (userID && publicOnly) {
      snapshot = this.firebaseDatabase.collection<Certificate>(
        'certificates',
        (ref) =>
          ref
            .where('certifiedUsers', 'array-contains', userID)
            .where('public', '==', true)
      );
    } else {
      snapshot = this.firebaseDatabase.collection<Certificate>(
        'certificates',
        (ref) => ref.where('public', '==', true)
      );
    }
    return snapshot.snapshotChanges().pipe(
      map((item) => {
        const certificates: Certificate[] = [];
        item.map((element) => {
          const data = element.payload.doc.data() as Certificate;
          certificates.push(data as Certificate);
        });
        return certificates;
      })
    );
  }

  public getCertificate(key: string): Observable<Certificate> {
    const certificate = this.firebaseDatabase
      .collection('certificates')
      .doc<Certificate>(key)
      .valueChanges();
    return certificate;
  }

  public insertCertificate(certificate: Certificate) {
    this.getAllCertitficatesCollection().add({
      id: this.firebaseDatabase.createId(),
      name: certificate.name,
      description: certificate.description,
      color: certificate.color,
      pictureUrl: certificate.pictureUrl,
      public: certificate.public,
      slug: certificate.slug,
      uid: certificate.uid,
      created: certificate.created,
      modified: certificate.modified,
    });
  }

  public updateCertificate(certificate: Certificate) {
    return from(
      this.getCertificateData(certificate).update({
        name: certificate.name,
        description: certificate.description,
        color: certificate.color,
        pictureUrl: certificate.pictureUrl,
        public: certificate.public,
        slug: certificate.slug,
        modified: certificate.modified,
      })
    );
  }

  public deleteCertificate(certificate: Certificate): Observable<void> {
    if (certificate) {
      return from(this.getCertificateData(certificate).delete());
    } else {
      throw new Error("Can't delete without a certificate.");
    }
  }
}
