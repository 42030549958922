<a href="https://www.codecraftworks.com">
  <img
    src="/assets/img/codecraft-works-white-gears-animated.svg"
    height="40px"
    alt="Codecraft Works"
    id="logo"
    class="d-md-none"
  />
</a>

<nav
  #nav
  [@openCloseNav]="{
    value: isCollapsed ? 'closed' : 'open',
    params: { navWidth: this.navWidth }
  }"
  class="nav"
>
  <router-outlet name="nav"></router-outlet>
</nav>

<main class="d-flex">
  <app-navbar-item
    id="dropdown-toggle-item"
    dataItem="dropdown-toggle-item"
    ariaLabel="Hide or Show Menu"
    [faIcon]="isCollapsed === true ? faBars : faTimes"
    aria-pressed="false"
    (click)="toggleMenu()"
    (keydown.enter)="toggleMenu()"
    class="sticky-top pr-2"
  >
  </app-navbar-item>
  <router-outlet></router-outlet>
</main>

<ng-template #spinner>
  <codecraft-loading-spinner class="mx-auto"></codecraft-loading-spinner>
</ng-template>

<section id="buildinfo" class="d-none">
  <ul>
    <li>Build: {{ buildInfo.bitBucketBuildNUmber }}</li>
    <li>Commit: {{ buildInfo.bitBucketCommit }}</li>
  </ul>
</section>
