import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { GameProject, GameType } from '@codecraft-works/data-models';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

interface GridItem {
  gridID: number;
  row: number;
  column: number;
  image?: { src: string; alt: string };
  icon?: { faIcon: FaIconComponent; color: string };
}

@Component({
  selector: 'app-game-display-grid',
  templateUrl: './game-display-grid.component.html',
  styleUrls: ['./game-display-grid.component.css'],
})
export class GameDisplayGridComponent implements OnInit {
  @Input()
  gameProject: GameProject;

  @Input()
  color: string;

  @Input()
  bgImageRef: string;

  gridHTML: string;

  direction = 'vertical';

  @ViewChild('gridContainer', { static: true })
  gridContainer: ElementRef;

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this.buildGrid(this.gameProject.type);
  }

  public buildGrid(gameType: GameType) {
    // Find all existing grids and remove them
    const existingGrids =
      this.gridContainer.nativeElement.querySelectorAll('.grid');
    existingGrids.forEach((grid) => {
      this.gridContainer.nativeElement.removeChild(grid);
    });

    // Determine siz eof each grid and number of grids
    let size = 0;
    const grids: string[] = [];
    if (gameType === GameType.TICTACTOE) {
      size = 3;
      grids.push('1');
    } else if (gameType === GameType.BATTLESHIP) {
      size = 10;
      grids.push('1', '2');
    }

    // Figure out size for each grid square
    const squareWidth = 100 / size;

    // Create grids
    grids.forEach((gridId) => {
      // Create grid and add ID
      const newGrid = this.renderer.createElement('div');
      this.renderer.setAttribute(newGrid, 'id', `displayGrid${gridId}`);
      this.renderer.addClass(newGrid, 'grid');

      // Add new grid title
      const newGridTitle = this.renderer.createElement('h1');
      this.renderer.addClass(newGridTitle, 'display-4');
      this.renderer.appendChild(newGrid, newGridTitle);

      // Create grid rows
      for (let i = 0; i < size; i++) {
        // Create grid row
        const gridRow = this.renderer.createElement('div');
        this.renderer.addClass(gridRow, 'gridRow');
        for (let j = 0; j < size; j++) {
          // Create grid square
          const gridSquare = this.renderer.createElement('div');
          this.renderer.addClass(gridSquare, 'gridSquare');
          if (this.color) {
            this.renderer.setAttribute(
              gridSquare,
              'style',
              'width:' + squareWidth + '%; background-color:' + this.color
            );
          } else if (this.bgImageRef) {
            this.renderer.setAttribute(
              gridSquare,
              'style',
              'width:' +
                squareWidth +
                '%; background-image: url(' +
                this.bgImageRef +
                '); background-position: center; background-size: contain;'
            );
          } else {
            this.renderer.setAttribute(
              gridSquare,
              'style',
              'width:' + squareWidth + '%;'
            );
          }
          const gridContent = this.renderer.createElement('div');
          this.renderer.addClass(gridContent, 'content');
          // Sets a unique id for each grid square, allowing for coordinate-based placement.
          this.renderer.setAttribute(
            gridContent,
            'id',
            `grid${gridId}-row${i}-col${j}`
          );
          this.renderer.appendChild(gridSquare, gridContent);
          this.renderer.appendChild(gridRow, gridSquare);
        }
        // Add grid row to grid
        this.renderer.appendChild(newGrid, gridRow);
      }

      // Add grid to grid container
      this.renderer.appendChild(this.gridContainer.nativeElement, newGrid);
    });
  }

  public clearGrid() {
    // Find all existing grids and remove them
    const existingGridContent =
      this.gridContainer.nativeElement.querySelectorAll('.content');
    existingGridContent.forEach((gridContent) => {
      this.renderer.setStyle(gridContent, 'background-color', this.color);
      while (gridContent.firstChild) {
        gridContent.removeChild(gridContent.firstChild);
      }
    });
  }

  public setTitle(title: string, gridId = 1) {
    // Remove existing text
    const existingTitle = this.gridContainer.nativeElement.querySelector(
      `#displayGrid${gridId} > h1`
    );
    existingTitle.replaceChildren();

    // Add new text
    const gridTitle = this.renderer.createText(title);
    this.renderer.appendChild(existingTitle, gridTitle);
  }

  public placeItem(item: GridItem) {
    if (item.row >= 0 && item.column >= 0) {
      const key = `grid${item.gridID}-row${item.row}-col${item.column}`;
      const gridItem = document.getElementById(key);
      const children = gridItem.childNodes;
      for (const child of Array.from(children)) {
        this.renderer.removeChild(gridItem, child);
      }
      if (item.image) {
        const imageToAdd = this.renderer.createElement('img');
        this.renderer.setAttribute(imageToAdd, 'src', item.image.src);
        this.renderer.setAttribute(imageToAdd, 'alt', item.image.alt);
        this.renderer.appendChild(gridItem, imageToAdd);
      } else if (item.icon) {
        const iconToAdd = item.icon.faIcon;
        iconToAdd.styles = { color: item.icon.color };
        this.renderer.appendChild(gridItem, iconToAdd);
      }
    }
  }

  public setBackgroundColor(
    row: number,
    column: number,
    gridID: number,
    color: string
  ) {
    if (row >= 0 && column >= 0) {
      const key = `grid${gridID}-row${row}-col${column}`;
      const item = document.getElementById(key);
      this.renderer.setStyle(item, 'background-color', `${color}`);
    }
  }

  public removeItem(row: number, column: number, gridID: number) {
    if (row >= 0 && column >= 0) {
      const key = `grid${gridID}-row${row}-col${column}`;
      const gridItem = document.getElementById(key);
      this.renderer.setStyle(gridItem, 'background-color', this.color);
      while (gridItem.firstChild) {
        gridItem.removeChild(gridItem.firstChild);
      }
    }
  }
}
