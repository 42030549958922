import { Component, Input, OnInit } from '@angular/core';
import { ProgramService } from '@app/shared/program/program.service';
import { Program, User } from '@codecraft-works/data-models';
import { faBroadcastTower } from '@fortawesome/free-solid-svg-icons/faBroadcastTower';
import { Observable } from 'rxjs';
import { CardComponent } from '../card.component';

@Component({
  selector: 'app-program-card',
  templateUrl: './program-card.component.html',
  styleUrls: ['./program-card.component.css']
})
export class ProgramCardComponent extends CardComponent implements OnInit {
  @Input()
  program: Program;

  program$: Observable<Program>;

  @Input()
  user: User;

  @Input()
  id: string;

  @Input()
  hideAddToCart = false;

  faBroadcastTower = faBroadcastTower;

  constructor(private programService: ProgramService) {
    super();
  }

  ngOnInit() {
    if (this.id) {
      this.program$ = this.programService.getProgram(this.id);
    }
  }
}
