<div
  class="nav-item"
  [attr.data-item]="dataItem"
  [attr.aria-label]="ariaLabel"
  [ngStyle]="computedStyles"
>
  <fa-icon
    *ngIf="faIcon && !isToggle"
    [icon]="faIcon"
    [spin]="iconSpin"
    [fixedWidth]="true"
    transform="shrink-6"
    class="align-middle"
  >
    <span
      *ngIf="toolTipText"
      class="tooltip-wrapper"
      data-toggle="tooltip"
      [title]="toolTipText"
    ></span>
  </fa-icon>
  <fa-icon
    *ngIf="isToggle"
    [icon]="toggleState ? faToggleOn : faToggleOff"
    [fixedWidth]="true"
    class="align-middle"
  >
    <span
      *ngIf="toolTipText"
      class="tooltip-wrapper"
      data-toggle="tooltip"
      [title]="toolTipText"
    ></span>
  </fa-icon>
  <span
    *ngIf="image"
    [ngClass]="{
      'rounded-circle-image-wrapper': image && isImageCircle,
      'image-wrapper': image && !isImageCircle
    }"
  >
    <img
      [src]="image"
      [alt]="alt ? alt : label ? label : null"
      [title]="toolTipText ? toolTipText : null"
      class="align-middle"
      [ngClass]="{ 'rounded-circle': isImageCircle }"
    />
  </span>
  <div
    *ngIf="label"
    class="icon-label"
    [ngClass]="{ 'd-inline': showLabel, 'd-none': !showLabel }"
  >
    {{ label }}
  </div>
  <ng-content
    [ngClass]="{ 'd-inline': showLabel, 'd-none': !showLabel }"
  ></ng-content>
</div>
