<card
  [title]="learndashCourse.post_title || ''"
  [showTitle]="[learndashCourse.post_title]"
  [isGrid]="true"
  [showDetailButton]="true"
  [detailUrl]="['/courses/' + learndashCourse.ID]"
  detailButtonText="View Course"
>
  <div class="my-2">
    <app-learndash-progress-bar
      *ngIf="courseProgress; else noProgress"
      [progressPercentage]="
        (courseProgress.numOfCompletedSteps / courseProgress.totalSteps) * 100
      "
      [completedSteps]="courseProgress.numOfCompletedSteps"
      [totalSteps]="courseProgress.totalSteps"
      [type]="'steps'"
      [title]="['Progress for ' + learndashCourse.post_title || '']"
    ></app-learndash-progress-bar>
    <ng-template #noProgress>
      <app-learndash-progress-bar
        *ngIf="user"
        [progressPercentage]="0"
        [completedSteps]="0"
        [totalSteps]="1"
        [type]="'steps'"
        [title]="'Not started'"
        [alternateText]="'Not started'"
      ></app-learndash-progress-bar>
    </ng-template>
  </div>
  <p class="text-danger h5" *ngIf="!learndashCourse.public">
    <fa-icon [icon]="faEyeSlash" class="fa-lg"></fa-icon> Not Published
  </p>

  <div
    *ngIf="
      learndashCourse.courseOrder && learndashCourse.courseOrder.length > 0
    "
    class="p-1"
  >
    <p class="h5 mb-1">Lessons</p>

    <ul class="list-group list-group-flush">
      <li
        class="list-group-item"
        *ngFor="
          let orderElement of filterOnlyLessons(learndashCourse.courseOrder)
            | slice : 0 : 6;
          let i = index
        "
      >
        <ng-container *ngIf="i < 5">
          {{ orderElement.post_title }}
        </ng-container>
        <ng-container *ngIf="i === 5">
          <span class="font-italic">... and more</span>
        </ng-container>
      </li>
    </ul>
  </div>
</card>
