import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-seat-count-cell',
  templateUrl: './seat-count-cell.component.html',
  styleUrls: ['./seat-count-cell.component.css']
})
export class SeatCountCellComponent implements AgRendererComponent {
  programSeatMemberships: number;
  programSeats: number;

  constructor() {}

  refresh(params: ICellRendererParams): boolean {
    this.programSeats = params.data.seats;
    this.programSeatMemberships = params.data.seatMemberships;
    return true;
  }
  agInit(params: ICellRendererParams): void {
    this.programSeats = params.data.seats;
    this.programSeatMemberships = params.data.seatMemberships;
  }
}
