import { Injectable } from '@angular/core';
import {
  CodecraftAnswer,
  CodecraftQuestion,
  CodecraftQuestionApiResult,
  CodecraftQuizInstance,
  CodecraftQuizInstanceApiResult,
  Queue,
} from '@codecraft-works/data-models';
import { BehaviorSubject, Observable } from 'rxjs';

export type QuizState = {
  component: 'completed' | 'in-progress' | 'not-started' | 'resuming' | 'error';
  quizId?: string;
  questionId?: string;
  error?: string;
};

type ApiError = {
  error: string;
};

@Injectable({
  providedIn: 'root',
})
export class QuizService {
  private userUid!: string;
  private stateSubject: BehaviorSubject<QuizState>;
  private quizInstance: BehaviorSubject<CodecraftQuizInstance>;

  setQuizState(state: QuizState) {
    if (!this.stateSubject) {
      this.stateSubject = new BehaviorSubject<QuizState>(state);
    }
    this.stateSubject.next(state);
  }
  getQuizState(): QuizState {
    return this.stateSubject.getValue();
  }
  getQuizState$(): Observable<QuizState> {
    return this.stateSubject.asObservable();
  }

  private setQuizInstance(quizInstance: CodecraftQuizInstance) {
    if (!this.quizInstance) {
      this.quizInstance = new BehaviorSubject<CodecraftQuizInstance>(
        quizInstance
      );
    }
    this.quizInstance.next(quizInstance);
  }
  getQuizInstance$(): Observable<CodecraftQuizInstance> {
    return this.quizInstance.asObservable();
  }
  getQuizInstanceValue(): CodecraftQuizInstance {
    return this.quizInstance.getValue();
  }

  async initialize(uid: string, quizId: string): Promise<void> {
    this.userUid = uid;

    // no quizId or uid - error
    if (!quizId || quizId === '' || !uid || uid === '') {
      this.stateSubject = new BehaviorSubject<QuizState>({
        component: 'error',
        error: 'Invalid quiz state - missing quizId or uid',
      });
      return;
    }
    const isComplete = await this.isComplete(quizId);

    if (isComplete) {
      // all instances are complete - show completed page
      this.stateSubject = new BehaviorSubject<QuizState>({
        component: 'completed',
        quizId: quizId,
      });
      return;
    }

    // get current/new quizInstance
    const instance = await this.getQuizInstanceFirestore(quizId);
    this.quizInstance = new BehaviorSubject<CodecraftQuizInstance>(instance);

    let quizStarted = false;

    if (
      !instance ||
      !instance.questions ||
      !(instance.questions instanceof Map)
    ) {
      this.stateSubject = new BehaviorSubject<QuizState>({
        component: 'error',
        error: 'Invalid quiz state - missing quizInstance or questions',
      });
      return;
    }

    for (const question of instance.questions.values()) {
      if (question.resultId) {
        quizStarted = true;
        break;
      }
    }

    if (!quizStarted) {
      this.stateSubject = new BehaviorSubject<QuizState>({
        component: 'not-started',
        quizId: quizId,
      });
      return;
    }

    // found quizInstance that is in progress
    this.stateSubject = new BehaviorSubject<QuizState>({
      component: 'resuming',
      quizId: quizId,
    });
    return;
  }

  async restartQuiz() {
    const quizInstance = await this.getQuizInstanceFirestore();
    this.setQuizInstance(quizInstance);
    const quizState = this.getQuizState();
    this.setQuizState({
      component: 'not-started',
      quizId: quizState.quizId,
    });
  }

  async isComplete(quizId?: string): Promise<boolean> {
    if (!quizId) {
      const quizState = this.getQuizState();
      quizId = quizState.quizId;
    }

    const url = new URL('/api/quiz/isComplete', window.location.origin);
    url.search = new URLSearchParams({
      uid: this.userUid,
      quizId: quizId,
    }).toString();
    const res = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const result = (await res.json()) as
      | {
          isComplete: boolean;
        }
      | ApiError;
    if ('error' in result) {
      console.log('error: ', result.error);
      return false;
    }
    return result.isComplete;
  }

  async submitAnswer(options: {
    question: CodecraftQuestion;
    answerGiven: CodecraftAnswer;
  }): Promise<
    | { error: true }
    | {
        error: false;
        correctAnswer: CodecraftAnswer;
        isCorrect: boolean;
      }
  > {
    const quizInstance = this.getQuizInstanceValue();
    const docOptions: {
      uid: string;
      quizId: string;
      instanceId: string;
      question: CodecraftQuestion;
      answerGiven: CodecraftAnswer;
    } = {
      ...options,
      question: this.convertFromCodecraftQuestion(options.question),
      answerGiven:
        options.answerGiven instanceof Map
          ? Object.fromEntries(options.answerGiven)
          : options.answerGiven,
      uid: this.userUid,
      instanceId: quizInstance.id,
      quizId: quizInstance.quizId,
    };

    const res = await fetch('/api/quiz/submitAnswer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ options: { ...docOptions } }),
    });

    const docResult = (await res.json()) as
      | {
          correctAnswer: CodecraftAnswer;
          isCorrect: boolean;
        }
      | ApiError;

    if ('error' in docResult) {
      console.log('error: ', docResult.error);
      return { error: true };
    }

    return { ...docResult, error: false };
  }

  nextQuestion() {
    const quizInstance = this.getQuizInstanceValue();
    // dequeue question
    if (!(quizInstance.queue instanceof Queue))
      throw new Error('Invalid queue type');
    quizInstance.queue.dequeue();
    this.setQuizInstance(quizInstance);

    const quizState = this.getQuizState();
    // mark quiz complete if no more questions
    if (quizInstance.queue.size() === 0) {
      this.setQuizState({
        component: 'completed',
        quizId: quizState.quizId,
      });
      return;
    }

    // set next question
    const queue = quizInstance.queue as Queue<CodecraftQuestion>;
    this.setQuizState({
      ...quizState,
      questionId: queue.peek().id,
    });
  }

  async getCompletionDetails(quizId?: string) {
    if (!quizId) {
      const quizState = this.getQuizState();
      quizId = quizState.quizId;
    }

    const url = new URL(
      '/api/quiz/getCompletionDetails',
      window.location.origin
    );
    url.search = new URLSearchParams({
      uid: this.userUid,
      quizId: quizId,
    }).toString();

    const res = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const docCompletionDetails = (await res.json()) as
      | {
          completionDetails: {
            recentQuizInstance: CodecraftQuizInstance;
            bestQuizInstance: CodecraftQuizInstance;
          };
        }
      | ApiError;
    if ('error' in docCompletionDetails) {
      console.log('error: ', docCompletionDetails.error);
      throw new Error(
        'Get quiz completion details error: ' + docCompletionDetails.error
      );
    }
    return docCompletionDetails.completionDetails;
  }

  async getQuizInstanceFirestore(
    quizId?: string
  ): Promise<CodecraftQuizInstance> {
    if (!quizId) {
      const quizState = this.getQuizState();
      quizId = quizState.quizId;
    }

    const url = new URL('/api/quiz/getQuizInstance', window.location.origin);
    url.search = new URLSearchParams({
      uid: this.userUid,
      quizId: quizId,
    }).toString();

    const res = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const docQuizInstance = (await res.json()) as {
      quizInstance: CodecraftQuizInstanceApiResult;
    } | null;
    const quizInstance = this.fromFirestore(docQuizInstance.quizInstance);
    return quizInstance;
  }

  convertToCodecraftQuestion(
    question: CodecraftQuestionApiResult
  ): CodecraftQuestion {
    if (question.type === 'boolean') {
      return { ...question, answer: null };
    } else if (question.type === 'multiple') {
      return {
        ...question,
        answer: null,
        choices: new Map(Object.entries(question.choices)),
      };
    } else {
      return {
        ...question,
        answer: null,
        choices: new Map(Object.entries(question.choices)),
      };
    }
  }
  convertFromCodecraftQuestion(question: CodecraftQuestion): CodecraftQuestion {
    const choices =
      question.choices instanceof Map
        ? Object.fromEntries(question.choices)
        : question.choices;
    if (question.type === 'boolean' || question.type === 'single') {
      return {
        ...question,
        choices: choices,
      };
    } else if (question.type === 'multiple') {
      return {
        ...question,
        answer: null,
        choices: choices,
      };
    }
  }

  fromFirestore(
    quizInstance: CodecraftQuizInstanceApiResult
  ): CodecraftQuizInstance {
    const questionMap = new Map(
      Object.entries(quizInstance.questions).map(([questionId, question]) => {
        return [questionId, this.convertToCodecraftQuestion(question)];
      })
    );
    const queueArray: CodecraftQuestion[] = quizInstance.queue.map(
      (question) => {
        return this.convertToCodecraftQuestion(question);
      }
    );
    const queue = new Queue<CodecraftQuestion>(
      queueArray.length,
      false,
      queueArray
    );
    return {
      ...quizInstance,
      questions: questionMap,
      queue: queue,
    };
  }
}
