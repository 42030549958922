import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ConfigService } from '@app/core/config/config.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  title = 'Codecraft Works';
  content: any;
  content2: any;
  docId = 'landing-page-content';

  constructor(
    private titleService: Title,
    private configService: ConfigService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.configService.getConfig(this.docId).subscribe(config => {
      this.content = this.sanitizer.bypassSecurityTrustHtml(config.content);
    });
  }
}
