<ng-container *ngIf="membership">
  <ng-container [ngSwitch]="userView">
    <ng-container *ngSwitchCase="'student'">
      <div
        *ngIf="first"
        id="table-header"
        class="row align-items-center no-gutters p-1 font-weight-bold d-none d-md-flex"
      >
        <div class="col-12 col-sm-4">Program</div>

        <div class="col-12 col-sm-4">Status</div>
        <div class="col-12 col-sm-4">Membership Purchaser</div>
      </div>
      <div
        class="row align-items-center no-gutters p-2"
        [ngClass]="{ first: first, last: last, odd: odd, even: even }"
      >
        <div class="col-12 col-sm-4">
          <label
            for="program"
            class="d-sm-block d-md-none badge badge-pill badge-primary"
            >Program
          </label>
          <p id="program" class="mb-0">
            <a [routerLink]="['/programs/', membership.programId]">
              <h5>
                {{ membership.programName || membership.programId }}
              </h5>
            </a>
          </p>
        </div>

        <div class="col-12 col-sm-4">
          <label
            for="status"
            class="d-sm-block d-md-none badge badge-pill badge-primary"
            >Membership Status
          </label>
          <p id="status" class="mb-0">
            {{ membership.stripeSubscriptionStatus || 'Active' | titlecase }}
          </p>
        </div>

        <div class="col-12 col-sm-4 text-break">
          <label
            for="purchaser"
            class="d-sm-block d-md-none badge badge-pill badge-primary"
            >Membership Purchaser
          </label>
          <p id="purchaser" class="mb-0">
            <strong>{{ membership.customerUser.displayName }}</strong> <br />
            {{ membership.customerUser.email }}
          </p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'admin'">
      <div
        *ngIf="first"
        id="table-header"
        class="row align-items-center no-gutters p-1 font-weight-bold d-none d-md-flex"
      >
        <div class="col-sm-12 col-md-3 text-break">Customer</div>

        <div class="col-sm-12 col-md-3 text-break">Program</div>

        <div class="col-sm-12 col-md-1 text-break">Payment</div>

        <div class="col-sm-12 col-md-2 text-break">Status</div>

        <div class="col-sm-12 col-md-1 text-break">Code</div>

        <div class="col-sm-12 col-md-2 text-break">Enrollment</div>
      </div>

      <div
        class="row align-items-center no-gutters p-2"
        [ngClass]="{ first: first, last: last, odd: odd, even: even }"
      >
        <div class="col-sm-12 col-md-3 text-break pr-2">
          <label
            for="customer"
            class="d-sm-inline d-md-none badge badge-pill badge-primary"
            >Customer
          </label>

          <strong>{{ membership.customerUser.displayName }}</strong> <br />
          {{ membership.customerUser.email }}
        </div>

        <div class="col-sm-12 col-md-3 text-break">
          <a [routerLink]="['/programs/', membership.programId]">
            <label
              for="program"
              class="d-sm-inline d-md-none badge badge-pill badge-primary"
              >Program
            </label>
            <span class="text-truncate">{{
              membership.programName || membership.programId
            }}</span>
          </a>
        </div>

        <div class="col-sm-12 col-md-1 text-break">
          <p
            [attr.data-test]="
              'membership-row-admin-payment-type-' + membership.id
            "
            class="mb-0"
          >
            <label
              for="type"
              class="d-sm-inline d-md-none badge badge-pill badge-primary"
              >Payment
            </label>
            {{
              membership.membershipType === 'one_time'
                ? 'One Time'
                : (membership.membershipType | titlecase)
            }}
          </p>
        </div>

        <div class="col-sm-12 col-md-2 text-break">
          <p
            [attr.data-test]="'membership-row-admin-status-' + membership.id"
            class="mb-0"
          >
            <label
              for="status"
              class="d-sm-inline d-md-none badge badge-pill badge-primary"
              >Status
            </label>
            {{
              (membership.stripeSubscriptionStatus || 'Active') +
                ' - ' +
                (membership.memberUser ? 'Assigned' : 'Unassinged') | titlecase
            }}
          </p>
        </div>

        <div class="col-sm-12 col-md-1 text-break">
          <label
            for="membershipCode"
            class="d-sm-inline d-md-none badge badge-pill badge-primary"
            >Membership code
          </label>
          <p id="membershipCode" class="mb-0">
            {{ membership.membershipRedemptionCode || 'Generating Code...' }}
          </p>
        </div>

        <div class="col-sm-12 col-md-2 text-break">
          <ng-container *ngIf="membership.memberUser; else notAssigned">
            <label
              for="memberUser"
              class="d-sm-inline d-md-none badge badge-pill badge-primary"
              >Enrollment
            </label>
            <a
              [routerLink]="['/user/memberships/', membership.id]"
              [attr.data-test]="
                'membership-row-admin-member-user' + membership.id
              "
              class="mb-0"
            >
              Assigned to
              <strong>{{ membership.memberUser.displayName }}</strong> <br />
              {{ membership.memberUser.email }}
            </a>
            <br />
            <a
              [routerLink]="['/user/memberships/', membership.id]"
              fragment="assign"
              role="button"
              class="btn btn-primary"
            >
              Reassign
            </a>
          </ng-container>
          <ng-template #notAssigned>
            <button
              [routerLink]="['/user/memberships/', membership.id]"
              fragment="assign"
              [attr.data-test]="
                'membership-row-admin-assign-button-' + membership.id
              "
              class="btn btn-primary col-12"
            >
              Assign Membership
            </button>
          </ng-template>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'customer'">
      <div
        *ngIf="first"
        id="table-header"
        class="row align-items-center no-gutters p-1 font-weight-bold d-none d-md-flex"
      >
        <div class="col-12 col-sm-4">Program</div>

        <div class="col-12 col-md-2">Payment Type</div>

        <div class="col-12 col-sm-2">Status</div>

        <div class="col-12 col-sm-4">Enrollment</div>
      </div>

      <div
        class="row align-items-center no-gutters p-2"
        [ngClass]="{ first: first, last: last, odd: odd, even: even }"
      >
        <div class="col-12 col-sm-4 col-md-4">
          <a [routerLink]="['/programs/', membership.programId]">
            <h5>
              <label
                for="program"
                class="d-sm-inline d-md-none badge badge-pill badge-primary"
                >Program
              </label>
              {{ membership.programName || membership.programId }}
            </h5>
          </a>
        </div>

        <div class="col-12 col-sm-2 col-md-2">
          <p
            [attr.data-test]="
              'membership-row-customer-payment-type-' + membership.id
            "
            class="mb-0"
          >
            <label
              for="type"
              class="d-sm-inline d-md-none badge badge-pill badge-primary"
              >Payment Type
            </label>
            {{
              membership.membershipType === 'one_time'
                ? 'One Time'
                : (membership.membershipType | titlecase)
            }}
          </p>
        </div>

        <div class="col-12 col-sm-2 col-md-2">
          <p
            [attr.data-test]="'membership-row-customer-status-' + membership.id"
            class="mb-0"
          >
            <label
              for="status"
              class="d-sm-inline d-md-none badge badge-pill badge-primary"
              >Status
            </label>
            {{ membership.stripeSubscriptionStatus || 'Active' }}
          </p>
        </div>

        <div class="col-12 col-sm-4 col-md-4 text-break">
          <ng-container *ngIf="membership.memberUser; else notAssigned">
            <label
              for="memberUser"
              class="d-sm-inline d-md-none badge badge-pill badge-primary"
              >Enrollment
            </label>
            Assigned to
            <strong>{{ membership.memberUser.displayName }}</strong> <br />
            {{ membership.memberUser.email }}
            <div
              class="btn-group w-100"
              role="group"
              aria-label="Membership Actions"
            >
              <a
                [routerLink]="['/user/memberships/', membership.id]"
                [attr.data-test]="
                  'membership-row-customer-member-user' + membership.id
                "
                class="btn btn-primary w-50"
              >
                View Details
              </a>
              <a
                [routerLink]="['/user/memberships/', membership.id]"
                fragment="assign"
                role="button"
                class="btn btn-light w-50"
              >
                Reassign
              </a>
            </div>
          </ng-container>
          <ng-template #notAssigned>
            <a
              [routerLink]="['/user/memberships/', membership.id]"
              fragment="assign"
              [attr.data-test]="
                'membership-row-customer-assign-button-' + membership.id
              "
              class="btn btn-primary"
            >
              Assign Membership
            </a>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
