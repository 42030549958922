<link
  href="https://fonts.googleapis.com/css?family=Montserrat:500,700,900"
  rel="stylesheet"
/>

<div id="sign-wrapper">
  <header id="header">
    <h1>403 forbidden</h1>
    <div id="strike1" class="strike"></div>
    <div id="strike2" class="strike"></div>
  </header>
  <section id="sign-body">
    <div id="copy-container">
      <h2>Authorized Personnel Only</h2>
      <p>
        <strong>Error 403: Forbidden</strong>. You do not have permission to
        view this page.
      </p>
      <p>Go back to <a href="/">Home</a></p>
    </div>
    <div id="circle-container">
      <svg
        version="1.1"
        viewBox="0 0 500 500"
        preserveAspectRatio="xMinYMin meet"
      >
        <defs>
          <pattern
            id="image"
            patternUnits="userSpaceOnUse"
            height="450"
            width="450"
          >
            <image
              x="25"
              y="25"
              height="450"
              width="450"
              xlink:href="../../../assets/img/codecraft-works-mascot.svg"
            ></image>
          </pattern>
        </defs>
        <circle
          cx="250"
          cy="250"
          r="200"
          stroke-width="40px"
          stroke="#ef5350"
          fill="url(#image)"
        />
        <line
          x1="100"
          y1="100"
          x2="400"
          y2="400"
          stroke-width="40px"
          stroke="#ef5350"
        />
      </svg>
    </div>
  </section>
</div>

<!-- credit to @claireremmert from codepen -->
