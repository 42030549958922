import firebase from 'firebase/compat/app';
import { StripeSubscriptionItem } from './stripe-subscription-item.model';
import { StripeSubscriptionPlan } from './stripe-subscription-plan.model';

export class StripeSubscription {
  id: string;
  created: firebase.firestore.Timestamp;
  modified: firebase.firestore.Timestamp;
  items: StripeSubscriptionItem[];
  metaData: any[];
  quantity: number;
  startDate: firebase.firestore.Timestamp;
  billingCycleAnchor: firebase.firestore.Timestamp;
  cancelAtPeriodEnd: boolean;
  canceledAt: firebase.firestore.Timestamp;
  currentPeriodEnd: firebase.firestore.Timestamp;
  currentPeriodStart: firebase.firestore.Timestamp;
  trialStart: firebase.firestore.Timestamp;
  trialEnd: firebase.firestore.Timestamp;
  plan: StripeSubscriptionPlan;
}
