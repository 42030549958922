import { Component, Input, OnInit } from '@angular/core';
import { User } from '@codecraft-works/data-models';
import { ProgramService } from '../program/program.service';
@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements OnInit {
  messages: Array<string> = [];

  @Input()
  src: string;

  @Input()
  currentPage: number;

  @Input()
  user: User;

  @Input()
  programId: string;

  constructor(private programService: ProgramService) {}

  ngOnInit() {
    // disable scroll on PDF
    (document.querySelector(
      '#viewerContainer'
    ) as HTMLElement).style.overflowY = 'hidden';
  }

  userCanAccessControls(): boolean {
    if (
      this.user &&
      this.user.roles &&
      (this.user.roles.admin || this.user.roles.instructor)
    ) {
      return true;
    }
    return false;
  }

  onPageChange(pageChangeEvent: number): void {
    if (this.userCanAccessControls()) {
      this.programService.updateSlidePage(this.programId, pageChangeEvent);
    }
  }
}
