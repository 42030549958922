import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { isObservable, Observable } from 'rxjs';

@Component({
  selector: 'app-console',
  templateUrl: './console.component.html',
  styleUrls: ['./console.component.css']
})
export class ConsoleComponent implements OnInit, OnChanges {
  @Input()
  consoleText: string | Observable<string>;

  console: string;

  constructor() {}

  ngOnInit() {
    if (typeof this.consoleText === 'string') {
      this.console = this.consoleText;
    } else if (isObservable(this.consoleText)) {
      this.consoleText.subscribe(consoleText => {
        this.console = consoleText;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      if (propName === 'consoleText') {
        if (typeof this.consoleText === 'string') {
          this.console = this.consoleText;
        } else if (isObservable(this.consoleText)) {
          this.consoleText.subscribe(consoleText => {
            this.console = consoleText;
          });
        }
      }
    }
  }
}
