import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CodecraftQuestion } from '@codecraft-works/data-models';
import { Observable, combineLatest, map } from 'rxjs';
import { LoadingSpinnerComponent } from '../../loading-spinner/loading-spinner.component';
import { QuizService } from '../util/quiz.service';
import { CodecraftQuizQuestionPageFormComponent } from './question-page-form.component';

@Component({
  standalone: true,
  selector: 'codecraft-quiz-question-page-wrapper',
  template: `
    <ng-container *ngIf="question$ | async as question; else loading">
      <codecraft-quiz-question-page-form
        [question]="question"
      ></codecraft-quiz-question-page-form>
    </ng-container>
    <ng-template #loading>
      <codecraft-loading-spinner></codecraft-loading-spinner>
    </ng-template>
  `,
  styles: [],
  imports: [
    NgIf,
    AsyncPipe,
    CodecraftQuizQuestionPageFormComponent,
    LoadingSpinnerComponent,
  ],
})
export class CodecraftQuizQuestionPageWrapperComponent implements OnInit {
  question$: Observable<CodecraftQuestion>;

  constructor(private quizService: QuizService) {}
  ngOnInit(): void {
    try {
      this.question$ = combineLatest([
        this.quizService.getQuizInstance$(),
        this.quizService.getQuizState$(),
      ]).pipe(
        map(([quizInstance, quizState]) => {
          if (
            !quizState ||
            !quizState.quizId ||
            !quizState.questionId ||
            !quizInstance
          ) {
            return null;
          }

          if (!(quizInstance.questions instanceof Map)) {
            throw new Error('Invalid question from quiz instance');
          }
          const question = quizInstance.questions.get(quizState.questionId);

          if (!question) {
            this.quizService.setQuizState({
              component: 'error',
              error: 'Invalid question from quiz instance',
            });
            return null;
          }

          return question;
        })
      );
    } catch (error) {
      this.quizService.setQuizState({
        component: 'error',
        error: 'Question load error: ' + error,
      });
    }
  }
}
