<link
  href="https://fonts.googleapis.com/css?family=Montserrat:500,700,900"
  rel="stylesheet"
/>

<div id="sign-wrapper">
  <header id="header">
    <h1>404</h1>
    <div id="strike1" class="strike"></div>
    <div id="strike2" class="strike"></div>
  </header>
  <section id="sign-body">
    <div id="copy-container">
      <h2>Page Not Found!</h2>
      <p>Did you get lost?</p>
      <p>Go back <a href="/">Home</a></p>
    </div>

    <img id="codey" src="../../../assets/img/codey-404.png" />
  </section>
</div>

<!-- credit to @claireremmert from codepen -->
