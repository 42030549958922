<div class="container-fluid p-0">
  <h3 class="mb-3" *ngIf="title">{{ title }}</h3>
  <ng-container
    *ngIf="
      targetObservableArray$ || chunkedData[currentChunk]?.length > 0;
      else noData
    "
  >
    <div class="row no-gutters mb-3">
      <ng-container [ngSwitch]="true">
        <ng-container *ngSwitchCase="path === 'programs'">
          <app-program-row
            *ngFor="
              let program of chunkedData[currentChunk];
              let even = even;
              let odd = odd;
              let first = first;
              let last = last
            "
            [program]="program"
            class="col-12"
            [first]="first"
            [last]="last"
            [even]="even"
            [odd]="odd"
            [ngClass]="{ first: first, last: last, odd: odd, even: even }"
          ></app-program-row>
        </ng-container>
        <ng-container *ngSwitchCase="isAttendancePath(path)">
          <app-attendance-row
            *ngFor="
              let attendanceItem of chunkedData[currentChunk];
              let even = even;
              let odd = odd;
              let first = first;
              let last = last
            "
            [attendanceItem]="attendanceItem"
            class="col-12"
            [first]="first"
            [last]="last"
            [even]="even"
            [odd]="odd"
            [ngClass]="{ first: first, last: last, odd: odd, even: even }"
          ></app-attendance-row>
        </ng-container>
        <ng-container *ngSwitchCase="path === 'memberships'">
          <ng-container
            *ngIf="targetObservableArray$; else membershipNoObservable"
          >
            <ng-container
              *ngIf="
                (targetObservableArray$ | async) as memberships;
                else noData
              "
            >
              <ng-container *ngIf="memberships.length > 0; else noData">
                <app-membership-row
                  *ngFor="
                    let membership of memberships;
                    let even = even;
                    let odd = odd;
                    let first = first;
                    let last = last
                  "
                  [membership]="membership"
                  [userView]="userView"
                  class="col-12"
                  [first]="first"
                  [last]="last"
                  [even]="even"
                  [odd]="odd"
                  [user]="user"
                  [ngClass]="{ first: first, last: last, odd: odd, even: even }"
                ></app-membership-row>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-template #membershipNoObservable>
            <app-membership-row
              *ngFor="
                let membership of chunkedData[currentChunk];
                let even = even;
                let odd = odd;
                let first = first;
                let last = last
              "
              [membership]="membership"
              [userView]="userView"
              class="col-12"
              [first]="first"
              [last]="last"
              [even]="even"
              [odd]="odd"
              [user]="user"
              [ngClass]="{ first: first, last: last, odd: odd, even: even }"
            ></app-membership-row>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>
    <!--row-->
    <div class="row" *ngIf="!targetObservableArray$">
      <div class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 mb-3 d-flex">
        <button
          type="button"
          class="btn btn-dark mr-auto"
          [disabled]="currentChunk === 0"
          [ngClass]="{
            disabled: currentChunk === 0
          }"
          (click)="this.prev()"
        >
          <fa-icon [icon]="faChevronLeft" class="fa-lg"></fa-icon> Prev
        </button>
        <button
          type="button"
          class="btn btn-dark ml-auto"
          [disabled]="lastPage && ((page.done | async) || singlePage)"
          (click)="this.next()"
        >
          Next <fa-icon [icon]="faChevronRight" class="fa-lg"></fa-icon>
        </button>
      </div>
    </div>
    <!--row-->
  </ng-container>
  <ng-template #noData>
    <div class="col-12 container shadow bg-white p-3 rounded text-center">
      <h6 header class="display-5">No {{ entityName }}s Found!</h6>
    </div>
  </ng-template>
</div>
<!--container-->
