import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ProgramService } from '@app/shared/program/program.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-program-add-wishlist-button',
  templateUrl: './program-add-wishlist-button.component.html',
  styleUrls: ['./program-add-wishlist-button.component.css'],
})
export class ProgramAddWishlistButtonComponent implements OnInit, OnDestroy {
  @Input() program;
  @Input() user;
  @Input() mobile = false;
  @Input() minimalView = false;
  programQuantity: number;
  maxSeats: number;

  modalRef: BsModalRef;

  @ViewChild('errorModal', { static: true }) errorModal;
  @ViewChild('successModal', { static: true }) successModal;

  handleCartItem$: Observable<boolean>;
  modalSubscription: Subscription;
  s;
  constructor(
    private modalService: BsModalService,
    private programService: ProgramService
  ) {}

  openModal(template: TemplateRef<any>, program?) {
    if (program) {
      this.modalSubscription = this.modalService.onHidden.subscribe(() => {
        document.getElementById(`add-to-cart-${program.id}`).focus();
      });
    }
    this.modalRef = this.modalService.show(template, {
      class: 'modal-md modal-dialog modal-dialog-centered',
      animated: true,
    });
  }

  closeModalAndReturnFocus() {
    this.modalRef.hide();
    document.getElementById(`add-to-cart-${this.program.id}`).focus();
  }

  ngOnInit() {
    this.maxSeats = this.program.seats;
    this.programQuantity = 1;
  }

  switchToClose() {
    document.getElementById('closeAddedToCartModalButton').focus();
  }

  switchToGoToCart() {
    document.getElementById('goToCartButton').focus();
  }

  async addToWaitlist() {
    const result = await this.programService
      .addToWaitlist(this.user, this.program)
      .toPromise();
    if (result) {
      this.openModal(this.successModal, this.program);
    } else {
      this.openModal(this.errorModal, this.program);
    }
  }

  ngOnDestroy() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }
}
