<card
  [title]="instructor.user.displayName"
  [detailUrl]="['/instructors/' + instructor.uid]"
  [editUrl]="['/admin/instructor/' + instructor.uid]"
  [user]="user"
  [isGrid]="isGrid"
>
  <div *ngIf="certificates && certificates.length > 0" class="pb-4">
    <div class="text-muted">Certificates</div>
    <app-instructor-certificate
      *ngFor="let certificate of certificates"
      [certificate]="certificate"
    ></app-instructor-certificate>
  </div>
</card>
