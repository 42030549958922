<ng-container
  *ngIf="
    (customerMemberships$ | async)?.length > 0 ||
      (studentMemberships$ | async)?.length > 0;
    else noMemberships
  "
>
  <ng-container *ngIf="(customerMemberships$ | async)?.length > 0">
    <div class="container-fluid mb-3 p-0">
      <h3>
        Memberships I Own
        <fa-icon
          (click)="openModal(this.successModal)"
          id="help-icon"
          class="helpButton"
          [icon]="faQuestionCircle"
        >
        </fa-icon>
      </h3>
    </div>

    <app-table
      path="memberships"
      [userView]="'customer'"
      [targetObservableArray$]="customerMemberships$"
      entityName="Membership"
    ></app-table>
  </ng-container>

  <ng-container *ngIf="(studentMemberships$ | async)?.length > 0">
    <div class="container-fluid mb-3 p-0">
      <h3>
        Memberships Assigned to Me
      </h3>
    </div>
    <app-table
      path="memberships"
      [userView]="'student'"
      [targetObservableArray$]="studentMemberships$"
      entityName="Membership"
    ></app-table>
  </ng-container>
</ng-container>

<ng-template #noMemberships>
  <div class="container-fluid p-0">
    <div class="row no-gutters rounded dark-shadow mb-3">
      <div class="col-12 container shadow bg-white p-3 rounded text-center">
        <h6 header class="display-5">No Memberships Found!</h6>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #success>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Thanks for your purchase - the program seat is saved!
    </h4>
  </div>
  <div class="modal-body">
    <p>
      To onboard your student, you'll need to assign the memberships you
      purchased to their account.
    </p>
    <div class="alert alert-light" role="alert">
      <small
        >Note: you may need to wait up to a minute for the memberships to be
        generated.</small
      >
    </div>
    <p>
      Once your membership has loaded, click the `Assign Membership` or
      `Reassign` button next to your membership and follow the directions.
    </p>
    <div class="alert alert-light" role="alert">
      <small>Note: memberships are assigned to the owner by default</small>
    </div>
    <div class="alert alert-light" role="alert">
      <small>Note: you will have to close this window first</small>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="modalRef.hide()">
      Close
    </button>
  </div>
</ng-template>
