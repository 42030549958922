import { Component, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ProgramService } from '@app/shared/program/program.service';
import { Program, User } from '@codecraft-works/data-models';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';

@Component({
  selector: 'app-program-copy-button',
  templateUrl: './program-copy-button.component.html',
  styleUrls: ['./program-copy-button.component.css'],
})
export class ProgramCopyButtonComponent {
  /**
   * Input for program
   */
  @Input() program: Program;

  /**
   * Input for user
   */
  @Input() user: User;

  /**
   * Font awesome code branch
   */
  faCopy = faCopy;

  /**
   * Initialize program service and Angular router
   * @param programService
   * @param router
   */

  constructor(private programService: ProgramService, private router: Router) {}

  /**
   * Fork webproject to current user
   */
  @HostListener('click')
  async copyProgram() {
    const newProgramId = await this.programService.copyProgram(
      this.program,
      this.user
    );
    this.router.navigate([`/admin/program/${newProgramId}/`]);
  }
}
