import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import {
  AttendanceEvent,
  CourseInfo,
  CreateK12StudentTask,
  Instructor,
  InstructorInfo,
  K12Student,
  Location,
  Membership,
  Program,
  ProgramAgeGroup,
  ProgramType,
  Project,
  Student,
  User,
} from '@codecraft-works/data-models';
import firebase from 'firebase/compat/app';
import Papa from 'papaparse';
import { Observable, Subscription, firstValueFrom, from } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { InstructorService } from '../instructor/instructor.service';
import { LocationService } from '../location/location.service';
import { StudentService } from '../student/student.service';

type RowDataType = {
  userName: string;
  password: string;
  displayName: string;
  gradeLevel: K12Student['gradeLevel'];
};
@Injectable()
export class ProgramService {
  myPrograms: Program[];
  myProgramIDs$: Observable<string[]>;
  instructor$: Observable<string[]>;
  programs$: Observable<string[]>;
  student$: Observable<string[]>;
  instructor: Instructor;
  student: Student;
  allSubs: Subscription = new Subscription();
  environment = environment;
  now = new Date();

  constructor(
    private firebaseDatabase: AngularFirestore,
    private studentService: StudentService,
    private instructorService: InstructorService,
    private locationService: LocationService,
    private httpClient: HttpClient
  ) {}

  private async convertStudentCsvToJson(
    csvString: string
  ): Promise<RowDataType[]> {
    return new Promise<RowDataType[]>((resolve) => {
      Papa.parse<RowDataType>(csvString, {
        header: true,
        complete: function (results) {
          resolve(results.data);
        },
      });
    });
  }

  public getPrograms(userID?: string): Observable<Program[]> {
    let snapshot: AngularFirestoreCollection<Program>;
    if (userID) {
      snapshot = this.firebaseDatabase.collection<Program>('programs', (ref) =>
        ref.where('uid', '==', userID).orderBy('modified')
      );
    } else {
      snapshot = this.firebaseDatabase.collection<Program>('programs', (ref) =>
        ref.where('public', '==', true).orderBy('timeAndDate.startDateTime')
      );
    }
    return snapshot.snapshotChanges().pipe(
      map((item) => {
        return item.map((a) => {
          return a.payload.doc.data() as Program;
        });
      })
    );
  }

  public getAllPrograms(options?: {
    publicOnly?: boolean;
    noPartner?: boolean;
    noArchived?: boolean;
    archivedOnly?: boolean;
  }): Observable<Program[]> {
    return this.firebaseDatabase
      .collection<Program>('programs', (ref) => {
        let query:
          | firebase.firestore.CollectionReference
          | firebase.firestore.Query = ref;
        if (options && options.publicOnly) {
          query = query.where('public', '==', true);
        }
        if (options && options.noPartner) {
          const programTypesNoPartner = Object.values(ProgramType).filter(
            (type) => {
              return type !== 'partner';
            }
          );
          query = query
            .where('programType', 'in', programTypesNoPartner)
            .where('timeAndDate.endDateTime', '>', this.now);
        }
        if (options && options.noArchived) {
          query = query.where('archive', '==', false);
        }
        if (options && options.archivedOnly) {
          query = query.where('archive', '==', true);
        }
        return query;
      })
      .valueChanges();
  }

  public getProgramsByLevel(level: number): Observable<Program[]> {
    return this.firebaseDatabase
      .collection<Program>('programs', (ref) => {
        const query: firebase.firestore.Query = ref;
        return query
          .where('public', '==', true)
          .where('level', '==', level)
          .where('timeAndDate.endDateTime', '>', this.now);
      })
      .valueChanges();
  }

  public getProgramsByType(type: string): Observable<Program[]> {
    return this.firebaseDatabase
      .collection<Program>('programs', (ref) => {
        const query: firebase.firestore.Query = ref;
        return query
          .where('public', '==', true)
          .where('programType', '==', <ProgramType>type)
          .where('timeAndDate.endDateTime', '>', this.now);
      })
      .valueChanges();
  }

  public getProgramsByAgeGroup(ageGroup: string): Observable<Program[]> {
    return this.firebaseDatabase
      .collection<Program>('programs', (ref) => {
        const query: firebase.firestore.Query = ref;
        return query
          .where('public', '==', true)
          .where('ageGroup', '==', ProgramAgeGroup[ageGroup])
          .where('timeAndDate.endDateTime', '>', this.now);
      })
      .valueChanges();
  }

  public getStudentsByProgram(programId: string): Observable<Student[]> {
    return this.studentService.getStudentsByProgram(programId);
  }

  public getAllStudents(): Observable<Student[]> {
    return this.studentService.getAllStudents();
  }

  public getInstructorsByProgram(programId: string): Observable<Instructor[]> {
    return this.instructorService.getInstructorsByProgram(programId);
  }

  public getAllInstructors(): Observable<Instructor[]> {
    return this.instructorService.getAllInstructors();
  }

  public getLocation(locationId: string): Observable<Location> {
    return this.locationService.getLocation(locationId);
  }

  public getAllLocations(): Observable<Location[]> {
    return this.locationService.getAllLocations();
  }

  public getProgramsByIDs(programIDs: string[]): Program[] {
    const programs: Program[] = [];
    programIDs.map((programID) => {
      this.getProgram(programID).subscribe((program) => {
        programs.push(program as Program);
        programs.sort(this.sortByStartDate);
      });
    });

    return programs;
  }

  public sortByStartDate(a: Program, b: Program) {
    if (
      a.timeAndDate.startDateTime.seconds < b.timeAndDate.startDateTime.seconds
    ) {
      return -1;
    }
    if (
      a.timeAndDate.startDateTime.seconds > b.timeAndDate.startDateTime.seconds
    ) {
      return 1;
    }
    return 0;
  }

  public getProgram(id: string): Observable<Program> {
    const program = this.firebaseDatabase
      .collection('programs')
      .doc<Program>(id)
      .get()
      .pipe(map((doc) => doc.data() as Program));
    return program;
  }

  public getProgramByShortcode(shortcode: string): Observable<Program> {
    const program = this.firebaseDatabase
      .collection<Program>('programs')
      .ref.where('slug', '==', shortcode)
      .where('public', '==', true)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          throw new Error(`Program short code not found ${shortcode}`);
        } else {
          return querySnapshot.docs[0].data() as Program;
        }
      });
    return from(program);
  }

  public getSignUpCodes(programID: string) {
    const snapshot: AngularFirestoreCollection<Program> =
      this.firebaseDatabase.collection('sign-up-codes', (ref) =>
        ref.where('programId', '==', programID)
      );
    return snapshot.snapshotChanges().pipe(
      map((item) => {
        const signUpCodes: string[] = [];
        item.map((a) => {
          const data = a.payload.doc.data();
          signUpCodes.push(data.id);
        });

        return signUpCodes;
      })
    );
  }

  public insertSignUpCode(opts: { signUpCode: string; programID: string }) {
    const { signUpCode, programID } = opts;

    return this.firebaseDatabase
      .collection('sign-up-codes')
      .doc(signUpCode)
      .set({
        id: signUpCode,
        programId: programID,
      });
  }

  public deleteLocation(programID: string): Promise<void> {
    return this.firebaseDatabase
      .collection('programs')
      .doc<Program>(programID)
      .update({
        location: null,
      });
  }

  public deleteSignUpCode(signUpCode: string) {
    this.firebaseDatabase.collection('sign-up-codes').doc(signUpCode).delete();
  }

  public async insertProgram(program: Partial<Program>) {
    const newProgram: Program = {
      id: this.firebaseDatabase.createId(),
      name: program.name,
      created: firebase.firestore.Timestamp.now(),
      modified: firebase.firestore.Timestamp.now(),
      pictureUrl:
        program.pictureUrl ||
        'https://firebasestorage.googleapis.com/v0/b/codecraft-platform.appspot.com/o/course%2Fdefault%2FCodecraftWorks-Logo-Color-whitebg-square.png?alt=media&token=90825007-d3a5-40c8-ad18-a22e1b796f91',
      description: program.description || '',
      location: program.location || null,
      public: program.public,
      slug: program.slug || '',
      uid: program.uid,
      seats: program.seats || 0,
      seatMemberships: program.seatMemberships || 0,
      timeAndDate: {
        startDateTime: program.timeAndDate.startDateTime || null,
        endDateTime: program.timeAndDate.endDateTime || null,
      },
      live: {
        onAir: program.live.onAir || false,
        currentSlideURL: program.live.currentSlideURL || null,
        currentProjectURL: program.live.currentProjectURL || null,
        displayVideo: program.live.displayVideo || true,
      },
      users: program.users || [],
      stripe: program.stripe || null,
      daysOfTheWeek: program.daysOfTheWeek || {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      },
      tags: program.tags || null,
      technologies: program.technologies || null,
      programType: program.programType || null,
      level: program.level || null,
      ageGroup: program.ageGroup || null,
      archive: program.archive || false,
      courses: program.courses || [],
      courseIds: program.courseIds || [],
      instructors: program.instructors || [],
      instructorIds: program.instructorIds || [],
    };
    await this.firebaseDatabase
      .collection<Program>('programs')
      .doc(newProgram.id)
      .set(newProgram);

    return newProgram.id;
  }

  public updateProgram(opts: {
    program: Partial<Program>;
    id: string;
  }): Promise<void> {
    const { program, id } = opts;
    program.modified = firebase.firestore.Timestamp.now();
    return this.firebaseDatabase
      .collection<Program>('programs')
      .doc<Program>(id)
      .update(program);
  }

  public deleteProgram(program: Program): Observable<void> {
    if (program) {
      return from(
        this.firebaseDatabase
          .collection<Program>('programs')
          .doc<Program>(program.id)
          .delete()
      );
    } else {
      throw new Error("Can't delete without a key.");
    }
  }

  public searchPrograms(start: string, end: string): Observable<Program[]> {
    return this.firebaseDatabase
      .collection('/programs', (ref) =>
        ref.orderBy('searchableName').limit(10).startAt(start).endAt(end)
      )
      .valueChanges()
      .pipe(map((programs) => programs as Program[]));
  }

  public uploadPicture(program: Program, file: File): Promise<string> {
    const path = '/program/' + program.id + '/img/' + file.name;
    return firebase
      .app()
      .storage(this.environment.assetBucket)
      .ref(path)
      .put(file)
      .then((snapshot) => {
        return snapshot.ref.getDownloadURL();
      })
      .catch((error: Error) => {
        console.error(error);
        return null;
      });
  }

  /**
   * Copy existing Web Project to new Web Project
   * @param oldProgram
   * @param user
   */
  public async copyProgram(oldProgram: Program, user: User): Promise<string> {
    const newProgram = {
      id: this.firebaseDatabase.createId(),
      created: firebase.firestore.Timestamp.now(),
      modified: firebase.firestore.Timestamp.now(),
      name: 'Copy:' + oldProgram.name,
      public: oldProgram.public,
      uid: user.uid,
      pictureUrl:
        oldProgram.pictureUrl ||
        'https://firebasestorage.googleapis.com/v0/b/codecraft-platform.appspot.com/o/course%2Fdefault%2FCodecraftWorks-Logo-Color-whitebg-square.png?alt=media&token=90825007-d3a5-40c8-ad18-a22e1b796f91',
      description: oldProgram.description || '',
      location: {
        id: oldProgram.location.id || null,
        name: oldProgram.location.name || null,
        description: oldProgram.location.description || null,
        city: oldProgram.location.city || null,
        state: oldProgram.location.state || null,
        pictureUrl: oldProgram.location.pictureUrl || null,
      },
      slug: oldProgram.slug || '',
      seats: oldProgram.seats || 0,
      seatMemberships: 0,
      timeAndDate: {
        startDateTime: oldProgram.timeAndDate.startDateTime || null,
        endDateTime: oldProgram.timeAndDate.endDateTime || null,
      },
      live: {
        onAir: oldProgram.live.onAir || false,
        currentSlideURL: oldProgram.live.currentSlideURL || null,
        currentProjectURL: oldProgram.live.currentProjectURL || null,
        displayVideo: oldProgram.live.displayVideo,
      },
      users: [],
      stripe: oldProgram.stripe
        ? {
            id: null,
            type: oldProgram.stripe.type || null,
            price: oldProgram.stripe.price || null,
            frequency: oldProgram.stripe.frequency || null,
          }
        : null,
      daysOfTheWeek: oldProgram.daysOfTheWeek || null,
      tags: oldProgram.tags || null,
      technologies: oldProgram.technologies || null,
      programType: oldProgram.programType || null,
      level: oldProgram.level || null,
      ageGroup: oldProgram.ageGroup || null,
      archive: oldProgram.archive || false,
      courses: oldProgram.courses || [],
      courseIds: oldProgram.courseIds || [],
      instructors: oldProgram.instructors || [],
      instructorIds: oldProgram.instructorIds || [],
    };
    await this.firebaseDatabase
      .collection<Program>('programs')
      .doc(newProgram.id)
      .set(newProgram);
    return newProgram.id;
  }

  getLiveData(programId: string): Observable<any> {
    return this.firebaseDatabase
      .collection('programs')
      .doc(programId)
      .collection('metadata')
      .doc('live')
      .snapshotChanges()
      .pipe(
        map((action) => {
          if (action.payload.exists === false) {
            return {};
          } else {
            return action.payload.data();
          }
        })
      );
  }

  setLiveData(opts: { program: Program; meetingUrl: string }): Promise<void> {
    const { program, meetingUrl } = opts;

    return this.firebaseDatabase
      .collection('programs')
      .doc(program.id)
      .collection('metadata')
      .doc('live')
      .set({ meetingUrl });
  }

  getRoster(programId: string, userId: string): Observable<any> {
    return this.httpClient.post(
      '/api/students/getRoster',
      { programId: programId, userId: userId },
      { responseType: 'json' }
    );
  }

  async addUserToProgram(userId: string, programId: string) {
    await this.firebaseDatabase
      .collection('programs')
      .doc(programId)
      .set(
        {
          users: firebase.firestore.FieldValue.arrayUnion(userId),
        },
        { merge: true }
      );
  }

  addInstructorToProgram(
    instructor: Instructor,
    programId: string
  ): Promise<void> {
    const instructorInfo: InstructorInfo = {
      uid: instructor.uid,
      displayName: instructor.user.displayName,
      photoURL: instructor.user.photoURL,
      email: instructor.user.email,
    };

    return this.firebaseDatabase
      .collection('programs')
      .doc(programId)
      .set(
        {
          instructors: firebase.firestore.FieldValue.arrayUnion(instructorInfo),
          users: firebase.firestore.FieldValue.arrayUnion(instructor.uid),
        },
        { merge: true }
      );
  }

  removeUserFromProgram(userId: string, programId: string): Promise<void> {
    return this.firebaseDatabase
      .collection('programs')
      .doc(programId)
      .update({
        users: firebase.firestore.FieldValue.arrayRemove(userId),
      });
  }

  removeInstructorFromProgram(
    updatedArray: InstructorInfo[],
    instructorId: string,
    programId: string
  ) {
    const filteredInstructorInfos = updatedArray.filter((instructorInfo) => {
      return instructorInfo.uid !== instructorId;
    });
    // update the program with new courses array and arrayRemove the courseId
    this.firebaseDatabase
      .collection('programs')
      .doc(programId)
      .update({
        instructors: filteredInstructorInfos,
        users: firebase.firestore.FieldValue.arrayRemove(instructorId),
      });

    return filteredInstructorInfos;
  }

  getMaxSeats(programId: string): Observable<number> {
    return this.getProgram(programId).pipe(
      map((program) => {
        if (program.seats && program.seatMemberships) {
          return program.seats - program.seatMemberships;
        } else if (program.seats) {
          return program.seats;
        } else {
          return null;
        }
      })
    );
  }

  getLearndashCoursesByProgram(programId: string): Observable<CourseInfo[]> {
    return this.getProgram(programId).pipe(
      map((data) => {
        const learndashCourseInfo: CourseInfo[] = data.courses;
        return learndashCourseInfo;
      })
    );
  }

  addStudentArray(programId: string, userId?: string) {
    return this.firebaseDatabase
      .collection('programs')
      .doc(programId)
      .update({
        users: firebase.firestore.FieldValue.arrayUnion(userId),
      });
  }

  updateSlidePage(programId: string, value: number) {
    this.firebaseDatabase
      .collection('programs')
      .doc(programId)
      .set(
        {
          live: { currentSlidePage: value },
        },
        { merge: true }
      );
  }

  recordAttendance(programId: string, user: User, event: AttendanceEvent) {
    const id = this.firebaseDatabase.createId();
    this.firebaseDatabase
      .collection('programs')
      .doc(programId)
      .collection('attendance')
      .doc(id)
      .set({
        id: id,
        uid: user.uid,
        timestamp: firebase.firestore.Timestamp.now(),
        event: event,
        userInfo: {
          displayName: user.displayName || null,
          photoURL: user.photoURL || null,
          userName: user.userName || null,
          email: user.email || null,
        },
      });
  }

  updateProgramUsers(student: Student, program: Program): Observable<any> {
    return this.httpClient.post(
      '/api/programs/updateProgramUsers',
      {
        student,
        program,
      },
      { responseType: 'json' }
    );
  }

  async removeStudentFromProgram(opts: {
    program: Program;
    student: Student | K12Student;
  }) {
    const { program, student } = opts;

    await this.firebaseDatabase
      .collection('programs')
      .doc(program.id)
      .set(
        {
          users: firebase.firestore.FieldValue.arrayRemove(student.uid),
        },
        { merge: true }
      );

    // Remove program id from students' enrolled programs array
    await this.firebaseDatabase
      .collection('students')
      .doc(student.uid)
      .set(
        {
          enrolledPrograms: firebase.firestore.FieldValue.arrayRemove(
            program.id
          ),
        },
        { merge: true }
      );

    // Find memberships that need to be unassigned (If a membership matches a uid for the user and program Id)
    await this.firebaseDatabase
      .collection<Membership>('memberships', (ref) =>
        ref
          .where('memberUser.uid', '==', student.uid)
          .where('programId', '==', program.id)
      )
      .valueChanges()
      .pipe(
        take(1),
        map((results) => {
          results.forEach(async (membership) => {
            await this.firebaseDatabase
              .collection('memberships')
              .doc(membership.id)
              .set(
                {
                  memberUser: null,
                },
                { merge: true }
              );
          });
        })
      )
      .toPromise();
  }

  addToWaitlist(user: User, program: Program) {
    return this.httpClient.post(
      '/api/programs/addToWaitlist',
      { programId: program.id, userEmail: user.email },
      { responseType: 'json' }
    );
  }

  public getProjectsByProgram(options?: {
    publicOnly?: boolean;
    projectType: 'game' | 'web';
    entityId: string;
  }): Observable<Project[]> {
    return this.firebaseDatabase
      .collection<Project>(`${options.projectType}-projects`, (ref) => {
        let query:
          | firebase.firestore.CollectionReference
          | firebase.firestore.Query = ref;

        query = query.where('uid', '<', `${options.entityId}.`);
        query = query.where('uid', '>', `${options.entityId},`);

        if (options && options.publicOnly) {
          query = query.where('public', '==', true);
        }

        return query;
      })
      .valueChanges();
  }

  public getK12StudentsByEntity(options: {
    entityId: string;
    entityType: 'program' | 'classroom';
  }): Observable<K12Student[]> {
    return this.studentService.getK12StudentsByEntity({
      entityId: options.entityId,
      entityType: options.entityType,
    });
  }

  public async addK12StudentsToProgram(options: {
    students: string;
    entityId: string;
    entityType: 'program' | 'classroom';
  }) {
    const { students, entityId, entityType } = options;

    try {
      const csvJson: RowDataType[] = await this.convertStudentCsvToJson(
        students
      );

      const newStudents: K12Student[] = csvJson.map((student) => {
        return {
          id: `${entityId}-${student.userName}`,
          uid: `${entityId}-${student.userName}`,
          password: `${student.password}`,
          userName: `${student.userName}`,
          gradeLevel: `${student.gradeLevel}`,
          user: {
            displayName: `${student.displayName}`,
            uid: `${entityId}-${student.userName}`,
          },
          entityId: entityId,
          entityType: entityType,
          created: firebase.firestore.Timestamp.now(),
          modified: firebase.firestore.Timestamp.now(),
        };
      });

      const result = this.httpClient.post(
        'api/students/createK12Student',
        { newStudents: newStudents },
        { responseType: 'json' }
      );

      return await firstValueFrom(result);
    } catch (error) {
      throw new Error(error);
    }
  }

  public async addK12StudentToProgram(options: {
    userName: string;
    password: string;
    entityId: string;
    entityType: 'program' | 'classroom';
    gradeLevel: K12Student['gradeLevel'];
    displayName: string;
  }) {
    const {
      userName,
      password,
      entityId,
      entityType,
      gradeLevel,
      displayName,
    } = options;

    try {
      const newStudent: K12Student = {
        id: `${entityId}-${userName}`,
        uid: `${entityId}-${userName}`,
        password: `${password}`,
        userName: `${userName}`,
        gradeLevel: `${gradeLevel}`,
        user: {
          displayName: `${displayName}`,
          uid: `${entityId}-${userName}`,
        },
        entityId: entityId,
        entityType: entityType,
        created: firebase.firestore.Timestamp.now(),
        modified: firebase.firestore.Timestamp.now(),
      };

      const result = this.httpClient.post(
        'api/students/createK12Student',
        { newStudent: newStudent },
        { responseType: 'json' }
      );

      return await firstValueFrom(result);
    } catch (error) {
      throw new Error(error);
    }
  }

  public async updateK12Student(options: {
    password?: string;
    gradeLevel?: K12Student['gradeLevel'];
    displayName?: string;
    uid: string;
  }) {
    const updatedK12Student = options;

    try {
      const result = this.httpClient.post(
        'api/students/updateK12Student',
        { updatedStudent: updatedK12Student },
        { responseType: 'json' }
      );

      return firstValueFrom(result);
    } catch (error) {
      throw new Error(error);
    }
  }

  public getBatch(batchId: string): Observable<CreateK12StudentTask[]> {
    const entityId = batchId.split('-')[0];
    return this.firebaseDatabase
      .collection<CreateK12StudentTask>('tasks', (ref) =>
        ref
          .where('batchId', '==', batchId)
          .where('newK12Student.entityId', '==', entityId)
      )
      .valueChanges();
  }

  public getAllResults(entityId: string): Observable<CreateK12StudentTask[]> {
    return this.firebaseDatabase
      .collection<CreateK12StudentTask>('tasks', (ref) =>
        ref.where('newK12Student.entityId', '==', entityId)
      )
      .valueChanges();
  }
}
