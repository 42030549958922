import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { InfinteLoopPreventionPipe } from '@app/shared/pipes/infinte-loop-prevention.pipe';
import { WebProject } from '@codecraft-works/data-models';
import { CardComponent } from '../card.component';

/**
 * The web project's Card Component
 */
@Component({
  selector: 'app-web-project-card',
  templateUrl: './web-project-card.component.html',
  styleUrls: ['./web-project-card.component.css']
})

/**
 * @classdesc Class representing WebProjects' Card component
 */
export class WebProjectCardComponent extends CardComponent implements OnInit {
  /**
   * Web project input
   */
  @Input()
  webProject: WebProject;

  @Input()
  featuredCard: boolean;

  @ViewChild('WebProjectFrame', { static: true })
  previewFrame: ElementRef;

  /**
   * Initialization function
   */

  constructor(private infinteLoopPipe: InfinteLoopPreventionPipe) {
    super();
  }

  ngOnInit() {
    const preview =
      this.previewFrame.nativeElement.contentDocument ||
      this.previewFrame.nativeElement.contentWindow.document;

    // This code is injected into the JS of the project to prevent these functions from being used
    // Essentially in overwrites the functions with empty functions
    const stopLogs = `
      console.log = function(t) {};
      console.err = function() {}
      window.open = function() {};
      window.print   = function() {};
      window.alert   = function() {};
      window.confirm = function() {};
      window.prompt  = function() {};
      window.Notification = function() {};
      `;

    const iframeStopAnimation = `
      function disablewebkitAnimations() {
        var nodes = document.querySelectorAll("*");
        for (var i = 0; i < nodes.length; i++) {
          style = nodes[i].style;
          style.animationPlayState = "paused";
          style.animationDuration = 0;
          document.body.className = "paused";
        }
      }

      function pauseElementTypes(type) {
        let nodes = document.querySelectorAll("*");
        for (
          var i = 0, els = document.getElementsByTagName(type);
          i < els.length;
          i++
        ) {
          els[i].pause();
        }
      }

      function iFrameDisable() {
        disablewebkitAnimations();
        pauseElementTypes("audio");
        pauseElementTypes("video");
      }

      setTimeout(iFrameDisable, 4000); // execute after 4 seconds
      `;

    let webProjectHeader = this.webProject.html.header.replace(
      '{{title}}',
      this.webProject.name
    );

    // Replacing the css in the header
    webProjectHeader = webProjectHeader.replace(
      '{{css}}',
      '<style>' + this.webProject.css + '</style>'
    );

    // Replacing the js in the footer
    const webProjectFooter = this.webProject.html.footer.replace(
      '{{js}}',
      '<script>' +
        stopLogs +
        // If evalJS is true, pass code through loop detection and add, otherwise don't add
        (this.webProject.evalJS
          ? this.infinteLoopPipe.transform(this.webProject.js, {
              timeout: '2000'
            })
          : '') +
        iframeStopAnimation +
        '</script>'
    );

    // Concatenate Header, Footer and Body
    const iFrameData =
      webProjectHeader + this.webProject.html.body + webProjectFooter;

    preview.open();
    preview.write(iFrameData);
    preview.close();
  }
}
