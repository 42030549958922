import { CustomBlock } from 'ngx-blockly-new';

export class OpposingTeamBlock extends CustomBlock {
  constructor() {
    super('tictactoe_opposingteam');
    this.class = OpposingTeamBlock;
  }

  public defineBlock() {
    this.block.appendDummyInput().appendField('Opposing Team');
    this.block.setOutput(true, 'String');
    this.block.setColour(270);
    this.block.setTooltip(
      'Outputs the team your opponent is playing (X or O).'
    );
    this.block.setHelpUrl('https://app.codecraftworks.com/courses/33629');
  }

  public toPythonCode(): string | any[] {
    return ['"X" if self.get_team() == "O" else "O"', 99];
  }
}
