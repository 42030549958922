import firebase from 'firebase/compat/app';
import { User } from '../user/user.model';

export enum AttendanceEvent {
  Joined = 'joined',
  Left = 'left',
  NotLive = 'notLive',
}

export class Attendance {
  id: string;
  uid: string;
  event: AttendanceEvent;
  timestamp: firebase.firestore.Timestamp;
  userInfo: Pick<User, 'displayName' | 'photoURL' | 'email' | 'userName'>;
}
