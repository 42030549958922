import firebase from 'firebase/compat/app';
import { Program } from '../program/program.model';

export interface ShoppingCartItem {
  program: Pick<Program, 'id' | 'name' | 'stripe'>;
  quantity: number;
  created: firebase.firestore.Timestamp;
  modified: firebase.firestore.Timestamp;
  id: string;
  uid: string;
}

export class ShoppingCart {
  id: string;
  uid: string;
  created: firebase.firestore.Timestamp;
  modified: firebase.firestore.Timestamp;
  items: ShoppingCartItem[];
}
