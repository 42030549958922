import { AsyncPipe, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
  ValidatorFn,
} from '@angular/forms';
import {
  CodecraftAnswer,
  CodecraftQuestion,
} from '@codecraft-works/data-models';
import { BehaviorSubject } from 'rxjs';
import { CodecraftQuizBooleanAnswerQuestionComponent } from '../ui/boolean-question.component';
import { CodecraftQuizMultipleAnswerQuestionComponent } from '../ui/multiple-question.component';
import { CodecraftQuizSingleAnswerQuestionComponent } from '../ui/single-question.component';
import { QuizService } from '../util/quiz.service';

@Component({
  standalone: true,
  selector: 'codecraft-quiz-question-page-form',
  template: `
    <form
      *ngIf="questionForm && question"
      [formGroup]="questionForm"
      (ngSubmit)="submitAnswer()"
    >
      <ng-container [ngSwitch]="question.type">
        <ng-container *ngSwitchCase="'single'">
          <codecraft-quiz-single-answer-question
            [question]="question"
            [questionForm]="questionForm"
            [correctQuestionData]="correctQuestionData$ | async"
          ></codecraft-quiz-single-answer-question>
        </ng-container>

        <ng-container *ngSwitchCase="'boolean'">
          <codecraft-quiz-boolean-answer-question
            [question]="question"
            [questionForm]="questionForm"
            [correctQuestionData]="correctQuestionData$ | async"
          ></codecraft-quiz-boolean-answer-question>
        </ng-container>

        <ng-container *ngSwitchCase="'multiple'">
          <codecraft-quiz-multiple-answer-question
            [question]="question"
            [questionForm]="questionForm"
            [correctQuestionData]="correctQuestionData$ | async"
          ></codecraft-quiz-multiple-answer-question>
        </ng-container>
      </ng-container>
      <div
        class="btn-group"
        role="group"
        aria-label="Submit/Next Question Button Group"
      >
        <button
          class="btn btn-primary"
          type="submit"
          [disabled]="!questionForm?.valid"
        >
          Submit
        </button>
        <button
          class="btn btn-secondary"
          *ngIf="correctQuestionData$ | async"
          (click)="nextQuestion()"
        >
          Next
        </button>
      </div>
    </form>
  `,
  styles: [],
  imports: [
    NgIf,
    NgSwitch,
    NgSwitchCase,
    AsyncPipe,
    ReactiveFormsModule,
    CodecraftQuizSingleAnswerQuestionComponent,
    CodecraftQuizBooleanAnswerQuestionComponent,
    CodecraftQuizMultipleAnswerQuestionComponent,
  ],
})
export class CodecraftQuizQuestionPageFormComponent
  implements OnInit, OnChanges
{
  @Input() question: CodecraftQuestion;
  questionForm: FormGroup;
  correctQuestionData$: BehaviorSubject<{
    isCorrect: boolean;
    correctAnswer: CodecraftAnswer;
  } | null> = new BehaviorSubject(null);

  constructor(
    private quizService: QuizService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    if (!this.question) {
      return;
    }

    this.questionForm = this.createForm(this.question);

    if (!this.questionForm) {
      this.quizService.setQuizState({
        component: 'error',
        error: 'Invalid question form',
      });
      return;
    }
  }

  ngOnChanges(): void {
    if (this.question) {
      this.questionForm = this.createForm(this.question);
    }
  }

  createForm(question: CodecraftQuestion): FormGroup {
    let questionForm: FormGroup;
    if (question.type === 'boolean' || question.type === 'single')
      questionForm = this.formBuilder.group({
        question: ['', Validators.required],
      });
    else if (question.type === 'multiple' && question.choices instanceof Map) {
      const group: unknown = {};
      question.choices.forEach((_value, key) => {
        const formControlName = `${key}`;
        group[formControlName] = new FormControl(false, Validators.required);
      });
      questionForm = this.formBuilder.group(group, {
        validators: requireCheckboxesToBeCheckedValidator(1),
      });
    }
    return questionForm;
  }

  async submitAnswer(): Promise<void> {
    try {
      if (!this.questionForm?.valid) {
        return;
      }
      this.questionForm?.disable();

      let answerGiven = null;
      switch (this.question.type) {
        case 'single':
          answerGiven = this.questionForm.get('question')?.value;
          break;
        case 'boolean':
          answerGiven = JSON.parse(this.questionForm.get('question')?.value);
          break;
        case 'multiple':
          answerGiven = this.questionForm.value;
          break;
        default:
          break;
      }

      const submissionResult = await this.quizService.submitAnswer({
        question: this.question,
        answerGiven: answerGiven,
      });
      if (submissionResult.error === true) throw new Error('Submission failed');

      this.correctQuestionData$.next(submissionResult);
    } catch (error) {
      this.quizService.setQuizState({
        component: 'error',
        error: 'Submission error: ' + error,
      });
    }
  }
  nextQuestion(): void {
    try {
      this.quizService.nextQuestion();
      this.correctQuestionData$.next(null);
    } catch (error) {
      this.quizService.setQuizState({
        component: 'error',
        error: 'Next question error: ' + error,
      });
    }
  }
}

function requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
  return function validate(formGroup: FormGroup) {
    let checked = 0;

    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.controls[key];

      if (control.value === true) {
        checked++;
      }
    });

    if (checked < minRequired) {
      return {
        requireCheckboxesToBeChecked: true,
      };
    }

    return null;
  };
}
