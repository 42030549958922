import { Component, TemplateRef } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CcpcService } from '@app/shared/ccpc/ccpc.service';
import { CcpcPerson } from '@codecraft-works/data-models/lib/ccpc/ccpcPerson.model';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-sponsor-edit-button-cell',
  templateUrl: './sponsor-edit-button-cell.component.html',
  styleUrls: ['./sponsor-edit-button-cell.component.css'],
})
export class SponsorEditButtonCellComponent implements AgRendererComponent {
  sponsor: CcpcPerson;
  faEdit = faEdit;
  modalRef: BsModalRef;

  sponsorForm: FormGroup;
  sponsorName: FormControl;
  sponsorOrganization: FormControl;
  sponsorEmail: FormControl;

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private ccpcService: CcpcService
  ) {}
  refresh(params: ICellRendererParams): boolean {
    this.sponsor = params.data;
    return true;
  }
  agInit(params: ICellRendererParams): void {
    this.sponsor = params.data;
    this.sponsorName = new FormControl(null, [Validators.required]);
    this.sponsorEmail = new FormControl(null, [
      Validators.required,
      Validators.email,
    ]);
    this.sponsorOrganization = new FormControl(null);
    this.sponsorForm = this.formBuilder.group({
      name: this.sponsorName,
      organization: this.sponsorOrganization,
      email: this.sponsorEmail,
    });

    this.sponsorForm.patchValue(this.sponsor);
  }

  /**
   * Handles opening modal
   * @param modalTemplate
   */
  openModal(modalTemplate: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modalTemplate, {
      class: 'modal-lg modal-dialog modal-dialog-centered',
    });
  }

  async editSponsor() {
    if (this.sponsorForm.get('name').touched) {
      this.sponsor.name = this.sponsorForm.get('name').value;
    }
    if (this.sponsorForm.get('organization').touched) {
      this.sponsor.organization = this.sponsorForm.get('organization').value;
    }
    if (this.sponsorForm.get('email').touched) {
      this.sponsor.email = this.sponsorForm.get('email').value;
    }
    await this.ccpcService.updateSponsor(
      this.sponsor.competitionId,
      this.sponsor
    );
  }
}
