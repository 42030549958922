import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MembershipCodeService } from '@app/core/membership/membership-code-log.service';
import {
  Membership,
  Program,
  Student,
  User,
} from '@codecraft-works/data-models';
import firebase from 'firebase/compat/app';
import { from, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MembershipService {
  membership$: Observable<string[]>;
  environment = environment;

  constructor(
    private firebaseDatabase: AngularFirestore,
    private membershipCodeService: MembershipCodeService,
    private httpClient: HttpClient
  ) {}

  /**
   * Inserts item into membership code redemption log
   * @param membership
   * @param redemptionCode
   * @param memberUserId
   * @param customerId
   * @param ipAddress
   */
  insertCodeRedemption(
    membership: string,
    redemptionCode: string,
    memberUserId: string,
    customerId: string,
    ipAddress: string
  ) {
    this.membershipCodeService.createLog(
      membership,
      redemptionCode,
      memberUserId,
      customerId,
      ipAddress
    );
  }

  /**
   * Returns entire membership collection
   */
  getMembershipCodeCollection(id) {
    return this.firebaseDatabase.collection('membership-codes', (ref) =>
      ref.where('membershipId', '==', id)
    );
  }
  /**
   * Returns entire membership collection
   */
  getMembershipCollections(userID) {
    return this.firebaseDatabase.collection<Membership>('memberships', (ref) =>
      ref.where('uid', '==', userID)
    );
  }

  /**
   * Returns observable membership doc via doc ID
   * @param id
   */
  getMemberships(userId: string): Observable<Membership[]> {
    return this.firebaseDatabase
      .collection<Membership>('memberships', (ref) =>
        ref.where('uid', '==', userId).orderBy('created', 'desc')
      )
      .valueChanges();
  }

  getStudentMemberships(userId: string): Observable<Membership[]> {
    return this.firebaseDatabase
      .collection<Membership>('memberships', (ref) =>
        ref.where('memberUser.uid', '==', userId).orderBy('created', 'desc')
      )
      .valueChanges();
  }

  /**
   * Remove membership fr
   * @param id
   */
  dropMembership(id: string) {
    if (id) {
      return from(
        this.firebaseDatabase.collection('membership').doc(id).delete()
      );
    } else {
      throw new Error("Can't delete without a key.");
    }
  }

  getMembership(id: string): Observable<Membership> {
    const membership = this.firebaseDatabase
      .collection('memberships')
      .doc<Membership>(id)
      .valueChanges();
    return membership;
  }

  getAllMembershipCollection() {
    return this.firebaseDatabase.collection<Membership>('memberships');
  }

  redeemMembershipByCode(
    membershipCode: string,
    userId: string
  ): Observable<any> {
    return this.httpClient.post(
      '/api/memberships/redeemByCode',
      {
        membershipCode,
        userId,
      },
      { responseType: 'json' }
    );
  }

  unassignMembership(membershipId: string): Observable<any> {
    return this.httpClient.post(
      '/api/memberships/unassign',
      {
        membershipId,
      },
      { responseType: 'json' }
    );
  }

  createStudentForCustomer(
    newStudent: Partial<Student>,
    customerUser: User
  ): Observable<any> {
    return this.httpClient.post(
      '/api/students/createForCustomer',
      {
        newStudent,
        customerUser,
      },
      { responseType: 'json' }
    );
  }

  async generateCode(membershipId: string) {
    async function code(length) {
      let generatedCode = '';
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        generatedCode += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      const resultJSON = {
        code: generatedCode,
      };

      return resultJSON;
    }

    const genCode = await code(6);

    const membershipCodeObj = {
      id: genCode.code,
      membershipId: membershipId,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      modified: firebase.firestore.FieldValue.serverTimestamp(),
    };

    this.firebaseDatabase
      .collection('membership-codes')
      .doc(genCode.code)
      .set(membershipCodeObj);

    return membershipCodeObj.id;
  }

  /*
  Creates a membership manually without needing to purchase one
  */
  async createManualMembership(user: User, program: Program): Promise<any> {
    const membershipId = this.httpClient.post(
      '/api/memberships/create',
      {
        program,
        user,
      },
      { responseType: 'json' }
    );

    return await membershipId.toPromise();
  }

  scheduleProgramReminders(
    programId: string,
    programName: string,
    startDate: Date,
    endDate: Date
  ): Promise<Object> {
    const response = this.httpClient.post(
      '/api/memberships/scheduleProgramReminders',
      {
        programId,
        programName,
        startDate,
        endDate,
      },
      { responseType: 'json' }
    );
    return response.toPromise();
  }

  updateKlaviyoList(programId: string, listName: string, emails: string[]) {
    return this.httpClient.post(
      '/api/program/updateKlaviyoLists',
      {
        programId,
        listName,
        emails,
      },
      { responseType: 'json' }
    );
  }
}
