import { Component } from '@angular/core';
import { AuthService } from '@app/core/auth/auth.service';
import { User } from '@codecraft-works/data-models/public-api';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-program-copy-button-cell',
  templateUrl: './program-copy-button-cell.component.html',
  styleUrls: ['./program-copy-button-cell.component.css'],
})
export class ProgramCopyButtonCellComponent implements AgRendererComponent {
  programId: any;
  program: any;
  user$: Observable<User>;

  faCopy = faCopy;
  constructor(private authService: AuthService) {}
  refresh(params: ICellRendererParams): boolean {
    this.programId = params.data.id;
    this.program = params.data;
    this.user$ = this.authService.getUser();
    return true;
  }
  agInit(params: ICellRendererParams): void {
    this.programId = params.data.id;
    this.program = params.data;
    this.user$ = this.authService.getUser();
  }
}
