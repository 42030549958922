import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FileService } from '@app/core/file-service/file.service';
import {
  CcpcCompetition,
  CcpcPerson,
  CcpcSubmission,
  CcpcSubmissionType,
  CcpcTournament,
} from '@codecraft-works/data-models';
import { environment } from '@environments/environment';
import firebase from 'firebase/compat/app';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CcpcService {
  constructor(
    private db: AngularFirestore,
    private httpClient: HttpClient,
    private fileService: FileService
  ) {}

  getCompetition(competitionId: string): Observable<CcpcCompetition> {
    const competition$ = this.db
      .collection('ccpc-competitions')
      .doc<CcpcCompetition>(`${competitionId}`)
      .valueChanges();

    const tournaments$ = this.db
      .collection('ccpc-competitions')
      .doc<CcpcCompetition>(`${competitionId}`)
      .collection<CcpcTournament>('tournaments')
      .valueChanges();

    const sponsors$: Observable<CcpcPerson[]> = this.db
      .collection('ccpc-competitions')
      .doc<CcpcCompetition>(competitionId)
      .collection<CcpcPerson>('sponsors')
      .valueChanges();

    return combineLatest([competition$, tournaments$, sponsors$]).pipe(
      map(([competition, tournaments, sponsors]) => {
        competition.tournaments = tournaments;
        competition.sponsors = sponsors;
        return competition;
      })
    );
  }

  updateCompetition(
    competitionId: string,
    updatedCompetition: CcpcCompetition
  ): Promise<void> {
    updatedCompetition.modified = firebase.firestore.Timestamp.now();
    return this.db
      .collection('ccpc-competitions')
      .doc(`${competitionId}`)
      .set(updatedCompetition, { merge: true });
  }

  createCompetition(
    competitionId: string,
    newCompetition: CcpcCompetition
  ): Promise<void> {
    return this.db
      .collection('ccpc-competitions')
      .doc(`${competitionId}`)
      .set(newCompetition);
  }

  runTournament(competitionId: string, tournamentId: string): Promise<any> {
    return this.httpClient
      .post(
        '/api/ccpc/runTournament',
        { competitionId: competitionId, tournamentId },
        { responseType: 'json' }
      )
      .toPromise();
  }

  getTournament(
    competitionId: string,
    tournamentId: string
  ): Observable<CcpcTournament> {
    return this.httpClient
      .post(
        '/api/ccpc/getTournament',
        { competitionId: competitionId, tournamentId: tournamentId },
        { responseType: 'json' }
      )
      .pipe(shareReplay(1)) as Observable<CcpcTournament>;
  }

  submitProject(
    submission: CcpcSubmission,
    competitionId: string,
    tournamentId: string
  ): Promise<void> {
    let extension = '';
    let mimeType = '';

    switch (submission.projectType) {
      case CcpcSubmissionType.BATTLESHIP_JAVA:
      case CcpcSubmissionType.TICTACTOE_JAVA:
        extension = 'java';
        mimeType = 'text/x-java';
        break;

      case CcpcSubmissionType.TICTACTOE_BLOCKLY:
      case CcpcSubmissionType.TICTACTOE_PYTHON:
        extension = 'py';
        mimeType = 'text/x-python';
        break;

      default:
        break;
    }

    return this.db
      .collection('ccpc-competitions')
      .doc(`${competitionId}`)
      .collection('tournaments')
      .doc(`${tournamentId}`)
      .collection('submissions')
      .doc(`${submission.id}`)
      .set(submission)
      .then(() => {
        if (
          submission.projectType === CcpcSubmissionType.BATTLESHIP_JAVA ||
          submission.projectType === CcpcSubmissionType.TICTACTOE_JAVA ||
          submission.projectType === CcpcSubmissionType.TICTACTOE_PYTHON ||
          submission.projectType === CcpcSubmissionType.TICTACTOE_BLOCKLY
        ) {
          this.fileService.uploadFile(
            environment.projectBucket,
            `game/${submission.competitionId}_${submission.tournamentId}_${submission.uid}.${extension}`,
            submission.projectSubmission.code,
            `${mimeType}`
          );
        } else {
          return Promise.resolve();
        }
      });
  }

  getSubmission(
    uid: string,
    competitionId: string,
    tournamentId: string
  ): Observable<CcpcSubmission> {
    const submissionRef = this.db
      .collection('ccpc-competitions')
      .doc(`${competitionId}`)
      .collection('tournaments')
      .doc(`${tournamentId}`)
      .collection<CcpcSubmission>('submissions')
      .doc(`${uid}`);

    return submissionRef.snapshotChanges().pipe(
      mergeMap((submission) => {
        if (submission.payload.exists) {
          return submissionRef.valueChanges();
        } else {
          return of(null);
        }
      }),
      catchError(() => {
        return of(null);
      })
    );
  }

  getSubmissions(
    competitionId: string,
    tournamentId: string
  ): Observable<CcpcSubmission[]> {
    return this.db
      .collection('ccpc-competitions')
      .doc(`${competitionId}`)
      .collection('tournaments')
      .doc(`${tournamentId}`)
      .collection<CcpcSubmission>('submissions')
      .valueChanges();
  }

  getAllCompetitions(options?: {
    publicOnly?: true;
  }): Observable<CcpcCompetition[]> {
    return this.db
      .collection<CcpcCompetition>('ccpc-competitions', (ref) => {
        let query:
          | firebase.firestore.CollectionReference
          | firebase.firestore.Query = ref;
        if (options && options.publicOnly) {
          query = query.where('public', '==', true);
        }
        return query;
      })
      .valueChanges();
  }

  getAllTournaments(competitionId: string): Observable<CcpcTournament[]> {
    return this.httpClient
      .post(
        '/api/ccpc/getTournaments',
        { competitionId: competitionId },
        { responseType: 'json' }
      )
      .pipe(shareReplay(1)) as Observable<CcpcTournament[]>;
  }

  getAllSponsors(
    competitionId: string,
    options?: {
      publicOnly?: true;
    }
  ): Observable<CcpcPerson[]> {
    return this.db
      .collection('ccpc-competitions')
      .doc<CcpcCompetition>(`${competitionId}`)
      .collection<CcpcPerson>('sponsors', (ref) => {
        let query:
          | firebase.firestore.CollectionReference
          | firebase.firestore.Query = ref;
        if (options && options.publicOnly) {
          query = query.where('public', '==', true);
        }
        return query;
      })
      .valueChanges();
  }

  getAllCoaches(
    competitionId: string,
    tournamentId: string,
    options?: {
      publicOnly?: boolean;
    }
  ): Observable<CcpcPerson[]> {
    return this.db
      .collection('ccpc-competitions')
      .doc<CcpcCompetition>(`${competitionId}`)
      .collection('tournaments')
      .doc<CcpcCompetition>(`${tournamentId}`)
      .collection<CcpcPerson>('coaches', (ref) => {
        let query:
          | firebase.firestore.CollectionReference
          | firebase.firestore.Query = ref;
        if (options && options.publicOnly) {
          query = query.where('public', '==', true);
        }
        return query;
      })
      .valueChanges();
  }

  updateTournament(
    tournamentId: string,
    competitionId: string,
    updatedTournament: CcpcTournament
  ): Promise<void> {
    updatedTournament.modified = firebase.firestore.Timestamp.now();
    if (updatedTournament.tournamentResults) {
      delete updatedTournament.tournamentResults;
    }
    return this.db
      .collection('ccpc-competitions')
      .doc(`${competitionId}`)
      .collection('tournaments')
      .doc(`${tournamentId}`)
      .set(updatedTournament, { merge: true });
  }

  createTournament(
    competitionId: string,
    tournament: CcpcTournament
  ): Promise<void> {
    return this.db
      .collection('ccpc-competitions')
      .doc(competitionId)
      .collection('tournaments')
      .doc(tournament.id)
      .set(tournament);
  }

  createCoach(
    competitionId: string,
    tournamentId: string,
    coach: CcpcPerson
  ): Promise<void> {
    return this.db
      .collection('ccpc-competitions')
      .doc(competitionId)
      .collection('tournaments')
      .doc(tournamentId)
      .collection('coaches')
      .doc(coach.id)
      .set(coach);
  }

  updateCoach(
    competitionId: string,
    tournamentId: string,
    updatedCoach: CcpcPerson
  ): Promise<void> {
    updatedCoach.modified = firebase.firestore.Timestamp.now();

    return this.db
      .collection('ccpc-competitions')
      .doc(competitionId)
      .collection('tournaments')
      .doc(tournamentId)
      .collection('coaches')
      .doc(updatedCoach.id)
      .set(updatedCoach);
  }

  createSponsor(competitionId: string, sponsor: CcpcPerson): Promise<void> {
    return this.db
      .collection('ccpc-competitions')
      .doc(competitionId)
      .collection('sponsors')
      .doc(sponsor.id)
      .set(sponsor);
  }

  updateSponsor(
    competitionId: string,
    updatedSponsor: CcpcPerson
  ): Promise<void> {
    updatedSponsor.modified = firebase.firestore.Timestamp.now();

    return this.db
      .collection('ccpc-competitions')
      .doc(competitionId)
      .collection('sponsors')
      .doc(updatedSponsor.id)
      .set(updatedSponsor);
  }
}
