import firebase from 'firebase/compat/app';

export class Slide {
  public id: string;
  public uid: string;
  public url: string;
  public name: string;
  public public: boolean;
  public description?: string;
  public created: firebase.firestore.Timestamp;
  public modified: firebase.firestore.Timestamp;
}
