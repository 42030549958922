import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import {
  FormControl,
  FormBuilder,
  Validators,
  FormGroup,
} from '@angular/forms';
import { Router } from '@angular/router';
import { QuizService } from '@app/shared/quiz/quiz.service';
import { CodecraftQuestion, CodecraftQuiz } from '@codecraft-works/data-models';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-quiz-edit-form',
  templateUrl: './quiz-edit-form.component.html',
  styleUrls: ['./quiz-edit-form.component.css'],
})
export class QuizEditFormComponent implements OnInit, OnChanges {
  @Input() quizData: [CodecraftQuiz, Map<string, CodecraftQuestion>];
  @Output() resetQuiz = new EventEmitter<void>();

  quiz$: BehaviorSubject<CodecraftQuiz>;
  quizQuestions$: BehaviorSubject<Map<string, CodecraftQuestion>>;
  loadingQuestions$: Observable<boolean>;

  quizForm: FormGroup;
  hasQuizInstance$: Observable<boolean>;

  selectedCsvFiles: FileList;
  quizCsvSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  modalRef: BsModalRef;
  quizUpdateError: string;
  questionModalData: CodecraftQuestion;

  addQuestionsCollapsed = true;
  faPlusCircle = faPlusCircle;

  constructor(
    private formBuilder: FormBuilder,
    private quizService: QuizService,
    private modalService: BsModalService,
    private router: Router
  ) {}
  async ngOnInit(): Promise<void> {
    const quiz = this.quizData[0];
    const quizQuestions = this.quizData[1];
    // set loading questions to be true for 1000ms, then false
    this.loadingQuestions$ = new Observable<boolean>((observer) => {
      observer.next(true);
      setTimeout(() => {
        observer.next(false);
      }, 1500);
    });

    this.quiz$ = new BehaviorSubject<CodecraftQuiz>(quiz);
    this.quizQuestions$ = new BehaviorSubject<Map<string, CodecraftQuestion>>(
      quizQuestions
    );

    this.hasQuizInstance$ = this.quizService.hasQuizInstance(quiz.id);

    const quizName = new FormControl(quiz.name, [
      Validators.required,
      Validators.maxLength(100),
    ]);
    const unlimitedAttempts = new FormControl(quiz.maxAttempts < 0, []);

    const maxAttempts = new FormControl(
      {
        value: quiz.maxAttempts,
        disabled: unlimitedAttempts.value,
      },
      [Validators.required, Validators.min(-1)]
    );

    const passingScore = new FormControl(quiz.passingScore, [
      Validators.required,
      Validators.min(0),
      Validators.max(100),
    ]);
    const quizCsvForm = new FormControl();

    this.quizForm = this.formBuilder.group({
      quizName: quizName,
      maxAttempts: maxAttempts,
      unlimitedAttempts: unlimitedAttempts,
      passingScore: passingScore,
      quizCsvForm: quizCsvForm,
    });
  }

  ngOnChanges() {
    if (this.quiz$ && this.quizQuestions$) {
      this.quiz$.next(this.quizData[0]);
      this.quizQuestions$.next(this.quizData[1]);
    }
  }

  async updateQuiz(
    loadingModal: TemplateRef<unknown>,
    completeModal: TemplateRef<unknown>,
    errorModal: TemplateRef<unknown>
  ) {
    this.openModal(loadingModal);
    let result;
    if (!this.quizCsvSubject.value) {
      result = await this.quizService.updateQuiz({
        quizId: this.quiz$.value.id,
        quizName: this.quizForm.get('quizName').value,
        maxAttempts: this.quizForm.get('maxAttempts').value,
        passingScore: this.quizForm.get('passingScore').value,
      });
    } else {
      const trimmedQuizCsv = this.quizCsvSubject.value.substring(
        this.quizCsvSubject.value.indexOf(
          'Question Prompt,Question Type,Choice A,Choice B,Choice C,Choice D,Answer 1,Answer 2,Answer 3,Answer 4,Tag 1,Tag 2,Tag 3,Tag 4,Tag 5'
        )
      );
      result = await this.quizService.updateQuiz({
        quizId: this.quiz$.value.id,
        quizCsv: trimmedQuizCsv,
        quizName: this.quizForm.get('quizName').value,
        maxAttempts: this.quizForm.get('maxAttempts').value,
        passingScore: this.quizForm.get('passingScore').value,
      });
      this.resetQuiz.emit();
    }
    this.modalRef.hide();
    if (result && !result.success) {
      this.quizUpdateError = result.error || 'Error updating quiz';
      this.openModal(errorModal);
    } else {
      if (this.quizCsvSubject.value) {
        this.quizCsvSubject.next(null);
        this.quizForm.get('quizCsvForm').reset();
      }
      this.openModal(completeModal);
    }
  }

  unlimitedCheckboxChange(event: Event) {
    const target = event.currentTarget as HTMLInputElement;
    if (target.checked) {
      this.quizForm.get('maxAttempts').disable();
      this.quizForm.get('maxAttempts').setValue(-1);
    } else {
      this.quizForm.get('maxAttempts').enable();
    }
  }

  /**
   * Check if media files are present in database
   * @param event
   */
  detectMediaFiles(event) {
    this.selectedCsvFiles = event.target.files;
    const reader = new FileReader();

    reader.onload = () => {
      this.quizCsvSubject.next(reader.result as string);
    };

    reader.readAsText(this.selectedCsvFiles[0]);
  }

  /**
   * Handles open modal
   * @param modalTemplate
   */
  openModal(modalTemplate: TemplateRef<unknown>) {
    this.modalRef = this.modalService.show(modalTemplate, {
      class: 'modal-dialog modal-dialog-centered',
    });
  }

  openQuestionModal(
    options:
      | { modal: TemplateRef<unknown>; questionId: string }
      | { modal: TemplateRef<unknown>; questionType: string }
  ) {
    if ('questionId' in options) {
      this.questionModalData = this.quizQuestions$.value.get(
        options.questionId
      );
    } else {
      this.questionModalData = this.createNewQuestion(options.questionType);
    }
    this.modalRef = this.modalService.show(options.modal, {
      class: 'modal-dialog modal-dialog-centered',
    });
    this.addQuestionsCollapsed = true;
  }

  async deleteQuizDocument() {
    const success = await this.quizService.deleteQuiz(this.quiz$.value.id);
    if (success) {
      this.router.navigate(['/admin']);
    }
  }

  private createNewQuestion(type: string): CodecraftQuestion {
    let question: CodecraftQuestion;
    switch (type) {
      case 'single': {
        const choices = new Map<string, string>(
          Object.entries({ A: '', B: '', C: '', D: '' })
        );
        question = {
          id: null,
          public: true,
          created: null,
          modified: null,
          type: 'single',
          prompt: '',
          choices: choices,
          answer: '',
          tags: [],
        };
        break;
      }
      case 'multiple': {
        const choices = new Map<string, string>(
          Object.entries({ A: '', B: '', C: '', D: '' })
        );
        const answers = new Map<string, boolean>(
          Object.entries({ A: false, B: false, C: false, D: false })
        );

        question = {
          id: null,
          public: true,
          created: null,
          modified: null,
          type: 'multiple',
          prompt: '',
          choices: choices,
          answer: answers,
          tags: [],
        };
        break;
      }
      case 'boolean':
        question = {
          id: null,
          public: true,
          created: null,
          modified: null,
          choices: null,
          type: 'boolean',
          prompt: '',
          answer: null,
          tags: [],
        };
        break;
      default:
        console.error('Invalid question type');
        return;
    }
    return question;
  }
}
