import firebase from 'firebase/compat/app';
/**
 * Importing the `User` model to be partially used in this class
 */
import { UserInfo } from '../user/user.model';

type StudentUserInfo = UserInfo & { userName?: string };

export class Student {
  gradeLevel?: number;
  age?: number;
  enrolledPrograms?: string[];
  created: firebase.firestore.Timestamp;
  modified: firebase.firestore.Timestamp;
  slug?: string;
  uid: string;
  id: string;

  /**
   * Partial [user](/classes/User.html) class properties for use in in web project
   *
   * It is an optional property
   *
   * Copy of owner's display name from [user](/classes/User.html#displayName) class
   * `displayName?: string;`
   *
   * Copy of owner's display name from [email](/classes/User.html#email) class
   * `email?: string;`
   *
   * URL of owner's photo from [user](/classes/User.html#photoURL) class
   * `photoURL?: string;`
   *
   * URL of owner's userName from [user](/classes/User.html#photoURL) class
   * `photoURL?: string;
   *
   */
  user?: StudentUserInfo;
}
