import { Component, Input } from '@angular/core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-learndash-progress-bar',
  templateUrl: './learndash-progress-bar.component.html',
  styleUrls: ['./learndash-progress-bar.component.css'],
})
export class LearndashProgressBarComponent {
  @Input()
  progressPercentage: number;

  @Input()
  completedSteps: number;

  @Input()
  totalSteps: number;

  @Input()
  alternateText: string;

  @Input()
  type: string;

  @Input()
  title: string;

  faCheck = faCheck;
  constructor() {}
}
