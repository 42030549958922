import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/auth/auth.service';
import { SessionStorageService } from '@app/core/auth/session-storage.service';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons/faMicrosoft';
import { faHashtag } from '@fortawesome/free-solid-svg-icons';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css'],
})
export class LoginPageComponent implements OnInit, OnDestroy {
  title = 'Codecraft Works - Login';
  /**
   * Font awesome sign in icon
   */
  faSignInAlt = faSignInAlt;

  /**
   * Font awesome google icon
   */
  faGoogle = faGoogle;

  /**
   * Font awesome google icon
   */
  faMicrosoft = faMicrosoft;

  faHashtag = faHashtag;

  loading = new BehaviorSubject(false);
  loading$ = this.loading.asObservable();
  providers$: Observable<any>;
  providers: string[];
  providerSubscription: Subscription;
  shortCode = '';

  constructor(
    private userService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private sessionStorage: SessionStorageService,
    private changeRef: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    this.titleService.setTitle(this.title);

    // if session storage contains loading - show spinner and route
    if (window.sessionStorage.getItem('loading')) {
      this.loading.next(true);
      await this.userService.isLoggedIn().then((isLoggedIn) => {
        if (isLoggedIn) {
          if (this.activatedRoute.snapshot.queryParamMap.get('redirect')) {
            this.router.navigate([
              this.activatedRoute.snapshot.queryParamMap.get('redirect'),
            ]);
          } else {
            this.router.navigate(['/home']);
          }
        } else {
          this.hideSpinner();
        }
      });
    }

    this.providers$ = this.sessionStorage.watch('providers');
    this.providerSubscription = this.providers$.subscribe((providers) => {
      this.providers = providers;
      this.changeRef.detectChanges();
    });
  }

  logInViaGoogle() {
    this.showSpinner();
    this.userService.loginGoogleUserService();
  }

  logInViaMSFT() {
    this.showSpinner();
    this.userService.loginMSFTUserService();
  }

  showSpinner() {
    window.sessionStorage.setItem('loading', '1');
    this.loading.next(true);
  }

  hideSpinner() {
    window.sessionStorage.removeItem('loading');
    this.loading.next(false);
  }

  logInViaShortCode() {
    this.showSpinner();
    this.router.navigate([`/p/${this.shortCode}/login`]);
  }

  isShortCodeValid(): boolean {
    const regex = new RegExp(/[A-Za-z0-9]{4,4}/);
    return regex.test(this.shortCode);
  }

  ngOnDestroy() {
    if (this.providerSubscription) {
      this.providerSubscription.unsubscribe();
    }
  }
}
