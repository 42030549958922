import { Directive, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector:
    // eslint-disable-next-line @angular-eslint/directive-selector
    '[customMin][formControlName],[customMin][formControl],[customMin][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: CustomMinDirective, multi: true }
  ]
})
export class CustomMinDirective implements Validator {
  @Input()
  customMin: number;

  validate(c: FormControl): { [key: string]: any } {
    const v = c.value;
    return v < this.customMin ? { customMin: true } : null;
  }
}
