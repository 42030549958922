import { Directive, EventEmitter, Output } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'textarea[markdown-editor]'
})
export class MarkdownEditorDirective {
  @Output() markdownChanged = new EventEmitter<string>();

  constructor() {}

  onMarkdownChanged(e) {
    const body = e.target.value;
    const placeholder = e.target.placeholder;

    if (!body) {
      // reset to initial state
      return this.markdownChanged.emit(placeholder);
    } else {
      this.markdownChanged.emit(body);
    }
  }
}
