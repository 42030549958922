import firebase from 'firebase/compat/app';
export class Certificate {
  id: string;
  name: string;
  description: string;
  color: string;
  pictureUrl?: string;
  certifiedUsers?: string[];
  public: boolean;
  slug: string;
  uid: string;
  created: firebase.firestore.Timestamp;
  modified: firebase.firestore.Timestamp;
}
