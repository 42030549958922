import { Component, Input, OnInit } from '@angular/core';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginationDirective } from '../pagination.directive';
import { PaginationService } from '../pagination.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
  providers: [PaginationService],
})
export class TableComponent extends PaginationDirective implements OnInit {
  @Input()
  entityName: string;

  chunkedData$: Observable<any>;

  chunkedData: any[];

  lastPage: boolean;

  @Input()
  currentChunk = 0;

  @Input()
  chunkSize = 8;

  @Input()
  title: string;

  @Input()
  userView: string;

  /**
   * Font Awesome icon
   */
  faChevronLeft = faChevronLeft;

  /**
   * Font Awesome icon
   */
  faChevronRight = faChevronRight;

  singlePage: boolean;

  constructor(public data: PaginationService) {
    super(data);
  }
  ngOnInit() {
    if (!this.targetObservableArray$) {
      super.init();
      this.chunkedData$ = this.page.data.pipe(
        map((data) => this.chunk(data, this.chunkSize))
      );

      this.chunkedData$.subscribe((data) => {
        this.chunkedData = data;
      });
    }
  }

  chunk(array: any[], size: number) {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i++) {
      const last = chunkedArray[chunkedArray.length - 1];
      if (!last || last.length === size) {
        chunkedArray.push([array[i]]);
      } else {
        last.push(array[i]);
      }
    }
    return chunkedArray;
  }

  next() {
    if (this.currentChunk < this.chunkedData.length - 1) {
      this.currentChunk++;
      this.page.more();
      this.lastPage = false;
    } else {
      this.currentChunk = 0;
    }

    if (this.currentChunk === this.chunkedData.length - 1) {
      this.lastPage = true;
    }

    if (this.chunkedData[0].length < this.chunkSize) {
      this.singlePage = true;
    }
  }

  prev() {
    this.lastPage = false;
    this.page.done && this.currentChunk === 0
      ? (this.currentChunk = this.chunkedData.length - 1)
      : this.currentChunk--;
  }

  isAttendancePath(path: string): boolean {
    return /programs\/[a-zA-Z0-9_]*\/attendance/.test(path);
  }
}
