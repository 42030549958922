<button
  type="button"
  class="btn btn-primary d-block"
  (click)="this.openModal(editCoachModal)"
>
  <fa-icon [icon]="faEdit" title="Edit"></fa-icon>
</button>

<!--Edit Coach Modal-->
<ng-template #editCoachModal>
  <form id="coachForm" [formGroup]="coachForm">
    <div class="modal-header bg-primary text-light">
      <h4 class="modal-title pull-left">Edit Coach</h4>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="this.modalRef.hide()"
      >
        <span aria-hidden="true" class="text-light">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label for="coachName">Name</label>
        <input
          type="text"
          class="form-control"
          id="coachName"
          name="coachName"
          formControlName="name"
          required
          [ngClass]="{
            'is-invalid': coachName.invalid,
            'is-valid': coachName.valid && coachName.dirty
          }"
        />
      </div>

      <div class="form-group">
        <label for="coachOrganization">Organization</label>
        <input
          type="text"
          class="form-control"
          id="coachOrganization"
          name="coachOrganization"
          formControlName="organization"
        />
      </div>

      <div class="form-group">
        <label for="coachEmail">Email</label>
        <input
          type="email"
          class="form-control"
          id="coachEmail"
          name="coachEmail"
          formControlName="email"
          required
          [ngClass]="{
            'is-invalid': coachEmail.invalid,
            'is-valid': coachEmail.valid && coachEmail.dirty
          }"
        />
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        [disabled]="coachForm.invalid"
        class="btn btn-success float-right pull-right"
        (click)="editCoach(); modalRef.hide()"
      >
        Save Coach
      </button>
    </div>
  </form>
</ng-template>
