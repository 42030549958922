import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';

enum BoardTile {
  x = 'X',
  o = 'O',
  empty = ' '
}

interface Board {
  row1: [BoardTile, BoardTile, BoardTile];
  row2: [BoardTile, BoardTile, BoardTile];
  row3: [BoardTile, BoardTile, BoardTile];
  row1Strike?: string;
  row2Strike?: string;
  row3Strike?: string;
  col1Strike?: string;
  col2Strike?: string;
  col3Strike?: string;
  diag1Strike?: string;
  diag2Strike?: string;
  ocolor?: string;
  xcolor?: string;
  strikecolor?: string;
}
@Component({
  selector: 'codecraft-tic-tac-toe',
  templateUrl: './tic-tac-toe.component.html',
  styleUrls: ['./tic-tac-toe.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom
})
export class TicTacToeComponent {
  board: Board = {
    row1: [BoardTile.empty, BoardTile.empty, BoardTile.empty],
    row2: [BoardTile.empty, BoardTile.empty, BoardTile.empty],
    row3: [BoardTile.empty, BoardTile.empty, BoardTile.empty]
  };
  @Input() set row1(row1: string) {
    if (row1) {
      this.board.row1 = row1
        .toUpperCase()
        .replace(/\s/g, '')
        .split(',') as [BoardTile, BoardTile, BoardTile];
    }
  }
  @Input() set row2(row2: string) {
    if (row2) {
      this.board.row2 = row2
        .toUpperCase()
        .replace(/\s/g, '')
        .split(',') as [BoardTile, BoardTile, BoardTile];
    }
  }
  @Input() set row3(row3: string) {
    if (row3) {
      this.board.row3 = row3
        .toUpperCase()
        .replace(/\s/g, '')
        .split(',') as [BoardTile, BoardTile, BoardTile];
    }
  }
  @Input() set row1strike(row1Strike: string) {
    if (row1Strike) {
      this.board.row1Strike = row1Strike.toLowerCase();
    }
  }
  @Input() set row2strike(row2Strike: string) {
    if (row2Strike) {
      this.board.row2Strike = row2Strike.toLowerCase();
    }
  }
  @Input() set row3strike(row3Strike: string) {
    if (row3Strike) {
      this.board.row3Strike = row3Strike.toLowerCase();
    }
  }
  @Input() set col1strike(col1Strike: string) {
    if (col1Strike) {
      this.board.col1Strike = col1Strike.toLowerCase();
    }
  }
  @Input() set col2strike(col2Strike: string) {
    if (col2Strike) {
      this.board.col2Strike = col2Strike.toLowerCase();
    }
  }
  @Input() set col3strike(col3Strike: string) {
    if (col3Strike) {
      this.board.col3Strike = col3Strike.toLowerCase();
    }
  }
  @Input() set diag1strike(diag1Strike: string) {
    if (diag1Strike) {
      this.board.diag1Strike = diag1Strike.toLowerCase();
    }
  }
  @Input() set diag2strike(diag2Strike: string) {
    if (diag2Strike) {
      this.board.diag2Strike = diag2Strike.toLowerCase();
    }
  }
  @Input() set ocolor(ocolor: string) {
    if (ocolor) {
      this.board.ocolor = ocolor;
    }
  }
  @Input() set xcolor(xcolor: string) {
    if (xcolor) {
      this.board.xcolor = xcolor;
    }
  }
  @Input() set strikecolor(strikecolor: string) {
    if (strikecolor) {
      this.board.strikecolor = strikecolor;
    }
  }
}
