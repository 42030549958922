import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-tournament-title-link-cell',
  templateUrl: './tournament-title-link-cell.component.html',
  styleUrls: ['./tournament-title-link-cell.component.css']
})
export class TournamentTitleLinkCellComponent implements AgRendererComponent {
  name: string;
  id: string;
  competitionId: string;

  constructor() {}

  refresh(params: ICellRendererParams): boolean {
    this.name = params.data.name;
    this.id = params.data.id;
    this.competitionId = params.data.competitionId;
    return true;
  }

  agInit(params: ICellRendererParams): void {
    this.name = params.data.name;
    this.id = params.data.id;
    this.competitionId = params.data.competitionId;
  }
}
