<card
  [title]="lessonItem.post_title || ''"
  [showTitle]="[lessonItem.post_title]"
  [isGrid]="true"
  [showDetailButton]="showLinks"
  [icon]="showLinks ? null : faLock"
  [detailUrl]="
    showLinks ? '/courses/' + [courseId] + '/lesson/' + [lessonItem.id] : null
  "
  [detailButtonText]="'View Lesson'"
>
  <ng-container *ngIf="lessonProgress">
    <div class="my-2">
      <app-learndash-progress-bar
        *ngIf="lessonProgress.totalSteps !== 0; else hasNoTopics"
        [progressPercentage]="
          (lessonProgress.numOfCompletedSteps / lessonProgress.totalSteps) * 100
        "
        [completedSteps]="lessonProgress.numOfCompletedSteps"
        [totalSteps]="lessonProgress.totalSteps"
        [type]="'topics'"
      ></app-learndash-progress-bar>

      <ng-template #hasNoTopics
        ><app-learndash-progress-bar
          *ngIf="lessonProgress.completed; else notComplete"
          [progressPercentage]="100"
          [completedSteps]="1"
          [totalSteps]="1"
          [type]="'lessons'"
        ></app-learndash-progress-bar>
        <ng-template #notComplete>
          <app-learndash-progress-bar
            [progressPercentage]="0"
            [completedSteps]="0"
            [totalSteps]="1"
            [type]="'lessons'"
          ></app-learndash-progress-bar>
        </ng-template>
      </ng-template>
    </div>
  </ng-container>

  <div *ngIf="topicSteps && topicSteps.length > 0" class="p-1">
    <h5 class="mb-1">Topics</h5>
    <ul class="list-group list-group-flush">
      <li class="list-group-item" *ngFor="let topicItem of topicSteps">
        {{ topicItem.post_title }}
      </li>
    </ul>
  </div>
</card>
