<ng-container *ngIf="program">
  <div
    *ngIf="first"
    id="table-header"
    class="row align-items-center no-gutters p-1 font-weight-bold d-none d-md-flex"
  >
    <div class="col-12 col-sm-3">
      Name
    </div>

    <div class="col-12 col-sm-3">
      Location
    </div>

    <div class="col-12 col-sm-3">
      Time and Date
    </div>

    <div class="col-12 col-sm-3">
      Days of the Week
    </div>
  </div>
  <a [routerLink]="['/programs/', program.id]">
    <div
      class="row align-items-center no-gutters p-1"
      [ngClass]="{ first: first, last: last, odd: odd, even: even }"
    >
      <h3 class="col-12 col-sm-3 mb-3">
        {{ program.name }}
      </h3>

      <div class="col-12 col-sm-3">
        <label
          *ngIf="program.location"
          class="d-sm-block d-md-none badge badge-pill badge-primary"
          >Location
        </label>
        <p *ngIf="program.location && program.location.name">
          {{ program.location.name }}
          <span *ngIf="program.location && program.location.city">{{
            program.location.city
          }}</span
          ><span *ngIf="program.location && program.location.state"
            >, {{ program.location.state }}</span
          >
        </p>
      </div>

      <div class="col-12 col-sm-3">
        <label
          *ngIf="
            program.timeAndDate &&
            program.timeAndDate.startDateTime &&
            program.timeAndDate.endDateTime
          "
          class="d-sm-block d-md-none badge badge-pill badge-primary"
          >Time and Date
        </label>
        <p
          *ngIf="
            program.timeAndDate &&
            program.timeAndDate.startDateTime &&
            program.timeAndDate.endDateTime
          "
        >
          <span>
            {{ program.timeAndDate.startDateTime.toDate() | date }} -
            {{ program.timeAndDate.endDateTime.toDate() | date }}
          </span>
          <br />
          <span>
            {{ program.timeAndDate.startDateTime.toDate() | date: 'shortTime' }}
            -
            {{ program.timeAndDate.endDateTime.toDate() | date: 'shortTime' }}
            Local
          </span>
        </p>
      </div>

      <div class="col-12 col-sm-3">
        <label
          *ngIf="program.daysOfTheWeek"
          class="d-sm-block d-md-none badge badge-pill badge-primary"
          >Days of the Week
        </label>
        <div *ngIf="program.daysOfTheWeek && program.daysOfTheWeek.sunday">
          Sunday
        </div>
        <div *ngIf="program.daysOfTheWeek && program.daysOfTheWeek.monday">
          Monday
        </div>
        <div *ngIf="program.daysOfTheWeek && program.daysOfTheWeek.tuesday">
          Tuesday
        </div>
        <div *ngIf="program.daysOfTheWeek && program.daysOfTheWeek.wednesday">
          Wednesday
        </div>
        <div *ngIf="program.daysOfTheWeek && program.daysOfTheWeek.thursday">
          Thursday
        </div>
        <div *ngIf="program.daysOfTheWeek && program.daysOfTheWeek.friday">
          Friday
        </div>
        <div *ngIf="program.daysOfTheWeek && program.daysOfTheWeek.saturday">
          Saturday
        </div>
      </div>
    </div>
  </a>
</ng-container>
