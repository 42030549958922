<label
  for="email"
  *ngIf="!loading"
>Sign in with your email address</label>
<div
  class="input-group"
  *ngIf="!loading; else spinner"
>
  <div class="input-group-prepend">
    <div class="input-group-text">
      <fa-icon
        [icon]="faEnvelope"
        class="fa-lg"
      ></fa-icon>
    </div>
  </div>
  <input
    [(ngModel)]="email"
    type="email"
    class="form-control"
    id="email"
    placeholder="Sign in with your email address"
    required
  />
  <div class="input-group-append">
    <button
      type="submit"
      class="btn btn-primary"
      [disabled]="emailSent || !email"
      (click)="sendEmailLink()"
    >
      Continue <fa-icon
        [icon]="faSignInAlt"
        class="fa-lg"
      ></fa-icon>
    </button>
  </div>
</div>

<div
  *ngIf="emailSent"
  class="alert alert-success mt-2"
  role="alert"
>
  Cool! We sent you an email with your login link!
</div>

<div
  *ngIf="errorMessage"
  class="alert alert-danger mt-2"
  role="alert"
>
  We received an error: {{ errorMessage }}
</div>

<ng-template #spinner>
  <codecraft-loading-spinner></codecraft-loading-spinner>
</ng-template>
