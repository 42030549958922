import { CustomBlock } from 'ngx-blockly-new';

export class EmptyBlock extends CustomBlock {
  constructor() {
    super('tictactoe_empty');
    this.class = EmptyBlock;
  }

  public defineBlock() {
    this.block.appendDummyInput().appendField('Empty');
    this.block.setOutput(true, 'String');
    this.block.setColour(270);
    this.block.setTooltip(
      'Outputs a " " for representing a blank space on the board.'
    );
    this.block.setHelpUrl('https://app.codecraftworks.com/courses/33629');
  }

  public toPythonCode(): string | any[] {
    return ['" "', 99];
  }
}
