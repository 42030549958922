import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  Query,
} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { scan, take, tap } from 'rxjs/operators';

interface QueryConfig {
  path: string; //  path to collection
  field: string; // field to orderBy
  limit: number; // limit per query
  reverse: boolean; // reverse order?
  prepend: boolean; // prepend to source?
  publicOnly: boolean; // get public documents only
  userId: string; // get documents by user id
  inArray: {
    field: string; // Array field to check
    argument: string; // Check to see if this value is in the array
  };
  inQuery: {
    field: string; // field to check
    argument: string; // Check to see if field value is in this array
  };
  isEqual: {
    field: string; // Array field to check
    argument: any; // Check to see if this value is equal to the value in the field
  };
  isGreaterThan: {
    field: string; // Array field to check
    argument: any; // Check to see if this value is greater than the value in the field
  };
  isLessThan: {
    field: string; // Array field to check
    argument: any; // Check to see if this value is less than the value in the field
  };
}

@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  // Source data
  private doneFlag = new BehaviorSubject(false);
  private loadingFlag = new BehaviorSubject(false);
  private dataFlag = new BehaviorSubject([]);
  private isFirstQuery: boolean;

  private query: QueryConfig;

  private firstQuery: firebase.firestore.Query;

  // Observable data
  data: Observable<any>;
  done: Observable<boolean> = this.doneFlag.asObservable();
  loading: Observable<boolean> = this.loadingFlag.asObservable();

  constructor(private afs: AngularFirestore) {}

  // Initial query sets options and defines the Observable
  // passing opts will override the defaults
  init(path: string, field?: string, opts?: any) {
    this.query = {
      path,
      field,
      limit: 2,
      reverse: false,
      prepend: false,
      publicOnly: null,
      userId: null,
      inArray: null,
      inQuery: null,
      isEqual: null,
      isGreaterThan: null,
      isLessThan: null,
      ...opts,
    };

    const first = this.afs.collection(this.query.path, (ref) => {
      let query: Query = ref;
      if (this.query.publicOnly) {
        query = query.where('public', '==', this.query.publicOnly);
      }
      if (this.query.userId) {
        query = query.where('uid', '==', this.query.userId);
      }
      if (this.query.isEqual) {
        query = query.where(
          this.query.isEqual.field,
          '==',
          this.query.isEqual.argument
        );
      }
      if (this.query.isGreaterThan) {
        query = query.where(
          this.query.isGreaterThan.field,
          '>',
          this.query.isGreaterThan.argument
        );
      }
      if (this.query.isLessThan) {
        query = query.where(
          this.query.isLessThan.field,
          '<',
          this.query.isLessThan.argument
        );
      }
      if (this.query.inArray) {
        query = query.where(
          this.query.inArray.field,
          'array-contains',
          this.query.inArray.argument
        );
      }
      if (this.query.inQuery) {
        query = query.where(
          this.query.inQuery.field,
          'in',
          this.query.inQuery.argument
        );
      }
      query = query.orderBy(
        this.query.field,
        this.query.reverse ? 'desc' : 'asc'
      );
      query = query.limit(this.query.limit);
      this.firstQuery = query;
      return query;
    });

    this.mapAndUpdate(first, true);

    // Create the observable array for consumption in components
    this.data = this.dataFlag.asObservable().pipe(
      scan((acc, val) => {
        if (this.isFirstQuery) {
          return val;
        } else {
          return this.query.prepend ? val.concat(acc) : acc.concat(val);
        }
      })
    );
  }

  // Retrieves additional data from firestore
  more() {
    const cursor = this.getCursor();

    const more = this.afs.collection(this.query.path, () => {
      const query = this.firstQuery.startAfter(cursor);
      return query;
    });
    this.mapAndUpdate(more, false);
  }

  // Determines the doc snapshot to paginate query
  private getCursor() {
    const current = this.dataFlag.value;
    if (current.length) {
      return this.query.prepend
        ? current[0].doc
        : current[current.length - 1].doc;
    }
    return null;
  }

  // Maps the snapshot to usable format the updates source
  private mapAndUpdate(
    col: AngularFirestoreCollection<any>,
    isFirst: boolean
  ): Subscription {
    this.isFirstQuery = isFirst;

    if (this.doneFlag.value || this.loadingFlag.value) {
      return;
    }

    // loading
    this.loadingFlag.next(true);

    // Map snapshot with doc ref (needed for cursor)
    return col
      .snapshotChanges()
      .pipe(
        tap((arr) => {
          let values = arr.map((snap) => {
            const data = snap.payload.doc.data();
            const doc = snap.payload.doc;
            return { ...data, doc };
          });

          // If prepending, reverse the batch order
          values = this.query.prepend ? values.reverse() : values;

          // update source with new values, done loading
          this.dataFlag.next(values);
          this.loadingFlag.next(false);

          // no more values, mark done
          if (!values.length) {
            this.doneFlag.next(true);
          }
        })
      )
      .pipe(take(1))
      .subscribe();
  }
}
