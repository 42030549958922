/**
 * @classdesc Class representing a web project html document
 */

export class WebProjectHTML {
  /**
   * Header of index.html
   */

  header: string;

  /**
   * Body of index.html, the part the is editable on the web project editor page
   */
  body: string;

  /**
   * Footer of index.html
   */
  footer: string;
}
