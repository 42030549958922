<codecraft-hero-block>
  <div class="row">
    <div class="col-12">
      <h1 class="text-primary">
        <fa-icon [icon]="faUser" class="mr-1"> </fa-icon> User
      </h1>
    </div>
  </div>
</codecraft-hero-block>

<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <tabset class="col-12 mt-3 pb-2">
        <tab
          heading="Memberships"
          tabOrder="1"
          id="Memberships"
          *ngIf="(user$ | async) as user"
        >
          <app-membership-list class="mt-5"> </app-membership-list>
        </tab>
        <tab
          heading="Settings"
          tabOrder="2"
          id="settings"
          *ngIf="(user$ | async) as user"
        >
          <app-user-settings-form
            [user]="user"
            [isModal]="false"
            class="bg-white dark-shadow"
          >
          </app-user-settings-form>
        </tab>
      </tabset>
    </div>
  </div>
</div>
<hr />
