import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/core/auth/auth.service';
import { ShoppingCartService } from '@app/core/shopping-cart/shopping-cart.service';
import { User } from '@codecraft-works/data-models';
import { faUser, faUserCog } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-user-settings-page',
  templateUrl: './user-settings-page.component.html',
  styleUrls: ['./user-settings-page.component.css']
})
export class UserSettingsPageComponent
  implements OnInit, AfterViewInit, OnDestroy {
  user$: Observable<User>;

  faUserCog = faUserCog;
  faUser = faUser;
  fragment: string;
  modalRef: BsModalRef;
  userSub: Subscription;

  @ViewChild('success')
  successModal;

  constructor(
    private userService: AuthService,
    private route: ActivatedRoute,
    private shoppingCartService: ShoppingCartService,
    private modalService: BsModalService
  ) {
    this.fragment = this.route.snapshot.fragment;
  }

  ngOnInit() {
    this.user$ = this.userService.getUser();
  }

  ngAfterViewInit() {
    switch (this.fragment) {
      // Clear the shopping cart after being redirected here after a purchase
      case 'success':
        this.userSub = this.user$.subscribe(user => {
          this.shoppingCartService.dropCart(user.uid);
          this.openModal(this.successModal);
        });

        break;

      default:
        break;
    }
  }

  ngOnDestroy() {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }

  /**
   * Handles opening any modal
   * @param modalName
   */
  openModal(modalName: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modalName, {
      class: 'modal-lg modal-dialog modal-dialog-centered'
    });
  }
}
