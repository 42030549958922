import { Injectable } from '@angular/core';
import {
  Membership,
  Program,
  ShoppingCartItem,
} from '@codecraft-works/data-models';
import { environment } from '../../../environments/environment';

declare const gtag: Function;
declare const klaviyoTrack: Function;
const noop = () => {};

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  environment = environment;

  addToCartEvent(item: ShoppingCartItem, cart: ShoppingCartItem[]) {
    gtag
      ? gtag('event', 'add_to_cart', {
          event_label: `${item.program.id} - ${item.program.name}`,
          items: [
            {
              id: item.program.id,
              name: item.program.name,
              brand: 'Codecraft Works',
              category: 'Program',
              variant: item.program.stripe.type,
              quantity: item.quantity,
              price: item.program.stripe.price,
            },
          ],
        })
      : noop();

    const itemToTrack = {
      $value: (item.program.stripe.price / 100) * item.quantity,
      AddedItemPrice: item.program.stripe.price / 100,
      AddedItemProductName: item.program.name,
      AddedItemProductID: item.program.id,
      AddedItemQuantity: item.quantity,
      AddedItemPaymentFrequency: item.program.stripe.frequency,
      Items: null,
    };

    itemToTrack.Items = cart.map((cartItem) => {
      const quantity =
        item.program.id === cartItem.program.id
          ? cartItem.quantity + item.quantity
          : cartItem.quantity;

      return {
        ProductID: cartItem.program.id,
        ProductName: cartItem.program.name,
        Quantity: quantity,
        ItemPrice: cartItem.program.stripe.price / 100,
        RowTotal: quantity * (cartItem.program.stripe.price / 100),
        PaymentFrequency: cartItem.program.stripe.frequency,
        ProductURL: `${this.environment.host}/program/${cartItem.program.id}`,
      };
    });

    klaviyoTrack
      ? klaviyoTrack(['track', 'Added To Cart', itemToTrack])
      : noop();
  }

  removeFromCartEvent(item: ShoppingCartItem, cart: ShoppingCartItem[]) {
    gtag
      ? gtag('event', 'remove_from_cart', {
          event_label: `${item.program.id} - ${item.program.name}`,
          items: [
            {
              id: item.program.id,
              name: item.program.name,
              brand: 'Codecraft Works',
              category: 'Program',
              variant: item.program.stripe.type,
              quantity: item.quantity,
              price: item.program.stripe.price,
            },
          ],
        })
      : noop();

    const itemToTrack = {
      $value: (item.program.stripe.price / 100) * item.quantity,
      RemovedItemPrice: item.program.stripe.price / 100,
      RemovedItemProductName: item.program.name,
      RemovedItemProductID: item.program.id,
      RemovedItemQuantity: item.quantity,
      RemovedItemPaymentFrequency: item.program.stripe.frequency,
      Items: null,
    };

    itemToTrack.Items = cart.map((cartItem) => {
      const quantity =
        item.program.id === cartItem.program.id
          ? cartItem.quantity - item.quantity
          : cartItem.quantity;

      return {
        ProductID: cartItem.program.id,
        ProductName: cartItem.program.name,
        Quantity: quantity,
        ItemPrice: cartItem.program.stripe.price / 100,
        RowTotal: quantity * (cartItem.program.stripe.price / 100),
        PaymentFrequency: cartItem.program.stripe.frequency,
        ProductURL: `${this.environment.host}/program/${cartItem.program.id}`,
      };
    });

    klaviyoTrack
      ? klaviyoTrack(['track', 'Removed From Cart', itemToTrack])
      : noop();
  }

  notEnoughSeatsEvent(item: ShoppingCartItem) {
    gtag
      ? gtag('event', 'not_enough_seats', {
          event_category: 'ecommerce',
          event_label: `${item.program.id} - ${item.program.name}`,
          items: [
            {
              id: item.program.id,
              name: item.program.name,
              brand: 'Codecraft Works',
              category: 'Program',
              variant: item.program.stripe.type,
              quantity: item.quantity,
              price: item.program.stripe.price,
            },
          ],
        })
      : noop();

    const itemToTrack = {
      $value: (item.program.stripe.price / 100) * item.quantity,
      Price: item.program.stripe.price / 100,
      ProductName: item.program.name,
      ProductID: item.program.id,
      Quantity: item.quantity,
      PaymentFrequency: item.program.stripe.frequency,
    };

    klaviyoTrack
      ? klaviyoTrack(['track', 'Not Enough Seats', itemToTrack])
      : noop();
  }

  viewProductEvent(program: Program) {
    gtag
      ? gtag('event', 'view_item', {
          event_label: `${program.id} - ${program.name}`,
          items: [
            {
              id: program.id,
              name: program.name,
              brand: 'Codecraft Works',
              category: 'Program',
              variant: program.programType,
            },
          ],
        })
      : noop();

    const klaviyoItemToTrack = {
      ProductName: program.name,
      ProductID: program.id,
      URL: `${this.environment.host}/program/${program.id}`,
      ProgramType: program.programType,
      Price: null,
      PaymentFrequency: null,
      Type: null,
    };

    if (program.stripe && program.stripe.price) {
      klaviyoItemToTrack.Price = program.stripe.price / 100;
    }

    if (program.stripe && program.stripe.type) {
      klaviyoItemToTrack.Type = program.stripe.type;
    }

    if (program.stripe && program.stripe.frequency) {
      klaviyoItemToTrack.PaymentFrequency = program.stripe.frequency;
    }

    klaviyoTrack
      ? klaviyoTrack(['track', 'Viewed Product', klaviyoItemToTrack])
      : noop();
  }

  beginCheckoutEvent(cart: ShoppingCartItem[], sessionId: string) {
    let totalCostCart = 0;

    const items = cart.map((item) => {
      totalCostCart += (item.program.stripe.price / 100) * item.quantity;

      return {
        id: item.program.id,
        name: item.program.name,
        brand: 'Codecraft Works',
        category: 'Program',
        variant: item.program.stripe.type,
        quantity: item.quantity,
        price: item.program.stripe.price,
      };
    });

    gtag
      ? gtag('event', 'begin_checkout', {
          items: items,
        })
      : noop();

    const itemsToTrack = {
      $event_id: sessionId + '_' + Date.now(),
      $value: totalCostCart,
      ItemNames: cart.map((item) => {
        return item.program.name;
      }),
      Items: cart.map((item) => {
        return {
          ProductID: item.program.id,
          ProductName: item.program.name,
          Quantity: item.quantity,
          ItemPrice: item.program.stripe.price / 100,
          RowTotal: item.quantity * (item.program.stripe.price / 100),
          PaymentFrequency: item.program.stripe.frequency,
          ProductURL: `${this.environment.host}/program/${item.program.id}`,
        };
      }),
    };

    klaviyoTrack
      ? klaviyoTrack(['track', 'Started Checkout', itemsToTrack])
      : noop();
  }

  checkoutCanceledEvent(cart: ShoppingCartItem[]) {
    let totalCostCart = 0;

    const items = cart.map((item) => {
      totalCostCart += (item.program.stripe.price / 100) * item.quantity;

      return {
        id: item.program.id,
        name: item.program.name,
        brand: 'Codecraft Works',
        category: 'Program',
        variant: item.program.stripe.type,
        quantity: item.quantity,
        price: item.program.stripe.price,
      };
    });

    gtag
      ? gtag('event', 'checkout_canceled', {
          event_category: 'ecommerce',
          items: items,
        })
      : noop();

    const itemsToTrack = {
      $value: totalCostCart,
      ItemNames: cart.map((item) => {
        return item.program.name;
      }),
      Items: cart.map((item) => {
        return {
          ProductID: item.program.id,
          ProductName: item.program.name,
          Quantity: item.quantity,
          ItemPrice: item.program.stripe.price / 100,
          RowTotal: item.quantity * (item.program.stripe.price / 100),
          PaymentFrequency: item.program.stripe.frequency,
          ProductURL: `${this.environment.host}/program/${item.program.id}`,
        };
      }),
    };

    klaviyoTrack
      ? klaviyoTrack(['track', 'Checkout Canceled', itemsToTrack])
      : noop();
  }

  membershipCreated(membership: Membership) {
    klaviyoTrack(['track', 'membership-created', membership]);
  }
}
