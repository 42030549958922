import { Pipe, PipeTransform } from '@angular/core';
import * as Esprima from 'esprima';

@Pipe({
  name: 'infinteLoopPrevention',
})
/**
 * Adds timed limit on the loops found in the passed code.
 * Contributed by Ariya Hidayat!
 * @param code {string}	Code to be protected from infinite loops.
 */
export class InfinteLoopPreventionPipe implements PipeTransform {
  transform(code: string, { timeout }): any {
    let loopId = 1;
    const patches = [];
    const varPrefix = '_wmloopvar';
    const varStr = 'let %d = Date.now();\n';
    const checkStr = `\nif (Date.now() - %d > ${timeout}) { parent.evalJSFlag(false); break;}\n`;

    function generateRandomId(len: number) {
      const alphaNum =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      const length = len || 10;
      let id = '';
      for (let i = length; i--; ) {
        const randomNUmber = Math.floor(
          Math.random() * (alphaNum.length - 0) + 0
        );
        id += alphaNum[randomNUmber];
      }
      return id;
    }

    Esprima.parseScript(
      code,
      {
        tolerant: true,
        range: true,
        jsx: true,
      },
      (node) => {
        switch (node.type) {
          case 'DoWhileStatement':
          case 'ForStatement':
          case 'ForInStatement':
          case 'ForOfStatement':
          case 'WhileStatement': {
            let start = 1 + node.body.range[0];
            const random = generateRandomId(5);
            const end = node.body.range[1];
            let prolog = checkStr.replace(
              '%d',
              varPrefix + loopId + '_' + random
            );
            let epilog = '';

            if (node.body.type !== 'BlockStatement') {
              // `while(1) doThat()` becomes `while(1) {doThat()}`
              prolog = '{' + prolog;
              epilog = '}';
              --start;
            }

            patches.push({
              pos: start,
              str: prolog,
            });
            patches.push({
              pos: end,
              str: epilog,
            });
            patches.push({
              pos: node.range[0],
              str: varStr.replace('%d', varPrefix + loopId + '_' + random),
            });
            ++loopId;
            break;
          }
          default:
            break;
        }
      }
    );

    /* eslint-disable no-param-reassign */
    patches
      .sort(function (a, b) {
        return b.pos - a.pos;
      })
      .forEach(function (patch) {
        code = code.slice(0, patch.pos) + patch.str + code.slice(patch.pos);
      });

    /* eslint-disable no-param-reassign */
    return code;
  }
}
