import firebase from 'firebase/compat/app';
import { CcpcCompetition } from './ccpcCompetition.model';
import { CcpcGameResults } from './ccpcGameResults.model';
import { CcpcPerson } from './ccpcPerson.model';
import { CcpcSubmission, CcpcSubmissionType } from './ccpcSubmission.model';

export interface CcpcTournamentResults {
  /*
   * Winning team names
   */
  winner?: string;

  /*
   * list of all submissions that passed validation
   */
  validSubmissions?: CcpcSubmission[];

  /*
   * list of all submissions that failed validation
   */
  failedSubmissions?: CcpcSubmission[];

  /*
   * list of rounds with game results
   */
  rounds?: CcpcTournamentRound[];
}

export interface CcpcTournamentRound {
  /*
   * list containing the results from each game in the round
   */
  gameResults?: CcpcGameResults[];

  /*
   * If the number of players in the round is odd, this player auto advances
   */
  oddPlayer?: CcpcSubmission;

  /*
   * list of winning submissions in this round
   */
  winners?: CcpcSubmission[];
}

export class CcpcTournament {
  /**
   * Unique ID string for this document generated by Firebase
   */
  id: string;

  /**
   * Unique ID string for this document's parent generated by Firebase
   */
  competitionId: string;

  /**
   * Competition Data
   */
  competition?: CcpcCompetition;

  /**
   * Timestamp of initial document creation
   */
  created: firebase.firestore.Timestamp;

  /**
   * Timestamp of the last time the document was updated
   */
  modified: firebase.firestore.Timestamp;

  /**
   * Signifies whether or not document is available for others to see
   */
  public: boolean;

  /**
   * User ID string that identifies the owner of the document
   */
  uid: string;

  /**
   * Name of the competition
   */
  name: string;

  /**
   * Type of the competition
   */
  type: CcpcSubmissionType;

  /**
   * Timestamp of tournament submission deadline
   */
  submissionDeadline: firebase.firestore.Timestamp;

  /*
   * List of organizations/teachers/coaches in tournament
   */
  entities?: CcpcPerson[];

  /*
   * Results of a tournament that was run
   */
  tournamentResults?: CcpcTournamentResults;
}
