import { Component } from '@angular/core';
import { CcpcSubmission } from '@codecraft-works/data-models/public-api';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-submission-project-cell',
  templateUrl: './submission-project-cell.component.html',
  styleUrls: ['./submission-project-cell.component.css']
})
export class SubmissionProjectCellComponent implements AgRendererComponent {
  submission: CcpcSubmission;

  constructor() {}

  refresh(params: ICellRendererParams): boolean {
    this.submission = params.data;
    return true;
  }

  agInit(params: ICellRendererParams): void {
    this.submission = params.data;
  }
}
