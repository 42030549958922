<div class="container-fluid">
  <ng-container *ngIf="(cart$ | async)?.length > 0; else noItems">
    <div
      id="table-header"
      class="row align-items-center p-1 font-weight-bold no-gutters"
    >
      <div class="col-xl-5 d-none d-xl-block">Program</div>

      <div class="col-xl-1 d-none d-xl-block">Quantity</div>

      <div id="price" class="col-xl-1 d-none d-xl-block">Price</div>

      <div id="total" class="col-xl-1 d-none d-xl-block">Total</div>

      <div class="col-xl-2 d-none d-xl-block">Payment Frequency</div>
    </div>
    <app-shopping-cart-item
      *ngFor="
        let item of cart;
        let even = even;
        let odd = odd;
        let first = first;
        let last = last
      "
      [item]="item"
      [user]="user"
      [ngClass]="{ first: first, last: last, odd: odd, even: even }"
    >
      >
    </app-shopping-cart-item>

    <div id="table-footer" class="row no-gutters p-1">
      <div class="col-12" *ngIf="recurringYearlyCartTotal">
        Total Cost Yearly: {{ recurringYearlyCartTotal / 100 | currency }}
      </div>

      <div class="col-12" *ngIf="recurringMonthlyCartTotal">
        Total Cost Monthly: {{ recurringMonthlyCartTotal / 100 | currency }}
      </div>

      <div class="col-12" *ngIf="recurringWeeklyCartTotal">
        Total Cost Weekly: {{ recurringWeeklyCartTotal / 100 | currency }}
      </div>

      <div class="col-12" *ngIf="recurringDailyCartTotal">
        Total Cost Daily: {{ recurringDailyCartTotal / 100 | currency }}
      </div>

      <div class="col-12" *ngIf="oneTimeCartTotal">
        Total Cost Once: {{ oneTimeCartTotal / 100 | currency }}
      </div>
      <div class="col-12 mt-1 ml-auto font-weight-bold">
        <span class="float-right">
          Total Cost Now: {{ totalCostNow / 100 | currency }}
          <button
            class="btn btn-primary"
            [disabled]="isCheckingOut"
            (click)="checkout()"
          >
            <span>Checkout</span>
          </button>
        </span>
      </div>
    </div>
    <div *ngIf="error" class="alert alert-danger">
      {{ error }}
    </div>
  </ng-container>
</div>

<ng-template #noItems>
  <div class="row align-items-center p-1">
    <div class="col-12">
      <div class="jumbotron">
        <h3>Shopping Cart Is Empty</h3>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loadingModal>
  <div class="modal-body mx-auto">
    <codecraft-loading-spinner></codecraft-loading-spinner>
  </div>
</ng-template>

<ng-template #invalidCouponCodeModal>
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title">Invalid Coupon Code</p>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="(closeInvalidCouponCodeModal)"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body mx-auto">
      <p>
        We tried to automatically apply a discount to your order but the coupon
        code is no longer valid. You may continue the purchase at full price or
        contact us for assistance.
      </p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-primary"
        (click)="redirectToSession(sessionId)"
      >
        Continue Purchase
      </button>
      <button type="button" class="btn btn-danger">Cancel</button>
    </div>
  </div>
</ng-template>
