import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  CcpcPerson,
  CcpcSubmission,
  CcpcSubmissionType,
  CcpcTournament,
  GameProject,
  Project,
  User,
  WebProject,
} from '@codecraft-works/data-models';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import firebase from 'firebase/compat/app';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { WebProjectService } from '../../web-project/web-project.service';
import { CcpcService } from '../ccpc.service';

@Component({
  selector: 'app-ccpc-submission-form-modal',
  templateUrl: './ccpc-submission-form-modal.component.html',
  styleUrls: ['./ccpc-submission-form-modal.component.css'],
})
export class CcpcSubmissionFormModalComponent implements OnInit, AfterViewInit {
  projectSelected: Project;
  projectSelectionMade = false;
  projectList: FormControl;
  studentName: FormControl;

  faCloudUploadAlt = faCloudUploadAlt;

  @Input()
  tournament: CcpcTournament;

  @Input()
  user: User;

  @Input()
  currentSubmission$: Observable<CcpcSubmission>;

  @ViewChild('successModal')
  successModal: TemplateRef<any>;
  successModalRef: BsModalRef;

  @ViewChild('failModal')
  failModal: TemplateRef<any>;
  failModalRef: BsModalRef;

  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

  noResult = false;

  @Input()
  myProjectData$: Observable<Project[]>;

  filteredProjectData$: Observable<Project[]>;

  myProjectFormData$: Observable<Project[]>;

  isInTeam: boolean;
  entitySelect: FormControl;
  teammateName: FormControl;
  submissionForm: FormGroup;
  scratchProject: FormControl;
  projectItemSelected: string;

  constructor(
    private webProjectService: WebProjectService,
    private formBuilder: FormBuilder,
    private ccpcService: CcpcService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.studentName = new FormControl('', [Validators.required]);
    this.entitySelect = new FormControl('', [Validators.required]);
    this.teammateName = new FormControl('');

    this.submissionForm = this.formBuilder.group({
      studentName: this.studentName,
      entitySelect: this.entitySelect,
      teammateName: this.teammateName,
    });

    if (this.tournament && this.tournament.type) {
      if (this.tournament.type !== CcpcSubmissionType.SCRATCH) {
        this.projectList = new FormControl('', [
          Validators.required,
          (): ValidationErrors | null => {
            return this.projectSelectionMade
              ? null
              : {
                  projectList: {
                    valid: false,
                    message: 'Project selection form invalid',
                  },
                };
          },
        ]);
      }
      /* eslint-disable no-fallthrough */
      switch (this.tournament.type) {
        case CcpcSubmissionType.SCRATCH:
          this.scratchProject = new FormControl('', [
            Validators.required,
            Validators.pattern(
              '^(https\\:\\/\\/)+scratch.mit.edu/projects/[0-9]+(/)?'
            ),
          ]);
          this.submissionForm.addControl('scratchProject', this.scratchProject);
          break;
        case CcpcSubmissionType.WEB:
          this.myProjectFormData$ = this.createTypeaheadObservable(
            this.webProjectService.getWebProjects(this.user.uid)
          ) as Observable<WebProject[]>;
        case CcpcSubmissionType.TICTACTOE_JAVA:
          this.myProjectFormData$ = this.createTypeaheadObservable(
            this.myProjectData$
          ) as Observable<GameProject[]>;
        case CcpcSubmissionType.TICTACTOE_PYTHON:
          this.myProjectFormData$ = this.createTypeaheadObservable(
            this.myProjectData$
          ) as Observable<GameProject[]>;
        case CcpcSubmissionType.TICTACTOE_BLOCKLY:
          this.myProjectFormData$ = this.createTypeaheadObservable(
            this.myProjectData$
          ) as Observable<GameProject[]>;
        case CcpcSubmissionType.BATTLESHIP_JAVA:
          this.myProjectFormData$ = this.createTypeaheadObservable(
            this.myProjectData$
          ) as Observable<GameProject[]>;
        default:
          this.submissionForm.addControl('projectList', this.projectList);
          break;
      }
    }

    const projectToken$ = this.projectList.valueChanges.pipe(
      filter((value) => {
        return typeof value === 'string';
      })
    );

    this.filteredProjectData$ = combineLatest([
      this.myProjectData$,
      projectToken$,
    ]).pipe(
      map(([myProjectData, token]) => {
        const query = new RegExp(token, 'i');
        return myProjectData.filter((project: Project) => {
          return query.test(project.name + ' ' + project.readme);
        });
      })
    );
  }

  ngAfterViewInit() {
    if (this.currentSubmission$) {
      this.currentSubmission$.subscribe((currentSubmission) => {
        if (currentSubmission && currentSubmission.studentName) {
          this.studentName.patchValue(currentSubmission.studentName);
        }
        if (currentSubmission && currentSubmission.coach) {
          this.entitySelect.patchValue(currentSubmission.coach);
        }
        if (currentSubmission && currentSubmission.teammateName) {
          this.isInTeam = true;
          this.teammateName.patchValue(currentSubmission.teammateName);
        } else {
          this.isInTeam = false;
        }
        if (
          currentSubmission &&
          currentSubmission.projectType === CcpcSubmissionType.SCRATCH &&
          currentSubmission.scratchUrl
        ) {
          this.scratchProject.patchValue(currentSubmission.scratchUrl);
        }
      });
    }
  }

  getUserWebProjects(): Observable<WebProject[]> {
    return this.createTypeaheadObservable(
      this.webProjectService.getWebProjects(this.user.uid)
    ) as Observable<WebProject[]>;
  }

  createTypeaheadObservable(projectsObservable$: Observable<Project[]>) {
    return combineLatest([
      this.projectList.valueChanges.pipe(
        filter((value) => {
          return typeof value === 'string';
        })
      ),
      projectsObservable$,
    ]).pipe(
      map(([token, projects]) => {
        const query = new RegExp(token, 'i');
        return projects.filter((project: Project) => {
          return query.test(project.name);
        });
      })
    );
  }

  submitForm() {
    const studentName: string = this.studentName.value;
    const coach: CcpcPerson = this.entitySelect.value;

    const submission: CcpcSubmission = {
      id: this.user.uid,
      tournamentId: this.tournament.id,
      competitionId: this.tournament.competitionId,
      studentName: studentName,
      uid: this.user.uid,
      projectType: this.tournament.type,
      coach: coach,
      submitted: firebase.firestore.Timestamp.now(),
      ...(this.tournament.type === CcpcSubmissionType.SCRATCH && {
        scratchUrl: this.scratchProject.value.replace(/\/$/, ''),
      }),
      ...((this.tournament.type === CcpcSubmissionType.WEB ||
        this.tournament.type === CcpcSubmissionType.TICTACTOE_JAVA ||
        this.tournament.type === CcpcSubmissionType.TICTACTOE_PYTHON ||
        this.tournament.type === CcpcSubmissionType.TICTACTOE_BLOCKLY ||
        this.tournament.type === CcpcSubmissionType.BATTLESHIP_JAVA) && {
        projectSubmission: this.projectList.value,
      }),
    };

    if (this.isInTeam) {
      submission.teammateName = this.teammateName.value;
    }

    this.ccpcService
      .submitProject(
        submission,
        this.tournament.competitionId,
        this.tournament.id
      )
      .then(() => {
        this.openSuccessModal(this.successModal);
        this.onModalClose();
      })
      .catch(() => {
        this.openFailModal(this.failModal);
        this.onModalClose();
      });
  }

  onProjectSelect(event: TypeaheadMatch): void {
    this.projectSelected = event.item as Project;
    this.submissionForm.get('projectList').setValue(this.projectSelected);
    this.projectSelectionMade = true;
    this.projectList.updateValueAndValidity();
  }

  chooseAnotherProject(): void {
    this.projectSelectionMade = false;
    this.projectItemSelected = '';
    this.projectList.updateValueAndValidity();
  }

  toggleTeam() {
    this.isInTeam = !this.isInTeam;

    if (this.isInTeam) {
      this.submissionForm.addControl('teammateName', this.teammateName);
    } else {
      this.submissionForm.removeControl('teammateName');
    }
  }

  typeaheadNoResults(event: boolean): void {
    this.noResult = event;
  }

  onModalClose(): void {
    this.closeModal.emit();
  }

  openSuccessModal(template: TemplateRef<any>) {
    this.successModalRef = this.modalService.show(template, {
      class: 'modal-dialog modal-dialog-centered',
    });
  }

  openFailModal(template: TemplateRef<any>) {
    this.failModalRef = this.modalService.show(template, {
      class: 'modal-dialog modal-dialog-centered',
    });
  }
}
