export class Queue<T> extends Array<T> {
  private data: T[] = [];

  constructor(
    private capacity: number = Infinity,
    private doShuffle: boolean = false,
    private array?: T[]
  ) {
    super();
    if (array) {
      this.data = array;
    }
    if (this.doShuffle) {
      this.shuffle();
    }
  }

  enqueue(item: T): void {
    if (this.size() === this.capacity) {
      throw Error('Queue has reached max capacity, you cannot add more items');
    }
    this.data.push(item);
  }

  dequeue(): T | undefined {
    return this.data.shift();
  }

  peek(): T | undefined {
    return this.data[0];
  }

  size(): number {
    return this.data.length;
  }

  shuffle(): void {
    for (let i = this.data.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.data[i], this.data[j]] = [this.data[j], this.data[i]];
    }
  }

  from(): Array<T> {
    return this.data;
  }
}
