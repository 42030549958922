<div class="modal-header">
  <h4 class="modal-title pull-left">
    {{ isNew ? 'Create' : isModify ? 'Modify' : 'View' }} Question
  </h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="modalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form
    id="questionForm"
    [formGroup]="questionForm"
    (ngSubmit)="onSubmit()"
    class="container-fluid"
  >
    <div class="form-group row">
      <label for="questionPrompt" class="col-sm-2 col-form-label">Prompt</label>
      <textarea
        type="text"
        id="questionPrompt"
        class="form-control"
        formControlName="questionPrompt"
        rows="1"
        [ngClass]="{
          'is-invalid': questionForm.get('questionPrompt').invalid,
          'is-valid':
            questionForm.get('questionPrompt').valid &&
            questionForm.get('questionPrompt').dirty
        }"
      ></textarea>
    </div>
    <div class="form-group row">
      <label for="questionType" class="col-sm-2 col-form-label">Type</label>
      <input
        type="text"
        id="questionType"
        class="form-control col-sm-4"
        [value]="questionData.type"
        disabled
      />
    </div>

    <h3>Choices</h3>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Letter</th>
          <th scope="col">Text</th>
          <th scope="col">Correct</th>
        </tr>
      </thead>
      <tbody>
        <!-- boolean question type -->
        <ng-container [ngSwitch]="questionData.type">
          <ng-container *ngSwitchCase="'boolean'">
            <tr
              [ngClass]="{
                correctQuestion: questionForm.get('answer').value === true,
              }"
            >
              <td>A</td>
              <td>True</td>
              <td>
                <input
                  [id]="'boolean_True_Correct'"
                  name="answer"
                  formControlName="answer"
                  type="radio"
                  [value]="true"
                />
              </td>
            </tr>
            <tr
              [ngClass]="{
                correctQuestion: questionForm.get('answer').value === false,
              }"
            >
              <td>B</td>
              <td>False</td>
              <td>
                <input
                  [id]="'boolean_False_Correct'"
                  name="answer"
                  formControlName="answer"
                  type="radio"
                  [value]="false"
                />
              </td>
            </tr>
          </ng-container>

          <!-- single question type -->
          <ng-container *ngSwitchCase="'single'">
            <tr
              *ngFor="let choice of questionData.choices | keyvalue"
              [ngClass]="{
                correctQuestion: choice.key === questionForm.get('answer').value,
              }"
            >
              <td>{{ choice.key }}</td>
              <td>
                <textarea
                  type="text"
                  [id]="'single_' + choice.key + '_Choice'"
                  formControlName="choice_{{ choice.key }}"
                  class="form-control"
                  rows="1"
                  [ngClass]="{
                    'is-invalid': questionForm.get('choice_' + choice.key)
                      .invalid,
                    'is-valid':
                      questionForm.get('choice_' + choice.key).valid &&
                      questionForm.get('choice_' + choice.key).dirty
                  }"
                ></textarea>
              </td>
              <td>
                <input
                  [id]="'single_' + choice.key + '_Correct'"
                  name="answer"
                  formControlName="answer"
                  type="radio"
                  [value]="choice.key"
                />
              </td>
            </tr>
          </ng-container>

          <!-- multiple question type -->
          <ng-container *ngSwitchCase="'multiple'">
            <tr
              *ngFor="let choice of questionData.choices | keyvalue"
              [ngClass]="{
                correctQuestion: questionForm.get('checkboxGroup').get('answer_' + choice.key).value,
              }"
            >
              <td>{{ choice.key }}</td>
              <td>
                <textarea
                  type="text"
                  [id]="'multiple_' + choice.key + '_Choice'"
                  formControlName="choice_{{ choice.key }}"
                  class="form-control"
                  rows="1"
                  [ngClass]="{
                    'is-invalid': questionForm.get('choice_' + choice.key)
                      .invalid,
                    'is-valid':
                      questionForm.get('choice_' + choice.key).valid &&
                      questionForm.get('choice_' + choice.key).dirty
                  }"
                ></textarea>
              </td>
              <td>
                <div class="form-group" formGroupName="checkboxGroup">
                  <input
                    [id]="'multiple_' + choice.key + '_Correct'"
                    name="answer"
                    formControlName="answer_{{ choice.key }}"
                    type="checkbox"
                    [value]="choice.key"
                  />
                </div>
              </td></tr
          ></ng-container>
        </ng-container>
      </tbody>
    </table>

    <!-- tags -->
    <div class="form-group row" formArrayName="tags">
      <label for="tags" class="col-sm-2 col-form-label">Tags</label>
      <div
        *ngFor="let tag of questionForm.get('tags')['controls']; let i = index"
        [formGroupName]="i"
      >
        <div class="input-group mb-3">
          <input
            type="text"
            id="tags"
            class="form-control col-12"
            formControlName="tag"
            [ngClass]="{
              'is-invalid': questionForm.get('tags').controls[i].get('tag')
                .invalid,
              'is-valid':
                questionForm.get('tags').controls[i].get('tag').valid &&
                questionForm.get('tags').controls[i].get('tag').dirty
            }"
          />
          <div class="input-group-append">
            <button
              class="btn btn-danger btn-outline-secondary addTag"
              type="button"
              (click)="removeTag(i)"
              [disabled]="!isModify"
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
    <button
      type="button"
      class="btn btn-primary"
      (click)="addTag()"
      [disabled]="!isModify"
    >
      Add Tag
    </button>
    <hr />
    <div
      class="btn-group"
      role="group"
      aria-label="Question Submission/Deletion Button Group"
    >
      <button
        type="submit"
        class="btn btn-success"
        [disabled]="!isModify || questionForm.invalid || loadingSubmission"
      >
        {{ isNew ? 'Create Question' : 'Save Changes' }}
      </button>
      <button
        type="button"
        class="btn btn-danger"
        (click)="openModal(confirmDelete)"
        [disabled]="!isModify || loadingSubmission"
        *ngIf="!isNew"
      >
        Delete Question
      </button>
    </div>
    <!-- Error message -->
    <div class="alert alert-danger mt-2" *ngIf="submissionError">
      Submission Error: {{ submissionError }}
    </div>
  </form>
</div>
<ng-template #loading>
  <codecraft-loading-spinner></codecraft-loading-spinner
></ng-template>
<!--Delete Modal-->
<ng-template #confirmDelete>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Delete Question</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="confirmModal.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">Are you sure you'd like to delete this question?</div>

  <button
    type="button"
    class="btn btn-danger"
    (click)="deleteQuestion(questionData.id); confirmModal.hide()"
  >
    Yes, delete F O R E V E R
  </button>
</ng-template>
