import { Component, Input } from '@angular/core';
import { Certificate } from '@codecraft-works/data-models';
import { CardComponent } from '../card.component';

@Component({
  selector: 'app-certificate-card',
  templateUrl: './certificate-card.component.html',
  styleUrls: ['./certificate-card.component.css'],
})
export class CertificateCardComponent extends CardComponent {
  @Input()
  certificate: Certificate;

  constructor() {
    super();
  }
}
