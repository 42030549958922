import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ProgramService } from '@app/shared/program/program.service';
import { ShoppingCartItem, User } from '@codecraft-works/data-models';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import {
  combineLatest,
  Observable,
  of as observableOf,
  Subject,
  Subscription,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { ShoppingCartService } from '../shopping-cart.service';

@Component({
  selector: 'app-shopping-cart-item',
  templateUrl: './shopping-cart-item.component.html',
  styleUrls: ['./shopping-cart-item.component.css'],
})
export class ShoppingCartItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  item: ShoppingCartItem;

  @Input()
  user: User;

  faMinusCircle = faMinusCircle;
  total: number;
  updateCartQuantity: Subject<number> = new Subject<number>();
  maxSeats$: Observable<number>;
  maxSeatsArray: number[];

  // maximum seats to show select dropdown
  maxSeatsDropdown = 20;
  allSubs = new Subscription();

  constructor(
    private shoppingCartService: ShoppingCartService,
    private programService: ProgramService
  ) {}

  ngOnInit() {
    this.maxSeats$ = this.programService.getMaxSeats(this.item.program.id).pipe(
      tap((maxSeats) => {
        if (maxSeats <= this.maxSeatsDropdown) {
          this.maxSeatsArray = Array.from(Array(maxSeats).keys()).map(
            (x) => ++x
          );
        }
      })
    );

    this.allSubs.add(
      combineLatest([
        this.updateCartQuantity.pipe(
          debounceTime(1500),
          distinctUntilChanged()
        ),
        this.programService.getProgram(this.item.program.id),
        this.shoppingCartService.getShoppingCartItems(this.user.uid),
      ])
        .pipe(map(([quantity, program, cart]) => ({ quantity, program, cart })))
        .pipe(take(1))
        .pipe(
          switchMap((result) => {
            if (typeof result.quantity === 'string') {
              result.quantity = parseInt(result.quantity, 10);
            }

            if (result.quantity < 0) {
              result.quantity = 0;
            }
            return observableOf(result);
          })
        )
        .pipe(
          switchMap((result) => {
            return this.shoppingCartService.handleCartItem(
              this.user,
              result.program,
              result.quantity,
              result.cart,
              true
            );
          })
        )
        .subscribe(() => {})
    );

    this.total = this.item.program.stripe.price * this.item.quantity;
  }

  deleteCartItem() {
    this.allSubs.add(
      this.shoppingCartService
        .getShoppingCartItems(this.user.uid)
        .subscribe((cart) => {
          this.shoppingCartService.dropItem(this.user, this.item, cart);
        })
    );
  }

  updateCartQty(quantity: number) {
    this.updateCartQuantity.next(quantity);
  }

  ngOnChanges() {
    this.total = this.item.program.stripe.price * this.item.quantity;
  }

  ngOnDestroy() {
    if (this.allSubs) {
      this.allSubs.unsubscribe();
    }
  }
}
