<div class="container-fluid">
  <ng-container *ngIf="membership">
    <ng-container *ngIf="user">
      <ng-container *ngIf="membership.memberUser">
        <h5 class="mb-2">
          Currently Assigned:
        </h5>
        <div class="row mb-2">
          <img
            [alt]="membership.memberUser.displayName"
            class="col-2 rounded-circle"
            [src]="membership.memberUser.photoURL"
          />
          <div class="col-10 my-auto text-wrap">
            {{
              membership.memberUser.displayName +
                ' | ' +
                membership.memberUser.email
            }}
          </div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          user.roles && (user.roles.admin || user.roles.editor);
          else nonAdmin
        "
      >
        <h5 class="mb-2">Select User: Admin Mode</h5>
        <form
          *ngIf="!selectionMade; else chooseAgain"
          id="associatedMembersForm"
          [formGroup]="membershipAssignmentFormGroup"
        >
          <div class="form-group">
            <input
              role="combobox"
              autocomplete="off"
              type="text"
              class="form-control my-class"
              id="userList"
              name="userList"
              formControlName="selectedMembers"
              aria-describedby="adminUserSelectHelp"
              [isAnimated]="true"
              [typeaheadAsync]="true"
              [typeahead]="dataSource$"
              [typeaheadOptionsLimit]="10"
              [typeaheadMinLength]="2"
              [typeaheadItemTemplate]="customItemTemplate"
              placeholder="Start typing to find user"
              (typeaheadOnSelect)="onUserSelect($event)"
              editable="false"
            />
            <ng-template
              #customItemTemplate
              class="container-fluid"
              let-model="item"
              let-index="index"
            >
              <div class="row">
                <img
                  class="col-2 rounded-circle"
                  [src]="model.photoURL"
                  [alt]="model.displayName"
                />
                <div class="col-10 my-auto text-wrap">
                  {{
                    model.displayName +
                      ' | ' +
                      (model.userName ? model.userName + ' | ' : ' ') +
                      model.email
                  }}
                </div>
              </div>
            </ng-template>

            <small id="adminUserSelectHelp" class="form-text text-muted">
              This is the Admin mode, allowing you to select any User on the
              platform to assign the membership to. Search by Display Name |
              Username | Email
            </small>
          </div>
        </form>
        <ng-template #chooseAgain>
          <div class="row mb-3">
            <img
              class="col-2 rounded-circle"
              [src]="userSelected.photoURL"
              [alt]="userSelected.displayName"
            />
            <div class="col-7 my-auto text-wrap">
              {{
                userSelected.displayName +
                  ' | ' +
                  (userSelected.userName
                    ? userSelected.userName + ' | '
                    : ' ') +
                  userSelected.email
              }}
            </div>
            <button
              type="button"
              class="btn btn-primary col-3 py-1 align-self-center"
              (click)="chooseAnother()"
            >
              Choose again
            </button>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #nonAdmin>
        <h5 class="mb-2">Associated Accounts</h5>
        <form
          *ngIf="
            user.associatedMembers && user.associatedMembers.length > 0;
            else noAssociatedMembers
          "
          id="associatedMembersForm"
          [formGroup]="membershipAssignmentFormGroup"
        >
          <div class="form-group">
            <div
              class="form-check mb-1"
              *ngFor="let associatedMember of user.associatedMembers"
            >
              <input
                name="selectedMembers"
                type="radio"
                class="form-check-input"
                [value]="associatedMember"
                formControlName="selectedMembers"
                [id]="
                  'memberships-assignment-form-user-' + associatedMember.email
                "
              />
              <label
                [attr.data-test]="
                  'memberships-assignment-form-user-' + associatedMember.email
                "
                [for]="
                  'memberships-assignment-form-user-' + associatedMember.email
                "
                class="form-check-label"
              >
                <strong>{{ associatedMember.displayName }}</strong> -
                {{ associatedMember.email }}
              </label>
            </div>
          </div>
        </form>
      </ng-template>
      <h5 class="mb-2">Don't see them in the list?</h5>
      <p class="mb-1">
        No problem! Have your student login and navigate to
        <strong>https://code.cr/redeem</strong> and enter the membership code
        below
      </p>
      <div class="input-group input-group-md  mb-2">
        <div class="input-group-prepend">
          <label class="input-group-text" for="membershipCode">
            Membership Code:</label
          >
        </div>
        <input
          #inputTarget
          class="membershipCode"
          id="membershipCode form-control"
          type="text"
          value="{{ this.membership.membershipRedemptionCode }}"
          [readonly]="true"
        />
        <div class="input-group-append">
          <button
            type="button"
            class="btn btn-primary"
            [ngxClipboard]="inputTarget"
          >
            Copy to Clipboard
          </button>
        </div>
      </div>
      <p>
        After they enter the code you should see them assigned to the membership
        and they will have access to the program. In the future, you won't need
        to use a code to assign them, they will be associated with your account
        and will appear in the list above.
      </p>
    </ng-container>
  </ng-container>
</div>

<ng-template #noAssociatedMembers>
  <p>No users associated with this account yet</p>
</ng-template>
