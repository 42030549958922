import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'externalLink'
})
export class ExternalLinkPipe implements PipeTransform {
  transform(value: string): any {
    const element: Element = document.createElement('div');
    element.innerHTML = value;
    const links = element.querySelectorAll('a');
    links.forEach(link => {
      link.setAttribute('rel', 'noopener noreferrer nofollow');
      link.setAttribute('target', '_blank');
    });

    return element.innerHTML;
  }
}
