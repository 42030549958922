import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import 'firebase/compat/firestore';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor(private firebaseDatabase: AngularFirestore) {}

  private getConfigsCollection() {
    return this.firebaseDatabase.collection('config');
  }

  public getAllConfigs(): Observable<any[]> {
    return this.getConfigsCollection()
      .snapshotChanges()
      .pipe(
        map((item) => {
          const configs: string[] = [];
          item.map((a) => {
            const data = a.payload.doc.data() as any;
            configs.push(data as any);
          });
          return configs;
        })
      );
  }

  getConfig(id: string): Observable<any> {
    const config = this.firebaseDatabase
      .collection('config')
      .doc(id)
      .valueChanges();

    return config;
  }

  public setConfig(id: string, payload: string) {
    this.firebaseDatabase.collection('config').doc(id).set({
      content: payload,
    });
  }

  public updateConfig(id: string, content: any): Observable<void> {
    return from(
      this.firebaseDatabase.collection('config').doc<any>(id).update(content)
    );
  }
}
