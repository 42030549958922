<button
  type="button"
  class="btn btn-lg btn-dark"
  (click)="newGameProject(); closeModal()"
  *ngIf="user && type && language"
  [attr.disabled]="
    language === 'javascript' ||
    (type === gameType.BATTLESHIP && language === 'python')
      ? true
      : null
  "
>
  <fa-icon [icon]="this.icon" class="fa-lg"></fa-icon>&nbsp;{{
    this.language | titlecase
  }}
</button>
