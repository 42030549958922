import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { Program, ShoppingCartItem, User } from '@codecraft-works/data-models';
import firebase from 'firebase/compat/app';
import { Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { AnalyticsService } from '../analytics/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class ShoppingCartService {
  constructor(
    private firebaseDatabase: AngularFirestore,
    private analyticsService: AnalyticsService
  ) {}

  /**
   * Returns observable array of ShoppingCartItem
   * @param userId
   */
  getShoppingCartItems(uid: string): Observable<ShoppingCartItem[]> {
    let snapshot: AngularFirestoreCollection<ShoppingCartItem>;

    if (uid) {
      snapshot = this.firebaseDatabase
        .collection('users')
        .doc(uid)
        .collection('shopping-cart');
    }
    return snapshot.snapshotChanges().pipe(
      map((item) => {
        const shoppingCartItems: ShoppingCartItem[] = [];

        item.map((a) => {
          const data = a.payload.doc.data() as ShoppingCartItem;
          shoppingCartItems.push(data as ShoppingCartItem);
        });
        return shoppingCartItems;
      })
    );
  }

  /**
   * Get collection reference
   * @param uid
   */
  getShoppingCart(uid: string) {
    return this.firebaseDatabase
      .collection('users')
      .doc(uid)
      .collection('shopping-cart');
  }
  /**
   * Get item reference
   * @param uid
   * @param id
   */
  getShoppingCartItem(
    uid: string,
    programId: string
  ): Observable<ShoppingCartItem> {
    return this.firebaseDatabase
      .collection('users')
      .doc(uid)
      .collection('shopping-cart')
      .doc<ShoppingCartItem>(programId)
      .valueChanges();
  }

  /**
   * Create cart item
   * @param uid
   * @param program
   * @param quantity
   */
  createCartItem(
    user: User,
    program: Program,
    quantity: number
  ): Observable<ShoppingCartItem> {
    const cartRef = this.firebaseDatabase
      .collection('users')
      .doc(user.uid)
      .collection('shopping-cart')
      .doc<ShoppingCartItem>(program.id);

    const cartItem = {
      id: program.id,
      uid: user.uid,
      created: firebase.firestore.Timestamp.now(),
      modified: firebase.firestore.Timestamp.now(),
      program: {
        id: program.id,
        stripe: program.stripe,
        name: program.name,
      },
      quantity: quantity,
    };

    cartRef.set(cartItem);

    return cartRef.valueChanges();
  }

  /**
   * Update Quantity
   * @param uid
   * @param programId
   * @param quantity
   */
  updateCartItem(uid: string, programId: string, quantity: number) {
    if (quantity >= 0) {
      this.firebaseDatabase
        .collection('users')
        .doc(uid)
        .collection('shopping-cart')
        .doc<Partial<ShoppingCartItem>>(programId)
        .set(
          {
            modified: firebase.firestore.Timestamp.now(),
            quantity: quantity,
          },
          {
            merge: true,
          }
        );
    }
  }

  /**
   * Delete one
   * @param uid
   * @param programId
   */
  dropItem(user: User, item: ShoppingCartItem, cart: ShoppingCartItem[]) {
    this.analyticsService.removeFromCartEvent(item, cart);

    const cartItem = this.firebaseDatabase
      .collection('users')
      .doc(user.uid)
      .collection<ShoppingCartItem>('shopping-cart')
      .doc<ShoppingCartItem>(item.id);

    cartItem.delete();
  }

  /**
   * Delete all
   * @param uid
   */
  dropCart(uid: string) {
    this.getShoppingCart(uid)
      .get()
      .toPromise()
      .then((x) => {
        x.forEach((doc) => {
          this.getShoppingCart(uid).doc(doc.id).delete();
        });
      })
      .catch((err) => {
        return err;
      });
  }

  handleCartItem(
    user: User,
    program: Program,
    quantity: number,
    cart: ShoppingCartItem[],
    overwriteQuantity?: boolean
  ): Observable<boolean> {
    // If the quantity being set is greater than  the number of seats available
    const notEnoughSeats: boolean =
      program.seatMemberships === null
        ? quantity > program.seats
        : quantity > program.seats - program.seatMemberships;
    return this.getShoppingCartItem(user.uid, program.id)
      .pipe(take(1))
      .pipe(
        mergeMap((shoppingCartItem) => {
          // If there are seats availible
          if (!notEnoughSeats) {
            if (shoppingCartItem === undefined) {
              return this.createCartItem(user, program, 0);
            } else {
              return of(shoppingCartItem);
            }
            // If no seats don't create a cart item or get cart item and return null
          } else {
            this.analyticsService.notEnoughSeatsEvent({
              id: program.id,
              uid: user.uid,
              created: firebase.firestore.Timestamp.now(),
              modified: firebase.firestore.Timestamp.now(),
              program: {
                id: program.id,
                stripe: program.stripe,
                name: program.name,
              },
              quantity: quantity,
            });
            return of(null);
          }
        })
      )
      .pipe(take(1))
      .pipe(
        mergeMap((shoppingCartItem) => {
          // If a null cart item was passed earlier, don't update cart, return of(false)
          if (shoppingCartItem) {
            // This object has the quantity value set to the difference rather than the new value,
            // So Klaviyo and Google Analytics see the item added but not the current items already in the cart

            let cartItemWithQuantityDifference: ShoppingCartItem;
            let quantityChange = 0;

            if (quantity > 0) {
              if (overwriteQuantity) {
                quantityChange = quantity - shoppingCartItem.quantity;
              } else {
                quantityChange = quantity;
              }

              cartItemWithQuantityDifference = {
                id: shoppingCartItem.id,
                uid: shoppingCartItem.uid,
                created: shoppingCartItem.created,
                modified: firebase.firestore.Timestamp.now(),
                program: shoppingCartItem.program,
                quantity: Math.abs(quantityChange),
              };

              if (quantityChange >= 1) {
                this.analyticsService.addToCartEvent(
                  cartItemWithQuantityDifference,
                  cart
                );
              } else if (quantityChange < 0) {
                this.analyticsService.removeFromCartEvent(
                  cartItemWithQuantityDifference,
                  cart
                );
              }
            }
            this.updateCartItem(
              user.uid,
              program.id,
              overwriteQuantity
                ? quantity
                : shoppingCartItem.quantity + quantityChange
            );
            return of(true);
          } else {
            return of(false);
          }
        })
      );
  }
}
