import { Component } from '@angular/core';
@Component({
  standalone: true,
  selector: 'codecraft-loading-spinner',
  template: `
    <img
      src="/assets/img/dark-gears-animated.svg"
      alt="Loading - 3 Spinning Dark Blue Gears"
    />
  `,
  styles: [],
  imports: [],
})
export class LoadingSpinnerComponent {}
