<div
  class="card"
  [ngClass]="{
    'h-100 d-flex flex-column justify-content-between': isGrid,
    'rounded-0 no-shadow': noGutters === true,
    shadow: noGutters === false,
    'p-0': noPadding === true,
    'hover-effect': noHoverEffect !== true
  }"
>
  <div
    #header
    [ngClass]="{
      'd-block': hasContent(headerElement),
      'd-none': !hasContent(headerElement),
      'p-0': noPadding
    }"
    class="card-header {{ headerClass }}"
  >
    <ng-content select="[header]"></ng-content>

    <a
      *ngIf="detailUrl && imageUrl && (imageAlt || title); else noUrl"
      routerLink="{{ detailUrl }}"
    >
      <img
        class="card-img"
        width="100%"
        src="{{ imageUrl }}"
        alt="{{ imageAlt ? imageAlt : title }}"
      />
    </a>
    <ng-template #noUrl>
      <img
        *ngIf="imageUrl && (imageAlt || title)"
        class="card-img-top"
        width="100%"
        src="{{ imageUrl }}"
        alt="{{ imageAlt ? imageAlt : title }}"
      />
    </ng-template>
  </div>
  <div
    [ngClass]="{
      'card-body p-2': !noPadding || noPadding === null,
      'p-0': noPadding
    }"
  >
    <a
      *ngIf="
        detailUrl && title && (showTitle || showTitle === null);
        else noUrlTitle
      "
      [ngClass]="{
        'card-title mb-2':
          detailUrl && title && (showTitle || showTitle === null)
      }"
      routerLink="{{ detailUrl }}"
    >
      <p class="h5">
        <fa-icon *ngIf="icon" [icon]="icon"></fa-icon> {{ title }}
      </p>
    </a>
    <ng-template #noUrlTitle>
      <h4
        [ngClass]="{
          'd-none': !showTitle,
          'card-title mb-2': title && (showTitle || showTitle === null)
        }"
      >
        <fa-icon *ngIf="icon" [icon]="icon"></fa-icon> {{ title }}
      </h4>
    </ng-template>
    <h5 *ngIf="subTitle" class="card-subtitle mb-2 text-muted">
      {{ subTitle }}
    </h5>
    <ng-content></ng-content>
  </div>
  <div
    #footer
    class="card-footer p-2 {{ footerClass }}"
    [ngClass]="{
      'rounded-0': noGutters
    }"
  >
    <ng-content select="[footer]"></ng-content>
    <div
      *ngIf="
        (detailUrl && showDetailButton) ||
        (editUrl && user && (user.roles.admin || user.roles.editor))
      "
      class="btn-group d-block"
      role="group"
    >
      <a
        *ngIf="detailUrl && showDetailButton && title"
        routerLink="{{ detailUrl }}"
        class="btn btn-primary"
        [attr.aria-label]="['View ' + title]"
        >{{ detailButtonText ? detailButtonText : 'See Details' }}</a
      >
      <a
        *ngIf="editUrl && user && (user.roles.admin || user.roles.editor)"
        routerLink="{{ editUrl }}"
        class="btn btn-secondary"
        >Edit</a
      >
      <ng-content select="[button-group]"></ng-content>
    </div>
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
