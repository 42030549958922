import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/core/auth/auth.service';
import { MembershipService } from '@app/core/membership/membership.service';
import { ShoppingCartService } from '@app/core/shopping-cart/shopping-cart.service';
import { Membership, User } from '@codecraft-works/data-models';
import { faIdBadge, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
@Component({
  selector: 'app-membership-list',
  templateUrl: './membership-list.component.html',
  styleUrls: ['./membership-list.component.css']
})
export class MembershipListComponent
  implements OnInit, AfterViewInit, OnDestroy {
  user$: Observable<User>;
  customerMemberships$: Observable<Membership[]>;
  studentMemberships$: Observable<Membership[]>;

  faIdBadge = faIdBadge;
  faQuestionCircle = faQuestionCircle;
  fragment: string;
  modalRef: BsModalRef;
  userSub: Subscription;

  @ViewChild('success')
  successModal;

  @Input()
  embedView = false;

  constructor(
    private userService: AuthService,
    private modalService: BsModalService,
    private shoppingCartService: ShoppingCartService,
    private route: ActivatedRoute,
    private titleService: Title,
    private membershipService: MembershipService
  ) {
    this.fragment = this.route.snapshot.fragment;
    this.titleService.setTitle('Codecraft Works - My Memberships');
  }

  ngOnInit() {
    this.user$ = this.userService.getUser();
    this.customerMemberships$ = this.user$.pipe(
      mergeMap(user => {
        return this.membershipService.getMemberships(user.uid);
      })
    );
    this.studentMemberships$ = this.user$.pipe(
      mergeMap(user => {
        return this.membershipService.getStudentMemberships(user.uid);
      })
    );
  }

  ngAfterViewInit() {
    switch (this.fragment) {
      // Clear the shopping cart after being redirected here after a purchase
      case 'success':
        this.userSub = this.user$.subscribe(user => {
          this.shoppingCartService.dropCart(user.uid);
          this.openModal(this.successModal);
        });

        break;

      default:
        break;
    }
  }

  ngOnDestroy() {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }

  /**
   * Handles opening any modal
   * @param modalName
   */
  openModal(modalName: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modalName, {
      class: 'modal-lg modal-dialog modal-dialog-centered'
    });
  }
}
