import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class AdminGuard {
  constructor(private auth: AuthService, private router: Router) {}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.user$.pipe(
      take(1),
      map((user) => {
        return user && (user.roles.admin || user.roles.editor) ? true : false;
      }),
      tap((isAdmin) => {
        if (!isAdmin) {
          console.error('Access denied - Admins only');
          this.router.navigate(['/']);
        }
      })
    );
  }
}
