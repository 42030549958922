import { Component, Input } from '@angular/core';
import { CodecraftQuiz, User } from '@codecraft-works/data-models';
import { CardComponent } from '../card.component';

@Component({
  selector: 'app-quiz-card',
  templateUrl: './quiz-card.component.html',
  styleUrls: ['./quiz-card.component.css'],
})
export class QuizCardComponent extends CardComponent {
  @Input()
  quiz: CodecraftQuiz;
  @Input()
  user: User;

  constructor() {
    super();
  }
}
