import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor() {}
  public getFile(
    bucketName: string,
    bucketFileName: string,
    responseType: string
  ): Observable<any> {
    return new Observable((observer) => {
      const storage = firebase.storage();
      const storageRef = storage.refFromURL(bucketName);
      storageRef
        .child(bucketFileName)
        .getDownloadURL()
        .then(function (url) {
          const xhr = new XMLHttpRequest();
          xhr.responseType = <XMLHttpRequestResponseType>responseType;
          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                observer.next(xhr.response);
                observer.complete();
              } else {
                observer.error(xhr.response);
              }
            }
          };
          xhr.open('GET', url);
          xhr.send();
        })
        .catch(function () {
          // Handle any errors
        });
    });
  }

  public uploadFile(
    bucketName: string,
    bucketFileName: string,
    data: string,
    fileContentType: string
  ) {
    // Create file metadata including the content type
    const metadata = {
      contentType: fileContentType,
    };
    const dataBlob = new Blob([data], {
      type: 'text/plain',
    });
    const storage = firebase.storage();
    const storageRef = storage.refFromURL(bucketName);
    storageRef
      .child(bucketFileName)
      .put(dataBlob, metadata)
      .then(function (snapshot) {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (progress === 100) {
          window.alert('Files are saved');
        }
      })
      .catch(function () {});
  }
}
