export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyB4rvLWYKm9fKL5LOH4W0Mpe0Lidnmlubo',
    authDomain: 'beta.auth.codecraftworks.com',
    databaseURL: 'https://codecraft-platform-test.firebaseio.com',
    projectId: 'codecraft-platform-test',
    storageBucket: 'beta.assets.codecraftworks.com',
    messagingSenderId: '230782669148',
    appId: '1:230782669148:web:0a982112ac55735f',
  },
  projectBucket: 'beta.projects.codecraftworks.com',
  assetBucket: 'beta.assets.codecraftworks.com',
  host: 'https://beta.app.codecraftworks.com',
  actionCodeSettings: {
    url: 'https://beta.app.codecraftworks.com/login',
    handleCodeInApp: true,
  },
  stripePublishableKey: 'pk_test_rh3IIAXmbRuwuWsNeFr3vrgo',
  cloudFunctionBaseUrl:
    'https://us-central1-codecraft-platform-test.cloudfunctions.net',
  googleAnalyticsTrackingId: 'UA-101275858-6',
  hotjarId: '2417335',
  emulator: false,
};
