import { DecimalPipe, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CodecraftQuizInstance } from '@codecraft-works/data-models';
import { LoadingSpinnerComponent } from '../../loading-spinner/loading-spinner.component';
import { QuizService } from '../util/quiz.service';

@Component({
  standalone: true,
  selector: 'codecraft-quiz-completed-page',
  template: `
    <p class="h1">Quiz Complete!</p>
    <hr />
    <div *ngIf="recentQuizInstance; else loading">
      <p>
        {{ recentQuizInstance.passed ? '✅' : '❌' }} Your most recent score:
        {{ recentQuizInstance.correctCount }} out of {{ recentQuestionCount }} =
        {{
          (recentQuizInstance.correctCount / recentQuestionCount) * 100
            | number : '1.0-1'
        }}%
      </p>
      <p>
        {{ bestQuizInstance.passed ? '✅' : '❌' }}
        Best Score: {{ bestQuizInstance.correctCount }} out of
        {{ bestQuestionCount }} =
        {{
          (bestQuizInstance.correctCount / bestQuestionCount) * 100
            | number : '1.0-1'
        }}%
      </p>
      <p>Minimum Passing Score: {{ recentQuizInstance.passingScore }}%</p>
      <p>
        Attempts: {{ recentQuizInstance.attemptCount }} /
        {{
          recentQuizInstance.maxAttempts < 0
            ? 'Unlimited'
            : recentQuizInstance.maxAttempts
        }}
      </p>
      <button
        class="btn btn-primary"
        (click)="restartQuiz()"
        [disabled]="
          recentQuizInstance.maxAttempts >= 0 &&
          recentQuizInstance.maxAttempts <= recentQuizInstance.attemptCount
        "
      >
        Retake Quiz
      </button>
    </div>
    <ng-template #loading>
      <codecraft-loading-spinner></codecraft-loading-spinner>
    </ng-template>
  `,
  styles: [],
  imports: [NgIf, DecimalPipe, LoadingSpinnerComponent],
})
export class CodecraftQuizCompletedPageComponent implements OnInit {
  recentQuizInstance!: CodecraftQuizInstance | undefined;
  bestQuizInstance!: CodecraftQuizInstance | undefined;
  recentQuestionCount: number | undefined;
  bestQuestionCount: number | undefined;

  constructor(private quizService: QuizService) {}

  async ngOnInit(): Promise<void> {
    try {
      const details = await this.quizService.getCompletionDetails();
      this.bestQuizInstance = details.bestQuizInstance;
      this.recentQuizInstance = details.recentQuizInstance;
      this.bestQuestionCount = Object.keys(
        this.bestQuizInstance.questions
      ).length;
      this.recentQuestionCount = Object.keys(
        this.recentQuizInstance.questions
      ).length;
    } catch (error) {
      console.error(error);
      this.quizService.setQuizState({
        component: 'error',
        error: 'Complete quiz instance fetch error: ' + error,
      });
    }
  }
  restartQuiz(): void {
    this.quizService.restartQuiz();
  }
}
