import { CustomBlock } from 'ngx-blockly-new';

export class MyTeamBlock extends CustomBlock {
  constructor() {
    super('tictactoe_myteam');
    this.class = MyTeamBlock;
  }

  public defineBlock() {
    this.block.appendDummyInput().appendField('My Team');
    this.block.setOutput(true, 'String');
    this.block.setColour(270);
    this.block.setTooltip('Outputs the team you are playing (X or O).');
    this.block.setHelpUrl('https://app.codecraftworks.com/courses/33629');
  }

  public toPythonCode(): string | any[] {
    return ['self.get_team()', 99];
  }
}
