import { Component, Input } from '@angular/core';
import { CardComponent } from '@app/shared/card/card.component';
import {
  LearndashCourse,
  LearndashProgress,
  User,
} from '@codecraft-works/data-models';
import { faCheck, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

/**
 * The leandash course's Card Component
 */
@Component({
  selector: 'app-learndash-course-card',
  templateUrl: './learndash-course-card.component.html',
  styleUrls: ['./learndash-course-card.component.css'],
})

/**
 * @classdesc Class representing LearndashCourse' Card component
 */
export class LearndashCourseCardComponent extends CardComponent {
  @Input()
  user: User;

  /**
   * Learendash course input
   */
  @Input()
  learndashCourse: LearndashCourse;

  @Input()
  courseProgress: LearndashProgress;

  faCheck = faCheck;
  faEyeSlash = faEyeSlash;

  constructor() {
    super();
  }

  public filterOnlyLessons(courseOrder: any[]): any[] {
    return courseOrder.filter((item) => {
      return item.type === 'lessons';
    });
  }
}
