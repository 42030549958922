<div class="container-fluid">
  <codecraft-hero-block>
    <div class="row">
      <div class="col-12">
        <h1 class="text-primary">
          Shopping Cart
        </h1>
      </div>
    </div>
  </codecraft-hero-block>

  <ng-container *ngIf="(user$ | async) as user">
    <app-shopping-cart [user]="user"></app-shopping-cart>
  </ng-container>

  <ng-template #canceled>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Did you change your mind?</h4>
    </div>
    <div class="modal-body">
      It looks like you canceled your transaction. Did something look wrong on
      checkout? If you have any questions
      <a class="text-info" href="mailto:support@codecraftworks.com/"
        >let us know</a
      >, we're here to help!
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" (click)="modalRef.hide()">
        Close
      </button>
    </div>
  </ng-template>
</div>
