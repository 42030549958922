<div *ngIf="isModal" class="modal-header bg-primary text-light">
  <h4 class="modal-title pull-left">
    <fa-icon [icon]="faUserCog" class="fa-sm"></fa-icon>&nbsp; User Settings
  </h4>
  <button
    [disabled]="user.displayName === user.email || !user.userName"
    type="button"
    class="close pull-right text-white"
    aria-label="Close"
    id="userSettingsModalClose"
    (click)="closeModalFunction()"
    (keydown.shift.tab)="returnToLastSelectableElement()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div id="userRolesHelp" class="alert alert-success">
    <strong>IMPORTANT:</strong> User Name and User Role cannot be changed once
    they are saved.
  </div>
  <form id="userForm" [formGroup]="userForm">
    <div class="form-group row">
      <label for="email" class="col-sm-4 col-form-label">Email</label>
      <div class="col-sm-8 text-break">
        <span>{{ user.email }}</span>
        <small id="userNameHelp" class="form-text text-muted">
          Email associated with the account. This cannot be changed.
        </small>
      </div>
    </div>
    <hr />
    <div class="form-group row">
      <label for="userName" class="col-sm-4 col-form-label">User Name</label>
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control"
          id="userName"
          formControlName="userName"
          aria-describedby="userNameHelp"
          [attr.aria-invalid]="userName.invalid"
          required
          [ngClass]="{
            'is-invalid': userName.invalid,
            'is-valid': userName.valid && userName.dirty
          }"
        />
        <small id="userNameHelp" class="form-text text-muted">
          User Name is set in the app to uniquely identify your work. It can be
          a real name, pen name, or online profile name.
        </small>
        <ng-container *ngIf="!user.userName">
          <div
            class="alert alert-danger"
            *ngIf="userName && userName.errors && userName.errors.userNameTaken"
          >
            Sorry, this user name is taken!
          </div>
          <div class="invalid-feedback">
            Please provide a user name with no spaces, alphanumeric characters
            only, and 3 -32 characters long.
          </div>
        </ng-container>
      </div>
    </div>
    <ng-container       *ngIf="this.userRoles"
>
    <hr />
    <div
      class="form-group row"
    >
      <label
        id="user-role-label"
        for="userRoles"
        class="col-sm-4 col-form-label"
        >User Role</label
      >
      <div role="group" aria-labelledby="user-role-label" class="col-sm-8">
        <div>
          <label for="userRolesStudent" class="p-1">
            <input
              type="radio"
              id="userRolesStudent"
              value="student"
              formControlName="userRoles"
              aria-describedby="userRolesStudentHelp"
              required
            />
            Student Coder / Maker
            <small id="userRolesStudentHelp" class="form-text text-muted">
              I’m a K12 student learning to code.
            </small>
          </label>
        </div>
        <div>
          <label for="userRolesParent" class="p-1">
            <input
              type="radio"
              value="parent"
              id="userRolesParent"
              formControlName="userRoles"
              aria-describedby="userRolesParentHelp"
              required
            />
            Parent / Guardian
            <small id="userRolesParentHelp" class="form-text text-muted">
              I’m a parent or guardian overseeing my child(ren)’s learning
              account.
            </small>
          </label>
        </div>
        <div>
          <label for="userRolesEducator" class="p-1">
            <input
              type="radio"
              id="userRolesEducator"
              value="educator"
              formControlName="userRoles"
              aria-describedby="userRolesEducatorHelp"
              required
            />
            Teacher / Afterschool Educator
            <small id="userRolesEducatorHelp" class="form-text text-muted">
              I’m a K12 educator, librarian, after school instructor, or remote
              educator.
            </small>
          </label>
        </div>
        <div>
          <label for="userRolesPartner" class="p-1">
            <input
              type="radio"
              id="userRolesPartner"
              value="partner"
              formControlName="userRoles"
              aria-describedby="userRolesPartnerHelp"
              required
            />
            Partner
            <small id="userRolesPartnerHelp" class="form-text text-muted">
              I’m working on behalf of a nonprofit, workforce development
              agency, or other provider.
            </small>
          </label>
        </div>
      </div>
    </div>
  </ng-container>
    <hr />
    <div class="form-group row">
      <label for="displayName" class="col-sm-4 col-form-label"
        >Display Name</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control"
          id="displayName"
          formControlName="displayName"
          aria-describedby="displayNameHelp"
          [attr.aria-invalid]="displayName.invalid"
          required
          [ngClass]="{
            'is-invalid': displayName.invalid,
            'is-valid': displayName.valid && displayName.dirty
          }"
        />
        <small id="displayNameHelp" class="form-text text-muted">
          Display Name is used throughout the app to personalize your work. It
          may be your real name or a pen name. It is not necessarily unique.
        </small>
        <div
          class="alert alert-danger"
          *ngIf="
            displayName &&
            displayName.errors &&
            displayName.errors.displayNameEmail
          "
        >
          Sorry, your name cannot be your email address
        </div>
        <div class="invalid-feedback">
          Please provide a name, between 3-32 characters, that is not your email
          address
        </div>
      </div>
    </div>
    <hr />
    <div class="form-group row">
      <label for="profile-avatar" class="col-md-4 pb-2 col-form-label"
        >Profile Image</label
      >
      <div class="col-md-4 pb-2">
        <img
          *ngIf="newPictureBlobUrl; else userPhoto"
          [src]="newPictureBlobUrl"
          [alt]="user.displayName"
          class="mx-auto"
        />
        <ng-template #userPhoto>
          <img
            *ngIf="user && user.photoURL"
            [src]="user.photoURL"
            [alt]="user.displayName"
            class="mx-auto"
          />
        </ng-template>
      </div>
      <div class="custom-file col-md-4 pb-2">
        <label class="btn btn-primary btn-file mb-0">
          Choose Image
          <input
            id="profile-avatar"
            type="file"
            accept="image/png, image/jpeg, image/gif"
            style="display: none;"
            (change)="detectMediaFiles($event)"
          />
        </label>
        <small id="userProfilePicHelp" class="form-text text-muted">
          Choose a new user profile image
        </small>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="btn-group">
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="!userForm.valid"
      (click)="updateForm()"
    >
      Save Changes
    </button>
    <button
      *ngIf="isModal"
      [disabled]="user.displayName === user.email || !user.userName"
      type="button"
      class="btn btn-light"
      id="userSettingsModalCloseButton"
      (click)="closeModalFunction()"
      (keydown.tab)="returnToFirstSelectableElement()"
    >
      Cancel
    </button>
  </div>
</div>

<ng-template #successModal>
  <div class="modal-header bg-primary text-light">
    <h4 class="modal-title pull-left">Update User Info</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="successModalRef.hide()"
    >
      <span aria-hidden="true" class="text-light">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    User Info Updated!
  </div>
  <div class="modal-footer">
    <button
      type="submit"
      class="btn btn-success"
      (click)="successModalRef.hide()"
    >
      Dismiss
    </button>
  </div>
</ng-template>

<ng-template #failModal>
  <div class="modal-header bg-primary text-light">
    <h4 class="modal-title pull-left">Update User Info</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="successModalRef.hide()"
    >
      <span aria-hidden="true" class="text-light">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    User Info Update Failed!
  </div>
  <div class="modal-footer">
    <button
      type="submit"
      class="btn btn-success"
      (click)="successModalRef.hide()"
    >
      Dismiss
    </button>
  </div>
</ng-template>
