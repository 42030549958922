import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeURL',
})
export class SafeURLPipe implements PipeTransform {
  allowedSites = [
    'codecraftworks.com',
    'codecraft.live',
    'localhost',
    'googleapis.com',
    'mit.edu',
  ];

  constructor(private sanitizer: DomSanitizer) {}

  transform(url) {
    const checkDomain = this.getDomain(url);
    if (this.allowedSites.includes(checkDomain)) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      return '';
    }
  }

  getDomain(url: string): string {
    let result: string;
    let match: RegExpMatchArray;
    // eslint-disable-next-line no-useless-escape
    if ((match = url.match(/^(?:https?:\/\/)?(?:www\.)?([^:\/\n?=]+)/im))) {
      result = match[1];
      if ((match = result.match(/^[^.]+\.(.+\..+)$/))) {
        result = match[1];
      }
    }
    return result;
  }
}
