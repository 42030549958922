import { Directive, Input } from '@angular/core';
import { PaginationService } from '@app/shared/pagination/pagination.service';
import { User } from '@codecraft-works/data-models';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';

@Directive()
export class PaginationDirective {
  /**
   * Current user, used to query only documents owned by user passed
   */
  @Input() user: User;

  /**
   * Database collection
   */
  @Input() path: string;

  /**
   * Sort field
   */
  @Input() field: string;

  /**
   * Number of documents to return per query
   */
  @Input() limit: number;

  /**
   * Reverse order of documents
   */
  @Input() reverse: boolean;

  /**
   * Prepend source
   */
  @Input() prepend: boolean;

  /**
   * Get public documents only
   */
  @Input() publicOnly: boolean;

  /**
   * Get only documents owned by provided user
   */
  @Input() userOnly: boolean;

  /**
   * Get documents using in query with array
   */
  @Input() inQuery: any;

  /**
   * Get documents by array members
   */
  @Input() inArray: any;

  /**
   * Get documents by field bing equal to an argument
   */
  @Input() isEqual: any;

  /**
   * Get documents by field bing greater than an argument
   */
  @Input() isGreaterThan: any;

  /**
   * Get documents by field bing less than an argument
   */
  @Input() isLessThan: any;

  /**
   * Get data from typeahead search
   */
  @Input() filteredArray: any;

  @Input()
  targetObservableArray$: Observable<any[]> = null;

  @Input()
  targetArray: any[] = null;

  /**
   * Font Awesome icon
   */
  faSyncAlt = faSyncAlt;

  constructor(public page: PaginationService) {}

  init() {
    if (this.userOnly === null) {
      this.userOnly = true;
    }

    this.page.init(this.path, this.field, {
      reverse: this.reverse,
      prepend: this.prepend,
      limit: this.limit,
      publicOnly: this.publicOnly,
      userId: this.user && this.userOnly ? this.user.uid : null,
      inArray: this.inArray,
      inQuery: this.inQuery,
      isEqual: this.isEqual,
      isGreaterThan: this.isGreaterThan,
      isLessThan: this.isLessThan
    });
  }
}
