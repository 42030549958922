import { Component, Input } from '@angular/core';
import { CardComponent } from '@app/shared/card/card.component';
import {
  LearndashCourseOrder,
  LearndashProgress,
} from '@codecraft-works/data-models';
import { faCheck, faLock, faSquare } from '@fortawesome/free-solid-svg-icons';

/**
 * The leandash lesson's Card Component
 */
@Component({
  selector: 'app-learndash-lesson-card',
  templateUrl: './learndash-lesson-card.component.html',
  styleUrls: ['./learndash-lesson-card.component.css'],
})

/**
 * @classdesc Class representing LearndashLesson Card component
 */
export class LearndashLessonCardComponent extends CardComponent {
  /**
   * Learendash lesson input
   */
  @Input()
  topicSteps: LearndashCourseOrder[];

  @Input()
  lessonItem: LearndashCourseOrder;

  @Input()
  courseId: string;

  @Input()
  lessonProgress: LearndashProgress;

  @Input()
  showLinks: boolean;

  faCheck = faCheck;

  faLock = faLock;

  faSquare = faSquare;

  constructor() {
    super();
  }
}
