import { Component, Input } from '@angular/core';
import { Certificate } from '@codecraft-works/data-models';

@Component({
  selector: 'app-instructor-certificate',
  templateUrl: './instructor-certificate.component.html',
  styleUrls: ['./instructor-certificate.component.css'],
})
export class InstructorCertificateComponent {
  @Input()
  public certificate: Certificate;

  constructor() {}
}
