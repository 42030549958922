import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class UserExistsGuard  {
  userNameExists$: Observable<boolean>;

  constructor(private userService: UserService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    this.userNameExists$ = this.userService.checkUserNameNotTaken(
      route.params.userName
    );

    return this.userNameExists$.pipe(
      map(userNameExists => {
        // We need to flip the boolean because the service checks to see if the name exists
        userNameExists = !userNameExists;
        if (!userNameExists) {
          this.router.navigate(['/404']);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
