<app-game-display-grid
  #displayGrid
  *ngIf="this.gameProject"
  [gameProject]="this.gameProject"
  color="white"
  id="displayGrid"
></app-game-display-grid>
<p class="lead p-2">
  Turn Number <span class="badge badge-info">{{ turnNumber }}</span
  ><span *ngIf="this.gameResults && this.showInvalid"
    >&nbsp;&nbsp;&nbsp;
    <span class="badge badge-pill badge-danger">Invalid Move</span>
  </span>
</p>

<h2
  *ngIf="this.gameResults && this.gameResults.winner && this.showWinner"
  class="p-2"
>
  The winner is {{ this.gameResults.winner }}
  <span *ngIf="this.gameType === 'tictactoe'">
    as {{ this.gameResults.game.winner.team }}</span
  >
</h2>

<div
  class="btn-group w-100 p-2"
  role="group"
  id="turnByTurnButtons"
  aria-label="Turn by Turn Buttons"
>
  <button
    type="button"
    class="btn btn-lg btn-dark"
    [disabled]="true"
    (click)="firstTurn(this.gameProject.type)"
  >
    First Turn<br />
    <fa-icon [icon]="faAngleDoubleLeft" class="fa-lg"></fa-icon>
  </button>
  <button
    type="button"
    class="btn btn-lg btn-dark"
    [disabled]="true"
    (click)="previousTurn(this.gameProject.type)"
  >
    Prev Turn<br />
    <fa-icon [icon]="faArrowLeft" class="fa-lg"></fa-icon>
  </button>
  <button
    type="button"
    class="btn btn-lg btn-dark"
    [disabled]="true"
    (click)="playGame(this.gameProject.type)"
  >
    Play<br />
    <fa-icon [icon]="faPlay" class="fa-lg"></fa-icon>
  </button>
  <button
    type="button"
    class="btn btn-lg btn-dark"
    [disabled]="true"
    (click)="nextTurn(this.gameProject.type)"
  >
    Next Turn<br />
    <fa-icon [icon]="faArrowRight" class="fa-lg"></fa-icon>
  </button>
  <button
    type="button"
    class="btn btn-lg btn-dark"
    [disabled]="true"
    (click)="lastTurn(this.gameProject.type)"
  >
    Last Turn<br />
    <fa-icon [icon]="faAngleDoubleRight" class="fa-lg"></fa-icon>
  </button>
</div>
