import { Component } from '@angular/core';
import { User } from '@codecraft-works/data-models/public-api';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-cart-button-cell',
  templateUrl: './cart-button-cell.component.html',
  styleUrls: ['./cart-button-cell.component.css'],
})
export class CartButtonCellComponent implements AgRendererComponent {
  program: any;
  user: User;
  constructor() {}
  refresh(params: ICellRendererParams): boolean {
    this.program = params.data;
    this.user = params.data.user;
    return true;
  }
  agInit(params: ICellRendererParams): void {
    this.program = params.data;
    this.user = params.data.user;
  }
}
