import { Component } from '@angular/core';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-user-edit-button-cell',
  templateUrl: './user-edit-button-cell.component.html',
  styleUrls: ['./user-edit-button-cell.component.css'],
})
export class UserEditButtonCellComponent implements AgRendererComponent {
  userId: any;

  faInfo = faInfo;
  constructor() {}
  refresh(params: ICellRendererParams): boolean {
    this.userId = params.data.uid;
    return true;
  }
  agInit(params: ICellRendererParams): void {
    this.userId = params.data.uid;
  }
}
