import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { Technology } from '@codecraft-works/data-models';
import firebase from 'firebase/compat/app';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KebabCasePipe } from '../pipes/kebab-case.pipe';

@Injectable()
export class TechnologyService {
  constructor(
    private firebaseDatabase: AngularFirestore,
    private kebabCasePipe: KebabCasePipe
  ) {}

  public getTechnologies(): Observable<Technology[]> {
    return this.getAllTechnologiesCollection()
      .snapshotChanges()
      .pipe(
        map((item) => {
          const technologies: Technology[] = [];
          item.map((element) => {
            const data = element.payload.doc.data() as Technology;
            technologies.push(data as Technology);
          });
          return technologies;
        })
      );
  }

  public getTechnologiesByCourse(
    courseId: string,
    publicOnly?: boolean
  ): Observable<Technology[]> {
    let snapshot: AngularFirestoreCollection<Technology>;
    if (!publicOnly) {
      snapshot = this.firebaseDatabase.collection<Technology>(
        'technologies',
        (ref) => ref.where('coursesUsingTech', 'array-contains', courseId)
      );
    } else {
      snapshot = this.firebaseDatabase.collection<Technology>(
        'technologies',
        (ref) =>
          ref
            .where('coursesUsingTech', 'array-contains', courseId)
            .where('public', '==', true)
      );
    }
    return snapshot.snapshotChanges().pipe(
      map((item) => {
        const technologies: Technology[] = [];
        item.map((a) => {
          const data = a.payload.doc.data() as Technology;
          technologies.push(data as Technology);
        });

        return technologies;
      })
    );
  }

  public insertCourseTechnology(technologyID: string, courseId: string) {
    this.getTechnology(technologyID).subscribe((technology) => {
      if (technology.coursesUsingTech.indexOf(courseId) === -1) {
        technology.coursesUsingTech.push(courseId);
        this.updateTechnology(technology);
      }
    });
  }

  public deleteCourseTechnology(technologyID: string, courseId: string) {
    this.getTechnology(technologyID).subscribe((technology) => {
      const indexOfCourseUsingTech =
        technology.coursesUsingTech.indexOf(courseId);
      if (indexOfCourseUsingTech !== -1) {
        technology.coursesUsingTech.splice(indexOfCourseUsingTech, 1);
        this.updateTechnology(technology);
      }
    });
  }

  public updateCourseTechnology(technologyIDs: string[], courseId: string) {
    this.getTechnologiesByCourse(courseId).subscribe((currentTech) => {
      // get arrays of IDs of current Technology associated wit this Course ID
      const currentTechIDs = currentTech.map((tech) => tech.id);

      // create an array of IDs of Technology that is no longer marked as being used
      const removeTechIDs = currentTechIDs.filter(
        (techID) => technologyIDs.indexOf(techID) < 0
      );

      // remove old Technology IDs from coursesUsingTech array
      removeTechIDs.forEach((removeTechID) =>
        this.deleteCourseTechnology(removeTechID, courseId)
      );
    });

    // add Technology IDs to coursesUsingTech
    technologyIDs.forEach((techID) => {
      this.insertCourseTechnology(techID, courseId);
    });
  }

  private getAllTechnologiesCollection() {
    return this.firebaseDatabase.collection<Technology>('technologies');
  }

  private getTechnologyData(technology: Technology) {
    return this.firebaseDatabase
      .collection('technologies')
      .doc<Technology>(technology.id);
  }

  public getTechnology(id: string): Observable<Technology> {
    const technology = this.firebaseDatabase
      .collection('technologies')
      .doc<Technology>(id)
      .valueChanges();
    return technology;
  }

  public insertTechnology(technology: Technology) {
    const doc = this.firebaseDatabase
      .collection<Technology>('technologies')
      .doc(this.kebabCasePipe.transform(technology.name));

    // check to see if the technology exists first, If it does, throw error
    // If it doesn't, go ahead and add it
    doc
      .get()
      .toPromise()
      .then((tech) => {
        if (!tech.exists) {
          doc.set({
            id: this.kebabCasePipe.transform(technology.name),
            created: firebase.firestore.Timestamp.now(),
            description: technology.description,
            modified: firebase.firestore.Timestamp.now(),
            name: technology.name,
            pictureUrl: technology.pictureUrl || '',
            public: technology.public,
            uid: technology.uid,
            coursesUsingTech: technology.coursesUsingTech,
          });
        } else {
          throw new Error('Technology already exists!');
        }
      });
  }

  public updateTechnology(technology: Technology): Observable<void> {
    return from(
      this.getTechnologyData(technology).update({
        description: technology.description,
        modified: firebase.firestore.Timestamp.now(),
        name: technology.name,
        pictureUrl: technology.pictureUrl || '',
        public: technology.public,
        coursesUsingTech: technology.coursesUsingTech,
      })
    );
  }

  public deleteTechnology(technology: Technology): Observable<void> {
    if (technology) {
      return from(this.getTechnologyData(technology).delete());
    } else {
      throw new Error("Can't delete without a key.");
    }
  }
}
