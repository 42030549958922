<div class="container-fluid">
  <div class="row pb-2">
    <div class="col-12 col-lg-4 col-md-6 col-xl-3">
      <label> Search this list </label>
      <input
        role="combobox"
        [(ngModel)]="asyncSelected"
        [typeaheadAsync]="true"
        [typeahead]="dataSource"
        typeaheadOptionField="name"
        dropup="true"
        (typeaheadLoading)="changeTypeaheadLoading($event)"
        (typeaheadOnSelect)="typeaheadOnSelect($event)"
        [typeaheadOptionsLimit]="7"
        typeaheadOptionField="name"
        placeholder="Search"
        class="w-100"
        (input)="updateList()"
        aria-label="Search this list"
      />
      <div *ngIf="typeaheadLoading">Loading</div>
    </div>
  </div>

  <!-- Firebase Grid  -->
  <ng-container *ngIf="page.data | async as data">
    <ng-container *ngIf="loaded; else loading">
      <ng-container *ngIf="data.length > 0; else noData">
        <div class="row">
          <ng-container [ngSwitch]="path">
            <ng-container *ngSwitchCase="'programs'">
              <app-program-card
                *ngFor="let program of populator | async"
                [hideAddToCart]="hideAddToCart"
                [program]="program"
                class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3"
                isGrid="true"
                [user]="user"
              ></app-program-card>
            </ng-container>

            <ng-container *ngSwitchCase="'web-projects'">
              <app-web-project-card
                *ngFor="let webProject of populator | async"
                [webProject]="webProject"
                class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3"
                isGrid="true"
              ></app-web-project-card>
            </ng-container>

            <ng-container *ngSwitchCase="'certificates'">
              <app-certificate-card
                *ngFor="let certificate of page.data | async"
                [certificate]="certificate"
                class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3"
                isGrid="true"
              ></app-certificate-card>
            </ng-container>

            <ng-container *ngSwitchCase="'educators'">
              <app-educator-card
                *ngFor="let educator of page.data | async"
                [educator]="educator"
                class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3"
                isGrid="true"
              ></app-educator-card>
            </ng-container>

            <ng-container *ngSwitchCase="'instructors'">
              <app-instructor-card
                *ngFor="let instructor of page.data | async"
                [instructor]="instructor"
                class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3"
                isGrid="true"
              ></app-instructor-card>
            </ng-container>

            <ng-container *ngSwitchCase="'locations'">
              <app-location-card
                *ngFor="let location of page.data | async"
                [location]="location"
                class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3"
                isGrid="true"
              ></app-location-card>
            </ng-container>

            <ng-container *ngSwitchCase="'students'">
              <app-student-card
                *ngFor="let student of page.data | async"
                [student]="student"
                class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3"
                isGrid="true"
              ></app-student-card>
            </ng-container>
            <ng-container *ngSwitchCase="'quiz'">
              <app-quiz-card
                *ngFor="let quiz of populator | async"
                [quiz]="quiz"
                [user]="user"
                class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3"
                isGrid="true"
              ></app-quiz-card>
            </ng-container>
          </ng-container>
        </div>
        <!--row-->
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
            <button
              class="btn btn-block btn-dark"
              (click)="swapObservables(); this.page.more()"
              [disabled]="page.done | async"
              [ngClass]="{
                disabled: page.done | async
              }"
              data-test="load-more-button"
            >
              <fa-icon [icon]="faSyncAlt" class="fa-lg"></fa-icon>
              More {{ entityName }}s
            </button>
          </div>
        </div>
        <!--row-->
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #noData>
    <div class="row">
      <card class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
        <h6 header class="display-5">No {{ entityName }}s Found</h6>
        <ng-container [ngSwitch]="entityName">
          <ng-container *ngSwitchCase="'Program'">
            <p class="lead display-6">
              <a routerLink="/programs">Let's find a program!</a>
            </p>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <p class="lead display-6">Let's find one!</p>
          </ng-container>
        </ng-container>
      </card>
    </div>
    <!--row-->
  </ng-template>

  <ng-template #loading>
    <div class="row">
      <card class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
        <h6 header class="display-5">Loading {{ entityName }}s ...</h6>
        <codecraft-loading-spinner></codecraft-loading-spinner>
      </card>
    </div>
    <!--row-->
  </ng-template>
</div>
<!--container-->
