import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { MembershipService } from '@app/core/membership/membership.service';
import { Student, User } from '@codecraft-works/data-models';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';

@Component({
  selector: 'app-membership-add-student-form',
  templateUrl: './membership-add-student-form.component.html',
  styleUrls: ['./membership-add-student-form.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MembershipAddStudentFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MembershipAddStudentFormComponent),
      multi: true,
    },
  ],
})
export class MembershipAddStudentFormComponent
  implements OnInit, ControlValueAccessor, Validator
{
  @Input() user: User;
  @Input() isMultiple = true;
  @Input() isModal = false;

  public addStudentFormGroup: FormGroup;
  newStudentFromCustomer = new FormArray([]);
  group: FormGroup[];
  newStudents: FormArray;
  show: boolean;
  success: boolean[] = [null];
  returnMessage: string[] = [null];
  studentName: FormControl;
  studentEmail: FormControl;
  faPlusCircle = faPlusCircle;
  faMinusCircle = faMinusCircle;

  constructor(
    private membershipService: MembershipService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    // this.initForm();
    // parent, customer, educator
    if (
      this.user.roles.parent === true ||
      this.user.roles.customer === true ||
      this.user.roles.educator === true
    ) {
      this.show = true;
    }
    this.addStudentFormGroup = this.formBuilder.group({
      newStudents: this.formBuilder.array([this.createNewStudent()]),
    });
  }

  createNewStudent(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.minLength(4)]),
      age: new FormControl('', [
        Validators.required,
        Validators.min(5),
        Validators.max(99),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.minLength(1),
      ]),
    });
  }

  addItem() {
    this.newStudents = this.addStudentFormGroup.get('newStudents') as FormArray;
    this.newStudents.push(this.createNewStudent());
    this.success.push(null);
    this.returnMessage.push(null);
  }

  deleteItem(i: number) {
    this.newStudents = this.addStudentFormGroup.get('newStudents') as FormArray;
    this.newStudents.removeAt(i);
    this.success.pop();
    this.returnMessage.pop();
  }

  submit() {
    this.newStudents = this.addStudentFormGroup.get('newStudents') as FormArray;
    const newStudentsForm = this.newStudents.value;
    newStudentsForm.map((newStudentData, i) => {
      const newStudent: Partial<Student> = {
        age: newStudentData.age,
        user: {
          displayName: newStudentData.name,
          email: newStudentData.email,
          uid: null,
        },
      };
      this.membershipService
        .createStudentForCustomer(newStudent, this.user)
        .subscribe(
          (newStudentUser) => {
            this.returnMessage[
              i
            ] = `Your student, ${newStudentUser.user.displayName},  has been invited!`;
            this.success[i] = true;
          },
          (error) => {
            this.returnMessage[i] = error.error.error || 'Something went wrong';
            this.success[i] = false;
          }
        );
    });
  }

  get item() {
    return this.addStudentFormGroup.get('newStudents');
  }

  alertOnClosed(arrayIndex: number) {
    this.returnMessage[arrayIndex] = null;
    this.success[arrayIndex] = null;
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val) {
      this.addStudentFormGroup.setValue(val, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.addStudentFormGroup.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled
      ? this.addStudentFormGroup.disable()
      : this.addStudentFormGroup.enable();
  }

  validate(): ValidationErrors | null {
    return this.addStudentFormGroup.valid
      ? null
      : { invalidForm: { valid: false, message: 'Add student form invalid' } };
  }
}
