import { Component, Input } from '@angular/core';
import { CardComponent } from '@app/shared/card/card.component';
import { LearndashTopic } from '@codecraft-works/data-models';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

/**
 * The leandash lesson's Card Component
 */
@Component({
  selector: 'app-learndash-topic-card',
  templateUrl: './learndash-topic-card.component.html',
  styleUrls: ['./learndash-topic-card.component.css'],
})

/**
 * @classdesc Class representing LearndashLesson Card component
 */
export class LearndashTopicCardComponent extends CardComponent {
  /**
   * Learendash lesson input
   */
  @Input()
  learndashTopic: LearndashTopic;

  @Input()
  courseId: string;

  @Input()
  lessonId: string;

  @Input()
  topicStatus: boolean;

  faCheck = faCheck;

  constructor() {
    super();
  }
}
