import { Component, Input } from '@angular/core';
import { Membership, User } from '@codecraft-works/data-models';

@Component({
  selector: 'app-membership-row',
  templateUrl: './membership-row.component.html',
  styleUrls: ['./membership-row.component.css'],
})
export class MembershipRowComponent {
  @Input() membership: Membership;
  @Input() user: User;
  @Input() first: boolean;
  @Input() last: boolean;
  @Input() even: boolean;
  @Input() odd: boolean;
  @Input() userView: string;

  constructor() {}
}
