import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class AuthGuard  {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | boolean {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  async checkLogin(url: string): Promise<boolean> {
    const loggedIn = await this.auth.isLoggedIn();
    if (loggedIn) {
      return true;
    } else {
      // Store the attempted URL for redirecting
      this.auth.returnUrl = url;

      // Navigate to the login page with extras
      this.router.navigate(['/login'], { queryParams: { redirect: url } });
      return false;
    }
  }
}
