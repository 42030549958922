import { LearndashCourseOrder } from './learndash-course-order.model';

export class LearndashPreviousNextStep {
  previous: LearndashCourseOrder;
  next: LearndashCourseOrder;

  getPreviousPath(): string {
    if (this.previous.type === 'topic') {
      return `/lesson/${this.previous.parent}/topic/${this.previous.id}`;
    } else if (this.previous.type === 'lessons') {
      return `/lesson/${this.previous.id}`;
    } else {
      return null;
    }
  }

  getNextPath(): string {
    if (this.next.type === 'topic') {
      return `/lesson/${this.next.parent}/topic/${this.next.id}`;
    } else if (this.next.type === 'lessons') {
      return `/lesson/${this.next.id}`;
    } else {
      return null;
    }
  }
}
