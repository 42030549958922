import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Educator } from '@codecraft-works/data-models';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class EducatorService {
  constructor(private fireDatabase: AngularFirestore) {}

  private getData() {
    return this.fireDatabase.collection<Educator>('educators');
  }

  public getEducators(): Observable<Educator[]> {
    return this.getData()
      .snapshotChanges()
      .pipe(
        map((item) => {
          const educators: Educator[] = [];
          item.map((a) => {
            const data = a.payload.doc.data() as Educator;
            data['$key'] = a.payload.doc.id;
            educators.push(data as Educator);
          });
          return educators;
        })
      );
  }

  private getEducatorData(educator: Educator) {
    return this.fireDatabase.doc<Educator>(educator.id);
  }

  public getEducator(key: string): Observable<Educator> {
    const educator = this.fireDatabase
      .collection('educators')
      .doc<Educator>(key)
      .valueChanges();
    educator['$key'] = key;
    return educator;
  }

  insertEducator(educator: Educator) {
    this.getData().add({
      id: this.fireDatabase.createId(),
      firstName: educator.firstName,
      lastName: educator.lastName,
      title: educator.title,
      specialty: educator.specialty,
      pictureUrl: educator.pictureUrl,
    });
  }

  updateEducator(educator: Educator) {
    return from(
      this.getEducatorData(educator).update({
        firstName: educator.firstName,
        lastName: educator.lastName,
        title: educator.title,
        specialty: educator.specialty,
        pictureUrl: educator.pictureUrl,
      })
    );
  }

  deleteEducator(educator: Educator) {
    if (educator) {
      return from(this.getEducatorData(educator).delete());
    } else {
      throw new Error("Can't delete without a key.");
    }
  }
}
