<div
  *ngIf="program && program.stripe && program.stripe.price"
  class="pt-1 row pr-2 pl-3"
>
  <ng-container *ngIf="program.stripe">
    <form class="float-right" *ngIf="user; else noUser">
      <div class="input-group">
        <button
          [attr.aria-label]="'Add to waitlist -' + program.name"
          [id]="
            mobile
              ? 'add-to-wishlist-' + program.id + '-mobile'
              : 'add-to-wishlist-' + program.id
          "
          type="button"
          class="btn btn-primary pill-button"
          (click)="addToWaitlist()"
        >
          Add me to waitlist
        </button>
      </div>
    </form>
  </ng-container>
</div>

<ng-template #successModal>
  <div class="modal-body">
    <p>
      You've been added to the waitlist for {{ program.name }}. We'll email you
      when this program becomes available.
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      role="button"
      [id]="
        mobile
          ? 'closeAddedToWaitlistMobileButton' + '-mobile'
          : 'closeAddedToWaitlistMobileButton'
      "
      class="btn btn-primary   btn-sm pull-right"
      aria-label="Close"
      (click)="closeModalAndReturnFocus()"
    >
      Close
    </button>
  </div>
</ng-template>

<ng-template #errorModal>
  <div class="modal-body">
    <p>
      There was an issue adding you to the program waitlist. This program might
      not have a waitlist, for more information contact us at
      <a href="mailto:support@codecraftworks.com">support@codecraftworks.com</a>
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      role="button"
      [id]="
        mobile
          ? 'closeAddedToWaitlistMobileButton' + '-mobile'
          : 'closeAddedToWaitlistMobileButton'
      "
      class="btn btn-primary   btn-sm pull-right"
      aria-label="Close"
      (click)="closeModalAndReturnFocus()"
    >
      Close
    </button>
  </div>
</ng-template>

<ng-template #noUser>
  <div *ngIf="program.id" class="float-right">
    <a
      class="btn btn-primary pill-button text-white"
      [routerLink]="['/login']"
      [queryParams]="{ redirect: '/programs/' + program.id }"
    >
      Login or Sign Up to Enroll</a
    >
  </div>
</ng-template>
