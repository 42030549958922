import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire/compat';
import {
  AngularFireAuth,
  AngularFireAuthModule,
  USE_EMULATOR as USE_AUTH_EMULATOR,
} from '@angular/fire/compat/auth';
import {
  AngularFireDatabase,
  AngularFireDatabaseModule,
  USE_EMULATOR as USE_DATABASE_EMULATOR,
} from '@angular/fire/compat/database';
import {
  AngularFirestoreModule,
  SETTINGS,
} from '@angular/fire/compat/firestore';
import {
  AngularFireStorage,
  AngularFireStorageModule,
  BUCKET,
  USE_EMULATOR as USE_STORAGE_EMULATOR,
} from '@angular/fire/compat/storage';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { CoreModule } from '@app/core/core.module';
import { LoadingSpinnerComponent } from '@codecraft-works/shared-components';
import { environment } from '@environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularSplitModule } from 'angular-split';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxPermissionsModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideDatabase(() => getDatabase()),
    provideStorage(() => getStorage()),
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    CoreModule,
    ModalModule.forRoot(),
    FontAwesomeModule,
    PaginationModule.forRoot(),
    HttpClientModule,
    AngularSplitModule,
    AngularFireStorageModule,
    CollapseModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    LoadingSpinnerComponent,
    //NgxHotjarModule.forRoot(environment.hotjarId),
    //NgxHotjarRouterModule,
    RouterModule,
  ],
  providers: [
    {
      provide: SETTINGS,
      useValue: environment.emulator
        ? {
            host: 'localhost:8888',
            ssl: false,
            experimentalForceLongPolling: true,
          }
        : undefined,
    },
    {
      provide: USE_AUTH_EMULATOR,
      useValue: environment.emulator ? ['http://localhost:9099'] : undefined,
    },
    {
      provide: USE_DATABASE_EMULATOR, // i.e., Realtime Database
      useValue: environment.emulator ? ['localhost', 9000] : undefined,
    },
    {
      provide: USE_STORAGE_EMULATOR, // i.e., Realtime Database
      useValue: environment.emulator ? ['localhost', 9199] : undefined,
    },
    AngularFireDatabase,
    AngularFireAuth,
    AngularFireStorage,
    // AngularFirestore,
    Title,
    { provide: BUCKET, useValue: environment.projectBucket },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
