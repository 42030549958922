<div data-test="add-student-form" class="container">
  <div class="row">
    <div class="col-12">
      <small class="text-primary">All fields are required</small>
    </div>
  </div>
</div>

<form [formGroup]="addStudentFormGroup" class="container mb-3">
  <div
    formArrayName="newStudents"
    *ngFor="
      let newStudent of addStudentFormGroup.get('newStudents')['controls'];
      let i = index
    "
    attr.data-test-id="add-student-row-{{ i }}"
  >
    <div [formGroupName]="i" class="row mb-1">
      <div
        class="form-group col-12 col-md-6"
        [ngClass]="{
          'col-lg-4': isMultiple,
          'col-lg-6': !isMultiple
        }"
      >
        <label [for]="'name-' + i">Name</label>
        <input
          data-test="add-student-form-name"
          [id]="'name-' + i"
          formControlName="name"
          placeholder="First & Last Name"
          type="text"
          class="form-control"
          aria-label="Student's
          Name"
          required
          [ngClass]="{
            'is-invalid':
              addStudentFormGroup.controls.newStudents.controls[i].controls.name
                .invalid,
            'is-valid':
              addStudentFormGroup.controls.newStudents.controls[i].controls.name
                .valid &&
              addStudentFormGroup.controls.newStudents.controls[i].controls.name
                .dirty
          }"
        />
      </div>

      <div
        class="form-group col-12 col-md-6"
        [ngClass]="{
          'col-lg-4': isMultiple,
          'col-lg-6': !isMultiple
        }"
      >
        <label [for]="'email-' + i">Email</label>
        <input
          data-test="add-student-form-email"
          [id]="'email-' + i"
          formControlName="email"
          placeholder="student@email.com"
          type="email"
          class="form-control"
          aria-label="Student's Email"
          required
          [ngClass]="{
            'is-invalid':
              addStudentFormGroup.controls.newStudents.controls[i].controls
                .email.invalid,
            'is-valid':
              addStudentFormGroup.controls.newStudents.controls[i].controls
                .email.valid &&
              addStudentFormGroup.controls.newStudents.controls[i].controls
                .email.dirty
          }"
        />
      </div>

      <div
        class="form-group col-12 col-md-6"
        [ngClass]="{
          'col-lg-2': isMultiple,
          'col-lg-4': !isMultiple
        }"
      >
        <label [for]="'age-' + i">Age</label>
        <input
          data-test="add-student-form-age"
          [id]="'age-' + i"
          formControlName="age"
          placeholder="5-99"
          type="number"
          min="5"
          max="99"
          class="form-control"
          aria-label="Student's Age, 5-99"
          required
          [ngClass]="{
            'is-invalid':
              addStudentFormGroup.controls.newStudents.controls[i].controls.age
                .invalid,
            'is-valid':
              addStudentFormGroup.controls.newStudents.controls[i].controls.age
                .valid &&
              addStudentFormGroup.controls.newStudents.controls[i].controls.age
                .dirty
          }"
        />
      </div>

      <div *ngIf="isMultiple" class="form-group col-12 col-md-6 col-lg-2">
        <label [for]="'addFormRow-' + i">Row</label>
        <div class="btn-group w-100" role="group" aria-label="Add/remove row">
          <span
            class="tooltip-wrapper"
            tabindex="0"
            data-toggle="tooltip"
            title="Delete this row"
          >
            <button
              data-test="add-student-form-remove-row"
              [id]="'addFormRow-' + i"
              class="btn btn-dark"
              *ngIf="newStudentFromCustomer"
              [disabled]="i === 0"
              (click)="deleteItem(i)"
              type="button"
            >
              <fa-icon [icon]="faMinusCircle"> </fa-icon>
            </button>
          </span>
          <span
            class="tooltip-wrapper"
            tabindex="0"
            data-toggle="tooltip"
            title="Add a new row to create another student account"
          >
            <button
              data-test="add-student-form-add-row"
              [id]="'addFormRow-' + i"
              class="btn btn-light"
              *ngIf="newStudentFromCustomer"
              (click)="addItem()"
              type="button"
            >
              <fa-icon [icon]="faPlusCircle"> </fa-icon>
            </button>
          </span>
        </div>
      </div>
    </div>

    <alert
      *ngIf="success && success[i]"
      type="info"
      class="m-0"
      (onClosed)="alertOnClosed(i)"
      [id]="'alert-' + i"
      data-test="add-student-form-alert-success"
    >
      {{ returnMessage[i] }}
    </alert>

    <alert
      *ngIf="success && success[i] === false"
      type="danger"
      class="m-0"
      (onClosed)="alertOnClosed(i)"
      [id]="'alert-' + i"
      data-test="add-student-form-alert-error"
    >
      {{ returnMessage[i] }}
    </alert>
  </div>

  <div class="row" *ngIf="!isModal">
    <div class="col-12 col-md-6">
      <button
        data-test="add-student-form-submit-button"
        [disabled]="addStudentFormGroup.invalid"
        class="btn btn-dark"
        (click)="submit()"
      >
        Create Student Accounts
      </button>
    </div>
  </div>
</form>
