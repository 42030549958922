import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import {
  CourseProgress,
  LearndashContent,
  LearndashCourse,
  LearndashCourseOrder,
  LearndashLesson,
  LearndashPreviousNextStep,
  LearndashProgress,
  LearndashTopic,
  LearningProgress,
  Program,
  User,
} from '@codecraft-works/data-models';
import firebase from 'firebase/compat/app';
import { Observable, combineLatest, of } from 'rxjs';
import { map, mergeMap, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LearndashService {
  /**
   * Initializes required services and database references
   * @param http
   * @param db
   */
  constructor(private db: AngularFirestore, private httpClient: HttpClient) {}

  public getCourseSteps(
    courseId: string,
    user?: User
  ): Observable<LearndashCourseOrder[]> {
    let snapshot;
    if (user && (user.roles.admin || user.roles.editor)) {
      snapshot = this.db.collection('learndash', (ref) =>
        ref.where('ID', '==', Number(courseId))
      );
    } else {
      snapshot = this.db.collection('learndash', (ref) =>
        ref.where('public', '==', true).where('ID', '==', Number(courseId))
      );
    }

    return snapshot.valueChanges().pipe(
      map((learndashCourses) => {
        const data = learndashCourses[0] as LearndashCourse;
        return data.courseOrder;
      })
    );
  }

  // gets courseProgress for specific user/course combo

  public getCourseProgressArray(
    courseId: string,
    userID: string
  ): Observable<CourseProgress[]> {
    return this.db
      .collection<LearningProgress>('learning-progress')
      .doc(`${userID}_${courseId}`)
      .valueChanges()
      .pipe(
        map((progress) => {
          if (progress) {
            return progress.courseProgress;
          } else {
            return null;
          }
        })
      );
  }

  // returns [numberOfCompletedSteps, totalSteps]
  // makes it easy to update course progress bar

  public getCourseProgress(
    courseId: string,
    userID: string
  ): Observable<LearndashProgress | null> {
    return this.getCourseProgressArray(courseId, userID).pipe(
      map((courseProgressArray) => {
        if (courseProgressArray) {
          const progress = new LearndashProgress();
          progress.numOfCompletedSteps = 0;
          progress.totalSteps = courseProgressArray.length;
          courseProgressArray.forEach((item) => {
            if (item.completed) {
              ++progress.numOfCompletedSteps;
            }
            if (progress.numOfCompletedSteps / progress.totalSteps === 1) {
              progress.completed = true;
            } else {
              progress.completed = false;
            }
          });
          return progress;
        } else {
          return null;
        }
      })
    );
  }

  // Return an array of [courseId, numberOfCompletedSteps, totalSteps] for each course
  // Can get the course progress for all courses in one db query
  // good for updating all course cards on list page

  public getAllCourseProgress(userID: string): Observable<LearndashProgress[]> {
    if (userID === null) {
      return of([] as LearndashProgress[]);
    }
    const snapshot = this.db.collection('learning-progress', (ref) =>
      ref.where('uid', '==', userID)
    );
    return snapshot.valueChanges().pipe(
      map((learningProgressDocuments) => {
        const allCoursesProgress: LearndashProgress[] = [];

        learningProgressDocuments.forEach((doc) => {
          const progress = new LearndashProgress();
          const courseProgress = doc['courseProgress'];
          progress.ID = doc['courseId'];
          progress.totalSteps = courseProgress.length;
          progress.numOfCompletedSteps = 0;
          courseProgress.forEach((progressElement) => {
            if (progressElement.completed === true) {
              ++progress.numOfCompletedSteps;
            }
          });
          if (progress.numOfCompletedSteps / progress.totalSteps === 1) {
            progress.completed = true;
          } else {
            progress.completed = false;
          }
          allCoursesProgress.push(progress);
        });

        return allCoursesProgress;
      })
    );
  }

  // returns [numberOfCompletedSteps, totalSteps, isCompleted]
  // makes it easy to update lesson progress bar

  public getLessonProgress(
    courseId: string,
    lessonId: string,
    userID: string
  ): Observable<LearndashProgress> {
    return this.getCourseProgressArray(courseId, userID).pipe(
      map((courseProgressArray) => {
        if (courseProgressArray) {
          const lessonSteps = courseProgressArray.filter(
            (item) => item.parent === lessonId
          );
          const progress = new LearndashProgress();
          progress.ID = lessonId;
          progress.totalSteps = lessonSteps.length;
          progress.numOfCompletedSteps = 0;
          lessonSteps.forEach((element) => {
            if (element.completed) {
              ++progress.numOfCompletedSteps;
            }
          });
          progress.completed = courseProgressArray.find(
            (item) => item.id === lessonId
          ).completed;
          return progress;
        } else {
          return null;
        }
      })
    );
  }

  // Return an array of [lessonId, numberOfCompletedSteps, totalSteps, isCompleted] for each lesson in course
  // Can get the course progress for all lessons in one db query
  // good for updating all lesson cards on course page

  public getAllLessonProgress(
    courseId: string,
    userID: string
  ): Observable<LearndashProgress[]> {
    return this.getCourseProgressArray(courseId, userID).pipe(
      map((courseProgressArray) => {
        if (courseProgressArray) {
          const lessons = courseProgressArray.filter(
            (item) => item.type === 'lessons'
          );
          const allLessonProgress: LearndashProgress[] = [];

          lessons.forEach((lesson) => {
            const progress = new LearndashProgress();
            const lessonSteps = courseProgressArray.filter(
              (item) => item.parent === lesson.id
            );
            progress.ID = String(lesson.id);
            progress.totalSteps = lessonSteps.length;
            progress.numOfCompletedSteps = 0;
            lessonSteps.forEach((element) => {
              if (element.completed) {
                ++progress.numOfCompletedSteps;
              }
            });
            progress.completed = courseProgressArray.find(
              (item) => item.id === lesson.id
            ).completed;
            allLessonProgress.push(progress);
          });
          return allLessonProgress;
        } else {
          return null;
        }
      })
    );
  }

  // gets an array [topicId, completed] for each topic in lesson
  // can get topic status for all topic in lesson in one query
  // good for updating check/ no check on topic cards

  public getTopicStatusArray(
    courseId: string,
    lessonId: string,
    userID: string
  ): Observable<Map<number, boolean>> {
    return this.getCourseProgressArray(courseId, userID).pipe(
      map((courseProgressArray) => {
        if (courseProgressArray) {
          const topics = courseProgressArray.filter(
            (item) => item.type === 'topic' && item.parent === lessonId
          );
          const topicProgress: Map<number, boolean> = new Map();

          topics.forEach((topic) => {
            topicProgress.set(Number(topic.id), topic.completed);
          });
          return topicProgress;
        } else {
          return null;
        }
      })
    );
  }

  // returns observable true if the step is completed

  public getCourseProgressStepCompleted(
    courseId: string,
    userId: string,
    stepId: string
  ): Observable<boolean> {
    return this.getCourseProgressArray(courseId, userId).pipe(
      map((courseProgressArray) => {
        if (stepId === null) {
          return null;
        } else if (courseProgressArray) {
          return courseProgressArray.find(
            (element) => element['id'] === stepId
          )['completed'];
        } else {
          return null;
        }
      })
    );
  }

  /* sets the specific course step as completed
  will automatically update lesson as completed
  if all topics in that lesson are complete */

  public updateCourseProgress(params: {
    stepId: string;
    courseId: string;
    userId: string;
    isComplete: boolean;
  }) {
    return this.db
      .collection<LearningProgress>('learning-progress')
      .doc(`${params.userId}_${params.courseId}`)
      .valueChanges()
      .pipe(
        take(1),
        map((learningProgress) => {
          // get array and find where that step is
          const index = learningProgress.courseProgress.findIndex((element) => {
            return element['id'] === params.stepId;
          });

          // update courseProgress array
          learningProgress.courseProgress[index].completed = params.isComplete;

          // If its a topic, check if it completes the lesson
          if (learningProgress.courseProgress[index].type === 'topic') {
            learningProgress.courseProgress = this.checkIfUpdateLesson(
              learningProgress.courseProgress[index].parent,
              learningProgress.courseProgress
            );
          }
          return learningProgress.courseProgress;
        })
      )
      .toPromise()
      .then(async (courseProgress) => {
        return await this.db
          .collection<LearningProgress>('learning-progress')
          .doc(`${params.userId}_${params.courseId}`)
          .update({
            courseProgress: courseProgress,
          });
      });
  }

  /*   used by updateCourseProgress
  put in separate function just to make it easier to read
  takes the courseProgress array and marks lesson as
  complete if necessary and returns array back */

  public checkIfUpdateLesson(
    lessonId: string,
    courseProgressArray: any[]
  ): any[] {
    // get all of the topics with that lesson as parent
    const topicsInLesson = courseProgressArray.filter((item) => {
      return item['parent'] === lessonId;
    });

    // If every topic is complete
    if (
      topicsInLesson.every((item) => {
        return item['completed'] === true;
      })
    ) {
      // set that lesson as complete
      courseProgressArray[
        courseProgressArray.findIndex((item) => item.id === lessonId)
      ].completed = true;
    }
    return courseProgressArray;
  }

  /* Creates db entry in learning-progress when a course hasn't been accessed by a user yet */

  public setupCourseProgress(
    courseId: string,
    userID: string,
    user?: User
  ): Observable<CourseProgress> {
    let courseProgress: CourseProgress[];

    return this.getCourseSteps(courseId, user).pipe(
      map((courseOrder) => {
        return courseOrder.map((orderObj) => {
          if (orderObj.type === 'lessons') {
            return { id: orderObj.id, type: orderObj.type, completed: false };
          } else if (orderObj.type === 'topic') {
            return {
              id: orderObj.id,
              parent: orderObj['parent'],
              type: orderObj.type,
              completed: false,
            };
          }
        });
      }),
      map((courseProg) => {
        courseProgress = courseProg;
        return this.db
          .collection<LearningProgress>('learning-progress')
          .doc(`${userID}_${courseId}`)
          .valueChanges();
      }),
      switchMap((learningProgress$) => {
        return learningProgress$.pipe(
          map((learningProgress) => {
            if (!learningProgress) {
              this.db
                .collection<LearningProgress>('learning-progress')
                .doc(`${userID}_${courseId}`)
                .set({
                  courseProgress: courseProgress,
                  uid: userID,
                  courseId: courseId,
                });
            }
            // Send back first step in course progress for routing
            return courseProgress[0];
          })
        );
      })
    );
  }

  public getPrevAndNextStep(
    courseId: string,
    currentStepId: string,
    user?: User
  ): Observable<LearndashPreviousNextStep> {
    return this.getCourseSteps(courseId, user).pipe(
      map((courseOrder) => {
        const prevAndNext = new LearndashPreviousNextStep();

        const prevStepIndex =
          courseOrder.findIndex((order) => order.id === currentStepId) - 1;
        if (prevStepIndex >= 0) {
          prevAndNext.previous = courseOrder[prevStepIndex];
        }

        const nextStepIndex =
          courseOrder.findIndex((order) => order.id === currentStepId) + 1;
        if (nextStepIndex > 0) {
          prevAndNext.next = courseOrder[nextStepIndex];
        }
        return prevAndNext;
      })
    );
  }

  public getPrevLessonId(
    courseId: string,
    currentStepId: string
  ): Observable<string> {
    return this.db
      .collection('learndash')
      .doc<LearndashCourse>(courseId)
      .valueChanges()
      .pipe(
        map((course) => {
          const data = course as LearndashCourse;
          const lessons = course.courseOrder
            .filter((orderObj) => orderObj.type === 'lessons')
            .map((orderObj) => orderObj.id);
          const prevIndex =
            data.courseOrder
              .filter((orderObj) => orderObj.type === 'lessons')
              .findIndex((orderObj) => orderObj.id === currentStepId) - 1;
          if (prevIndex > 0) {
            return lessons[prevIndex];
          } else {
            return null;
          }
        })
      );
  }

  public getTopicOrder(courseId: string): Observable<any> {
    const snapshot = this.db.collection('learndash').doc(courseId);
    return snapshot.valueChanges().pipe(
      map((learndashCourse) => {
        return learndashCourse['topicOrder'];
      })
    );
  }

  public getLearndashCourses(user?: User): Observable<LearndashCourse[]> {
    let snapshot: AngularFirestoreCollection<LearndashCourse>;
    if (user && (user.roles.admin || user.roles.editor)) {
      snapshot = this.db.collection<LearndashCourse>('learndash', (ref) =>
        ref.orderBy('post_title', 'asc')
      );
    } else {
      snapshot = this.db.collection<LearndashCourse>('learndash', (ref) =>
        ref.where('public', '==', true).orderBy('post_title', 'asc')
      );
    }

    return snapshot.snapshotChanges().pipe(
      map((item) => {
        return item.map((learndashCourse) => {
          return learndashCourse.payload.doc.data() as LearndashCourse;
        });
      })
    );
  }

  // Gets the course with just the post data
  getLearndashContent(params: {
    courseId?: string;
    lessonId?: string;
    topicId?: string;
    programId?: string;
  }): Observable<LearndashContent> {
    let contentPath = params.programId
      ? `programs/${params.programId}/course`
      : 'learndash';
    contentPath += `/${params.courseId}`;
    contentPath += params.lessonId ? `/lessons/${params.lessonId}` : '';
    contentPath += params.topicId ? `/topics/${params.topicId}` : '';

    const snapshot: AngularFirestoreDocument<LearndashContent> =
      this.db.doc(contentPath);

    return snapshot.valueChanges().pipe(
      map((learndashContent) => {
        const data = learndashContent as LearndashContent;
        return data;
      })
    );
  }

  // Gets the course with just the post data
  getLearndashCourse(courseId: string): Observable<LearndashCourse> {
    const snapshot: AngularFirestoreDocument<LearndashCourse> = this.db
      .collection('learndash')
      .doc(courseId);

    return snapshot.valueChanges().pipe(
      map((learndashCourse) => {
        const data = learndashCourse as LearndashCourse;
        return data;
      })
    );
  }

  // Gets just the course title (good for breadcrumb bar)
  public getLearndashCourseTitle(courseId: string): Observable<string> {
    return this.getLearndashCourse(courseId).pipe(
      map((learndashCourse) => {
        return learndashCourse.post_title;
      })
    );
  }

  // Get the lesson with just the post data
  getLearndashLesson(
    courseId: string,
    lessonId: string
  ): Observable<LearndashLesson> {
    const snapshot: AngularFirestoreDocument<LearndashLesson> = this.db
      .collection('learndash')
      .doc(courseId)
      .collection('lessons')
      .doc(lessonId);

    return snapshot.valueChanges().pipe(
      map((learndashLesson) => {
        return learndashLesson as LearndashLesson;
      })
    );
  }

  // Get just the lesson title (good for breadcrumb bar)
  public getLearndashLessonTitle(
    courseId: string,
    lessonId: string
  ): Observable<string> {
    return this.getLearndashLesson(courseId, lessonId).pipe(
      map((learndashLesson) => {
        return learndashLesson.post_title;
      })
    );
  }

  // gets all lessons in a course with topic data embedded
  public getLearndashLessonsByCourseId(
    courseId: string
  ): Observable<LearndashLesson[]> {
    const lessons = this.db
      .collection('learndash')
      .doc(courseId)
      .collection<LearndashLesson>('lessons');

    const courseOrder = this.db
      .collection('learndash')
      .doc<LearndashCourse>(courseId)
      .valueChanges()
      .pipe(
        take(1),
        map((course) => {
          const data = course as LearndashCourse;
          return data.courseOrder
            .filter((orderObj) => orderObj.type === 'lessons')
            .map((orderObj) => orderObj.id);
        })
      );

    return combineLatest([lessons.snapshotChanges(), courseOrder]).pipe(
      map((sortedLessons) => {
        sortedLessons[0].sort((first, second) => {
          return (
            sortedLessons[1].indexOf(first.payload.doc.id) -
            sortedLessons[1].indexOf(second.payload.doc.id)
          );
        });

        const sortedLearndashLessons: LearndashLesson[] = [];

        sortedLessons[0].map((lessonObj) => {
          const data = lessonObj.payload.doc.data() as LearndashLesson;
          // get Topics
          data.topics = [];
          this.getLearndashTopicsByLessonId(
            courseId,
            String(data.ID)
          ).subscribe((topics) => {
            topics.map((topic) => data.topics.push(topic));
          });
          sortedLearndashLessons.push(data);
        });
        return sortedLearndashLessons;
      })
    );
  }

  public getLearndashTopic(
    courseId: string,
    lessonId: string,
    topicId: string
  ): Observable<LearndashTopic> {
    const snapshot: AngularFirestoreDocument<LearndashLesson> = this.db
      .collection('learndash')
      .doc(courseId)
      .collection('lessons')
      .doc(lessonId)
      .collection('topics')
      .doc(topicId);

    return snapshot.valueChanges().pipe(
      map((learndashTopic) => {
        const data = learndashTopic as LearndashTopic;
        return data;
      })
    );
  }

  public getLearndashTopicsByLessonId(
    courseId: string,
    lessonId: string
  ): Observable<LearndashTopic[]> {
    const topics: AngularFirestoreCollection<LearndashTopic> = this.db
      .collection('learndash')
      .doc(courseId)
      .collection('lessons')
      .doc(lessonId)
      .collection('topics');

    const topicOrder$ = this.db
      .collection('learndash')
      .doc(courseId)
      .valueChanges()
      .pipe(
        map((learndashCourse) => {
          const data = learndashCourse as LearndashCourse;
          return data.topicOrder[lessonId] as number[];
        })
      );

    return combineLatest([topics.snapshotChanges(), topicOrder$]).pipe(
      map((sortedTopics) => {
        sortedTopics[0].sort((first, second) => {
          return (
            sortedTopics[1].indexOf(first.payload.doc.data().ID) -
            sortedTopics[1].indexOf(second.payload.doc.data().ID)
          );
        });

        const sortedLearndashTopics: LearndashTopic[] = [];

        sortedTopics[0].map((topicObj) => {
          const data = topicObj.payload.doc.data() as LearndashTopic;
          sortedLearndashTopics.push(data);
        });

        return sortedLearndashTopics;
      })
    );
  }

  public async insertLearndashCourseToProgram(opts: {
    program: Program;
    course: LearndashCourse;
  }) {
    const { program, course } = opts;

    const courseInfoToAdd = {
      ID: course.ID,
      post_title: course.post_title,
      post_content: course.post_content,
    };

    await this.db
      .collection('programs')
      .doc(program.id)
      .update({
        courseIds: firebase.firestore.FieldValue.arrayUnion(course.ID),
        courses: firebase.firestore.FieldValue.arrayUnion(courseInfoToAdd),
      });

    program.users.forEach(async (userId) => {
      await this.db
        .collection('learndash')
        .doc(String(course.ID))
        .update({
          users: firebase.firestore.FieldValue.arrayUnion(userId),
        });
    });
  }

  // returns just the courses specified by the array of IDs (currently limited to max array size of 10)
  // TODO: Fix this function to allow for array size of > 10
  public getSomeLearndashCourses(
    courseIds: number[]
  ): Observable<LearndashCourse[]> {
    if (courseIds && courseIds.length > 0) {
      const snapshot = this.db.collection<LearndashCourse>('learndash', (ref) =>
        ref.where('public', '==', true).where('ID', 'in', courseIds)
      );

      return snapshot.valueChanges().pipe(
        map((matchingCourses) => {
          return matchingCourses;
        })
      );
    } else {
      return of([]);
    }
  }

  public getCoursesByProgram(programID): Observable<LearndashCourse[]> {
    return this.db
      .collection('programs')
      .doc<Program>(programID)
      .valueChanges()
      .pipe(
        mergeMap((program: Program) => {
          if (program?.courseIds?.length !== 0) {
            return this.getSomeLearndashCourses(program.courseIds);
          } else {
            return of([]);
          }
        })
      );
  }
  // NOTE: This function should be called before insertProgramCourses!!
  public async removeProgramCourse(opts: {
    program: Program;
    course: LearndashCourse;
  }) {
    const { program, course } = opts;

    const courseInfoToDelete = {
      ID: course.ID,
      post_title: course.post_title,
      post_content: course.post_content,
    };

    await this.db
      .collection('programs')
      .doc(program.id)
      .update({
        courseIds: firebase.firestore.FieldValue.arrayRemove(course.ID),
        courses: firebase.firestore.FieldValue.arrayRemove(courseInfoToDelete),
      });
  }

  public getAssociatedPrograms(courseId: string): Observable<Program[]> {
    return this.db
      .collection<Program>('programs', (ref) =>
        ref
          .where('public', '==', true)
          .where('courseIds', 'array-contains', Number(courseId))
      )
      .valueChanges()
      .pipe(
        map((matchingPrograms) => {
          return matchingPrograms;
        })
      );
  }

  public userCanViewCourse(user: User, courseId: string): Observable<boolean> {
    return this.getLearndashCourse(courseId).pipe(
      map((course) => {
        const isPublic = course.public ? true : false;
        const isOpen = course.access === 'open' ? true : false;
        const isFree = course.access === 'free' ? true : false;
        const isAdmin =
          user && user.roles && (user.roles.admin || user.roles.editor);
        const isEnrolled =
          user && course.users && course.users.includes(user.uid);
        const isActiveUser = user && user.roles && user.roles.user;

        if (isAdmin) {
          return true;
        }

        if (isEnrolled && isPublic && isActiveUser) {
          return true;
        }

        if (isOpen && isPublic && isActiveUser) {
          return true;
        }

        if (isFree && isPublic && isActiveUser) {
          return true;
        }

        return false;
      })
    );
  }

  public updateCourseAccess(
    programId: string,
    itemsModified: {
      usersAdded?: string[];
      coursesAdded?: number[];
      usersRemoved?: string[];
      coursesRemoved?: number[];
    }
  ): Observable<any> {
    if (
      itemsModified.coursesAdded ||
      itemsModified.usersAdded ||
      itemsModified.coursesRemoved ||
      itemsModified.coursesRemoved
    ) {
      return this.httpClient.post(
        '/api/programs/updateCourseAccess',
        {
          programId: programId,
          itemsModified: {
            usersAdded: itemsModified.usersAdded || null,
            coursesAdded: itemsModified.coursesAdded || null,
            usersRemoved: itemsModified.usersRemoved || null,
            coursesRemoved: itemsModified.coursesRemoved || null,
          },
        },
        { responseType: 'json' }
      );
    } else {
      return of(null);
    }
  }
}
