import firebase from 'firebase/compat/app';
import { InstructorInfo } from '../instructor/instructor.model';
import { LearndashCourse } from '../learndash/learndash-course.model';
import { LocationInfo } from '../location/location.model';
import { Tag } from '../tag/tag.model';
import { TechInfo } from '../technology/technology.model';

// To hold partial data about courses
export type CourseInfo = Pick<
  LearndashCourse,
  'ID' | 'post_title' | 'post_content'
>;

export enum StripeProgramType {
  Product = 'product',
  Plan = 'plan',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const StripeProgramTypeToLabelMapping: Record<
  StripeProgramType,
  string
> = {
  [StripeProgramType.Product]: 'Product',
  [StripeProgramType.Plan]: 'Plan',
};

enum StripeProgramFrequency {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Yearly = 'yearly',
  Once = 'once',
}

export enum ProgramLevel {
  one = 1,
  two = 2,
  three = 3,
  four = 4,
  five = 5,
  six = 6,
}

export enum ProgramAgeGroup {
  A = '6-8',
  B = '9-11',
  C = '12-14',
  D = '15-17',
  E = '18 and older',
}

export enum ProgramType {
  Camp = 'camp',
  Club = 'club',
  Lab = 'lab',
  Partner = 'partner',
}

interface StripeProgram {
  id?: string; // Stripe Price ID
  type: StripeProgramType; // product or plan
  price: number;
  frequency: StripeProgramFrequency; // daily, weekly, monthly, yearly, once
}

export type DaysOfTheWeek = {
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
};
export class Program {
  id: string;
  name: string;
  accessLevel?: string;
  /**
   * Timestamp of initial document creation
   */
  created: firebase.firestore.Timestamp;

  /**
   * Timestamp of the last time the document was updated
   */
  modified: firebase.firestore.Timestamp;
  pictureUrl?: string;
  description?: string;
  location?: LocationInfo;
  public: boolean;
  slug?: string;
  uid: string;
  seats?: number;
  seatMemberships?: number;
  timeAndDate?: {
    startDateTime?: firebase.firestore.Timestamp;
    endDateTime?: firebase.firestore.Timestamp;
  };
  live?: {
    onAir: boolean;
    currentSlideURL?: string;
    currentSlidePage?: number;
    currentProjectURL?: string;
    displayVideo?: boolean;
  };
  users?: string[]; // User IDs of associated instructors and students
  stripe?: StripeProgram; // Stripe information for program
  daysOfTheWeek?: DaysOfTheWeek;
  tags?: Tag[];
  technologies?: TechInfo[];
  programType: ProgramType; // camp, club, lab
  archive: boolean; // Whether or not program is archived
  courses: CourseInfo[]; // Array of CourseInfo objs
  courseIds: number[];
  instructors?: InstructorInfo[]; // Array of instructors for the program
  instructorIds?: string[];
  level?: ProgramLevel;
  ageGroup?: ProgramAgeGroup;
  ccpc?: {
    competitionId?: string;
    tournamentId?: string;
  };
}
