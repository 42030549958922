import { Component, Input } from '@angular/core';
import { Attendance } from '@codecraft-works/data-models';

@Component({
  selector: 'app-attendance-row',
  templateUrl: './attendance-row.component.html',
  styleUrls: ['./attendance-row.component.css'],
})
export class AttendanceRowComponent {
  @Input()
  attendanceItem: Attendance;
  @Input() first: boolean;
  @Input() last: boolean;
  @Input() even: boolean;
  @Input() odd: boolean;

  constructor() {}
}
