import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-username-link-cell',
  templateUrl: './username-link-cell.component.html',
  styleUrls: ['./username-link-cell.component.css']
})
export class UsernameLinkCellComponent implements AgRendererComponent {
  userName: string;

  constructor() {}

  refresh(params: ICellRendererParams): boolean {
    this.userName = params.data.userName;
    return true;
  }

  agInit(params: ICellRendererParams): void {
    this.userName = params.data.userName;
  }
}
