<div class="container-fluid">
  <codecraft-hero-block class="pb-0">
    <div class="row">
      <h1 class="col-12 text-primary">
        Dashboard
      </h1>
    </div>
  </codecraft-hero-block>
</div>
<codecraft-hero-block class="p-0">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 pt-2 pb-2">
        <ng-container *ngIf="(user$ | async) as user; else dashNotLoggedIn">
          <div class="d-flex flex-column flex-lg-row">
            <div id="dash-hero" class="d-flex flex-column">
              <div class="d-flex flex-row">
                <img
                  id="userPhoto"
                  height="128px"
                  src="{{ user.photoURL }}"
                  class="rounded"
                  [alt]="user.displayName"
                  (error)="handleMissingImage($event)"
                />
                <span class="ml-2 align-self-center">
                  <h2>Welcome {{ user.displayName }}!</h2>
                </span>
              </div>
              <div
                *ngIf="(myPrograms$ | async) as myPrograms; else noPrograms"
                class="mt-2"
              >
                <p class="lead">
                  Access the programs you have enrolled in below. If you don't
                  see the program you are expecting below,
                  <a
                    href="https://codecraft-works-help-center.scrollhelp.site/faq/"
                    >visit the help center</a
                  >.
                </p>
              </div>
              <ng-template #noPrograms>
                <p class="mt-2">
                  You haven't signed up for any programs yet. You can take a
                  look at
                  <a routerLink="/programs">our available programs</a>
                  and get started!
                </p>
              </ng-template>
            </div>
            <div
              id="dash-buttons"
              class="d-flex flex-column flex-md-row justify-content-between align-items-center align-items-lg-start ml-lg-5"
            >
              <app-web-project-new-project-button
                [user]="user"
                class="pb-3 pb-sm-0 mr-lg-3"
              >
              </app-web-project-new-project-button>
              <div>
                <a
                  routerLink="/games"
                  class="btn btn-primary text-decoration-none"
                >
                  <fa-icon [icon]="faPlusCircle" class="fa-lg"></fa-icon> Game
                </a>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #dashNotLoggedIn>
          <div class="d-flex flex-column flex-lg-row">
            <div id="dash-hero" class="d-flex flex-column">
              <div class="d-flex flex-column flex-sm-row">
                <img
                  width="300px"
                  src="/assets/img/codey.svg"
                  alt="Codey Mascot Waving"
                />
                <span class="ml-2 align-self-center">
                  <h2>Welcome!</h2>
                </span>
              </div>
              <p class="mt-2">
                You haven't signed up for any programs yet. You can take a look
                at
                <a routerLink="/programs" fragment="available-programs"
                  >our available programs</a
                >
                and get started!
              </p>
              <p>
                You can also take a look at
                <a routerLink="/web" fragment="featured"
                  >our community projects</a
                >
                and see what our talented users have been creating or
                <a routerLink="/login">login</a> and create your own!
              </p>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</codecraft-hero-block>
<div class="container-fluid">
  <ng-container *ngIf="(user$ | async) as user">
    <tabset class="col-12 mt-3 pb-2">
      <tab
        heading="Instructing"
        tabOrder="1"
        id="current-programs-instructing"
        *ngIf="user.roles.instructor"
      >
        <h2 class="col-12 mt-3 pb-2">
          Instructing
        </h2>
        <app-grid
          [publicOnly]="true"
          path="programs"
          field="timeAndDate.endDateTime"
          [reverse]="false"
          [limit]="8"
          entityName="Program"
          [user]="user"
          [userOnly]="false"
          [isEqual]="availablePrograms"
          [inArray]="{ field: 'instructorIds', argument: user.uid }"
          [isGreaterThan]="currentAndFuturePrograms"
          [hideAddToCart]="true"
        >
        </app-grid>
      </tab>
      <tab
        heading="Archived Instructing"
        tabOrder="2"
        id="past-programs-instructing"
        *ngIf="user.roles.instructor"
      >
        <h2 class="col-12 mt-3 pb-2">
          Archived Instructing
        </h2>
        <app-grid
          [publicOnly]="true"
          path="programs"
          field="timeAndDate.endDateTime"
          [reverse]="false"
          [limit]="8"
          entityName="Program"
          [user]="user"
          [userOnly]="false"
          [inArray]="{ field: 'instructorIds', argument: user.uid }"
          [isLessThan]="pastPrograms"
          [hideAddToCart]="true"
        >
        </app-grid>
      </tab>
      <tab
        heading="My Programs"
        tabOrder="3"
        active="true"
        id="current-programs-tab"
        *ngIf="(myPrograms$ | async) as myPrograms"
      >
        <h2 class="col-12 mt-3 pb-2">
          My Programs
        </h2>
        <app-slider
          [publicOnly]="true"
          path="programs"
          field="timeAndDate.startDateTime"
          [reverse]="false"
          [limit]="36"
          [chunkSize]="4"
          entityName="Program"
          [inArray]="myPrograms"
          [hideAddToCart]="true"
        >
        </app-slider>
      </tab>
      <tab heading="My Web Projects" tabOrder="4" id="web-projects-tab">
        <h2 class="col-12 mt-3 pb-2">
          My Web Projects
        </h2>
        <app-grid
          [publicOnly]="null"
          path="web-projects"
          field="modified"
          [reverse]="true"
          [limit]="8"
          [user]="user"
          [userOnly]="true"
          entityName="Project"
        >
        </app-grid>
      </tab>
      <tab heading="My Game Projects" tabOrder="5" id="game-projects-tab">
        <h2 class="col-12 mt-3 pb-2">
          My Game Projects
        </h2>
        <app-table-view
          [publicOnly]="false"
          path="game-projects"
          field="modified"
          [reverse]="true"
          [limit]="10"
          [user]="user"
          [userOnly]="true"
          entityName="Game Project"
        >
        </app-table-view>
      </tab>
    </tabset>
  </ng-container>
</div>
