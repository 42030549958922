import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'codecraft-quiz-error-page',
  template: `
    <div>
      Error loading quiz: {{ error }}.
      <br />
      Please reload or contact the administrators for help.
    </div>
  `,
  styles: [],
})
export class CodecraftQuizErrorPageComponent {
  @Input() error!: string;
}
