import { NgModule } from '@angular/core';
import { Route, RouterModule, mapToCanActivate } from '@angular/router';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { HomePageComponent } from '@app/core/home-page/home-page.component';
import { LoginPageComponent } from '@app/core/login/login-page/login-page.component';
import { AdminGuard } from './core/guards/admin.guard';
import { MembershipGuard } from './core/guards/membership.guard';
import { UserExistsGuard } from './core/guards/user-exists.guard';
import { MembershipDetailsComponent } from './core/membership/membership-details/membership-details.component';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { PermissionDeniedComponent } from './core/permission-denied/permission-denied.component';
import { ShoppingCartPageComponent } from './core/shopping-cart/shopping-cart-page/shopping-cart-page.component';
import { UserProfilePageComponent } from './core/user/user-profile-page/user-profile-page.component';
import { UserSettingsPageComponent } from './core/user/user-settings-page/user-settings-page.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { PdfViewerComponent } from './shared/pdf-viewer/pdf-viewer.component';

const routes: Route[] = [
  {
    path: '',
    component: HomePageComponent,
  },
  {
    path: '',
    outlet: 'nav',
    component: NavbarComponent,
  },
  {
    path: 'home',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: 'admin',
    canActivate: mapToCanActivate([AdminGuard]),
    loadChildren: () =>
      import('./admin/admin.module').then((m) => {
        return m.AdminModule;
      }),
  },
  {
    path: 'students',
    canActivate: mapToCanActivate([AdminGuard]),
    loadChildren: () =>
      import('./student/student.module').then((m) => {
        return m.StudentModule;
      }),
  },
  {
    path: 'programs',
    loadChildren: () =>
      import('./program/program.module').then((m) => {
        return m.ProgramModule;
      }),
  },
  {
    path: 'p',
    loadChildren: () =>
      import('./program/program.module').then((m) => {
        return m.ProgramModule;
      }),
  },
  {
    path: 'educators',
    canActivate: mapToCanActivate([AdminGuard]),
    loadChildren: () =>
      import('./educator/educator.module').then((m) => {
        return m.EducatorModule;
      }),
  },
  {
    path: 'locations',
    loadChildren: () =>
      import('./location/location.module').then((m) => {
        return m.LocationModule;
      }),
  },
  {
    path: 'certificates',
    canActivate: mapToCanActivate([AuthGuard]),
    loadChildren: () =>
      import('./certificate/certificate.module').then(
        (m) => m.CertificateModule
      ),
  },
  {
    path: 'instructors',
    loadChildren: () =>
      import('./instructor/instructor.module').then((m) => {
        return m.InstructorModule;
      }),
  },
  {
    path: 'web',
    loadChildren: () =>
      import('./web-project/web-project.module').then((m) => {
        return m.WebProjectModule;
      }),
  },
  {
    path: 'memberships',
    canActivate: mapToCanActivate([AuthGuard]),
    loadChildren: () =>
      import('./membership/membership.module').then((m) => {
        return m.MembershipModule;
      }),
  },
  {
    path: 'games',
    loadChildren: () =>
      import('./game/game.module').then((m) => {
        return m.GameModule;
      }),
  },
  {
    path: 'courses',
    loadChildren: () =>
      import('./learndash/learndash.module').then((m) => {
        return m.LearndashModule;
      }),
  },
  {
    path: 'learning',
    redirectTo: 'courses',
    pathMatch: 'prefix',
  },
  {
    path: 'cart',
    canActivate: mapToCanActivate([AuthGuard]),
    component: ShoppingCartPageComponent,
  },
  {
    path: 'users/:userName',
    canActivate: mapToCanActivate([UserExistsGuard]),
    component: UserProfilePageComponent,
  },
  {
    path: 'user',
    canActivate: mapToCanActivate([AuthGuard]),
    component: UserSettingsPageComponent,
  },
  {
    path: 'user/memberships/:membershipId',
    canActivate: mapToCanActivate([MembershipGuard]),
    component: MembershipDetailsComponent,
  },
  {
    path: 'pdf',
    component: PdfViewerComponent,
  },
  {
    path: 'permission-denied',
    component: PermissionDeniedComponent,
  },
  {
    path: '404',
    component: PageNotFoundComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    RouterModule.forRoot([], { enableTracing: false }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
