import { pythonGenerator } from 'blockly/python';
import { Blockly, CustomBlock } from 'ngx-blockly-new';

export class CheckBoardBlock extends CustomBlock {
  constructor() {
    super('tictactoe_checkboard');
    this.class = CheckBoardBlock;
  }

  public defineBlock() {
    this.block.appendDummyInput().appendField('Check Board');
    this.block
      .appendValueInput('ROW')
      .appendField('Row')
      .setAlign(Blockly.ALIGN_RIGHT)
      .setCheck('Number');
    this.block
      .appendValueInput('COL')
      .appendField('Column')
      .setAlign(Blockly.ALIGN_RIGHT)
      .setCheck('Number');
    this.block.setOutput(true, 'String');
    this.block.setColour(270);
    this.block.setTooltip('Outputs the state of a spot on the board');
    this.block.setHelpUrl('https://app.codecraftworks.com/courses/33629');
  }

  public toPythonCode(): string | any[] {
    return [
      'self.get_game_state()[' +
        pythonGenerator.valueToCode(this.block, 'ROW', 0) +
        '][' +
        pythonGenerator.valueToCode(this.block, 'COL', 0) +
        ']',
      99,
    ];
  }
}
