import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { isObservable, Observable } from 'rxjs';

@Component({
  selector: 'app-readme',
  templateUrl: './readme.component.html',
  styleUrls: ['./readme.component.css']
})
export class ReadmeComponent implements OnInit, OnChanges {
  @Input()
  markdownText: string | Observable<string>;

  readme: string;

  constructor() {}

  ngOnInit() {
    if (typeof this.markdownText === 'string') {
      this.readme = this.markdownText;
    } else if (isObservable(this.markdownText)) {
      this.markdownText.subscribe(markdown => {
        this.readme = markdown;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      if (propName === 'markdownText') {
        if (typeof this.markdownText === 'string') {
          this.readme = this.markdownText;
        } else if (isObservable(this.markdownText)) {
          this.markdownText.subscribe(markdown => {
            this.readme = markdown;
          });
        }
      }
    }
  }
}
