import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { StripeService } from '@app/core/stripe/stripe.service';
import { StripeCustomer, User } from '@codecraft-works/data-models';
import { environment } from '@environments/environment';
import { combineLatest, Observable } from 'rxjs';

@Component({
  selector: 'app-stripe-elements-form',
  templateUrl: './stripe-elements-form.component.html',
  styleUrls: ['./stripe-elements-form.component.css'],
})
export class StripeElementsFormComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  stripe = Stripe(`${environment.stripePublishableKey}`);
  elements = this.stripe.elements();
  @Input()
  user: User;
  @Input()
  item: string;
  @Input()
  startDate: number;
  stripeCustomer$: Observable<StripeCustomer>;
  stripeCustomerSource$: Observable<any>;
  stripeItem$: Observable<any>;
  @ViewChild('cardInfo') cardInfo: ElementRef;
  @ViewChild('quantity') quantity: ElementRef;
  @ViewChild('couponCode') couponCode: ElementRef;
  @ViewChild('newCardBlock') newCardBlock: ElementRef;
  @ViewChild('existingCardBlock')
  existingCardBlock: ElementRef;
  @ViewChild('productInfoBlock')
  productInfoBlock: ElementRef;
  @ViewChild('purchaseMembershipButtonBlock')
  purchaseMembershipButtonBlock: ElementRef;
  @ViewChild('quantityBlock') quantityBlock: ElementRef;
  @ViewChild('couponCodeBlock') couponCodeBlock: ElementRef;

  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  couponError: string;
  stripeCustomerSource: any;
  stripePlan: any;
  stripeProduct: any;
  stripeCoupon: any;
  loading: boolean;
  cost: number;

  constructor(
    private cd: ChangeDetectorRef,
    private stripeService: StripeService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loading = true;
  }

  /**
   * On change, saves editor contents
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      if (propName === 'user') {
        if (this.user && this.user.stripeCustomerId) {
          this.stripeCustomer$ = this.stripeService.getCustomerByUserId(
            this.user.uid
          );
          this.stripeCustomerSource$ = this.stripeService.getStripeCustomer(
            this.user.stripeCustomerId
          );

          const stripeCustomerData$ = combineLatest([
            this.stripeCustomer$,
            this.stripeCustomerSource$,
          ]);

          stripeCustomerData$.subscribe(([, stripeCustomerSource]) => {
            this.stripeCustomerSource = stripeCustomerSource.sources.data.find(
              (source) => source.id === stripeCustomerSource.default_source
            );
            this.loading = false;

            if (!this.stripeCustomerSource) {
              this.showCardSource();
            }
          });
        } else if (this.user) {
          // Check if Stripe customer exists
          const existingStripeCustomer$ =
            this.stripeService.getStripeCustomerByEmail(this.user);
          existingStripeCustomer$.subscribe((existingStripeCustomer) => {
            // If the Stripe customer exists, add to Firebase
            if (existingStripeCustomer.id) {
              this.stripeService.insertCustomer(
                this.user,
                existingStripeCustomer
              );
            } else {
              this.stripeService
                .createStripeCustomer(this.user)
                .subscribe((newStripeCustomer) => {
                  if (newStripeCustomer.id) {
                    this.stripeService.insertCustomer(
                      this.user,
                      newStripeCustomer
                    );
                  }
                });
            }
          });
        }
      }
      if (propName === 'item') {
        if (this.item && this.item.startsWith('plan')) {
          this.stripeItem$ = this.stripeService.getStripePlan(this.item);
          this.stripeItem$.subscribe((plan) => {
            this.stripePlan = plan;
            this.onQuantityChange();
            this.stripeService
              .getStripeProduct(plan.product)
              .subscribe((stripeProduct) => {
                this.stripeProduct = stripeProduct;
              });
          });
        } else if (this.item && this.item.startsWith('prod')) {
          this.stripeItem$ = this.stripeService.getStripeProduct(this.item);
          this.stripeItem$.subscribe((product) => {
            this.stripeProduct = product;
          });
        }
      }
    }
  }

  ngAfterViewInit() {
    this.card = this.elements.create('card');
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }

  showExistingCard() {
    this.existingCardBlock.nativeElement.classList.remove('d-none');
    this.loading = false;
  }

  showCardSource() {
    this.newCardBlock.nativeElement.classList.remove('d-none');
    this.loading = false;
  }

  hideCardSource() {
    this.newCardBlock.nativeElement.classList.add('d-none');
  }

  showProductInfo() {
    this.productInfoBlock.nativeElement.classList.remove('d-none');
  }

  hideProductInfo() {
    this.productInfoBlock.nativeElement.classList.add('d-none');
  }

  showQuantityBlock() {
    this.quantityBlock.nativeElement.classList.remove('d-none');
  }

  hideQuantityBlock() {
    this.quantityBlock.nativeElement.classList.add('d-none');
  }

  showCouponCodeBlock() {
    this.couponCodeBlock.nativeElement.classList.remove('d-none');
  }

  hideCouponCodeBlock() {
    this.couponCodeBlock.nativeElement.classList.add('d-none');
  }

  showPurchaseMembershipButton() {
    this.purchaseMembershipButtonBlock.nativeElement.classList.remove('d-none');
  }

  hidePurchaseMembershipButton() {
    this.purchaseMembershipButtonBlock.nativeElement.classList.add('d-none');
  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  handleNewCardRadioChange(event) {
    const newCardRadio = event.target;
    if (newCardRadio.checked) {
      this.hideProductInfo();
      this.hidePurchaseMembershipButton();
      this.hideQuantityBlock();
      this.hideCouponCodeBlock();
      this.showCardSource();
    }
  }

  handleUseCardRadioChange(event) {
    const useCardRadio = event.target;
    if (useCardRadio.checked) {
      this.hideCardSource();
      this.showQuantityBlock();
      this.showProductInfo();
      this.showPurchaseMembershipButton();
      this.showCouponCodeBlock();
    }
  }

  async addNewCard() {
    this.hideCardSource();
    this.loading = true;
    const { source, error } = await this.stripe.createSource(this.card);
    if (!error) {
      // ...send the token to the your backend to process the charge
      this.stripeService
        .createStripeSource(this.user.stripeCustomerId, source.id)
        .subscribe((customer) => {
          this.stripeCustomerSource = customer.sources.data.find(
            (addSource) => addSource.id === customer.default_source
          );
          this.loading = false;
        });
    }
  }

  onQuantityChange() {
    if (this.stripePlan && this.quantity) {
      switch (true) {
        case this.stripePlan.billing_scheme === 'per_unit': {
          this.cost =
            (this.quantity.nativeElement.value * this.stripePlan.amount) / 100;
          break;
        }
        case this.stripePlan.billing_scheme === 'tiered' &&
          this.stripePlan.tiers_mode === 'volume': {
          const tiers = this.stripePlan.tiers;
          const quantity = this.quantity.nativeElement.value;

          for (const tier of tiers) {
            if (!tier.up_to) {
              this.cost = quantity * tier.unit_amount;
              break;
            } else if (quantity <= tier.up_to) {
              this.cost = quantity * tier.unit_amount;
              break;
            }
          }
          this.cost = this.cost / 100;
          break;
        }
        case this.stripePlan.billing_scheme === 'tiered' &&
          this.stripePlan.tiers_mode === 'graduated': {
          let cost = 0;
          const tiers = this.stripePlan.tiers;
          const quantity = this.quantity.nativeElement.value;
          let i = 0;
          for (const tier of tiers) {
            const previousTier = i - 1;
            if (tier.up_to === null && i === 0) {
              cost += quantity * tier.unit_amount;
              break;
            } else if (tier.up_to === null && i !== 0) {
              cost += (quantity - tiers[previousTier].up_to) * tier.unit_amount;
              break;
            } else if (quantity <= tier.up_to && i === 0) {
              cost += quantity * tier.unit_amount;
              break;
            } else if (quantity <= tier.up_to && i !== 0) {
              cost += (quantity - tiers[previousTier].up_to) * tier.unit_amount;
              break;
            } else if (quantity > tier.up_to && i === 0) {
              cost += tier.up_to * tier.unit_amount;
            } else if (quantity > tier.up_to && i !== 0) {
              cost +=
                (tier.up_to - tiers[previousTier].up_to) * tier.unit_amount;
            }
            i++;
          }
          this.cost = cost / 100;
          break;
        }
      }
    } else if (this.stripePlan) {
      switch (this.stripePlan.billing_scheme) {
        case 'per_unit': {
          this.cost = this.stripePlan.amount / 100;
          break;
        }
        case 'tiered': {
          this.cost = this.stripePlan.amount / 100;
          break;
        }
      }
    }
  }

  applyCoupon() {
    this.couponError = '';
    const couponCode = this.couponCode.nativeElement.value.trim();
    if (couponCode !== '') {
      this.stripeService.getStripeCoupon(couponCode).subscribe((coupon) => {
        switch (true) {
          case coupon.code === 'resource_missing': {
            this.couponError = 'This coupon code is invalid!';
            break;
          }
          case !coupon.valid: {
            this.couponError = 'This coupon code is no longer valid!';
            break;
          }
          case coupon.valid: {
            this.hideCouponCodeBlock();
            this.stripeCoupon = coupon;
            break;
          }
        }
      });
    } else {
      this.couponError = 'Please provide a valid coupon code.';
    }
  }

  async purchaseMembership(proRate = false) {
    const now = Date.now();
    const anchorDate = this.startDate < now ? null : this.startDate;
    const couponCode =
      this.stripeCoupon && this.stripeCoupon.id ? this.stripeCoupon.id : null;

    this.stripeService
      .createStripeSubscription(
        this.user.stripeCustomerId,
        this.item,
        this.quantity.nativeElement.value,
        anchorDate,
        proRate,
        couponCode
      )
      .subscribe((stripeSubscription) => {
        if (stripeSubscription.id) {
          this.stripeService.insertCustomerSubscription(
            this.user,
            stripeSubscription
          );
          this.router.navigate(['/membership/thank-you']);
        }
      });
  }
}
