import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GameProjectService } from '@app/shared/game-project/game-project.service';
import { GameType, User } from '@codecraft-works/data-models';
import {
  faJava,
  faJsSquare,
  faPython
} from '@fortawesome/free-brands-svg-icons';
import { faCube, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-game-new-project-button',
  templateUrl: './game-new-project-button.component.html',
  styleUrls: ['./game-new-project-button.component.css']
})
export class GameNewProjectButtonComponent implements OnInit {
  /**
   * Input for user
   */
  @Input() user: User;

  @Input() language: string;

  @Input() type: GameType;

  java = faJava;
  javascript = faJsSquare;
  python = faPython;
  blockly = faCube;

  @Output()
  closeModalEmitter = new EventEmitter();

  icon: IconDefinition;

  public gameType = GameType;

  /**
   * Initialize web project service and angular router
   * @param gameProjectService
   * @param router
   */
  constructor(
    private gameProjectService: GameProjectService,
    private router: Router
  ) {}

  ngOnInit() {
    switch (this.language) {
      case 'java':
        this.icon = this.java;
        break;
      case 'javascript':
        this.icon = this.javascript;
        break;
      case 'python':
        this.icon = this.python;
        break;
      case 'blockly':
        this.icon = this.blockly;
        break;
    }
  }

  async newGameProject() {
    const newGameProjectId = await this.gameProjectService.insertGameProject(
      this.user,
      this.type,
      this.language
    );
    this.router.navigate([`/games/projects/${newGameProjectId}/`]);
  }

  closeModal() {
    this.closeModalEmitter.emit();
  }
}
