import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class TextFormatterService {
  constructor(private domSanitizer: DomSanitizer) {}

  public formatText(rawText?: string): string {
    if (!rawText) {
      return '';
    }

    return (
      rawText
        // split by lines
        .split('\n')
        // where the paragraph is not empty
        .filter(section => section.length > 0)
        // sanitize for html
        .map(section =>
          this.domSanitizer.sanitize(SecurityContext.HTML, section)
        )
        // wrap in a paragraph
        .map(section => `<p>${section}</p>`)
        // join into one innerHTML value
        .join('')
    );
  }

  public sanitizeText(rawText?: string): string {
    if (!rawText) {
      return '';
    }

    // sanitize for html
    return this.domSanitizer.sanitize(SecurityContext.HTML, rawText);
  }
}
