import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginationDirective } from '../pagination.directive';
import { PaginationService } from '../pagination.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
  providers: [PaginationService]
})
export class SliderComponent extends PaginationDirective
  implements OnInit, OnDestroy {
  @Input()
  entityName: string;

  @Input()
  featuredCard: boolean;

  chunkedData$: Observable<any>;

  chunkedData: any[];

  @Input()
  currentChunk = 0;

  @Input()
  chunkSize = 4;

  @Input()
  noGutters = false;

  @Input()
  isGrid = true;

  @Input() hideAddToCart = false;

  /**
   * Font Awesome icon
   */
  faChevronLeft = faChevronLeft;

  /**
   * Font Awesome icon
   */
  faChevronRight = faChevronRight;
  loaded: boolean;
  paginationSub: Subscription;
  loadingSub: Subscription;

  constructor(public data: PaginationService) {
    super(data);
  }

  ngOnInit() {
    super.init();
    this.chunkedData$ = this.page.data.pipe(
      map(data => this.chunk(data, this.chunkSize))
    );

    this.paginationSub = this.chunkedData$.subscribe(data => {
      this.chunkedData = data;
    });
    this.loadingSub = this.page.loading.subscribe(loading => {
      if (!this.loaded && !loading) {
        this.loaded = true;
      }
    });
  }

  chunk(array: any[], size: number) {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i++) {
      const last = chunkedArray[chunkedArray.length - 1];
      if (!last || last.length === size) {
        chunkedArray.push([array[i]]);
      } else {
        last.push(array[i]);
      }
    }
    return chunkedArray;
  }

  next() {
    if (!this.page.done) {
      this.page.more();
    }
    this.currentChunk < this.chunkedData.length - 1
      ? this.currentChunk++
      : (this.currentChunk = 0);
  }

  prev() {
    this.page.done && this.currentChunk === 0
      ? (this.currentChunk = this.chunkedData.length - 1)
      : this.currentChunk--;
  }

  ngOnDestroy() {
    if (this.loadingSub) {
      this.loadingSub.unsubscribe();
    }

    if (this.paginationSub) {
      this.paginationSub.unsubscribe();
    }
  }
}
