<card
  [detailUrl]="['/web/' + webProject.id]"
  [showDetailButton]="false"
  [user]="user"
  [footerClass]="'bg-primary text-white'"
  [isGrid]="isGrid"
  [noPadding]="true"
  class="p-0"
>
  <a
    [routerLink]="['/web/' + webProject.id]"
    target="_blank"
    [attr.aria-label]="[
      featuredCard ? 'Featured Project ' + webProject.name : webProject.name
    ]"
  >
    <div class="embed-responsive embed-responsive-1by1">
      <iframe
        #WebProjectFrame
        [attr.aria-label]="[
          featuredCard ? 'Featured Project ' + webProject.name : webProject.name
        ]"
        class="projectCardFrame embed-responsive-item flex-grow-1"
        frameborder="0"
        allowfullscreen="false"
        scrolling="no"
        sandbox="allow-same-origin"
        [title]="[
          featuredCard ? 'Featured Project ' + webProject.name : webProject.name
        ]"
      ></iframe>
    </div>
  </a>

  <div
    footer
    *ngIf="webProject.user"
    class="flex-grow-1"
  >
    <div class="w-100 text-uppercase font-weight-bold text-truncate">
      <a
        class="text-white"
        [routerLink]="['/web/' + webProject.id]"
      >
        <span>{{ webProject.name }}</span></a>
    </div>
    <a
      *ngIf="webProject.user.userName; else noUserNamePic"
      [routerLink]="['/users/', webProject.user.userName]"
    >
      <img
        src="{{ webProject.user.photoURL }}"
        [attr.alt]="['Photo of ' + webProject.user.displayName]"
        width="32px"
      />
    </a>

    <a
      *ngIf="webProject.user.userName; else noUserName"
      class="small pl-1 font-italic text-white"
      [routerLink]="['/users/', webProject.user.userName]"
    >{{ webProject.user.displayName }}</a>
    <ng-template #noUserName>
      <span class="small pl-1 font-italic">{{
        webProject.user.displayName
        }}</span>
    </ng-template>
    <ng-template #noUserNamePic>
      <img
        src="{{ webProject.user.photoURL }}"
        [attr.alt]="['Photo of ' + webProject.user.displayName]"
        width="32px"
      />
    </ng-template>
  </div>
</card>
