<form #checkout id="stripeElementForm" class="checkout">
  <div
    #existingCardBlock
    *ngIf="stripeCustomerSource && stripeProduct && stripePlan"
    class="form-group"
  >
    <div class="form-check" id="existing-card-block">
      <input
        type="radio"
        id="existing-card-info"
        name="useCard"
        class="form-check-input"
        value="useoldcard"
        checked
        (change)="handleUseCardRadioChange($event)"
      />
      <label for="existing-card-info" class="form-check-label"
        >Use
        {{ stripeCustomerSource.brand || stripeCustomerSource.card.brand }}
        ending in
        {{
          stripeCustomerSource.last4 || stripeCustomerSource.card.last4
        }}</label
      >
    </div>
    <div class="form-check" id="use-new-card-block">
      <input
        type="radio"
        id="use-new-card"
        name="useCard"
        class="form-check-input"
        value="usenewcard"
        (change)="handleNewCardRadioChange($event)"
      />
      <label for="use-new-card" class="form-check-label">Use new card</label>
    </div>
  </div>

  <p #productInfoBlock *ngIf="stripeProduct && stripePlan">
    The {{ stripeProduct.name }} is
    <ng-container *ngIf="stripePlan.billing_scheme === 'per_unit'">
      {{ stripePlan.amount / 100 | currency }} every
      <ng-container *ngIf="stripePlan.interval_count > 1">
        {{ stripePlan.interval_count }}
      </ng-container>
      {{ stripePlan.interval
      }}<ng-container *ngIf="stripePlan.interval_count > 1">s</ng-container>.
    </ng-container>
    <ng-container
      *ngIf="
        stripePlan.billing_scheme === 'tiered' &&
        stripePlan.tiers_mode === 'volume'
      "
    >
      <ng-container *ngFor="let tier of stripePlan.tiers; index as i">
        {{ tier.unit_amount / 100 | currency }}
        for
        <ng-container *ngIf="i === 0 && tier.up_to !== null">1 </ng-container>
        <ng-container *ngIf="i === 0 && tier.up_to === null"
          >every seat
        </ng-container>
        <ng-container *ngIf="tier.up_to === 1"
          >{{ stripeProduct.unit_label }},
        </ng-container>
        <ng-container *ngIf="tier.up_to > 1 && i === 0; else notFirstTier"
          >to {{ tier.up_to }} {{ stripeProduct.unit_label }}s,
        </ng-container>
        <ng-template #notFirstTier>
          <ng-container *ngIf="tier.up_to !== null && i > 0"
            >{{ stripePlan.tiers[i - 1].up_to + 1 }} to {{ tier.up_to }}
            {{ stripeProduct.unit_label }}s,
          </ng-container>
          <ng-container *ngIf="tier.up_to === null && i > 0"
            >{{ stripePlan.tiers[i - 1].up_to + 1 }}
            {{ stripeProduct.unit_label }}s and up,
          </ng-container>
        </ng-template>
      </ng-container>
      {{ stripePlan.amount }} every
      <ng-container *ngIf="stripePlan.interval_count > 1; else singleInterval">
        {{ stripePlan.interval_count }} {{ stripePlan.interval }}s.
      </ng-container>
      <ng-template #singleInterval> {{ stripePlan.interval }}. </ng-template>
    </ng-container>
    <ng-container
      *ngIf="
        stripePlan.billing_scheme === 'tiered' &&
        stripePlan.tiers_mode === 'graduated'
      "
    >
      <ng-container *ngFor="let tiers of stripePlan.tiers; index as i">
        {{ tiers.unit_amount / 100 | currency }} for
        <ng-container *ngIf="i === 0; else theNext">the first </ng-container>
        <ng-template #theNext>
          <ng-container *ngIf="tiers.up_to !== null">the next </ng-container>
        </ng-template>
        <ng-container *ngIf="tiers.up_to === 1"
          >{{ stripeProduct.unit_label }},
        </ng-container>
        <ng-container *ngIf="tiers.up_to > 1 && i === 0; else notFirstTier"
          >{{ tiers.up_to }} {{ stripeProduct.unit_label }}s,
        </ng-container>
        <ng-template #notFirstTier>
          <ng-container *ngIf="tiers.up_to !== null && i > 0"
            >{{ tiers.up_to - stripePlan.tiers[i - 1].up_to }}
            {{ stripeProduct.unit_label }}s,
          </ng-container>
        </ng-template>
        <ng-container *ngIf="tiers.up_to === null"
          >each additional {{ stripeProduct.unit_label }}</ng-container
        >
      </ng-container>
      {{ stripePlan.amount }} every
      <ng-container *ngIf="stripePlan.interval_count > 1; else singleInterval">
        {{ stripePlan.interval_count }} {{ stripePlan.interval }}s.
      </ng-container>
      <ng-template #singleInterval> {{ stripePlan.interval }}. </ng-template>
    </ng-container>
  </p>

  <div class="form-group d-none" id="new-card-block" #newCardBlock>
    <label for="card-info">Enter Your Credit/Debit Card Info</label>
    <div id="card-info" #cardInfo></div>
    <div id="card-errors" class="alert alert-danger" role="alert" *ngIf="error">
      {{ error }}
    </div>
    <div class="btn-group">
      <button type="button" class="btn btn-success" (click)="addNewCard()">
        Add a new card
      </button>
    </div>
  </div>

  <ng-container *ngIf="stripeCustomerSource && stripeProduct">
    <div #quantityBlock class="form-group" id="quantity-block">
      <label for="quantity-info"
        >How many {{ stripeProduct.unit_label }}s would you like to
        purchase?</label
      >
      <div class="input-group">
        <input
          #quantity
          type="number"
          min="1"
          value="0"
          id="quantity"
          class="form-control"
          (change)="onQuantityChange($event)"
        />
        <div class="input-group-append">
          <span class="input-group-text">{{ cost | currency }} </span>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container
    *ngIf="stripeCustomerSource && stripeProduct && stripeCoupon && stripePlan"
  >
    <p #discountBlock id="discount-block">
      <ng-container *ngIf="cost">
        <span class="badge badge-pill badge-info">Current Price</span>
        {{ cost | currency }}
        every
        <ng-container *ngIf="stripePlan.interval_count > 1">
          {{ stripePlan.interval_count }}
        </ng-container>
        {{ stripePlan.interval
        }}<ng-container *ngIf="stripePlan.interval_count > 1">s</ng-container>
      </ng-container>
      <br />
      <ng-container *ngIf="stripeCoupon.amount_off"
        ><span class="badge badge-pill badge-info">Amount of Discount</span>
        {{ stripeCoupon.amount_off / 100 | currency }}</ng-container
      >
      <ng-container *ngIf="stripeCoupon.percent_off">
        <span class="badge badge-pill badge-info">Percent Off</span>
        {{ stripeCoupon.percent_off }}%</ng-container
      >
      <ng-container *ngIf="stripeCoupon.duration === 'once'">
        one time</ng-container
      >
      <ng-container *ngIf="stripeCoupon.duration === 'forever'">
        forever</ng-container
      >
      <ng-container *ngIf="stripeCoupon.duration === 'repeating'">
        for {{ stripeCoupon.duration_in_months }} months</ng-container
      >
      <br />
      <ng-container *ngIf="cost && stripeCoupon.amount_off">
        <span class="badge badge-pill badge-info">Discounted Price</span>
        {{
          cost - stripeCoupon.amount_off / 100 >= 0
            ? (cost - stripeCoupon.amount_off / 100 | currency)
            : (0 | currency)
        }}
        for the first payment, full price for every other payment
      </ng-container>
      <ng-container *ngIf="cost && stripeCoupon.percent_off">
        <span class="badge badge-pill badge-info">Discounted Price</span>
        {{ cost * (stripeCoupon.percent_off / 100) | currency }}
        every
        <ng-container *ngIf="stripePlan.interval_count > 1">
          {{ stripePlan.interval_count }}
        </ng-container>
        {{ stripePlan.interval
        }}<ng-container *ngIf="stripePlan.interval_count > 1">s</ng-container>
      </ng-container>
    </p>
  </ng-container>

  <ng-container *ngIf="stripeCustomerSource && stripeProduct">
    <div #couponCodeBlock class="form-group" id="coupon-block">
      <label for="coupon-code">If you have a coupon code, use it here.</label>
      <div class="input-group">
        <input #couponCode type="text" id="coupon-code" class="form-control" />
        <div class="input-group-append">
          <button type="button" class="btn btn-success" (click)="applyCoupon()">
            Apply Coupon
          </button>
        </div>
      </div>
      <div
        id="coupon-error"
        class="alert alert-danger mt-1"
        role="alert"
        *ngIf="couponError"
      >
        {{ couponError }}
      </div>
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      stripeCustomerSource &&
      stripePlan &&
      stripeProduct &&
      stripeProduct.type === 'service'
    "
  >
    <div
      #purchaseMembershipButtonBlock
      class="btn-group"
      id="purchase-membership-block"
    >
      <button
        *ngIf="
          quantity && quantity.nativeElement && quantity.nativeElement.value
        "
        type="button"
        class="btn btn-success"
        (click)="purchaseMembership()"
        [disabled]="quantity.nativeElement.value === 0"
      >
        Purchase a Membership
      </button>
    </div>
  </ng-container>
</form>

<codecraft-loading-spinner
  #loading
  *ngIf="loading && user"
></codecraft-loading-spinner>

<ng-container #showLoginButton *ngIf="!user">
  <div class="align-middle mx-auto">
    <a class="btn btn-success align-middle text-white" href="/login">
      Login to Purchase
    </a>
  </div>
</ng-container>
