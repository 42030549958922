import firebase from 'firebase/compat/app';

export class MembershipCodeLog {
  public id: string;
  public membershipId: string;
  public redemptionCode: string;
  public timestamp?: firebase.firestore.Timestamp;
  public memberUserId: string;
  public ipAddress: string;
  public customerId: string;
}
