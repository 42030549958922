import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ValidateDisplayNameNotEmail } from '@app/core/user/validate-display-name-not-email';
import { ValidateUserNameNotTaken } from '@app/core/user/validate-user-name-not-taken';
import { User } from '@codecraft-works/data-models';
import { faUserCog } from '@fortawesome/free-solid-svg-icons';
import firebase from 'firebase/compat/app';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-settings-form',
  templateUrl: './user-settings-form.component.html',
  styleUrls: ['./user-settings-form.component.css'],
})
export class UserSettingsFormComponent implements OnInit {
  @Input()
  user: User;
  faUserCog = faUserCog;
  newPictureBlobUrl: string;

  /**
   * Reference to save modal
   */
  modalRef: BsModalRef;

  @Input()
  isModal = true;
  userForm: FormGroup;
  displayName: FormControl;
  userName: FormControl;
  userRoles: FormControl;
  formDataInitialized: boolean;

  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  selectedFiles: FileList;

  @ViewChild('successModal')
  successModal: TemplateRef<any>;
  successModalRef: BsModalRef;

  @ViewChild('failModal')
  failModal: TemplateRef<any>;
  failModalRef: BsModalRef;

  constructor(
    private formBuilder: FormBuilder,
    private db: AngularFirestore,
    private userService: UserService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.initializeForm();
  }

  private initializeForm() {
    this.userName = new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(32),
      Validators.pattern('^[a-zA-Z0-9-_.]*$'), // Only alphanumeric characters
    ]);

    this.userName.setAsyncValidators(
      ValidateUserNameNotTaken.createValidator(this.userService)
    );

    this.displayName = new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(32),
    ]);

    const roleAssigned = [
      'admin',
      'instructor',
      'editor',
      'student',
      'parent',
      'educator',
      'partner',
    ];

    // Check if user has any of the roles assigned and only add form control if not
    if (roleAssigned.some((role) => this.user.roles[role]) === false) {
      this.userRoles = new FormControl('', [Validators.required]);
    }

    this.displayName.setAsyncValidators(
      ValidateDisplayNameNotEmail.createValidator(this.user)
    );

    this.userForm = this.formBuilder.group({
      userName: this.userName,
      displayName: this.displayName,
    });

    if (this.userRoles) {
      this.userForm.addControl('userRoles', this.userRoles);
    }

    this.initializeFormData();
  }

  private initializeFormData() {
    if (this.userRoles) {
      if (this.user.roles.student) {
        this.userRoles.setValue('student');
        this.userRoles.disable();
      }
      if (this.user.roles.parent) {
        this.userRoles.setValue('parent');
        this.userRoles.disable();
      }
      if (this.user.roles.educator) {
        this.userRoles.setValue('educator');
        this.userRoles.disable();
      }
      if (this.user.roles.partner) {
        this.userRoles.setValue('partner');
        this.userRoles.disable();
      }
    }

    this.userForm.patchValue(this.user);

    if (this.user.userName) {
      this.userName.disable();
    }
  }

  /**
   * Update user names
   */
  async updateForm() {
    const displayName: string = this.displayName.value;
    const userName: string = this.userName?.disabled
      ? null
      : this.userName?.value;
    const userRoles: string = this.userRoles?.disabled
      ? null
      : this.userRoles?.value;
    const convertedRole: any = {};
    convertedRole[userRoles] = true;

    const userRef = this.db.firestore.collection('users').doc(this.user.uid);

    let returnMessage = '';
    let updated = false;
    const batch = this.db.firestore.batch();

    if (userName) {
      const userNameRef = this.db.firestore
        .collection('user-names')
        .doc(userName);

      returnMessage += await userNameRef.get().then(async (docSnapshot) => {
        if (docSnapshot.exists || this.user.userName) {
          return 'User name already exists or is already set\n';
        }

        batch.set(userNameRef, {
          id: userName,
          uid: this.user.uid,
          modified: firebase.firestore.Timestamp.now(),
          created: firebase.firestore.Timestamp.now(),
        });

        batch.update(userRef, {
          userName: userName,
          displayName: displayName,
        });

        updated = true;
        return 'Batch set userName\n';
      });
    }

    if (displayName) {
      batch.update(userRef, { displayName: displayName });
      updated = true;
      returnMessage += 'Batch set `displayName`\n';
    }

    if (userRoles) {
      batch.update(userRef, {
        [`roles.${userRoles}`]: true,
      });
      updated = true;
      returnMessage += `Batch set \`userRoles\` roles.${userRoles}\n`;
    }

    if (this.selectedFiles) {
      await this.userService
        .uploadPicture(this.user, this.selectedFiles[0])
        .then((url) => {
          batch.update(userRef, {
            photoURL: url,
          });
          updated = true;
          returnMessage += 'Batch set `photoURL`\n';
        });
    }

    if (updated) {
      batch.update(userRef, {
        modified: firebase.firestore.Timestamp.now(),
      });

      batch
        .commit()
        .then(() => {
          returnMessage += 'User info updated\n';
          if (this.isModal) {
            this.closeModalFunction();
          }
          this.openSuccessModal(this.successModal);
        })
        .catch(() => {
          returnMessage += 'User info failed to update\n';
          this.openFailModal(this.failModal);
        });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      returnMessage += 'Nothing to update\n';
    }
  }
  openSuccessModal(template: TemplateRef<any>) {
    this.successModalRef = this.modalService.show(template, {
      class: 'modal-dialog modal-dialog-centered',
    });
  }

  openFailModal(template: TemplateRef<any>) {
    this.failModalRef = this.modalService.show(template, {
      class: 'modal-dialog modal-dialog-centered',
    });
  }

  openConfirmModal(confirmSettings: TemplateRef<any>) {
    this.modalRef = this.modalService.show(confirmSettings, {
      class: 'modal-dialog modal-dialog-centered',
    });
  }

  closeModalFunction() {
    this.closeModal.emit();
  }

  returnToFirstSelectableElement() {
    document.getElementById('userSettingsModalClose').focus();
  }

  returnToLastSelectableElement() {
    document.getElementById('userSettingsModalCloseButton').focus();
  }

  /**
   * Check if media files are present in database
   * @param event
   */
  detectMediaFiles(event) {
    this.selectedFiles = event.target.files;
    const reader = new FileReader();

    reader.onload = () => {
      this.newPictureBlobUrl = reader.result as string;
    };

    reader.readAsDataURL(this.selectedFiles[0]);
  }
}
