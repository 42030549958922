import { Injectable, OnDestroy } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import {
  Instructor,
  InstructorInfo,
  Program,
  User,
  UserInfo,
} from '@codecraft-works/data-models';
import firebase from 'firebase/compat/app';
import { from, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class InstructorService implements OnDestroy {
  allSubs: Subscription = new Subscription();

  constructor(private firebaseDatabase: AngularFirestore) {}

  public getInstructors(userID?: string): Observable<Instructor[]> {
    let snapshot: AngularFirestoreCollection<Instructor>;
    if (userID) {
      snapshot = this.firebaseDatabase.collection<Instructor>(
        'instructors',
        (ref) => ref.where('uid', '==', userID)
      );
    } else {
      snapshot = this.firebaseDatabase.collection<Instructor>(
        'instructors',
        (ref) => ref.where('public', '==', true)
      );
    }
    return snapshot.snapshotChanges().pipe(
      map((item) => {
        const instructors: Instructor[] = [];
        item.map((a) => {
          const data = a.payload.doc.data() as Instructor;
          instructors.push(data as Instructor);
        });

        return instructors;
      })
    );
  }

  public getInstructorsByProgram(
    programID: string,
    publicOnly?: boolean
  ): Observable<Instructor[]> {
    let snapshot: AngularFirestoreCollection<Instructor>;
    if (programID && !publicOnly) {
      snapshot = this.firebaseDatabase.collection<Instructor>(
        'instructors',
        (ref) => ref.where('programsInstructing', 'array-contains', programID)
      );
    } else {
      snapshot = this.firebaseDatabase.collection<Instructor>(
        'instructors',
        (ref) =>
          ref
            .where('programsInstructing', 'array-contains', programID)
            .where('public', '==', true)
      );
    }
    return snapshot.snapshotChanges().pipe(
      map((item) => {
        const instructors: Instructor[] = [];
        item.map((a) => {
          const data = a.payload.doc.data() as Instructor;
          instructors.push(data as Instructor);
        });

        return instructors;
      })
    );
  }

  private getAllInstructorsCollection() {
    return this.firebaseDatabase.collection('instructors');
  }

  public getAllInstructors(): Observable<Instructor[]> {
    return this.getAllInstructorsCollection()
      .snapshotChanges()
      .pipe(
        map((item) => {
          const instructors: Instructor[] = [];
          item.map((a) => {
            const data = a.payload.doc.data() as Instructor;
            instructors.push(data as Instructor);
          });

          return instructors;
        })
      );
  }

  public getInstructor(uid: string): Observable<Instructor> {
    const instructor = this.firebaseDatabase
      .collection('instructors')
      .doc<Instructor>(uid)
      .valueChanges();
    return instructor;
  }

  public async insertProgramInstructors(
    programID: string,
    instructors: Instructor[]
  ) {
    // get the info for the instructors
    if (instructors && instructors.length > 0) {
      const newInstructorInfos = instructors.map((instructor) => {
        return {
          uid: instructor.uid,
          displayName: instructor.user.displayName,
          email: instructor.user.email,
          photoURL: instructor.user.photoURL,
        };
      });

      const newInstructorIds = instructors.map((instructor) => instructor.uid);

      // Add the program to each instructor
      for (const instructor of instructors) {
        await this.firebaseDatabase
          .collection('instructors')
          .doc(instructor.uid)
          .update({
            programsInstructing:
              firebase.firestore.FieldValue.arrayUnion(programID),
          });
      }
      // merge the arrays into the existing arrays on update
      await this.firebaseDatabase
        .collection('programs')
        .doc(programID)
        .update({
          instructors: firebase.firestore.FieldValue.arrayUnion(
            ...newInstructorInfos
          ),
          users: firebase.firestore.FieldValue.arrayUnion(...newInstructorIds),
          instructorIds: firebase.firestore.FieldValue.arrayUnion(
            ...newInstructorIds
          ),
        });
    }
  }

  public deleteProgramInstructor(
    programID: string,
    instructorID: string
  ): Promise<void> {
    return this.firebaseDatabase
      .collection('instructors')
      .doc(instructorID)
      .update({
        programsInstructing:
          firebase.firestore.FieldValue.arrayRemove(programID),
      });
  }

  async deleteProgramInstructors(opts: {
    program: Program;
    instructor: Instructor;
  }) {
    const { program, instructor } = opts;

    const instructorInfo: InstructorInfo = {
      uid: instructor.uid,
      displayName: instructor.user.displayName,
      email: instructor.user.email,
      photoURL: instructor.user.photoURL,
    };
    // apply the changes
    await this.firebaseDatabase
      .collection('programs')
      .doc(program.id)
      .update({
        instructors: firebase.firestore.FieldValue.arrayRemove(instructorInfo),
        users: firebase.firestore.FieldValue.arrayRemove(instructor.uid),
        instructorIds: firebase.firestore.FieldValue.arrayRemove(
          instructor.uid
        ),
      });

    await this.firebaseDatabase
      .collection('instructors')
      .doc(instructor.uid)
      .update({
        programsInstructing: firebase.firestore.FieldValue.arrayRemove(
          program.id
        ),
      });
  }

  public async addInstructor(user: UserInfo, instructor: Partial<Instructor>) {
    const newInstructor: Instructor = {
      created: firebase.firestore.Timestamp.now(),
      modified: firebase.firestore.Timestamp.now(),
      bio: instructor.bio || '',
      public: instructor.public || false,
      slug: instructor.slug || '',
      uid: instructor.uid,
      programsInstructing: instructor.programsInstructing || [],
      teachingRadiusMiles: instructor.teachingRadiusMiles || 0,
      teachesVirtually: instructor.teachesVirtually || false,
      location: instructor.location || '',
      user: {
        displayName: user.displayName,
        photoURL: user.photoURL,
        email: user.email,
        uid: user.uid,
      },
    };
    await this.firebaseDatabase
      .collection('instructors')
      .doc<Instructor>(newInstructor.uid)
      .set(newInstructor);
    return newInstructor.uid;
  }

  public updateInstructor(
    instructor: Instructor,
    user?: Partial<User>
  ): Observable<void> {
    const updatedInstructor: Partial<Instructor> = {
      modified: firebase.firestore.Timestamp.now(),
      programsInstructing: instructor.programsInstructing || [],
      location: instructor.location || '',
      bio: instructor.bio || '',
      teachingRadiusMiles: instructor.teachingRadiusMiles || 0,
      teachesVirtually: instructor.teachesVirtually || false,
      public: instructor.public,
      slug: instructor.slug || '',
    };

    if (user) {
      updatedInstructor.user = {
        displayName: user.displayName,
        photoURL: user.photoURL,
        email: user.email,
        uid: user.uid,
      };
    } else {
      updatedInstructor.user = {
        displayName: instructor.user.displayName,
        photoURL: instructor.user.photoURL,
        email: instructor.user.email,
        uid: user.uid,
      };
    }

    return from(
      this.firebaseDatabase
        .collection('instructors')
        .doc<Instructor>(instructor.uid)
        .update(updatedInstructor)
    );
  }

  public deleteInstructor(instructor: Instructor): Promise<void> {
    const instructorDocRef: firebase.firestore.DocumentReference =
      this.firebaseDatabase.firestore
        .collection('instructors')
        .doc(instructor.uid);

    const userDocRef: firebase.firestore.DocumentReference =
      this.firebaseDatabase.firestore.collection('users').doc(instructor.uid);
    return this.firebaseDatabase.firestore.runTransaction(function (
      transaction
    ) {
      return transaction
        .get(instructorDocRef)
        .then(function (instructorDoc) {
          if (!instructorDoc.exists) {
            throw new Error('Instructor does not exist!');
          }
        })
        .then(() => {
          // Remove instructor role
          transaction.set(
            userDocRef,
            { roles: { instructor: false } },
            { merge: true }
          );

          // Remove instructor document
          transaction.delete(instructorDocRef);
        });
    });
  }

  // search function
  public searchInstructors(
    start: string,
    end: string
  ): Observable<Instructor[]> {
    return this.firebaseDatabase
      .collection('/instructors', (ref) =>
        ref.orderBy('searchableName').limit(10).startAt(start).endAt(end)
      )
      .valueChanges()
      .pipe(map((instructors) => instructors as Instructor[]));
  }

  ngOnDestroy() {
    if (this.allSubs) {
      this.allSubs.unsubscribe();
    }
  }
}
