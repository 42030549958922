<card
  [title]="educator.firstName + ' ' + educator.lastName"
  [subTitle]="educator.specialty"
  [imageUrl]="educator.pictureUrl"
  [imageAlt]="educator.firstName + ' ' + educator.lastName"
  [detailUrl]="['/educators/' + educator.id]"
  [user]="user"
  [isGrid]="isGrid"
>
</card>
