import { Component, Input, OnInit } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import {
  faCircle,
  faToggleOff,
  faToggleOn
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-navbar-item',
  templateUrl: './navbar-item.component.html',
  styleUrls: ['./navbar-item.component.css']
})
export class NavbarItemComponent implements OnInit {
  @Input()
  dataItem: string;

  @Input()
  ariaLabel: string;

  @Input()
  faIcon: FaIconComponent;

  @Input()
  iconSpin = false;

  @Input()
  image: string;

  @Input()
  alt: string;

  @Input()
  isImageCircle = false;

  @Input()
  label: string;

  @Input()
  showLabel = true;

  @Input()
  toolTipText: string;

  @Input()
  isToggle = false;

  @Input()
  toggleState: boolean;

  faCircle = faCircle;
  faToggleOn = faToggleOn;
  faToggleOff = faToggleOff;

  computedStyles = {};

  constructor() {}

  ngOnInit(): void {
    if (this.isToggle && this.toggleState) {
      this.computedStyles['margin-left'] = '8px';
    }
  }
}
