import { MembershipLevel } from '../membership/membership-level.model';

export class Roles {
  user?: boolean; // Basic signed in person
  admin?: boolean; // Administrator
  editor?: boolean; // Can edit entities
  student?: boolean; // Enrolled in program/course
  educator?: boolean; // Oversees students at school or similar location
  parent?: boolean; // Guardian of students
  instructor?: boolean; // Codecraft instructor in charge of delivering course material
  partner?: boolean; // Oversees delivery of programs/courses along with instructors
  customer?: boolean; // A person who is paying for a program
  member?: MembershipLevel; // User who has a membership
}
