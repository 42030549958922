import firebase from 'firebase/compat/app';
import { Tag } from '../tag/tag.model';
import { User } from '../user/user.model';

type ProjectUserInfo = Pick<
  User,
  'uid' | 'displayName' | 'photoURL' | 'userName'
>;

/**
 * @classdesc Class representing a web or game project
 */

export class Project {
  /**
   * Unique ID string for this document generated by Firebase
   */
  id: string;

  /**
   * Timestamp of initial document creation
   */
  created: firebase.firestore.Timestamp;

  /**
   * Timestamp of the last time the document was updated
   */
  modified: firebase.firestore.Timestamp;

  /**
   * Signifies whether or not document is available for others to see
   */
  public: boolean;

  /**
   * User ID string that identifies the owner of the document
   */
  uid: string;

  /**
   * User ID that identifies the user who created the document
   */
  authid: string;

  /**
   * Name of the web project
   */
  name: string;

  /**
   * Unique ID string for web project that was forked
   *
   * It is an optional property
   */
  root?: string;

  /**
   * String used in URL instead of the id
   *
   * It is an optional property
   */
  slug?: string;

  /**
   * Determines cloud function loading status
   *
   * It is an optional property
   */
  completedLoad?: boolean;

  /**
   * Partial [user](/classes/User.html) class properties for use in in web project
   *
   * It is an optional property
   *
   * Copy of owner's display name from [user](/classes/User.html#displayName) class
   * `displayName?: string;`
   *
   * URL of owner's photo from [user](/classes/User.html#photoURL) class
   * `photoURL?: string;`
   *
   * Copy of owner userName [user](/classes/User.html#userName) class
   * `userName?: string;`
   *
   */
  user?: ProjectUserInfo;

  /**
   * The lock field prevents write operations in the db
   */
  lock: boolean;

  /**
   * Users currently viewing the project
   */
  currentActiveUsers: string[];

  /**
   * Users that have been given collaboration rights to this project
   */
  collaborators: string[];

  /**
   * Stores the readme file
   */
  readme: string;

  /**
   * Determines whether the project has featured status or not
   */
  featured: boolean;

  /**
   * Contains the tags for this web Project
   */
  tags?: Tag[];

  /**
   * Determines if collaboration is on or not
   */
  collabOn: boolean;
}
