<ng-container *ngIf="item && total >= 0">
  <div class="row align-items-center no-gutters p-1">
    <div class="col-12 col-xl-5">
      <a [routerLink]="['/programs/', item.program.id]">{{
        item.program.name
      }}</a>
    </div>

    <div class="col-12 col-md-6 col-xl-1">
      <label
        id="forQuantity"
        for="quantity"
        class="d-sm-inline d-xl-none badge badge-pill badge-primary"
        >Quantity
      </label>
      <ng-container *ngIf="maxSeats$ | async as maxSeats; else inputQuantity">
        <select
          #selectQuantity
          *ngIf="
            maxSeats &&
              maxSeatsDropdown &&
              maxSeatsArray &&
              maxSeats <= maxSeatsDropdown;
            else inputQuantity
          "
          class="d-inline-block ml-1 w-50"
          aria-label="Quantity"
          aria-describedby="forQuantity"
          id="quantity"
          name="quantity"
          [(ngModel)]="item.quantity"
          (ngModelChange)="updateCartQty($event)"
        >
          <option [value]="null">
            --- Choose Quantity ---
          </option>
          <option
            *ngFor="let quantityValue of maxSeatsArray"
            [value]="quantityValue"
            [selected]="item.quantity === quantityValue"
            >{{ quantityValue }}</option
          >
        </select>
      </ng-container>

      <ng-template #inputQuantity>
        <input
          #cartQuantity="ngModel"
          aria-label="Quantity"
          aria-describedby="forQuantity"
          id="quantity"
          name="quantity"
          class="d-inline-block ml-1 w-50"
          type="number"
          min="1"
          [max]="maxSeats$ | async"
          [customMin]="1"
          [customMax]="maxSeats$ | async"
          [value]="item.quantity"
          [(ngModel)]="item.quantity"
          (ngModelChange)="updateCartQty($event)"
        />
      </ng-template>
    </div>

    <div id="cost" class="col-12 col-md-3 col-xl-1">
      <span class="d-sm-inline d-xl-none badge badge-pill badge-primary"
        >Price</span
      >
      {{ item.program.stripe.price / 100 | currency }}
    </div>

    <div id="total" class="col-12 col-md-3 col-xl-1">
      <span class="d-sm-inline d-xl-none badge badge-pill badge-primary"
        >Total</span
      >
      {{ total / 100 | currency }}
    </div>

    <div class="col-12 col-xl-2">
      <span class="d-sm-inline d-xl-none badge badge-pill badge-primary"
        >Payment Freqeuncy</span
      >
      {{ item.program.stripe.frequency | titlecase }}
    </div>

    <div class="col-12 col-xl-2">
      <button
        type="button"
        class="btn btn-sm btn-danger float-right"
        (click)="deleteCartItem()"
      >
        <small>Remove Item</small>
      </button>
    </div>
  </div>
  <div *ngIf="cartQuantity" class="row align-items-center mb-2">
    <div
      [hidden]="cartQuantity.valid"
      class="col-12 col-md-6 offset-md-3 alert alert-danger"
    >
      Quantity must be a number between 1 and {{ maxSeats$ | async }}
    </div>
  </div>
</ng-container>
