import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-game-project-title-link-cell',
  templateUrl: './game-project-title-link-cell.component.html',
  styleUrls: ['./game-project-title-link-cell.component.css']
})
export class GameProjectTitleLinkCellComponent implements AgRendererComponent {
  name: string;
  id: string;
  projectType: string;

  constructor() {}

  refresh(params: ICellRendererParams): boolean {
    this.name = params.data.name;
    this.id = params.data.id;
    this.projectType = params.data.type;
    return true;
  }

  agInit(params: ICellRendererParams): void {
    this.name = params.data.name;
    this.id = params.data.id;
    this.projectType = params.data.type;
  }
}
