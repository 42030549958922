<card
  [title]="certificate.name"
  [showTitle]="true"
  [imageUrl]="certificate.pictureUrl"
  [imageAlt]="certificate.name"
  [detailUrl]="['/certificates/' + certificate.id]"
  [user]="user"
  [isGrid]="isGrid"
>
</card>
