import {
  WP_Object_Filter_Context,
  WP_Post,
  WP_Post_Comment_Status_Name
} from 'wp-types';
import { LearndashTopic } from './learndash-topic.model';

export class LearndashLesson implements WP_Post {
  /**
   * Unique ID  for this document generated by Wordpress
   */
  ID: number;

  topics: LearndashTopic[];

  attachedPDFs?: string[];

  textToSpeechAudioUrl?: string;

  // Wordpress
  post_content: string;
  post_title: string;
  $schema?: string;
  post_author: string;
  post_date: string;
  post_date_gmt: string;
  post_excerpt: string;
  post_status: string;
  comment_status: WP_Post_Comment_Status_Name;
  ping_status: WP_Post_Comment_Status_Name;
  post_password: string;
  post_name: string;
  to_ping: string;
  pinged: string;
  post_modified: string;
  post_modified_gmt: string;
  post_content_filtered: string;
  post_parent: number;
  guid: string;
  menu_order: number;
  post_type: string;
  post_mime_type: string;
  comment_count: string;
  filter: WP_Object_Filter_Context;
}
