import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import {
  CodecraftQuestion,
  CodecraftQuestionChoice,
  CodecraftQuiz,
  CodecraftQuizInstance,
} from '@codecraft-works/data-models';
import firebase from 'firebase/compat/app';
import { deleteField } from 'firebase/firestore';
import Papa from 'papaparse';
import { Observable, OperatorFunction, from, lastValueFrom } from 'rxjs';
import {
  buffer,
  debounceTime,
  filter,
  map,
  mergeMap,
  scan,
  switchMap,
} from 'rxjs/operators';

type RowDataType = {
  'Question Prompt': string;
  'Question Type': string;
  'Choice A': string;
  'Choice B': string;
  'Choice C': string;
  'Choice D': string;
  'Answer 1': string;
  'Answer 2': string;
  'Answer 3': string;
  'Answer 4': string;
  'Tag 1': string;
  'Tag 2': string;
  'Tag 3': string;
  'Tag 4': string;
  'Tag 5': string;
};

class QuizCSVParseError extends Error {
  constructor(message) {
    super(message);
    this.name = 'QuizCSVParseError';
  }
}

type BufferDebounce = <T>(debounce: number) => OperatorFunction<T, T[]>;
const bufferDebounce: BufferDebounce = (debounce) => (source) =>
  new Observable((observer) =>
    source.pipe(buffer(source.pipe(debounceTime(debounce)))).subscribe({
      next(x) {
        observer.next(x);
      },
      error(err) {
        observer.error(err);
      },
      complete() {
        observer.complete();
      },
    })
  );

@Injectable()
export class QuizService {
  constructor(private firebaseDatabase: AngularFirestore) {}

  /**
   * Get a quiz document
   * @param quizId string
   * @returns Observable<DocumentCodecraftQuiz>
   */
  public getQuizDocument(quizId: string): Observable<CodecraftQuiz> {
    return this.getAllQuizzesCollection()
      .doc(quizId)
      .snapshotChanges()
      .pipe(
        filter((item) => item.payload.exists),
        map((item) => {
          return item.payload.data();
        })
      );
  }

  public getQuizQuestions(
    quizId: string
  ): Observable<Map<string, CodecraftQuestion>> {
    return this.getAllQuizzesCollection()
      .doc(quizId)
      .snapshotChanges()
      .pipe(
        filter((item) => item.payload.exists),
        switchMap((item) => {
          const quizDocument = item.payload.data();
          const questions = Object.keys(quizDocument.questions);
          return from(questions);
        }),
        mergeMap((questionId) => {
          return this.firebaseDatabase
            .collection<CodecraftQuestion>('quiz-question')
            .doc(questionId)
            .snapshotChanges();
        }),
        map((item) => {
          if (!item.payload.exists) {
            return { id: item.payload.id, question: null };
          }
          const questionDocument = item.payload.data();
          return {
            id: item.payload.id,
            question: this.convertToCodecraftQuestion(questionDocument),
          };
        }),
        bufferDebounce(1000),
        scan(
          (
            acc: Map<string, CodecraftQuestion>,
            questionStates: {
              id: string;
              question: CodecraftQuestion | null;
            }[]
          ) => {
            for (const qState of questionStates) {
              if (qState.question === null) {
                acc.delete(qState.id);
              } else {
                acc.set(qState.id, qState.question);
              }
            }
            return acc;
          },
          new Map<string, CodecraftQuestion>()
        )
      );
  }

  /**
   * Converts a DocumentCodecraftQuestion to a CodecraftQuestion
   * @param question DocumentCodecraftQuestion
   * @returns CodecraftQuestion
   */
  private convertToCodecraftQuestion(
    question: CodecraftQuestion
  ): CodecraftQuestion {
    if (question.type === 'boolean') {
      return question;
    } else if (question.type === 'multiple') {
      return {
        ...question,
        answer: new Map(Object.entries(question.answer)),
        choices: new Map(Object.entries(question.choices)),
      };
    } else {
      return {
        ...question,
        choices: new Map(Object.entries(question.choices)),
      };
    }
  }

  /**
   * Converts a CodecraftQuestion to a DocumentCodecraftQuestion
   * @param question CodecraftQuestion
   * @returns DocumentCodecraftQuestion
   */
  private convertToDocumentCodecraftQuestion(
    question: CodecraftQuestion
  ): CodecraftQuestion {
    const docChoices: CodecraftQuestionChoice =
      question.choices instanceof Map
        ? Object.fromEntries(question.choices)
        : question.choices;
    switch (question.type) {
      case 'boolean':
        return { ...question, choices: {} };
      case 'single':
        return {
          ...question,
          choices: docChoices,
        };
      case 'multiple':
        if (question.answer instanceof Map)
          return {
            ...question,
            choices: docChoices,
            answer: Object.fromEntries(question.answer),
          };
        throw new Error('Answer must be a Map');
    }
  }

  /**
   * Get all the quizzes
   */
  private getAllQuizzesCollection(): AngularFirestoreCollection<CodecraftQuiz> {
    return this.firebaseDatabase.collection<CodecraftQuiz>('quiz');
  }

  /**
   * Add a new quiz
   * @param quizName string
   * @returns Promise<string>
   */
  public async addQuiz(quizName: string): Promise<string> {
    const quizId = this.firebaseDatabase.collection('quiz').doc().ref.id;
    await this.firebaseDatabase
      .collection<CodecraftQuiz>('quiz')
      .doc(quizId)
      .set({
        id: quizId,
        name: quizName,
        maxAttempts: -1,
        passingScore: 0,
        questions: {},
        public: true,
        created: firebase.firestore.Timestamp.now(),
        modified: firebase.firestore.Timestamp.now(),
      });
    return quizId;
  }

  /**
   * Delete a quiz
   * @param quizId string
   * @returns Promise<boolean>
   */
  public async deleteQuiz(quizId: string): Promise<boolean> {
    try {
      if (
        !(
          await this.firebaseDatabase
            .collection('quiz-instance')
            .ref.where('quizId', '==', quizId)
            .get()
        ).empty
      ) {
        console.error('Quiz has instances, cannot delete');
        return false;
      }

      const batch = this.firebaseDatabase.firestore.batch();
      const quizDoc = this.firebaseDatabase
        .collection<CodecraftQuiz>('quiz')
        .doc(quizId);
      const quizQuestions = (await lastValueFrom(quizDoc.get())).data()
        .questions;
      const questionIds = Object.keys(quizQuestions);
      questionIds.forEach((questionId) => {
        const questionDoc = this.firebaseDatabase
          .collection<CodecraftQuestion>('quiz-question')
          .doc(questionId);
        batch.delete(questionDoc.ref);
      });
      batch.delete(quizDoc.ref);

      await batch.commit();

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  /**
   * Check if a quiz has an instance
   * @param quizId string
   * @returns Observable<boolean>
   */
  public hasQuizInstance(quizId: string): Observable<boolean> {
    return from(
      this.firebaseDatabase
        .collection('quiz-instance')
        .ref.where('quizId', '==', quizId)
        .get()
        .then((querySnapshot) => !querySnapshot.empty)
    );
  }

  /**
   * create a question
   * @param question CodecraftQuestion
   * @returns Promise<{success: boolean; error?: string}>
   */
  public async createQuestion(
    quizId: string,
    question: CodecraftQuestion
  ): Promise<{ success: boolean; error?: string }> {
    try {
      const docQuestion = this.convertToDocumentCodecraftQuestion(question);
      const tags: string[] = docQuestion.tags || [];
      const questionId = this.firebaseDatabase.collection('quiz-question').doc()
        .ref.id;
      await this.firebaseDatabase
        .collection('quiz-question')
        .doc(questionId)
        .set({
          ...docQuestion,
          id: questionId,
          ...(tags.length && { tags }),
          created: firebase.firestore.Timestamp.now(),
          modified: firebase.firestore.Timestamp.now(),
        });
      await this.firebaseDatabase
        .collection<CodecraftQuiz>('quiz')
        .doc(quizId)
        .update({
          ['questions.' + questionId]: null,
          modified: firebase.firestore.Timestamp.now(),
        });
      return { success: true };
    } catch (error) {
      console.error(error);
      return { success: false, error: error };
    }
  }

  /**
   * Update a question
   * @param question CodecraftQuestion
   * @returns Promise<{success: boolean; error?: string}>
   */
  public async updateQuestion(
    question: CodecraftQuestion
  ): Promise<{ success: boolean; error?: string }> {
    try {
      const docQuestion = this.convertToDocumentCodecraftQuestion(question);
      const tags: string[] = docQuestion.tags || [];
      await this.firebaseDatabase
        .collection('quiz-question')
        .doc(question.id)
        .update({
          ...docQuestion,
          tags: tags.length ? tags : deleteField(),
          modified: firebase.firestore.Timestamp.now(),
        });
      return { success: true };
    } catch (error) {
      return { success: false, error: error };
    }
  }

  /**
   * Delete a question
   * @param questionId string
   * @returns Promise<{success: boolean; error?: string}>
   */
  public async deleteQuestion(
    questionId: string
  ): Promise<{ success: boolean; error?: string }> {
    try {
      const batch = this.firebaseDatabase.firestore.batch();
      const quizDocs = await this.firebaseDatabase
        .collection<CodecraftQuiz>('quiz')
        .ref.orderBy('questions.' + questionId)
        .get();
      quizDocs.forEach((doc) => {
        const quizDoc = this.firebaseDatabase
          .collection<CodecraftQuiz>('quiz')
          .doc(doc.id);
        batch.update(quizDoc.ref, {
          ['questions.' + questionId]: deleteField(),
          modified: firebase.firestore.Timestamp.now(),
        });
      });
      const questionDoc = this.firebaseDatabase
        .collection<CodecraftQuestion>('quiz-question')
        .doc(questionId);
      batch.delete(questionDoc.ref);

      await batch.commit();

      return { success: true };
    } catch (error) {
      return { success: false, error: error };
    }
  }

  /**
   * Update a quiz
   * @param quiz CodecraftQuiz
   */
  public async updateQuiz(options: {
    quizId: string;
    quizName: string;
    maxAttempts: number;
    passingScore: number;
    quizCsv?: string;
  }): Promise<{ success: boolean; error?: string }> {
    const { quizId, quizName, maxAttempts, passingScore, quizCsv } = options;

    if (!quizCsv) {
      await this.firebaseDatabase.collection('quiz').doc(quizId).update({
        name: quizName,
        maxAttempts,
        passingScore,
        modified: firebase.firestore.Timestamp.now(),
      });
      return { success: true };
    }
    try {
      const csvJson = await this.convertCsvToJson(quizCsv);
      const questions = this.formatJsonData(csvJson);

      const batch = this.firebaseDatabase.firestore.batch();
      const questionIds: string[] = [];
      questions.forEach((question) => {
        const questionId = this.firebaseDatabase
          .collection('quiz-question')
          .doc().ref.id;
        const questionRef = this.firebaseDatabase
          .collection<CodecraftQuestion>('quiz-question')
          .doc(questionId).ref;

        batch.set(questionRef, { ...question, id: questionId });
        questionIds.push(questionId);
      });
      const quizRef = this.firebaseDatabase
        .collection<CodecraftQuiz>('quiz')
        .doc(quizId).ref;
      const questionsObj: Record<string, null> = {};
      questionIds.forEach((questionId) => {
        questionsObj[questionId] = null;
      });
      batch.update(quizRef, {
        name: quizName,
        maxAttempts,
        passingScore,
        modified: firebase.firestore.Timestamp.now(),
        questions: questionsObj,
      });

      await batch.commit();
      return { success: true };
    } catch (error) {
      return { success: false, error: error };
    }
  }

  /**
   * Get a quizzes results
   * @param quizId string
   * @returns Observable<CodecraftResult[]>
   */
  public getQuizResults(quizId: string): Observable<CodecraftQuizInstance[]> {
    return this.firebaseDatabase
      .collection<CodecraftQuizInstance>('quiz-instance', (ref) =>
        ref.where('quizId', '==', quizId).where('completed', '==', true)
      )
      .snapshotChanges()
      .pipe(
        map((results) => {
          return results.map((result) => {
            return result.payload.doc.data();
          });
        })
      );
  }

  private async convertCsvToJson(csvString: string): Promise<RowDataType[]> {
    return new Promise<RowDataType[]>((resolve) => {
      Papa.parse<RowDataType>(csvString, {
        header: true,
        complete: function (results) {
          resolve(results.data);
        },
      });
    });
  }

  private formatJsonData(jsonData: RowDataType[]): Array<CodecraftQuestion> {
    return jsonData.map((row) => {
      // create choices object
      const choices: CodecraftQuestionChoice = {};
      if (row['Choice A']) {
        choices['A'] = row['Choice A'];
      }
      if (row['Choice B']) {
        choices['B'] = row['Choice B'];
      }
      if (row['Choice C']) {
        choices['C'] = row['Choice C'];
      }
      if (row['Choice D']) {
        choices['D'] = row['Choice D'];
      }

      // create answers array
      const answers: string[] = [];
      if (row['Answer 1']) {
        answers.push(row['Answer 1']);
      }
      if (row['Answer 2']) {
        answers.push(row['Answer 2']);
      }
      if (row['Answer 3']) {
        answers.push(row['Answer 3']);
      }
      if (row['Answer 4']) {
        answers.push(row['Answer 4']);
      }

      // create tags array
      const tags: string[] = [];
      if (row['Tag 1']) {
        tags.push(row['Tag 1']);
      }
      if (row['Tag 2']) {
        tags.push(row['Tag 2']);
      }
      if (row['Tag 3']) {
        tags.push(row['Tag 3']);
      }
      if (row['Tag 4']) {
        tags.push(row['Tag 4']);
      }
      if (row['Tag 5']) {
        tags.push(row['Tag 5']);
      }

      // error checking

      // prompt is required
      if (!row['Question Prompt']) {
        throw new QuizCSVParseError('Question prompt is required');
      }
      // question type checks
      if (!row['Question Type']) {
        throw new QuizCSVParseError('Question type is required');
      }
      if (!['single', 'boolean', 'multiple'].includes(row['Question Type'])) {
        throw new QuizCSVParseError(
          'Question type must be single, boolean, or multiple'
        );
      }

      // question choices checks
      if (
        row['Question Type'] === 'boolean' &&
        Object.keys(choices).length > 1
      ) {
        throw new QuizCSVParseError('Boolean question cannot have choices');
      }
      if (
        row['Question Type'] === 'single' &&
        Object.keys(choices).length !== 4
      ) {
        throw new QuizCSVParseError('Single question must have 4 choices');
      }
      if (
        row['Question Type'] === 'multiple' &&
        Object.keys(choices).length !== 4
      ) {
        throw new QuizCSVParseError('Multiple question must have 4 choices');
      }

      // question answer checks
      if (row['Question Type'] === 'boolean' && answers.length != 1) {
        throw new QuizCSVParseError('Boolean question must have 1 answer');
      }
      if (
        row['Question Type'] === 'boolean' &&
        !['true', 'false'].includes(answers[0].toLowerCase())
      ) {
        throw new QuizCSVParseError(
          'Boolean question answer must be true or false'
        );
      }

      if (row['Question Type'] === 'single' && answers.length != 1) {
        throw new QuizCSVParseError('Single question must have 1 answer');
      }
      if (
        row['Question Type'] === 'single' &&
        !Object.keys(choices).includes(answers[0].toUpperCase())
      ) {
        throw new QuizCSVParseError(
          'Single question answer must be one of the choices'
        );
      }

      if (
        row['Question Type'] === 'multiple' &&
        (answers.length > 4 || answers.length < 1)
      ) {
        throw new QuizCSVParseError(
          'Multiple question must have >= 1 and <= 4 answers'
        );
      }
      if (
        row['Question Type'] === 'multiple' &&
        !answers.every((answer) => Object.keys(choices).includes(answer))
      ) {
        throw new QuizCSVParseError(
          'Multiple question answer must be one of the choices'
        );
      }

      // tags checks
      if (tags.length > 5) {
        throw new QuizCSVParseError('Question can only have up to 5 tags');
      }

      // create question object
      const questionBase = {
        id: '',
        prompt: row['Question Prompt'],
        choices,
        public: true,
        created: firebase.firestore.Timestamp.now(),
        modified: firebase.firestore.Timestamp.now(),
      };
      if (row['Question Type'] === 'boolean') {
        const answer = answers[0].toLowerCase() === 'true' ? true : false;
        const question: CodecraftQuestion = {
          ...questionBase,
          type: 'boolean',
          answer: answer,
        };
        if (tags.length > 0) {
          question.tags = tags;
        }

        return question;
      }
      if (row['Question Type'] === 'single') {
        const question: CodecraftQuestion = {
          ...questionBase,
          type: 'single',
          answer: answers[0],
        };
        if (tags.length > 0) {
          question.tags = tags;
        }

        return question;
      }
      const answerRecord: Record<string, boolean> = {};
      answers.forEach((answer) => {
        answerRecord[answer] = true;
      });
      Object.keys(choices)
        .filter((choice) => !answers.includes(choice))
        .forEach((choice) => {
          answerRecord[choice] = false;
        });

      const question: CodecraftQuestion = {
        ...questionBase,
        type: 'multiple',
        answer: answerRecord,
      };
      if (tags.length > 0) {
        question.tags = tags;
      }

      return question;
    });
  }
}
