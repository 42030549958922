<div class="container-fluid">
  <section *ngIf="userName" id="web-projects">
    <h2 class="col-12 mt-3 pb-2">Web Projects by {{ userName }}</h2>
    <app-grid
      [publicOnly]="true"
      path="web-projects"
      field="modified"
      [reverse]="true"
      [limit]="8"
      [isEqual]="{ field: 'user.userName', argument: userName }"
      entityName="Project"
    >
    </app-grid>
  </section>
</div>
