import { AbstractControl } from '@angular/forms';
import { UserService } from '@app/core/user/user.service';
import { map } from 'rxjs/operators';

export class ValidateUserNameNotTaken {
  static createValidator(userService: UserService) {
    return (control: AbstractControl) => {
      return userService.checkUserNameNotTaken(control.value).pipe(
        map(result => {
          return result ? null : { userNameTaken: true };
        })
      );
    };
  }
}
