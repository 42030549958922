<span>
  <a
    href="https://www.codecraftworks.com"
    target="_blank"
  >
    <img
      src="/assets/img/codecraft-works-white-gears-animated.svg"
      alt="Codecraft Works"
      id="logo-navbar-item"
      class="d-none d-md-block"
    />
  </a>

  <a routerLink="/">
    <app-navbar-item
      dataItem="dashboard-navbar-item"
      ariaLabel="Dashboard"
      label="Dashboard"
      [faIcon]="faTachometerAlt"
      [showLabel]="showLabels"
    >
    </app-navbar-item>
  </a>

  <a routerLink="/programs">
    <app-navbar-item
      dataItem="programs-navbar-item"
      ariaLabel="Programs"
      label="Programs"
      [faIcon]="faChalkboardTeacher"
      [showLabel]="showLabels"
    >
    </app-navbar-item>
  </a>

  <a routerLink="/web">
    <app-navbar-item
      dataItem="web-projects-navbar-item"
      ariaLabel="Web Projects"
      label="Web Projects"
      [faIcon]="faFileCode"
      [showLabel]="showLabels"
    >
    </app-navbar-item>
  </a>

  <a routerLink="/games">
    <app-navbar-item
      dataItem="programs-navbar-item"
      ariaLabel="Games"
      label="Games"
      [faIcon]="faPuzzlePiece"
      [showLabel]="showLabels"
    >
    </app-navbar-item>
  </a>

  <a
    href="https://codecraftworks.com/help"
    target="_blank"
  >
    <app-navbar-item
      dataItem="help-navbar-item"
      ariaLabel="Help"
      label="Help"
      [faIcon]="faQuestionCircle"
      [showLabel]="showLabels"
    >
    </app-navbar-item>
  </a>
</span>

<span>
  <ng-container *ngIf="(user$ | async) as user; else login">
    <ng-container *ngIf="user && user.roles && user.roles.admin">
      <a routerLink="/admin">
        <app-navbar-item
          dataItem="admin-navbar-item"
          ariaLabel="Admin"
          label="Admin"
          [faIcon]="faUserShield"
          [showLabel]="showLabels"
        >
        </app-navbar-item>
      </a>
    </ng-container>

    <ng-container *ngIf="(cart$ | async) as cart">
      <a routerLink="/cart">
        <app-navbar-item
          dataItem="cart-navbar-item"
          ariaLabel="Shopping Cart"
          label="Cart"
          [faIcon]="faShoppingCart"
          [showLabel]="showLabels"
        >
          <small *ngIf="cart.length > 0">
            <div class="badge badge-light d-inline ml-1">
              {{ cart.length }}
            </div>
          </small>
        </app-navbar-item>
      </a>
    </ng-container>

    <a routerLink="/user">
      <app-navbar-item
        dataItem="user-account-navbar-item"
        ariaLabel="User Account"
        label="Account"
        [faIcon]="faUserCog"
        [showLabel]="showLabels"
      >
      </app-navbar-item>
    </a>

    <app-navbar-item
      dataItem="logout-navbar-item"
      ariaLabel="Log Out"
      label="Log Out"
      [faIcon]="faSignOutAlt"
      [showLabel]="showLabels"
      (click)="logOut()"
      (keydown.enter)="logOut()"
    >
    </app-navbar-item>

    <a [routerLink]="user.userName ? '/users/' + user.userName : null">
      <app-navbar-item
        dataItem="user-page-navbar-item"
        ariaLabel="User Page"
        [label]="user.displayName || 'User Page'"
        [alt]="['Photo of ' + user.displayName]"
        [image]="user.photoURL"
        [isImageCircle]="true"
        [showLabel]="showLabels"
      >
      </app-navbar-item>
    </a>
  </ng-container>
  <ng-template #login>
    <a routerLink="/login">
      <app-navbar-item
        dataItem="login-navbar-item"
        ariaLabel="Log In"
        label="Log In"
        [faIcon]="faSignInAlt"
        [showLabel]="showLabels"
      >
      </app-navbar-item>
    </a>
  </ng-template>
</span>
