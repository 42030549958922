import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { K12Student, Student, User } from '@codecraft-works/data-models';
import firebase from 'firebase/compat/app';
import { Observable, Subscription, from, map } from 'rxjs';

@Injectable()
export class StudentService {
  allSubs: Subscription = new Subscription();

  constructor(private firebaseDatabase: AngularFirestore) {}
  /**
   * Get Observable array of all Students
   */
  public getAllStudents(): Observable<Student[]> {
    return this.firebaseDatabase.collection<Student>('students').valueChanges();
  }

  /**
   * Get Observable array of all Students
   */
  public getNonK12Students(): Observable<Student[]> {
    return this.firebaseDatabase
      .collection('students')
      .valueChanges()
      .pipe(
        map((students) => {
          return students.filter((student) => {
            return !Object.prototype.hasOwnProperty.call(student, 'entityId');
          }) as unknown as Student[];
        })
      );
  }

  /**
   * Get Observable array of Students by Program Id
   * @param programId
   */
  public getStudentsByProgram(programID: string): Observable<Student[]> {
    return this.firebaseDatabase
      .collection<Student>('students', (ref) =>
        ref.where('enrolledPrograms', 'array-contains', programID)
      )
      .valueChanges();
  }

  /**
   * Get the Student Document
   */
  private getStudentData(student: Student) {
    return this.firebaseDatabase
      .collection<Student>('students')
      .doc<Student>(student.uid);
  }

  /**
   * Get the Student
   * @param id
   */
  public getStudent(id: string): Observable<Student> {
    const student = this.firebaseDatabase
      .collection('students')
      .doc<Student>(id)
      .valueChanges();
    return student;
  }

  /**
   * Insert the Student
   * @param user
   * @param student
   */
  public async insertStudent(user: Partial<User>, student: Partial<Student>) {
    const newStudent: Student = {
      id: student.uid,
      gradeLevel: student.gradeLevel || 0,
      enrolledPrograms: student.enrolledPrograms || [],
      created: firebase.firestore.Timestamp.now(),
      modified: firebase.firestore.Timestamp.now(),
      slug: student.slug || '',
      uid: student.uid,
      user: {
        displayName: user.displayName,
        photoURL: user.photoURL,
        email: user.email,
        uid: user.uid,
        userName: user.userName || null,
      },
    };

    await this.firebaseDatabase
      .collection<Student>('students')
      .doc(newStudent.id)
      .set(newStudent);

    return newStudent;
  }

  public async insertEnrolledProgram(programID: string, studentID: string) {
    await this.firebaseDatabase
      .collection('students')
      .doc(studentID)
      .update({
        enrolledPrograms: firebase.firestore.FieldValue.arrayUnion(programID),
      });
  }

  public deleteEnrolledProgram(programID: string, studentID: string) {
    this.firebaseDatabase
      .collection('students')
      .doc(studentID)
      .update({
        enrolledPrograms: firebase.firestore.FieldValue.arrayRemove(programID),
      });
  }

  /**
   * Update the Student
   * @param user
   * @param student
   */
  public updateStudent(
    student: Student,
    user?: Partial<User>
  ): Observable<void> {
    const updatedStudent: Partial<Student> = {
      gradeLevel: student.gradeLevel,
      enrolledPrograms: student.enrolledPrograms,
      modified: firebase.firestore.Timestamp.now(),
      slug: student.slug,
    };

    if (user) {
      updatedStudent.user = {
        displayName: user.displayName,
        photoURL: user.photoURL,
        email: user.email,
        uid: user.uid,
        userName: user.userName || null,
      };
    } else {
      updatedStudent.user = {
        displayName: student.user.displayName,
        photoURL: student.user.photoURL,
        email: student.user.email,
        uid: user.uid,
        userName: student.user.userName || null,
      };
    }

    return from(
      this.firebaseDatabase
        .collection('students')
        .doc<Student>(student.uid)
        .update(updatedStudent)
    );
  }

  /**
   * Update the Student Grade Level
   * @param studentId
   * @param gradeLevel
   */
  public updateStudentGradeLevel(
    studentId: string,
    gradeLevel: number
  ): Observable<void> {
    return from(
      this.firebaseDatabase.collection('students').doc(studentId).update({
        gradeLevel: gradeLevel,
      })
    );
  }

  /**
   * Delete the Student by student object from the Firestore
   * @param student
   */
  public deleteStudent(student: Student): Observable<void> {
    if (student) {
      return from(this.getStudentData(student).delete());
    } else {
      throw new Error("Can't delete without a key.");
    }
  }

  /**
   * Delete the Student by studentId from the Firestore
   * @param studentid
   */
  public deleteStudentbyId(studentId: string) {
    if (studentId) {
      return from(
        this.firebaseDatabase.collection('students').doc(studentId).delete()
      );
    } else {
      throw new Error("Can't delete without a key.");
    }
  }

  /**
   * Get Observable array of Students by Program Id
   * @param programId
   */
  public getK12StudentsByEntity(options: {
    entityId: string;
    entityType: 'program' | 'classroom';
  }): Observable<K12Student[]> {
    const snapshot: AngularFirestoreCollection<K12Student> =
      this.firebaseDatabase.collection<K12Student>('students', (ref) =>
        ref
          .where('entityId', '==', options.entityId)
          .where('entityType', '==', options.entityType)
      );

    return snapshot.valueChanges();
  }
}
