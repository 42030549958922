import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { InstructorService } from '@app/shared/instructor/instructor.service';
import { Instructor, User } from '@codecraft-works/data-models';
import {
  faClock,
  faHome,
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {
  title = 'Codecraft Works - Home';
  user$: Observable<User>;
  instructor$: Observable<Instructor>;
  faHome = faHome;
  faClock = faClock;
  faPlusCircle = faPlusCircle;

  availablePrograms = {
    field: 'archive',
    argument: false
  };

  now = new Date();

  currentAndFuturePrograms = {
    field: 'timeAndDate.endDateTime',
    argument: this.now
  };

  pastPrograms = {
    field: 'timeAndDate.endDateTime',
    argument: this.now
  };

  myPrograms$: Observable<{ field: string; argument: string }>;

  constructor(
    private authService: AuthService,
    private titleService: Title,
    private instructorService: InstructorService
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.user$ = this.authService.getUser();
    this.myPrograms$ = this.user$.pipe(
      map(user => {
        return {
          field: 'users',
          argument: user.uid
        };
      })
    );
    this.instructor$ = this.user$.pipe(
      switchMap(user => this.instructorService.getInstructor(user.uid))
    );
  }

  handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).style.display = 'none';
  }
}
