<div id="editorHeader" class="text-center">
  <fa-icon *ngIf="icon" [icon]="icon"></fa-icon> {{ title }}
</div>
<div #editorContainer class="editor" id="#editorContainer">
  <ngx-blockly
    *ngIf="language === 'blockly'"
    [config]="config"
    [customBlocks]="customBlocks"
    (pythonCode)="compilePythonCode($event)"
    (resize)="(resize)"
  ></ngx-blockly>
</div>
