<ng-container *ngIf="(user$ | async) as user">
  <form
    *ngIf="!selectionMade && loadingComplete; else userSelected"
    [formGroup]="userTypeaheadFormGroup"
  >
    <input
      role="combobox"
      autocomplete="off"
      type="text"
      class="form-control"
      name="selectUser"
      formControlName="selectUser"
      aria-label="User Typeahead Select"
      [isAnimated]="true"
      [typeaheadAsync]="true"
      [typeahead]="dataSource$"
      [typeaheadOptionsLimit]="10"
      [typeaheadMinLength]="2"
      [typeaheadItemTemplate]="itemTemplate"
      [typeaheadScrollable]="true"
      [typeaheadOptionsInScrollableView]="5"
      placeholder="Start typing to find user"
      (typeaheadOnSelect)="onUserSelect($event)"
      editable="false"
    />
    <small class="form-text text-muted">
      Select any User on the platform. Search by Display Name, Username, or
      Email Address
    </small>
  </form>
</ng-container>

<ng-template #itemTemplate let-model="item" let-index="index">
  <div class="d-flex align-items-center">
    <img
      *ngIf="model.photoURL"
      class="rounded-circle"
      [src]="model.photoURL"
      [alt]="model.displayName"
    />
    <div class="ml-2 text-break">
      {{
        model.displayName +
          ' | ' +
          (model.userName ? model.userName + ' | ' : ' ') +
          model.email
      }}
    </div>
  </div>
</ng-template>

<ng-template #userSelected>
  <div class="d-flex align-items-center" *ngIf="selectionMade">
    <img
      *ngIf="selectUser.value.photoURL"
      class="rounded-circle"
      [src]="selectUser.value.photoURL"
      [alt]="selectUser.value.displayName"
    />
    <div class="ml-2 text-break">
      {{
        selectUser.value.displayName +
          ' | ' +
          (selectUser.value.userName
            ? selectUser.value.userName + ' | '
            : ' ') +
          selectUser.value.email
      }}
    </div>
    <button class="ml-2 btn btn-primary" (click)="chooseAnotherUser()">
      Choose Another User
    </button>
  </div>
</ng-template>
