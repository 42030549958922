import { Component, TemplateRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ProgramService } from '@app/shared/program/program.service';
import { K12Student } from '@codecraft-works/data-models';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faEdit,
  faToggleOff,
  faToggleOn,
} from '@fortawesome/free-solid-svg-icons';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-k12student-edit-button-cell',
  standalone: true,
  template: `
    <button (click)="openModal()" type="button" class="btn btn-primary d-block">
      <fa-icon [icon]="faEdit" title="Edit"></fa-icon>
    </button>

    <ng-template #editK12StudentModal>
      <div class="modal-header bg-primary text-light">
        <p class="modal-title pull-left lead">Edit Student Account</p>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="this.modalRef.hide()"
        >
          <span aria-hidden="true" class="text-light">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group row">
          <label for="studentUserName" class="col-sm-3 col-form-label"
            >User Name
          </label>
          <div class="col-sm-9">
            <input
              id="studentUserName"
              type="text"
              class="form-control disabled"
              disabled
              [value]="student.userName"
            />
          </div>
        </div>

        <form class="form-group" [formGroup]="editStudentForm">
          <div class="form-group row">
            <label for="studentPassword" class="col-sm-3 col-form-label"
              >Password</label
            >
            <div class="col-sm-9">
              <div class="input-group">
                <input
                  id="studentPassword"
                  type="password"
                  class="form-control"
                  formControlName="studentPassword"
                  aria-describedby="studentPasswordHelp"
                  [ngClass]="{
                    'is-invalid':
                      editStudentForm.get('studentPassword').invalid &&
                      editStudentForm.get('studentPassword').dirty,
                    'is-valid':
                      editStudentForm.get('studentPassword').valid &&
                      editStudentForm.get('studentPassword').dirty
                  }"
                  placeholder="Enter a new password for the student."
                />
                <div class="input-group-append flex-column">
                  <span class="input-group-text">
                    <fa-icon
                      [icon]="toggleState ? faToggleOn : faToggleOff"
                      [fixedWidth]="true"
                      size="1x"
                      (click)="showHidePassword()"
                    >
                    </fa-icon>
                    <small>
                      {{ toggleState ? 'Hide' : 'Show' }}
                    </small>
                  </span>
                </div>
              </div>
              <small id="studentPasswordHelp" class="form-text text-muted">
                While we can't show you the existing password, you may enter a
                new password for the student.
              </small>
            </div>
          </div>

          <div class="form-group row">
            <label for="studentDisplayName" class="col-sm-3 col-form-label"
              >Display Name</label
            >
            <div class="col-sm-9">
              <input
                id="studentDisplayName"
                type="text"
                class="form-control"
                formControlName="studentDisplayName"
                aria-describedby="studentDisplayNameHelp"
                [ngClass]="{
                  'is-invalid':
                    editStudentForm.get('studentDisplayName').invalid &&
                    editStudentForm.get('studentDisplayName').dirty,
                  'is-valid': editStudentForm.get('studentDisplayName').valid
                }"
                placeholder="Enter a display name for the student."
              />
              <small id="studentDisplayNameHelp" class="form-text text-muted">
                The display name is used throughout the app to personalize the
                student's work. It may be their real name or a pen name. It is
                not necessarily unique.
              </small>
            </div>
          </div>

          <div class="form-group row">
            <label for="studentGrade" class="col-sm-3 col-form-label"
              >Grade Level</label
            >
            <div class="col-sm-9">
              <select
                formControlName="studentGrade"
                id="studentGrade"
                class="form-control"
                [ngClass]="{
                  'is-invalid':
                    editStudentForm.get('studentGrade').invalid &&
                    editStudentForm.get('studentGrade').dirty,
                  'is-valid': editStudentForm.get('studentGrade').valid
                }"
                aria-describedby="studentGradeHelp"
              >
                <option [value]="null">--- Choose a grade level ---</option>
                <option [selected]="this.student.gradeLevel === 'K'" value="K">
                  K
                </option>
                <option [selected]="this.student.gradeLevel === '1'" value="1">
                  1
                </option>
                <option [selected]="this.student.gradeLevel === '2'" value="2">
                  2
                </option>
                <option [selected]="this.student.gradeLevel === '3'" value="3">
                  3
                </option>
                <option [selected]="this.student.gradeLevel === '4'" value="4">
                  4
                </option>
                <option [selected]="this.student.gradeLevel === '5'" value="5">
                  5
                </option>
                <option [selected]="this.student.gradeLevel === '6'" value="6">
                  6
                </option>
                <option [selected]="this.student.gradeLevel === '7'" value="7">
                  7
                </option>
                <option [selected]="this.student.gradeLevel === '8'" value="8">
                  8
                </option>
                <option [selected]="this.student.gradeLevel === '9'" value="9">
                  9
                </option>
                <option
                  [selected]="this.student.gradeLevel === '10'"
                  value="10"
                >
                  10
                </option>
                <option
                  [selected]="this.student.gradeLevel === '11'"
                  value="11"
                >
                  11
                </option>
                <option
                  [selected]="this.student.gradeLevel === '12'"
                  value="12"
                >
                  12
                </option>
              </select>
              <small id="studentGradeHelp" class="form-text text-muted"
                >Pick a grade level for the student
              </small>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          id="edit-account-button"
          class="btn btn-primary"
          [disabled]="editStudentForm.invalid || editStudentForm.untouched"
          (click)="updateK12Student()"
        >
          Edit Account
        </button>
        <button type="submit" class="btn btn-warning" (click)="modalRef.hide()">
          Dismiss
        </button>
      </div>
    </ng-template>
  `,
  styles: [],
  imports: [RouterModule, FontAwesomeModule, ReactiveFormsModule],
})
export class K12StudentEditButtonCellComponent implements AgRendererComponent {
  student: K12Student;

  faEdit = faEdit;
  faToggleOn = faToggleOn;
  faToggleOff = faToggleOff;

  @ViewChild('editK12StudentModal')
  editK12StudentModal: TemplateRef<any>;

  modalRef: BsModalRef;

  editStudentForm: FormGroup;

  studentPassword: FormControl;
  studentDisplayName: FormControl;
  studentGrade: FormControl;

  toggleState = false;

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private programService: ProgramService
  ) {}

  refresh(params: ICellRendererParams): boolean {
    this.student = params.data;
    return true;
  }
  agInit(params: ICellRendererParams): void {
    this.student = params.data;

    this.editStudentForm = this.formBuilder.group({
      studentPassword: [this.studentPassword, [Validators.minLength(4)]],
      studentDisplayName: [
        this.studentDisplayName,
        [Validators.required, Validators.minLength(3)],
      ],
      studentGrade: [
        this.studentGrade,
        [Validators.required, Validators.pattern('^(1[0-2]|[1-9]|K)$')],
      ],
    });

    this.editStudentForm.patchValue({
      studentDisplayName: this.student.user.displayName,
      studentGrade: this.student.gradeLevel,
    });
  }

  openModal() {
    this.modalRef = this.modalService.show(this.editK12StudentModal, {
      class: 'modal-lg',
      initialState: {
        student: this.student,
      },
    });
  }

  showHidePassword() {
    this.toggleState = !this.toggleState;
    const passwordField = document.getElementById('studentPassword');
    if (this.toggleState) {
      passwordField.setAttribute('type', 'text');
    } else {
      passwordField.setAttribute('type', 'password');
    }
  }

  async updateK12Student() {
    const updatedStudent: {
      password?: string;
      gradeLevel?: K12Student['gradeLevel'];
      displayName?: string;
      uid: string;
    } = {
      uid: this.student.uid,
    };

    if (
      this.editStudentForm.get('studentPassword').value &&
      this.editStudentForm.get('studentPassword').dirty
    ) {
      updatedStudent['password'] =
        this.editStudentForm.get('studentPassword').value;
    }

    if (
      this.editStudentForm.get('studentGrade').value &&
      this.editStudentForm.get('studentGrade').dirty
    ) {
      updatedStudent['gradeLevel'] =
        this.editStudentForm.get('studentGrade').value;
    }

    if (
      this.editStudentForm.get('studentDisplayName').value &&
      this.editStudentForm.get('studentDisplayName').dirty
    ) {
      updatedStudent['displayName'] =
        this.editStudentForm.get('studentDisplayName').value;
    }

    await this.programService.updateK12Student(updatedStudent).then(() => {
      this.editStudentForm.reset();
      this.modalRef.hide();
    });
  }
}
